import { Delete, Get, Patch, Post, Put } from 'api/index'
import { createAsyncAction } from 'redux-promise-middleware-actions'

export const GET_ROADMAP = 'GET_ROADMAP'
export const LIST_ROADMAP = 'LIST_ROADMAP'
export const CREATE_ROADMAP = 'CREATE_ROADMAP'
export const UPDATE_ROADMAP = 'UPDATE_ROADMAP'
export const DELETE_ROADMAP = 'DELETE_ROADMAP'

export const getRoadmap = createAsyncAction(GET_ROADMAP, async (id: number) => {
  const url = `/api/roadmap/${id}/`
  const data = await Get(url)
  return data
})

export const listRoadmap = createAsyncAction(LIST_ROADMAP, async () => {
  const url = `/api/roadmap/`
  const data = await Get(url)
  return data
})

export const createRoadmap = createAsyncAction(CREATE_ROADMAP, async body => {
  const url = `/api/roadmap/`
  const data = await Post(url, body)
  return data
})

export const updateRoadmap = createAsyncAction(UPDATE_ROADMAP, async (id, body) => {
  const url = `/api/roadmap/${id}/`
  const data = await Put(url, body)
  return data
})

export const partialUpdateRoadmap = createAsyncAction(UPDATE_ROADMAP, async (id, body) => {
  const url = `/api/roadmap/${id}/`
  const data = await Patch(url, body)
  return data
})

export const deleteRoadmap = createAsyncAction(DELETE_ROADMAP, async (id: number) => {
  const url = `/api/roadmap/${id}/`
  const data = await Delete(url)
  return data
})

import React, { Component } from 'react'
import moment from 'moment'
import styled from 'styled-components/macro'
import { TFunction, withTranslation } from 'react-i18next'
import { Title } from 'components/blocks'
import { connect } from 'react-redux'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { resetErrorMessage } from 'store/actions/error'
import { LogicBase } from 'components/logic'
import DateSelector from 'components/notification/DateSelector'
import timeLineFromDatesAndOrganization, {
  isYTDSelected,
  ISO_FORMAT_DATE
} from 'util/dates'
import { getRawDataTimespan } from 'store/actions/rawdata'
import { getOrganizations } from 'store/actions/organization'
import { RootState } from 'store/reducers'
import { findOrg } from 'util/organization'

const Container = styled.div`
  position: relative;
`
const TitleArea = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    h1 {
      margin: 0;
    }
  }
  h1 {
    margin-right: 100px;
  }
  > div {
    margin-top: 1.25rem;
    @media screen and (max-width: 768px) {
      margin-top: 5px;
      margin-right: 60px;
    }
  }
`
const DateSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  > span {
    color: #b9c5c8;
    font-family: 'Open Sans';
    font-size: 0.813rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.125rem;
    margin-bottom: 5px;
  }
`

type LogicReduxState = ReturnType<typeof mapStateToProps>
type LogicReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface LogicProps {
  t: TFunction
}

interface LogicState {
  startDate: string | null
  endDate: string | null
  dates: { [key: string]: Array<string> }
}

class Logic extends Component<
  LogicProps & LogicReduxState & LogicReduxDispatch,
  LogicState
> {
  constructor(props: LogicProps & LogicReduxState & LogicReduxDispatch) {
    super(props)
    this.state = {
      startDate: null,
      endDate: null,
      dates: {}
    }
  }

  componentDidMount = async () => {
    const {
      getRawDataTimespan: getRawDataTimespanAction,
      getOrganizations: getOrganizationsAction
    } = this.props
    await getRawDataTimespanAction(false)
    await getOrganizationsAction()
    this.checkNullTimeline()
  }

  checkNullTimeline = () => {
    const { activeOrganization, data } = this.props
    const { startDate, endDate } = this.state
    if (
      activeOrganization &&
      data.length &&
      startDate === null &&
      endDate === null
    ) {
      const org = findOrg(activeOrganization, data)
      if (org) {
        const { start_of_fiscal_year } = org
        const fiscalYear = parseInt(start_of_fiscal_year, 10)
        if (fiscalYear < moment().month()) {
          this.updateList({
            [`${moment().year()} - ${moment().year() + 1}`]: []
          })
        } else {
          this.updateList({
            [`${moment().year() - 1} - ${moment().year()}`]: []
          })
        }
      }
    }
  }

  updateList = (dates: LogicState['dates']) => {
    const { data, activeOrganization } = this.props
    if (dates === null) {
      const org = findOrg(activeOrganization, data)
      if (org) {
        const { start_of_fiscal_year } = org
        const fiscalYear = parseInt(start_of_fiscal_year, 10)
        if (fiscalYear < moment().month()) {
          this.updateList({
            [`${moment().year()} - ${moment().year() + 1}`]: []
          })
        } else {
          this.updateList({
            [`${moment().year() - 1} - ${moment().year()}`]: []
          })
        }
      }
      return
    }
    const { start, stop } = timeLineFromDatesAndOrganization(
      dates,
      data,
      activeOrganization
    )
    this.setState({
      startDate: start.format(ISO_FORMAT_DATE),
      endDate: stop.endOf('month').format(ISO_FORMAT_DATE),
      dates
    })
  }

  render() {
    const { t, activeOrganization, data, timespan } = this.props
    const { startDate, endDate, dates } = this.state
    const isYTD: boolean =
      startDate && endDate && activeOrganization
        ? isYTDSelected([startDate, endDate], {
            activeOrganization,
            data
          }) &&
          (Object.keys(dates).length === 0 ||
            (Object.values(dates)[0] && Object.values(dates)[0].length === 0))
        : false
    return (
      <Container>
        <TitleArea>
          <Title>{t('business_logic')}</Title>
          <DateSelectorContainer>
            <span>
              {t('selected')}:{' '}
              {isYTD ? (
                'YTD '
              ) : (
                <>
                  {moment(startDate).format('DD.MM.YYYY')} -{' '}
                  {moment(endDate).format('DD.MM.YYYY')}{' '}
                </>
              )}
              {isYTD ? `(${t('ytd-disclaimer')})` : ''}
            </span>
            {activeOrganization && data.length && (
              <DateSelector
                location="logic"
                onDateChange={this.updateList}
                timeSpan={{
                  start_date: timespan.start_date,
                  end_date: timespan.end_date
                }}
                useCurrentYearAsYtd
                ytdEmpty
                rangeSelect
              />
            )}
          </DateSelectorContainer>
        </TitleArea>
        <LogicBase
          dateTimeline={startDate && endDate ? [startDate, endDate] : []}
          isYTD={isYTD}
        />
      </Container>
    )
  }
}

const mapStateToProps = ({
  error,
  organization: { activeOrganization, data },
  rawData: { timespan }
}: RootState) => ({
  error,
  activeOrganization,
  data,
  timespan
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      resetErrorMessage,
      getRawDataTimespan,
      getOrganizations
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Logic))

import { applyMiddleware, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import promise from 'redux-promise-middleware'
import { composeWithDevTools } from 'redux-devtools-extension'

import rootReducer, { RootState } from './reducers/index'
import errorMiddleware from './middleware/promiseError'
import { useDispatch } from 'react-redux'

export const initStore = (preloadedState?: Partial<RootState>) => {
  const middlewares = [thunkMiddleware, errorMiddleware, promise]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer]
  const composedEnhancers = composeWithDevTools(...enhancers)
  const store = createStore(
    rootReducer,
    preloadedState,
    composedEnhancers
  )

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
  }

  return store
}

export default initStore

type Store = ReturnType<typeof initStore>
export type AppDispatch = Store['dispatch']
export const useAppDispatch = () => useDispatch<AppDispatch>()

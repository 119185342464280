import React, { Component } from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Card, Title } from 'components/blocks'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { convertToRaw, EditorState } from 'draft-js'
import { updateReportDataMap, updateReportGraph } from 'store/actions/report'
import { connect } from 'react-redux'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import toHtml from 'draftjs-to-html'
import ApiError from 'ApiError'
import { toast } from 'react-toastify'
import { convertFromHTML } from 'draft-convert'
import { ReportDataMap, ReportGraph } from 'store/reducers/report'

const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #33333366;
  visibility: ${(props: { isOpen: boolean }) =>
    props.isOpen ? 'visible' : 'hidden'};
  pointer-events: ${props => (props.isOpen ? 'all' : 'none')};
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    overflow-y: auto;
    position: relative;
    box-shadow: none;
    width: 100%;
    max-width: 600px;
    max-height: 100vh;
    padding: 0.938rem;
    opacity: ${props => (props.isOpen ? '1' : '0')};
    transform: translateY(${props => (props.isOpen ? '0' : '-50px')});
    transition: transform, opacity 0.3s ease-in-out;
    > h1 {
      margin-top: 0;
      margin-bottom: 1rem;
    }
    .editor-wrapper {
      border: 1px solid #ddd;
      min-height: 300px;
      padding: 0.625rem;
      margin-bottom: 0.938rem;
    }
  }
`
const CloseButton = styled.span`
  position: absolute;
  right: 0.938rem;
  top: 0.938rem;
  font-size: 1.875rem;
  cursor: pointer;
`
const Button = styled.div`
  width: 100%;
  cursor: pointer;
  border-radius: 3px;
  background: #b9c5c8;
  box-shadow: 2px 2px 0 0 #93a4a8;
  padding: 0.938rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.875rem;
  color: white;
  user-select: none;
  margin-top: 0.938rem;
`

type EditReportItemContentModalReduxDispatch = ReturnType<
  typeof mapDispatchToProps
>

interface EditReportItemContentModalProps {
  isOpen: boolean
  t: TFunction
  item: ReportGraph | ReportDataMap | null
  onClose: (arg?: boolean) => void
}

class EditReportItemContentModal extends Component<
  EditReportItemContentModalProps & EditReportItemContentModalReduxDispatch,
  { editorState: EditorState }
> {
  constructor(
    props: EditReportItemContentModalProps &
      EditReportItemContentModalReduxDispatch
  ) {
    super(props)
    this.state = {
      editorState: EditorState.createEmpty()
    }
  }

  componentDidUpdate(
    prevProps: EditReportItemContentModalProps &
      EditReportItemContentModalReduxDispatch
  ) {
    const { item } = this.props
    if (item !== null && prevProps.item === null) {
      this.updateContent(item)
    }
  }

  updateContent = (item: ReportGraph | ReportDataMap) => {
    const { content } = item
    if (!content) {
      this.setState({ editorState: EditorState.createEmpty() })
      return
    }
    const blocksFromHTML = convertFromHTML(content)

    this.setState({
      editorState: EditorState.createWithContent(blocksFromHTML)
    })
  }

  onEditorStateChange = (state: EditorState) => {
    this.setState({ editorState: state })
  }

  saveReportGraph = async () => {
    const { editorState } = this.state
    const {
      t,
      onClose,
      updateReportGraph: updateReportGraphAction,
      updateReportDataMap: updateReportDataMapAction,
      item
    } = this.props
    if (!item) {
      return
    }
    const updateFunction =
      'mapping' in item && item.mapping
        ? updateReportGraphAction
        : updateReportDataMapAction
    const raw = toHtml(convertToRaw(editorState.getCurrentContent()))
    const data = await updateFunction(
      item.id,
      JSON.stringify({
        content: raw
      })
    )
    if (data instanceof ApiError && data.messages) {
      const messages = Object.keys(data.messages).map(
        m => `${t(m)}: ${data.messages ? data.messages[m][0] : ''}\n`
      )
      messages.forEach(m => toast.error(m))
      return
    }
    const successMsg =
      'mapping' in item && item.mapping
        ? t('report-graph-updated')
        : t('report-data-map-updated')
    toast.success(successMsg)
    onClose(true)
  }

  render() {
    const { editorState } = this.state
    const { isOpen, t, onClose } = this.props
    return (
      <ModalContainer isOpen={isOpen}>
        <Card>
          <CloseButton onClick={() => onClose()}>&times;</CloseButton>
          <Title>{t('edit-report-graph')}</Title>
          <Editor
            editorState={editorState}
            editorClassName="editor-wrapper"
            onEditorStateChange={this.onEditorStateChange}
            editorStyle={{
              maxHeight: 200
            }}
            toolbar={{
              options: [
                'inline',
                'blockType',
                'fontSize',
                'list',
                'textAlign',
                'link',
                'history'
              ]
            }}
          />
          <Button onClick={this.saveReportGraph}>{t('Save')}</Button>
        </Card>
      </ModalContainer>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      updateReportGraph,
      updateReportDataMap
    },
    dispatch
  )

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(EditReportItemContentModal))

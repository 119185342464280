import React from 'react'
import { OrganizationUser } from 'store/reducers/organization'
import styled from 'styled-components'

const AvatarContainer = styled.div`
  position: absolute;
  border: 3px solid #fff;
  border-radius: 100%;
  background: ${(props: { src: string | null }) =>
    props.src ? `url(${props.src})` : '#f3f3f3'};
  background-size: cover;
  width: 46px;
  height: 46px;
  top: -1.438rem;
  left: calc(50% - 1.438rem);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #888;
  user-select: none;
  text-transform: uppercase;
  font-family: 'Open Sans';
  font-size: 1rem;
  font-weight: bold;
`

const Avatar = ({ user }: { user: OrganizationUser }) => {
  const [src, setSrc] = React.useState<string | null>(null)
  React.useEffect(() => {
    if (user && user.avatar) {
      fetch(user.avatar).then(res => {
        if (res.status === 200 && user.avatar) {
          setSrc(user.avatar)
        }
      })
    }
  }, [user])
  return (
    <AvatarContainer src={src}>
      {user && !src ? <span>{user.username[0]}</span> : null}
    </AvatarContainer>
  )
}

export default Avatar

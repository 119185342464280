import { AppDispatch } from "store/store"

export const SET_MENU_STATE = 'SET_MENU_STATE'
export const SAVE_TREE_STATE = 'SAVE_TREE_STATE'
export const SAVE_ACTIVE_THEME = 'SAVE_ACTIVE_THEME'

export const setMenuState = (state: string) => (dispatch: AppDispatch) =>
  dispatch({
    type: SET_MENU_STATE,
    payload: state
  })

export const saveTreeState = (state: {
  translate: { x?: number, y?: number}
  scale: number
  data: unknown
} | null) => (dispatch: AppDispatch) =>
  dispatch({
    type: SAVE_TREE_STATE,
    payload: state
  })

export const saveActiveTheme = (state: string) => (dispatch: AppDispatch) =>
  dispatch({
    type: SAVE_ACTIVE_THEME,
    payload: state
  })

import React, { Component, MouseEvent } from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Popup from 'reactjs-popup'
import Select from 'react-select'
import chroma from 'chroma-js'
import Icon from 'components/Icon'
import {
  createMaintask,
  updateMaintask,
  deleteMaintask
} from 'store/actions/maintask'
import { triggerRefresh } from 'store/actions/task'
import RemoveMainTaskModal from 'components/modals/RemoveMainTaskModal'
import { ColorOption } from 'components/roadmap/MainTask'
import { MainTaskType } from 'store/reducers/maintask'
import { ColorPair } from 'store/reducers/colorpair'
import { RoadmapType } from 'store/reducers/roadmap'
import { AppDispatch } from 'store/store'

type MainTaskModalReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface MainTaskModalProps {
  mainTask?: MainTaskType
  colorOptions: ColorOption[]
  colorpairs: ColorPair[]
  roadmaps: RoadmapType[]
  roadmap: RoadmapType
  iconStyle: React.CSSProperties
  iconName: string
  mainTasksCount: number
  t: TFunction
}

interface MainTaskState {
  id: number | null
  title: string
  color_pair: string | number
  row_count: number
  link_to_roadmap: Array<number>
  index: number
}

class MainTaskModal extends Component<
  MainTaskModalProps & MainTaskModalReduxDispatch,
  MainTaskState
> {
  constructor(props: MainTaskModalProps & MainTaskModalReduxDispatch) {
    super(props)
    const { mainTask, mainTasksCount } = this.props
    this.state = {
      id: mainTask ? mainTask.id : null,
      title: mainTask ? mainTask.title : '',
      color_pair: mainTask ? mainTask.color_pair : '',
      row_count: mainTask ? mainTask.row_count : 1,
      link_to_roadmap: mainTask ? mainTask.link_to_roadmap : [],
      index: (mainTasksCount ?? 0) + 1
    }
  }

  deleteMainTask = async () => {
    const { mainTask, deleteMaintask: deleteMaintaskAction } = this.props
    const { index } = this.state
    if (!mainTask) {
      return
    }
    const newIndex = index > 0 ? index - 1 : 0
    this.setState({ index: newIndex })
    await deleteMaintaskAction(mainTask.id)
  }

  createOrUpdateMaintask = async (mainTask: MainTaskType) => {
    const {
      createMaintask: createMaintaskAction,
      updateMaintask: updateMaintaskAction,
      roadmap: roadmapModel,
      triggerRefresh: triggerRefreshAction
    } = this.props
    /* eslint-disable camelcase */
    const {
      id,
      title,
      color_pair,
      row_count,
      link_to_roadmap,
      index
    } = this.state
    const roadmapId = roadmapModel ? roadmapModel.id : ''
    const data = JSON.stringify({
      id,
      title,
      roadmap: mainTask ? mainTask.roadmap : roadmapId,
      color_pair,
      row_count,
      link_to_roadmap,
      index
    })
    /* eslint-enable camelcase */
    if (!mainTask) {
      await createMaintaskAction(data)
    } else if (id) {
      await updateMaintaskAction(data, id)
    }
    this.setState({ index: index + 1 })
    triggerRefreshAction(true)
  }

  handleChange = (value: Array<{ value: number }> | { value: string }) => {
    if (value && Array.isArray(value) && value.length > 0) {
      const link_to_roadmap: MainTaskType['link_to_roadmap'] = []
      value.forEach(v => {
        link_to_roadmap.push(v.value)
      })
      this.setState({ link_to_roadmap })
    } else if (!Array.isArray(value)) {
      const color_pair = value.value
      this.setState({ color_pair })
    }
  }

  render() {
    const {
      mainTask,
      colorOptions,
      colorpairs,
      roadmaps,
      iconStyle,
      iconName,
      t
    } = this.props
    const { title } = this.state
    const modalTitle = !mainTask ? t('Add new maintask') : t('Edit maintask')
    const dot = (color = '#ccc') => ({
      alignItems: 'center',
      display: 'flex',

      ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'inline-block',
        marginRight: 8,
        height: 10,
        width: 10
      }
    })

    const roadmapOptions: { value: number; label: string }[] = []
    const defaultRoadmapOption: { value: number; label: string }[] = []
    roadmaps.forEach(r => {
      const option = { value: r.id, label: r.title }
      if (mainTask && mainTask.roadmap !== r.id) roadmapOptions.push(option)
      if (mainTask && mainTask.link_to_roadmap.includes(r.id))
        defaultRoadmapOption.push(option)
    })

    const colourStyles = {
      control: (styles: React.CSSProperties) => ({
        ...styles,
        backgroundColor: 'white'
      }),
      option: (
        styles: React.CSSProperties & { ':active': React.CSSProperties },
        {
          data,
          isDisabled,
          isFocused,
          isSelected
        }: {
          data: ColorOption
          isDisabled: boolean
          isFocused: boolean
          isSelected: boolean
        }
      ) => {
        const color = chroma(data.color)
        /* eslint-disable no-nested-ternary */
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? data.color
            : isFocused
            ? color.alpha(0.1).css()
            : null,
          color: isDisabled
            ? '#ccc'
            : isSelected
            ? chroma.contrast(color, 'white') > 2
              ? 'white'
              : 'black'
            : data.color,
          cursor: isDisabled ? 'not-allowed' : 'default',

          ':active': {
            ...styles[':active'],
            backgroundColor:
              !isDisabled && (isSelected ? data.color : color.alpha(0.3).css())
          }
        }
        /* eslint-enable no-nested-ternary */
      },
      menu: (styles: React.CSSProperties) => ({
        ...styles,
        backgroundColor: '#333'
      }),
      input: (styles: React.CSSProperties) => ({ ...styles, ...dot() }),
      placeholder: (styles: React.CSSProperties) => ({ ...styles, ...dot() }),
      singleValue: (
        styles: React.CSSProperties,
        { data }: { data: ColorOption }
      ) => {
        if (!data.color) {
          const option = colorOptions.find(o => o.value === data.value)
          if (option) {
            return { ...styles, ...dot(option.color) }
          }
        }
        return { ...styles, ...dot(data.color) }
      }
    }
    const colorPair =
      mainTask && colorpairs[mainTask.color_pair]
        ? colorpairs[mainTask.color_pair]
        : ''
    const defaultColorPairOption = {
      value: colorPair ? colorPair.id : '',
      label: colorPair ? colorPair.title : ''
    }
    return (
      <>
        <Popup
          trigger={
            <Icon
              style={iconStyle}
              color="#243C72"
              iconName={iconName}
              size={15}
              onMouseDown={() => {
                if (!mainTask) {
                  this.setState({
                    id: null,
                    title: '',
                    color_pair: '',
                    row_count: 1,
                    link_to_roadmap: []
                  })
                }
              }}
            />
          }
          position="top center"
          modal
          nested
        >
          {(close: {
            (): void
            (event: MouseEvent<HTMLButtonElement, MouseEvent>): void
          }) => (
            <div className="modal">
              <button type="button" className="close" onClick={close}>
                &times;
              </button>
              <div className="header"> {modalTitle} </div>
              <div className="content">
                <label htmlFor="maintask-title">{t('Maintask name')}</label>
                <input
                  id="maintask-title"
                  placeholder={t('Type maintask title here')}
                  value={title}
                  onChange={({ target: { value } }) =>
                    this.setState({ title: value })
                  }
                />
                <div className="column-50">
                  <div className="column-content first">
                    <label htmlFor="maintask-linked-roadmap">
                      {t('Linked roadmaps')}
                    </label>
                    <Select
                      isMulti
                      id="maintask-linked-roadmap"
                      options={roadmapOptions}
                      placeholder={t('Select')}
                      defaultValue={defaultRoadmapOption}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="column-50">
                  <div className="column-content last">
                    <label htmlFor="maintask-color">{t('Select color')}</label>
                    <Select
                      id="maintask-color"
                      options={colorOptions}
                      styles={colourStyles}
                      placeholder={t('Select')}
                      defaultValue={defaultColorPairOption}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="actions">
                <RemoveMainTaskModal mainTask={mainTask as MainTaskType} />

                <button
                  type="button"
                  className="button close"
                  onClick={() => {
                    close()
                  }}
                >
                  {t('Cancel')}
                </button>

                <button
                  type="button"
                  className="button"
                  onClick={() => {
                    this.createOrUpdateMaintask(mainTask as MainTaskType)
                    close()
                  }}
                >
                  {t('Save')}
                </button>
              </div>
            </div>
          )}
        </Popup>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      createMaintask,
      updateMaintask,
      deleteMaintask,
      triggerRefresh
    },
    dispatch
  )

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(MainTaskModal))

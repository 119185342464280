import {
  LOGIN,
  LOGOUT,
  USER_READ,
  PASSWORD_RESET,
  PASSWORD_CONFIRM,
  PASSWORD_CONFIRM_RESET,
  SET_NOTIFICATIONS_READ,
  PASSWORD_CHANGE
} from 'store/actions/auth'

import produce from 'immer'
import { RESET_STATE } from 'store/actions/reset'
import { AnyAction } from 'redux'
import ApiError from 'ApiError'

const API_TOKEN = sessionStorage.getItem('API_TOKEN')

export type User = {
  id: number
  name: string
  username: string
  email: string
  language: string
  notification_manager: boolean
  roadmap_manager: boolean
  status: string
  active_organization: number
  unread_notifications: boolean
  report_manager: boolean
  avatar?: string
}

interface AuthState {
  token: string | null
  isPending: boolean
  error: ApiError | Array<ApiError>
  tokenExpired: boolean
  loggedIn: boolean
  user: User | null
  passwordResetRedirect: boolean
  passwordResetMessage: string | null
  passwordResetSend: boolean
  passwordResetError: string | null
  notificationsRead: boolean
}

const initialState: AuthState = {
  token: API_TOKEN,
  isPending: false,
  error: [],
  tokenExpired: false,
  loggedIn: !!API_TOKEN,
  user: null,
  passwordResetRedirect: false,
  passwordResetMessage: null,
  passwordResetSend: false,
  passwordResetError: null,
  notificationsRead: false
}

const auth = (state = initialState, action: AnyAction) => {
  const { type, error, payload } = action

  if (error && payload.messages.code === 'token_expired') {
    return produce(state, draft => {
      draft.tokenExpired = true
      draft.isPending = false
      draft.error = payload.response
      draft.loggedIn = false
    })
  }

  switch (type) {
    case `${LOGIN}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${LOGIN}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
        draft.loggedIn = false
      })

    case `${LOGIN}_FULFILLED`:
      sessionStorage.setItem('API_TOKEN', payload.key)
      return produce(state, draft => {
        draft.token = payload.key
        draft.tokenExpired = false
        draft.isPending = false
        draft.loggedIn = true
      })

    case `${USER_READ}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
        draft.user = null
      })

    case `${USER_READ}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
        draft.user = null
      })

    case `${USER_READ}_FULFILLED`:
      return produce(state, draft => {
        draft.user = payload
        draft.isPending = false
      })
    case `${LOGOUT}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
      })

    case `${LOGOUT}_FULFILLED`:
    case `${LOGOUT}_REJECTED`:
      sessionStorage.removeItem('API_TOKEN')
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
        draft.user = null
        draft.loggedIn = false
        draft.token = null
      })

    case `${PASSWORD_RESET}_PENDING`:
      return produce(state, draft => {
        draft.passwordResetMessage = null
        draft.passwordResetError = null
        draft.passwordResetSend = false
        draft.error = []
        draft.isPending = true
      })

    case `${PASSWORD_RESET}_REJECTED`:
      return produce(state, draft => {
        draft.passwordResetError = payload
        draft.error = payload
        draft.isPending = false
        draft.passwordResetSend = false
      })

    case `${PASSWORD_RESET}_FULFILLED`:
      return produce(state, draft => {
        draft.passwordResetMessage = payload.detail
        draft.isPending = false
        draft.passwordResetSend = true
      })

    case `${PASSWORD_CONFIRM}_PENDING`:
      return produce(state, draft => {
        draft.passwordResetRedirect = false
        draft.passwordResetMessage = null
        draft.error = []
        draft.isPending = true
      })

    case `${PASSWORD_CONFIRM}_REJECTED`:
      return produce(state, draft => {
        draft.error = payload
        draft.isPending = false
      })

    case `${PASSWORD_CONFIRM}_FULFILLED`:
      return produce(state, draft => {
        draft.passwordResetRedirect = true
        draft.passwordResetMessage = payload.detail
        draft.isPending = false
      })

    case PASSWORD_CONFIRM_RESET:
      return produce(state, draft => {
        draft.passwordResetRedirect = false
        draft.passwordResetMessage = null
        draft.error = []
      })

    case SET_NOTIFICATIONS_READ:
      return produce(state, draft => {
        draft.notificationsRead = true
      })

    case `${PASSWORD_CHANGE}_PENDING`:
      return produce(state, draft => {
        draft.passwordResetRedirect = false
        draft.passwordResetMessage = null
        draft.error = []
        draft.isPending = true
      })

    case `${PASSWORD_CHANGE}_REJECTED`:
      return produce(state, draft => {
        draft.error = payload
        draft.isPending = false
      })

    case `${PASSWORD_CHANGE}_FULFILLED`:
      return produce(state, draft => {
        draft.passwordResetRedirect = true
        draft.passwordResetMessage = payload.detail
        draft.isPending = false
      })

    case RESET_STATE:
      return initialState

    default:
      return state
  }
}

export default auth

/* const typeReducer = typeToReducer(
  {
    [LOGIN]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),

      REJECTED: (state, { payload: { response } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = response
        }),
      FULFILLED: (state, action) => {
        const {
          payload: { key }
        } = action
        sessionStorage.setItem('API_TOKEN', key)
        return produce(state, draft => {
          draft.token = key
          draft.isPending = false
        })
      }
    }
  },
  initialState
) */
/* 
const foo = (storestate = initialState, action) => {
  const { type, error, payload } = action

  const typeReducer = typeToReducer(
    {
      [LOGIN]: {
        PENDING: state =>
          produce(state, draft => {
            draft.isPending = true
          }),

        REJECTED: (state, { payload: { response } }) =>
          produce(state, draft => {
            draft.isPending = false
            draft.error = response
          }),
        FULFILLED: (state, action) => {
          const {
            payload: { key }
          } = action
          sessionStorage.setItem('API_TOKEN', key)
          return produce(state, draft => {
            draft.token = key
            draft.isPending = false
            draft.tokenExpired = false
          })
        }
      }
    },
    initialState
  )

  console.log(typeReducer)
  return typeReducer
}

export default foo */

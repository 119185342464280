import React, { Component } from 'react'

interface MonthBoxProps {
  value: React.ReactNode
  onClick: (e: React.MouseEvent) => void
}

class MonthBox extends Component<MonthBoxProps, { value: React.ReactNode }> {
  constructor(props: MonthBoxProps) {
    super(props)
    const { value } = this.props
    this.state = {
      value: value || 'N/A'
    }
  }

  static getDerivedStateFromProps(props: MonthBoxProps) {
    const { value } = props
    return {
      value: value || 'N/A'
    }
  }

  _handleClick = (e: React.MouseEvent) => {
    const { onClick } = this.props
    if (onClick) return onClick(e)
    return ''
  }

  render() {
    const { value } = this.state

    return (
      <div className="box" onClick={this._handleClick}>
        <label>{value}</label>
      </div>
    )
  }
}

export default MonthBox

import React, { Component } from 'react'
import moment from 'moment'
import { Card } from 'components/blocks'
import styled from 'styled-components'
import { TFunction, withTranslation } from 'react-i18next'
import xss from 'xss'
import { SystemMessage } from 'store/reducers/systemMessage'

export const PRESENTATION_DATE_FORMAT = 'DD/MM/YYYY'

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    width: 100%;
    margin-bottom: 1.25rem;
    max-width: 1400px;
    flex-direction: column;
    @media screen and (min-width: 768px) {
      flex-direction: row;
      &:nth-of-type(odd) {
        margin-right: 1.25rem;
      }
    }
  }
`
const CardContent = styled.div`
  position: relative;
  padding: 1.25rem;
  flex-grow: 1;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  word-break: break-word;
  > div {
    overflow: hidden;
    min-height: 100px;
    margin-bottom: 1.25rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    font-family: 'Open Sans';
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 100;
    letter-spacing: 0;
  }
  h4 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.813rem;
  }
  span.date {
    color: #b9c5c8;
    font-family: 'Open Sans';
    font-size: 0.813rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.125rem;
  }
`
const NewIcon = styled.span`
  margin-left: 0.938rem;
  padding: 2px 0.938rem;
  background: #ff4545;
  color: white !important;
  border-radius: 0.875rem;
`
const NoSystemMessage = styled.h3`
  margin: 0;
  font-size: 1.25rem;
  font-weight: 300;
  color: #4a4a4a;
  letter-spacing: 0;
  line-height: 1.813rem;
`

interface SystemMessageListProps {
  data: Array<SystemMessage>
  t: TFunction
}

class SystemMessageList extends Component<SystemMessageListProps> {
  constructor(props: SystemMessageListProps) {
    super(props)
    this.state = {
      expanded: {}
    }
  }

  getContentHeight = (id: string | number) => {
    const el = document.querySelector(`#content_${id}`)
    if (el) {
      return el.scrollHeight
    }

    return 0
  }

  render() {
    const { data, t } = this.props
    if (data.length === 0) {
      return <NoSystemMessage>{t('noSystemMessages')}</NoSystemMessage>
    }
    return (
      <>
        <CardContainer>
          {data.map(d => (
            <Card key={d.id}>
              <CardContent>
                <h4>
                  {d.title}
                  {d.read ? null : <NewIcon>{t('new')}</NewIcon>}
                </h4>
                <span className="date">
                  {moment(d.published).format(PRESENTATION_DATE_FORMAT)}
                </span>
                <div
                  id={`content_${d.id}`}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: xss(d.text_value)
                  }}
                />
              </CardContent>
            </Card>
          ))}
        </CardContainer>
      </>
    )
  }
}

export default withTranslation()(SystemMessageList)

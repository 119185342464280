import { LIST_COLORPAIR } from 'store/actions/colorpair'

import produce from 'immer'
import { AnyAction } from 'redux'

export type ColorPair = {
  id: number
  title: string
  color: string
  dark_color: string
}

interface ColorPairState {
  data: ColorPair | null;
  created: ColorPair | null;
  list: {
    results: Array<ColorPair>
  };
  isPending: boolean;
  error: Array<Error> | Error | null
}

const initialState: ColorPairState = {
  data: null,
  created: null,
  list: { results: [] },
  isPending: false,
  error: null
}

const colorpair = (state = initialState, action: AnyAction) => {
  const { type, payload } = action

  switch (type) {
    case `${LIST_COLORPAIR}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${LIST_COLORPAIR}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${LIST_COLORPAIR}_FULFILLED`:
      return produce(state, draft => {
        draft.list = payload
        draft.isPending = false
      })

    default:
      return state
  }
}

export default colorpair

import {
  CREATE_NOTIFICATION,
  GET_NOTIFICATION,
  LIST_NOTIFICATION
} from 'store/actions/notification'

import produce from 'immer'
import { RESET_STATE } from 'store/actions/reset'
import { AnyAction } from 'redux'

export type Notification = {
  id: number
  title: string
  text_value: string
  published: string
  files: Array<{ notification_file: string; id: number; file_title: string }>
  read: boolean
  send_notifications: boolean
  notification_recipients: Array<number>
}

interface NotificationState {
  data: Notification | null
  created: Notification | null
  list: {
    results: Array<Notification>
  }
  isPending: boolean
  error: Error | Array<Error> | null
}

const initialState: NotificationState = {
  data: null,
  created: null,
  list: { results: [] },
  isPending: false,
  error: null
}

const notification = (state = initialState, action: AnyAction) => {
  const { type, payload } = action

  switch (type) {
    case `${GET_NOTIFICATION}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${GET_NOTIFICATION}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${GET_NOTIFICATION}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.data = payload
      })

    case `${LIST_NOTIFICATION}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${LIST_NOTIFICATION}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${LIST_NOTIFICATION}_FULFILLED`:
      return produce(state, draft => {
        draft.list = payload
        draft.isPending = false
      })

    case `${CREATE_NOTIFICATION}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${CREATE_NOTIFICATION}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${CREATE_NOTIFICATION}_FULFILLED`:
      return produce(state, draft => {
        draft.created = payload
        draft.isPending = false
      })

    case RESET_STATE:
      return initialState

    default:
      return state
  }
}

export default notification

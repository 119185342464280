import { AnyAction } from 'redux'
import { logout } from 'store/actions/auth'
import { resetState } from 'store/actions/reset'

const isPromise = (p: Promise<unknown>) =>
  typeof p === 'object' && p !== null && typeof p.then === 'function'

export default function errorMiddleware({
  dispatch
}: {
  dispatch: (action: AnyAction) => Promise<unknown>
}) {
  return (next: (action: AnyAction) => Promise<unknown> | AnyAction) => (action: AnyAction) => {
    const { payload } = action
    // If not a promise, continue on
    if (!isPromise(payload)) {
      return next(action)
    }

    // Dispatch initial pending promise, but catch any errors
    return (next(action) as Promise<unknown>).catch((err: { status: number }) => {
      if (err.status === 401) {
        dispatch(logout())
        dispatch(resetState())
      }
      return err
    })
  }
}

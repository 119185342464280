import React, { Component, MouseEvent } from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Popup from 'reactjs-popup'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { deleteMaintask } from 'store/actions/maintask'
import { triggerRefresh } from 'store/actions/task'
import { MainTaskType } from 'store/reducers/maintask'

type RemoveMainTaskModalReduxState = ReturnType<typeof mapDispatchToProps>

interface RemoveMainTaskModalProps {
  t: TFunction,
  mainTask: MainTaskType
}

class RemoveMainTaskModal extends Component<RemoveMainTaskModalProps & RemoveMainTaskModalReduxState> {
  deleteMainTask = async (close: () => void) => {
    const {
      deleteMaintask: deleteMaintaskAction,
      triggerRefresh: triggerRefreshAction,
      mainTask: { id }
    } = this.props
    await deleteMaintaskAction(id)
    triggerRefreshAction(true)
    close()
  }

  render() {
    const { t } = this.props
    return (
      <Popup
        position="top center"
        modal
        nested
        trigger={
          <button type="button" className="button delete">
            {t('Delete maintask')}
          </button>
        }
      >
        {(close: { (): void; (event: MouseEvent<HTMLButtonElement,MouseEvent>): void }) => (
          <div className="modal">
            <button type="button" className="close" onClick={close}>
              &times;
            </button>
            <div className="header">{t('remove-maintask')} </div>
            <div className="content">
              <p>{t('delete-maintask-disclaimer')}</p>
            </div>
            <div className="actions">
              <button
                type="button"
                className="button close"
                onClick={() => {
                  close()
                }}
              >
                {t('Cancel')}
              </button>

              <button
                type="button"
                className="button"
                onClick={() => this.deleteMainTask(close)}
              >
                {t('remove-maintask')}
              </button>
            </div>
          </div>
        )}
      </Popup>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      deleteMaintask,
      triggerRefresh
    },
    dispatch
  )

export default withTranslation()(
  connect(null, mapDispatchToProps)(RemoveMainTaskModal)
)

import React, { Component } from 'react'
import { RootState } from 'store/reducers'
import styled from 'styled-components/macro'

const getBackgroundColor = (percent: number, flat = false) => {
  if (!flat) {
    let backgroundColor = 'linear-gradient(315deg, #FE9898 0%, #FD6A6A 99.99%);'
    if (percent >= 50) {
      backgroundColor =
        'linear-gradient(274.18deg, #FFF8D0 0.03%, #FDCD1A 100%);'
    }
    if (percent >= 90) {
      backgroundColor = 'linear-gradient(225deg, #BEFF62 0%, #8CDC5F 100%);'
    }
    return backgroundColor
  }

  let backgroundColor = '#FD6A6A;'
  if (percent >= 50) {
    backgroundColor = '#FDCD1A;'
  }
  if (percent >= 90) {
    backgroundColor = '#8CDC5F;'
  }
  return backgroundColor
}

const TargetMeterContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0.813rem;
  border-radius: 100px;
  background: ${(props: { redContainerBackground: boolean }) =>  props.redContainerBackground ?'#FD6A6A': '#CFDBDE' };
  margin-top: 5px;
`
const TargetAccomplished = styled.div`
  position: absolute;
  width: ${(props: { percent: number; colorPercent: number }) =>
    Math.min(100, props.percent)}%;
  background: ${props => getBackgroundColor(props.colorPercent)};
  height: 0.813rem;
  border-radius: ${props =>
    props.percent >= 100 ? '100px' : '100px 0 0 100px'};
  top: 0;
  left: 0;
`
const TargetOverlay = styled.div`
  position: absolute;
  left: ${(props: { percent: number }) => Math.min(100, props.percent)}%;
  height: 1rem;
  border-left: 3px solid #777;
  top: -1.5px;
`

export default class TargetMeter extends Component<{
  target: RootState['target']['data']
}> {
  render() {
    const { target } = this.props
    if (!target) {
      return null
    }

    const minimum = target.minimum_value
    const availableMaximum = target.compare_value || target.target_value

    const percentRange = availableMaximum - minimum
    const colorPercentRange = target.target_value - minimum

    const overallPercentStartValue = target.current_value - minimum
    const overlayPercentStartValue = target.target_value - minimum

    let percent = (overallPercentStartValue  / percentRange) * 100
    let targetOverlayPercent = target.compare_value ? (overlayPercentStartValue  / percentRange) * 100 : 0
    let colorPercent = (overallPercentStartValue / colorPercentRange) * 100

    if (target.reversed_target) {
      percent =  (percentRange / overallPercentStartValue ) * 100
      colorPercent = (colorPercentRange / overallPercentStartValue) * 100
      if (target.compare_value) { 
        targetOverlayPercent = (percentRange / overlayPercentStartValue) * 100
      }
    }
    const redContainerBackground = percent <= 0 && target.data_available_for_current_value ? true : false

    return (
      <TargetMeterContainer redContainerBackground={redContainerBackground}>
        <TargetAccomplished percent={percent} colorPercent={colorPercent} />
        {target.compare_value &&
        target.compare_value !== target.target_value ? (
          <TargetOverlay percent={targetOverlayPercent} />
        ) : null}
      </TargetMeterContainer>
    )
  }
}

import styled from 'styled-components/macro'

export default styled.div`
  background: ${(props: {
    theme: { strategy: { cardBackground: string } }
    noShadow?: boolean
  }) => props.theme.strategy.cardBackground};
  box-shadow: ${props =>
    props.noShadow ? 'none' : props.theme.strategy.cardShadow};
  border-radius: 8px;
`

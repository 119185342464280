import { useEffect, useState } from 'react'

export default () => {
  const [prefersDark, setPrefersDark] = useState<boolean>(
    window.matchMedia('(prefers-color-scheme: dark)')?.matches || false
  )

  useEffect(() => {
    const media = window.matchMedia('(prefers-color-scheme: dark)')
    if (media?.matches !== prefersDark) setPrefersDark(media?.matches || false)
    const listener = () => setPrefersDark(media.matches)
    media?.addEventListener('change', listener)
    return () => media?.removeEventListener('change', listener)
  }, [prefersDark])

  return prefersDark
}

import { Delete, Get, Patch, Post } from 'api/index'
import { createAsyncAction } from 'redux-promise-middleware-actions'
import { CreateReportDataMap, CreateUpdateReportGraph, Report } from 'store/reducers/report'

export const CREATE_REPORT_GRAPH = 'CREATE_REPORT_GRAPH'
export const LIST_REPORT_GRAPH = 'LIST_REPORT_GRAPH'
export const DELETE_REPORT_GRAPH = 'DELETE_REPORT_GRAPH'
export const RETRIEVE_REPORT_GRAPH = 'RETRIEVE_REPORT_GRAPH'
export const CREATE_REPORT = 'CREATE_REPORT'
export const RETRIEVE_REPORT = 'RETRIEVE_REPORT'
export const LIST_REPORT = 'LIST_REPORT'
export const DELETE_REPORT = 'DELETE_REPORT'
export const UPDATE_REPORT_GRAPH = 'UPDATE_REPORT_GRAPH'
export const SEND_REPORT = 'SEND_REPORT'
export const REFRESH_REPORT = 'REFRESH_REPORT'
export const UPDATE_REPORT = 'UPDATE_REPORT'
export const CREATE_REPORT_DATA_MAP = 'CREATE_REPORT_DATA_MAP'
export const LIST_REPORT_DATA_MAP = 'LIST_REPORT_DATA_MAP'
export const DELETE_REPORT_DATA_MAP = 'DELETE_REPORT_DATA_MAP'
export const RETRIEVE_REPORT_DATA_MAP = 'RETRIEVE_REPORT_DATA_MAP'
export const UPDATE_REPORT_DATA_MAP = 'UPDATE_REPORT_DATA_MAP'

export const createReportGraph = createAsyncAction(
  CREATE_REPORT_GRAPH,
  async (body: Partial<CreateUpdateReportGraph>) => {
    const url = `/api/report_graph/`
    const data = await Post(url, JSON.stringify(body))
    return data
  }
)

export const listReportGraph = createAsyncAction(
  LIST_REPORT_GRAPH,
  async () => {
    const url = `/api/report_graph/`
    const data = await Get(url)
    return data
  }
)

export const deleteReportGraph = createAsyncAction(
  DELETE_REPORT_GRAPH,
  async (id: number) => {
    const url = `/api/report_graph/${id}/`
    const data = await Delete(url)
    return data
  }
)

export const retrieveReportGraph = createAsyncAction(
  RETRIEVE_REPORT_GRAPH,
  async (id: number) => {
    const url = `/api/report_graph/${id}/`
    const data = await Get(url)
    return data
  }
)

export const updateReportGraph = createAsyncAction(
  UPDATE_REPORT_GRAPH,
  async (id: number, body: string) => {
    const url = `/api/report_graph/${id}/`
    const data = await Patch(url, body)
    return data
  }
)

export const createReportDataMap = createAsyncAction(
  CREATE_REPORT_DATA_MAP,
  async (body: CreateReportDataMap) => {
    const url = `/api/report_data_map/`
    const data = await Post(url, JSON.stringify(body))
    return data
  }
)

export const listReportDataMap = createAsyncAction(
  LIST_REPORT_DATA_MAP,
  async () => {
    const url = `/api/report_data_map/`
    const data = await Get(url)
    return data
  }
)

export const deleteReportDataMap = createAsyncAction(
  DELETE_REPORT_DATA_MAP,
  async (id: number) => {
    const url = `/api/report_data_map/${id}/`
    const data = await Delete(url)
    return data
  }
)

export const retrieveReportDataMap = createAsyncAction(
  RETRIEVE_REPORT_DATA_MAP,
  async id => {
    const url = `/api/report_data_map/${id}/`
    const data = await Get(url)
    return data
  }
)

export const updateReportDataMap = createAsyncAction(
  UPDATE_REPORT_DATA_MAP,
  async (id, body) => {
    const url = `/api/report_data_map/${id}/`
    const data = await Patch(url, body)
    return data
  }
)

export const listReport = createAsyncAction(LIST_REPORT, async () => {
  const url = `/api/report/`
  const data = await Get(url)
  return data
})

export const deleteReport = createAsyncAction(DELETE_REPORT, async id => {
  const url = `/api/report/${id}/`
  const data = await Delete(url)
  return data
})

export const createReport = createAsyncAction(CREATE_REPORT, async body => {
  const url = `/api/report/`
  const data = await Post(url, body)
  return data
})

export const retrieveReport = createAsyncAction(RETRIEVE_REPORT, async id => {
  const url = `/api/report/${id}/`
  const data = await Get<Report>(url)
  return data
})

export const updateReport = createAsyncAction(
  UPDATE_REPORT,
  async (id, body) => {
    const url = `/api/report/${id}/`
    const data = await Patch(url, body)
    return data
  }
)

export const sendReport = createAsyncAction(SEND_REPORT, async (id, body) => {
  const url = `/api/report/${id}/send_report/`
  const data = await Post(url, body)
  return data
})

export const refreshReport = createAsyncAction(
  REFRESH_REPORT,
  async (id, theme) => {
    const url = `/api/report/${id}/update_report/`
    const data = await Get(url, { theme })
    return data
  }
)

import { AnyAction, combineReducers } from 'redux'

import { RESET_ERROR_MESSAGE } from 'store/actions/error'
import app from './app'
import auth from './auth'
import organization from './organization'
import rawData from './rawdata'
import target from './target'
import targetGroup from './targetgroup'
import term from './term'
import graph from './graph'
import notification from './notification'
import roadmap from './roadmap'
import milestone from './milestone'
import maintask from './maintask'
import task from './task'
import colorpair from './colorpair'
import report from './report'
import monthselector from './monthselector'
import timeGrouping from './timeGrouping'
import systemMessage from './systemMessage'

interface ErrorState {
  status: number | null
  statusText: string | null
  messages: { [key: string]: Array<string> } | null
}

const initialState: ErrorState = {
  status: null,
  statusText: null,
  messages: null
}

const errorMessage = (state = initialState, action: AnyAction) => {
  const { type, error, payload } = action

  if (type === RESET_ERROR_MESSAGE) {
    return initialState
  } else if (error && payload.generalError) {
    const { status, statusText, messages }: ErrorState = payload
    return { status, statusText, messages }
  }

  return state
}

const reducer = combineReducers({
  app,
  auth,
  error: errorMessage,
  graph,
  notification,
  organization,
  rawData,
  target,
  targetGroup,
  term,
  roadmap,
  milestone,
  maintask,
  task,
  colorpair,
  report,
  monthselector,
  timeGrouping,
  systemMessage
})

export default reducer

export type RootState = ReturnType<typeof reducer>

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

interface HoverElementProps {
  children: React.ReactNode | React.ReactNode[]
  style: React.CSSProperties
  width: number
  height: number
}

class HoverElement extends Component<
  HoverElementProps,
  { isHovering: boolean }
> {
  constructor(props: HoverElementProps) {
    super(props)
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.state = {
      isHovering: false
    }
  }

  onMouseEnter() {
    this.setState({ isHovering: true })
  }

  onMouseLeave() {
    this.setState({ isHovering: false })
  }

  render() {
    const { children, width, height, style } = this.props
    const { isHovering } = this.state
    const container = {
      width,
      height
    }
    return (
      <div
        style={{ position: 'relative', cursor: 'pointer' }}
        onMouseLeave={this.onMouseLeave}
        onClick={this.onMouseLeave}
      >
        <div style={style} onMouseEnter={this.onMouseEnter}>
          <div style={container}> </div>
        </div>
        <div onMouseLeave={this.onMouseLeave} style={style}>
          {isHovering && (
            <div onClick={this.onMouseLeave} style={container}>
              {children}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default withTranslation()(HoverElement)

import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { Card } from 'components/blocks'
import { TFunction, withTranslation } from 'react-i18next'
import { TermOfClassification } from 'store/reducers/term'

const Title = styled.h3`
  color: ${props => props.theme.termTooltip.title};
  font-family: 'Open Sans';
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.188rem;
  margin: 0;
  margin-bottom: 0.625rem;
`

const TooltipContent = styled.div`
  position: fixed;
  left: ${(props: { position: { x: number; y: number } }) =>
    props.position.x}px;
  top: ${props => props.position.y}px;
  width: 350px;
  max-width: 500px;
  padding: 0.625rem;
  z-index: 999;
  > div {
    padding: 0.938rem;
    h1 {
      margin-top: 0;
    }
    p {
      color: ${props => props.theme.termTooltip.contentColor};
      font-family: 'Open Sans';
      margin-top: 0;
      margin-bottom: 5px;
      font-size: 0.813rem;
      letter-spacing: 0;
      line-height: 1.125rem;
    }
    > div strong {
      color: ${props => props.theme.termTooltip.contentColor};
      font-family: 'Open Sans';
      margin-top: 0;
      font-size: 0.813rem;
      letter-spacing: 0;
      line-height: 1.125rem;
    }
  }
`

class TargetModal extends Component<{
  term: TermOfClassification
  t: TFunction
  position: { x: number; y: number }
}> {
  render() {
    const { t, term, position } = this.props
    return (
      <TooltipContent position={position}>
        <Card noShadow>
          {term ? (
            <>
              <Title>{term.title.split('\u00a0').join(' ')}</Title>
              <p>
                {t('source')}:{' '}
                <strong>
                  {term.formula ? t('formula') : t('data_source')}
                </strong>
              </p>
              {term.formula ? (
                <p>
                  {t('formula')}: <strong>{term.formula}</strong>
                </p>
              ) : null}
            </>
          ) : null}
        </Card>
      </TooltipContent>
    )
  }
}

export default withTranslation()(TargetModal)

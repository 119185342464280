import {
  GET_FORCED_SYSTEM_MESSAGES,
  UNREAD_SYSTEM_MESSAGE_COUNT,
  LIST_SYSTEM_MESSAGE
} from 'store/actions/systemMessage'

import produce from 'immer'
import { RESET_STATE } from 'store/actions/reset'
import { AnyAction } from 'redux'

export type SystemMessage = {
  id: number
  title: string
  text_value: string
  published: string
  read: boolean
}

interface SystemMessageState {
  data: {
    forcedMessages: Array<SystemMessage>
    unreadMessageCount: number
  }
  created: SystemMessage | null
  list: {
    results: Array<SystemMessage>
  }
  isPending: boolean
  error: Error | Array<Error> | null
}

const initialState: SystemMessageState = {
  data: {
    forcedMessages: [],
    unreadMessageCount: 0
  },
  created: null,
  list: { results: [] },
  isPending: false,
  error: null
}

const systemMessage = (state = initialState, action: AnyAction) => {
  const { type, payload } = action

  switch (type) {
    case `${GET_FORCED_SYSTEM_MESSAGES}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${GET_FORCED_SYSTEM_MESSAGES}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${GET_FORCED_SYSTEM_MESSAGES}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.data = payload
      })

    case `${UNREAD_SYSTEM_MESSAGE_COUNT}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${UNREAD_SYSTEM_MESSAGE_COUNT}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${UNREAD_SYSTEM_MESSAGE_COUNT}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.data = payload
      })

    case `${LIST_SYSTEM_MESSAGE}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${LIST_SYSTEM_MESSAGE}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${LIST_SYSTEM_MESSAGE}_FULFILLED`:
      return produce(state, draft => {
        draft.list = payload
        draft.isPending = false
      })

    case RESET_STATE:
      return initialState

    default:
      return state
  }
}

export default systemMessage

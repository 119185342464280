import React, { Component } from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import Empty from 'components/roadmap/Empty'
import Container from 'components/roadmap/Container'
import styled from 'styled-components'
import mutationObserver from 'util/mutation'
import { DateSelection } from './Roadmap'
import { RoadmapType } from 'store/reducers/roadmap'

const Line = styled.div`
  height: ${(props: { height: number }) => props.height}px;
  top: 5px;
  position: absolute;
  border-left: ${props => props.theme.roadMap.line};
  left: 0;
  z-index: 1;
`
const TimelineContainer = styled.div`
  border-radius: 10px;
  float: left;
  width: 100%;
`

interface TimelineProps {
  dateSelector: JSX.Element
  dateSelection: DateSelection
  roadmap: RoadmapType
  t: TFunction
}

interface TimelineState {
  roadmapHeight: number
}

class Timeline extends Component<TimelineProps, TimelineState> {
  constructor(props: TimelineProps) {
    super(props)
    this.state = {
      roadmapHeight:
        document.getElementById('roadmap-container')?.clientHeight || 0 - 30
    }
  }

  componentDidMount() {
    mutationObserver(
      document.getElementById('roadmap-container') as Node,
      this.handleHeightChange
    )
  }

  handleHeightChange = () => {
    this.setState({
      roadmapHeight:
        document.getElementById('roadmap-container')?.clientHeight || 0 - 30
    })
  }

  render() {
    const { roadmapHeight } = this.state
    const { dateSelection, dateSelector, roadmap, t } = this.props
    const years: JSX.Element[] = []
    const months: JSX.Element[] = []
    let year = dateSelection.startYear
    let month = dateSelection.startMonth
    for (let i = 0; i < dateSelection.rowLength; i += 1) {
      if (
        (year === dateSelection.startYear &&
          month === dateSelection.startMonth) ||
        month === 1
      ) {
        years.push(
          <Empty
            key={`timeline-year-${year}-${roadmap.id}${i}`}
            dateSelection={dateSelection}
          >
            {year}
          </Empty>
        )
      } else
        years.push(
          <Empty
            key={`timeline-year-${year}-${roadmap.id}${i}`}
            dateSelection={dateSelection}
          >
            {' '}
          </Empty>
        )
      months.push(
        <Empty
          key={`timeline-month-${year}-${month}${roadmap.id}${i}`}
          dateSelection={dateSelection}
        >
          {month}{' '}
          <div style={{ position: 'relative' }}>
            <Line height={roadmapHeight} />
          </div>
        </Empty>
      )
      if (month < 12) month += 1
      else {
        month = 1
        year += 1
      }
    }
    const data = (
      <TimelineContainer>
        <div
          style={{ marginBottom: '10px', color: '#B9C5C8', fontSize: '12px' }}
        >
          {years}
        </div>
        <div style={{ marginBottom: '10px', fontSize: '12px' }}>{months}</div>
      </TimelineContainer>
    )
    const header = (
      <TimelineContainer>
        <div style={{ color: '#B9C5C8', marginBottom: '15px' }}>
          {t('Select period of time')}
        </div>
        {dateSelector}
      </TimelineContainer>
    )
    return (
      <>
        <Container
          style={{ margin: '2px', padding: '10px 15px' }}
          header={header}
          data={data}
          disableBackground
        />
      </>
    )
  }
}

export default withTranslation()(Timeline)

import {
  CREATE_MAINTASK,
  UPDATE_MAINTASK,
  GET_MAINTASK,
  LIST_MAINTASK,
  DELETE_MAINTASK_ROW,
  DELETE_MAINTASK
} from 'store/actions/maintask'

import produce from 'immer'
import { AnyAction } from 'redux'

export type MainTaskType = {
  id: number
  title: string
  roadmap: number
  color_pair: number
  row_count: number
  link_to_roadmap: Array<number>
  targets: Array<number>
  index: number
}

interface MainTaskState {
  data: MainTaskType | null
  created: MainTaskType | null
  list: {
    results: Array<MainTaskType>
  }
  isPending: boolean
  error: Error | Array<Error> | null
}

const initialState: MainTaskState = {
  data: null,
  created: null,
  list: { results: [] },
  isPending: false,
  error: null
}

const maintask = (state = initialState, action: AnyAction) => {
  const { type, payload } = action

  switch (type) {
    case `${GET_MAINTASK}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${GET_MAINTASK}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${GET_MAINTASK}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.data = payload
      })

    case `${LIST_MAINTASK}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${LIST_MAINTASK}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${LIST_MAINTASK}_FULFILLED`:
      return produce(state, draft => {
        draft.list = payload
        draft.isPending = false
      })

    case `${CREATE_MAINTASK}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${CREATE_MAINTASK}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${CREATE_MAINTASK}_FULFILLED`:
      return produce(state, draft => {
        draft.created = payload
        draft.isPending = false
      })

    case `${UPDATE_MAINTASK}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${UPDATE_MAINTASK}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${UPDATE_MAINTASK}_FULFILLED`:
      return produce(state, draft => {
        draft.created = payload
        draft.isPending = false
      })

    case `${DELETE_MAINTASK_ROW}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${DELETE_MAINTASK_ROW}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${DELETE_MAINTASK_ROW}_FULFILLED`:
      return produce(state, draft => {
        draft.created = payload
        draft.isPending = false
      })

    case `${DELETE_MAINTASK}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${DELETE_MAINTASK}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${DELETE_MAINTASK}_FULFILLED`:
      return produce(state, draft => {
        draft.created = payload
        draft.isPending = false
      })

    default:
      return state
  }
}

export default maintask

import { Get, Post } from 'api/index'
import { createAsyncAction } from 'redux-promise-middleware-actions'
import { RootState } from 'store/reducers'
import { GraphMapping } from 'store/reducers/graph'
import { AppDispatch } from 'store/store'

export const GET_RAW_DATA = 'GET_RAW_DATA'
export const LIST_RAW_DATA = 'LIST_RAW_DATA'
export const GET_RAW_DATA_TIMESPAN = 'GET_RAW_DATA_TIMESPAN'
export const GET_PERCENTAGE_VALUE = 'GET_PERCENTAGE_VALUE'
export const SET_DATA_MAP_DATE_RANGE = 'SET_DATA_MAP_DATE_RANGE'
export const GET_DATA_MAP = 'GET_DATA_MAP'
export const SAVE_DATA_MAP = 'SAVE_DATA_MAP'
export const TOGGLE_DATAMAP_TERM_OPEN = 'TOGGLE_DATAMAP_TERM_OPEN'
export const TOGGLE_DATAMAP_TERMS_OPEN_ALL = 'TOGGLE_DATAMAP_TERMS_OPEN_ALL'

type Params = {
  [key: string]:
    | number
    | string
    | null
    | boolean
    | number[]
    | string[]
    | Params
    | Params[]
}

export const getRawData = createAsyncAction(
  GET_RAW_DATA,
  async (id: number) => {
    const url = `/api/data/${id}/`
    const data = await Get(url)
    return data
  }
)

export const listRawData = createAsyncAction(
  LIST_RAW_DATA,
  async (params: Params) => {
    const url = `/api/data/`
    const data = await Get<RootState['rawData']['list']>(url, params)
    return data
  }
)

export const getRawDataTimespan = createAsyncAction(
  GET_RAW_DATA_TIMESPAN,
  async (isVerified = true, forTargets = false) => {
    const url = `/api/data/get_raw_data_timespan/`
    const data = await Get<RootState['rawData']['timespan']>(url, {
      is_verified: isVerified,
      for_targets: forTargets
    })
    return data
  }
)

export const getPercentageValue = createAsyncAction(
  GET_PERCENTAGE_VALUE,
  async (
    ids: Array<number>,
    cumulative: boolean,
    properties: GraphMapping['graph_properties'],
    grouping: string
  ) => {
    const url = `/api/data/get_percentage_value/`
    const data = await Post(
      url,
      JSON.stringify({
        ids,
        cumulative,
        properties,
        grouping
      })
    )
    return data
  },
  (ids: Array<number>) => ({
    ids
  })
)
export const getDataMap = createAsyncAction(
  GET_DATA_MAP,
  async ({ startDate, endDate, rootKeys }: Params) => {
    const url = `/api/data/get_data_map/`
    const data = await Get(url, {
      start_date: startDate,
      end_date: endDate,
      ...(rootKeys ? { root_keys: rootKeys } : {})
    })
    return data
  }
)

export const saveDataMap = createAsyncAction(
  SAVE_DATA_MAP,
  async (body: Params) => {
    const url = `/api/report_data_map/`
    const data = Post(url, JSON.stringify(body))
    return data
  }
)

export const setDataMapDateRange = (payload: Params) => (
  dispatch: AppDispatch
) =>
  dispatch({
    type: SET_DATA_MAP_DATE_RANGE,
    payload
  })

export const toggleDataMapTermOpen = (term: string) => (
  dispatch: AppDispatch
) =>
  dispatch({
    type: TOGGLE_DATAMAP_TERM_OPEN,
    payload: term
  })

export const toggleDataMapOpenAll = (isExpanded: boolean) => (dispatch: AppDispatch) =>
  dispatch({
    type: TOGGLE_DATAMAP_TERMS_OPEN_ALL,
    payload: isExpanded
  })

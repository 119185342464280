import React, { Component } from 'react'
import Picker from 'react-month-picker'
import { TFunction, withTranslation } from 'react-i18next'
import MonthBox from 'components/blocks/MonthBox'
import moment from 'moment'
import { Years } from 'components/notification/DateSelector'
import { Organization } from 'store/reducers/organization'
import { YellowButtonMargin } from './YellowButton'

export type SingleValue = { year?: string | number; month?: string | number }

export type RangeValue = {
  from: SingleValue
  to: SingleValue
}

type SingleYear = { [key: string]: { month: string; year: string } }

interface MonthSelectorProps {
  initialValue?: RangeValue
  rangeSelect: boolean
  activeOrganization: Organization
  years: Years
  defaultValue?: RangeValue | SingleValue
  onInit: () => void
  onRangeChange: (
    value: Years | null,
    rangeValue?: RangeValue | SingleValue
  ) => void
  noYtd?: boolean
  ytdEmpty?: boolean
  t: TFunction
}

interface MonthSelectorState {
  ytdSelected: boolean
  rangeValue: RangeValue | SingleValue | null
}

class MonthSelector extends Component<MonthSelectorProps, MonthSelectorState> {
  pickRange: React.RefObject<typeof Picker>

  constructor(props: MonthSelectorProps) {
    super(props)
    this.state = {
      ytdSelected: false,
      rangeValue: props.initialValue ? props.initialValue : null
    }
    this.pickRange = React.createRef<typeof Picker>()
    if (props.initialValue) {
      props.onInit()
    }
  }

  handleRangeDissmis = (value: RangeValue) => {
    this.setState({ rangeValue: value })
  }

  _handleClickRangeBox = () => {
    this.pickRange.current?.show()
  }

  handleRangeChange = (year: string, month: string, listIndex: number) => {
    const { onRangeChange, rangeSelect } = this.props
    const { rangeValue } = this.state
    if (rangeSelect) {
      const fromYear =
        listIndex === 0
          ? year
          : ((rangeValue as RangeValue) || { from: {} }).from.year
      const toYear =
        listIndex === 1
          ? year
          : ((rangeValue as RangeValue) || { to: {} }).to.year
      const month1 = this.getMonthFormat(
        listIndex === 0
          ? month
          : ((rangeValue as RangeValue) || { from: {} }).from.month
      )
      const month2 = this.getMonthFormat(
        listIndex === 1
          ? month
          : ((rangeValue as RangeValue) || { to: {} }).to.month
      )
      const newRangeValue = {
        from: { year: fromYear, month: month1 },
        to: { year: toYear, month: month2 }
      }
      this.setState({
        rangeValue: newRangeValue
      })
      const key = `${fromYear} - ${toYear}`
      const m1 = `${fromYear}-${month1}`
      const m2 = `${toYear}-${month2}`
      const v = {
        [key]: [m1, m2]
      }
      onRangeChange(v, newRangeValue)
    } else {
      const year1 = listIndex === 0 ? year : (rangeValue as SingleValue).year
      const month1 = this.getMonthFormat(
        listIndex === 0 ? month : (rangeValue as SingleValue).month
      )
      const newRangeValue = { year: year1, month: month1 }
      this.setState({ rangeValue: newRangeValue })
      const key = year1
      const m1 = `${year1}-${month1}`
      const v = {
        [key as string]: [m1]
      }
      onRangeChange(v, newRangeValue)
    }
    this.setState({ ytdSelected: false })
  }

  getInt = (val: string | null) => {
    if (val === null) {
      return
    }
    return parseInt(val, 10)
  }

  getDefaultYtdValue = (rangeSelect: boolean) => {
    const firstYear = this.getInt(this.getYtdStartYear())
    const lastYear = this.getInt(this.getYtdEndYear())
    const month = this.getYtdStartMonth()
    const endMonth = moment().month() + 1
    if (rangeSelect) {
      return {
        from: { year: firstYear, month },
        to: { year: lastYear, month: endMonth }
      }
    }
    return { year: firstYear, month: endMonth }
  }

  setDefaultYtdValue = () => {
    const { onRangeChange, rangeSelect } = this.props
    const fromYear = this.getYtdStartYear()
    const toYear = moment().year()
    const month = this.getYtdStartMonth()
    const endMonth = moment().month() + 1
    if (rangeSelect) {
      const key = `${fromYear} - ${toYear}`
      const m1 = `${fromYear}-${month}`
      const m2 = endMonth === 0 ? `${fromYear}-12` : `${toYear}-${endMonth}`
      const v = {
        [key]: [m1, m2]
      }
      onRangeChange(v)
    } else {
      const key = month < endMonth ? fromYear : toYear
      const m1 = `${key}-${endMonth}`
      const v = {
        [key as string]: [m1]
      }
      onRangeChange(v)
    }
  }

  getYtdStartMonth = () => {
    const { activeOrganization } = this.props
    return parseInt(activeOrganization.start_of_fiscal_year, 10)
  }

  getYtdStartYear = () => {
    const { activeOrganization } = this.props
    if (!activeOrganization) {
      return null
    }
    const { start_of_fiscal_year } = activeOrganization
    const fiscalYear = parseInt(start_of_fiscal_year, 10)
    if (fiscalYear < moment().month()) {
      return `${moment().year()}`
    }
    return `${moment().year() - 1}`
  }

  getYtdEndYear = () => {
    const { activeOrganization } = this.props
    if (!activeOrganization) {
      return null
    }
    const { start_of_fiscal_year } = activeOrganization
    const fiscalYear = parseInt(start_of_fiscal_year, 10)
    if (fiscalYear < moment().month()) {
      return `${moment().year() + 1}`
    }
    return `${moment().year()}`
  }

  getYears = (initialYears: Years | SingleYear) => {
    const years = { ...initialYears }
    if (years.YTD) {
      delete years.YTD
    }
    const keys = Object.keys(years)
    if (keys.length === 0) return ''
    const firstKey = keys[0]
    const lastKey = keys[keys.length - 1]
    const firstMonth = this.getInt(
      firstKey.includes(' - ')
        ? (years as Years)[firstKey][0].split('-')[1]
        : (years as SingleYear)[firstKey].month
    )
    const lastMonth = this.getInt(
      lastKey.includes(' - ')
        ? (years as Years)[lastKey][(years as Years)[lastKey].length - 1].split(
            '-'
          )[1]
        : (years as SingleYear)[lastKey].month
    )
    const firstYear = this.getInt(
      firstKey.includes(' - ')
        ? firstKey.split(' - ')[0]
        : (years as SingleYear)[firstKey].year
    )
    const lastYear = this.getInt(
      lastKey.includes(' - ')
        ? lastKey.split(' - ')[1]
        : (years as SingleYear)[lastKey].year
    )
    return {
      min: { year: firstYear, month: firstMonth },
      max: { year: lastYear, month: lastMonth }
    }
  }

  getPickerLang = () => {
    const { t } = this.props
    return {
      months: [
        t('Jan'),
        t('Feb'),
        t('Mar'),
        t('Apr'),
        t('May'),
        t('Jun'),
        t('Jul'),
        t('Aug'),
        t('Sep'),
        t('Oct'),
        t('Nov'),
        t('Dec')
      ],
      from: t('From'),
      to: t('To')
    }
  }

  makeText = (m: SingleValue) => {
    if (m && m.year && m.month)
      return `${this.getPickerLang().months[(m.month as number) - 1]}. ${
        m.year
      }`
    return ''
  }

  getValue = () => {
    const { rangeSelect } = this.props
    const { rangeValue } = this.state
    if (rangeValue) {
      if (rangeSelect)
        return `${this.makeText(
          (rangeValue as RangeValue).from
        )} - ${this.makeText((rangeValue as RangeValue).to)}`
      return `${
        this.getPickerLang().months[
          ((rangeValue as SingleValue).month as number) - 1
        ]
      }. ${(rangeValue as SingleValue).year}`
    }
    return ''
  }

  getMonthFormat = (value?: string | number) => {
    if (!value) {
      return
    }

    const s = String(value)
    if (s.length === 1) return `0${s}`
    return s
  }

  selectYtd = () => {
    const { ytdSelected } = this.state
    const { rangeSelect, onRangeChange, defaultValue, ytdEmpty } = this.props
    if (ytdEmpty) {
      this.setState({ rangeValue: null })
      onRangeChange(null)
      return
    }
    this.setState({ ytdSelected: !ytdSelected })
    if (!ytdSelected) {
      this.setState({ rangeValue: this.getDefaultYtdValue(rangeSelect) })
      this.setDefaultYtdValue()
      return
    }
    if (!defaultValue) {
      return
    }
    this.setState({ rangeValue: defaultValue })
    onRangeChange({})
  }

  render() {
    const { years, noYtd, t, rangeSelect } = this.props
    const { rangeValue, ytdSelected } = this.state
    const y = this.getYears(years)
    const rv = rangeValue || {}
    if (rangeSelect && !(rv as RangeValue).from) {
      ;(rv as RangeValue).from = { year: 0, month: 0 }
    }
    if (rangeSelect && !(rv as RangeValue).to) {
      ;(rv as RangeValue).to = { year: 0, month: 0 }
    }
    if (
      !rangeSelect &&
      (!(rv as SingleValue).year || !(rv as SingleValue).month)
    ) {
      ;(rv as SingleValue).year = 0
      ;(rv as SingleValue).month = 0
    }
    if (!y) return ''
    return (
      <div className="edit">
        {noYtd ? null : (
          <YellowButtonMargin
            selected={ytdSelected}
            onClick={() => this.selectYtd()}
          >
            YTD
          </YellowButtonMargin>
        )}
        <YellowButtonMargin>
          <Picker
            key={`${y.max.year}-${y.max.month}-${y.min.year}-${y.min.month}`}
            ref={this.pickRange}
            autoRange={0}
            years={y}
            value={rv}
            lang={this.getPickerLang()}
            theme="light"
            onChange={this.handleRangeChange}
            onDismiss={this.handleRangeDissmis}
          >
            <MonthBox
              value={this.getValue() || t('select-date')}
              onClick={this._handleClickRangeBox}
            />
          </Picker>
        </YellowButtonMargin>
      </div>
    )
  }
}

export default withTranslation()(MonthSelector)

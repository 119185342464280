import {
  CREATE_REPORT_GRAPH,
  LIST_REPORT,
  LIST_REPORT_GRAPH,
  RETRIEVE_REPORT_GRAPH,
  UPDATE_REPORT_GRAPH,
  LIST_REPORT_DATA_MAP,
  RETRIEVE_REPORT_DATA_MAP,
  UPDATE_REPORT_DATA_MAP,
  CREATE_REPORT_DATA_MAP
} from 'store/actions/report'
import { produce } from 'immer'
import { AnyAction } from 'redux'

export type Report = {
  id: number
  organization: number
  title: string
  content: string
  report_graphs: number[]
  report_data_maps: number[]
  properties: { order: number[] } & { [key: string]: string }
  created: string
  modified: string
  published: string
}

type ReportGraphMapping = {
  id: number
  view_title: string
}

export type ReportGraph = {
  id: number
  organization: number
  mapping: ReportGraphMapping
  properties: { [key: string]: string | string[] | boolean }
  content: string
  image_file: string
  reporter: number
  created: string
  modified: string
}

export type CreateUpdateReportGraph = {
  id?: number
  organization: number
  mapping: number
  properties: { [key: string]: string | string[] | boolean }
  content: string
  image_file: string
  reporter: number
  created: string
  modified: string
}

export type ReportDataMap = {
  id: number
  organization: number
  properties: { [key: string]: string | string[] | null | number }
  content: string
  image_file: string
  reporter: number
  created: string
  modified: string
}

export type CreateReportDataMap = {
  properties: { [key: string]: string | string[] | null | number }
}

interface ReportState {
  data: Report | null
  list: Array<Report>
  graphList: Array<ReportGraph>
  graphData: { [key: number]: ReportGraph }
  dataMapList: Array<ReportDataMap>
  dataMapData: { [key: number]: ReportDataMap }
  isPending: boolean
  error: Error | Array<Error> | null
}

const initialState: ReportState = {
  data: null,
  list: [],
  graphList: [],
  graphData: {},
  dataMapList: [],
  dataMapData: {},
  isPending: false,
  error: null
}

const report = (state = initialState, action: AnyAction) => {
  const { type, payload } = action

  switch (type) {
    case `${LIST_REPORT_GRAPH}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${LIST_REPORT_GRAPH}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${LIST_REPORT_GRAPH}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.graphList = payload
      })

    case `${LIST_REPORT}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${LIST_REPORT}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${LIST_REPORT}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.list = payload
      })

    case `${CREATE_REPORT_GRAPH}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${CREATE_REPORT_GRAPH}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${CREATE_REPORT_GRAPH}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.data = payload
      })

    case `${RETRIEVE_REPORT_GRAPH}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${RETRIEVE_REPORT_GRAPH}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${RETRIEVE_REPORT_GRAPH}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.graphData[payload.id] = payload
      })

    case `${UPDATE_REPORT_GRAPH}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${UPDATE_REPORT_GRAPH}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${UPDATE_REPORT_GRAPH}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.graphData[payload.id] = payload
      })

    case `${CREATE_REPORT_DATA_MAP}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${CREATE_REPORT_DATA_MAP}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${CREATE_REPORT_DATA_MAP}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.data = payload
      })

    case `${RETRIEVE_REPORT_DATA_MAP}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${RETRIEVE_REPORT_DATA_MAP}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${RETRIEVE_REPORT_DATA_MAP}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.dataMapData[payload.id] = payload
      })

    case `${UPDATE_REPORT_DATA_MAP}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${UPDATE_REPORT_DATA_MAP}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${UPDATE_REPORT_DATA_MAP}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.dataMapData[payload.id] = payload
      })

    case `${LIST_REPORT_DATA_MAP}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${LIST_REPORT_DATA_MAP}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${LIST_REPORT_DATA_MAP}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.dataMapList = payload
      })

    default:
      return state
  }
}

export default report

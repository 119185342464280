import React, { Component, MouseEvent } from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators, EmptyObject } from 'redux'
import Popup from 'reactjs-popup'
import Select from 'react-select'
import {
  createMilestone,
  updateMilestone,
  deleteMilestone
} from 'store/actions/milestone'
import Picker from 'react-month-picker'
import MonthBox from 'components/blocks/MonthBox'
import { triggerRefresh } from 'store/actions/task'
import RemoveMilestoneModal from './RemoveMilestoneModal'
import { RootState } from 'store/reducers'
import { RoadmapType } from 'store/reducers/roadmap'
import { MilestoneType } from 'store/reducers/milestone'
import { AppDispatch } from 'store/store'
import { Get } from 'api'
import { OrganizationUser } from 'store/reducers/organization'

type MilestoneModalReduxState = ReturnType<typeof mapStateToProps>
type MilestoneModalReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface MilestoneModalProps {
  roadmap: RoadmapType
  milestone?: MilestoneType
  month: number
  year: number
  trigger: JSX.Element
  t: TFunction
}

interface MilestoneModalState {
  singleValue: { year: number; month: number }
  defaultUserOption: { label: string; value: number | string } | EmptyObject
  id: string | number
  title: string
  roadmap: string | number
  endDate: string
  user: string | number
  organizationUserOptions: Array<{ label: string; value: number | string }>
}

class MilestoneModal extends Component<
  MilestoneModalProps & MilestoneModalReduxDispatch & MilestoneModalReduxState,
  MilestoneModalState
> {
  pickerLang: { months: string[]; from: string; to: string }
  pickMonth: React.RefObject<typeof Picker>
  years: number[]
  constructor(
    props: MilestoneModalProps &
      MilestoneModalReduxDispatch &
      MilestoneModalReduxState
  ) {
    super(props)
    const { milestone, roadmap, t, month, year } = this.props
    this.state = {
      singleValue: { year, month },
      id: milestone ? milestone.id : '',
      title: milestone ? milestone.title : '',
      roadmap: roadmap ? roadmap.id : '',
      endDate: milestone ? milestone.end_date : this.getYear(month, year),
      user: milestone ? milestone.user : '',
      organizationUserOptions: [],
      defaultUserOption: {}
    }
    this.pickerLang = {
      months: [
        t('Jan'),
        t('Feb'),
        t('Mar'),
        t('Apr'),
        t('May'),
        t('Jun'),
        t('Jul'),
        t('Aug'),
        t('Sep'),
        t('Oct'),
        t('Nov'),
        t('Dec')
      ],
      from: t('From'),
      to: t('To')
    }
    this.pickMonth = React.createRef()
    this.years = this.getYears()
  }

  async componentDidMount() {
    const { milestone } = this.props
    const url = '/api/user/get_organization_users/'
    const data = await Get<{ users: OrganizationUser[] }>(url)
    const organizationUserOptions: { value: number; label: string }[] = []
    let defaultUserOption = {}
    data.users.forEach(user => {
      const option = { value: user.id, label: user.username }
      organizationUserOptions.push(option)
      if (milestone && milestone.user === user.id) defaultUserOption = option
    })
    this.setState({ defaultUserOption, organizationUserOptions })
  }

  getYears = () => {
    const years: number[] = []
    const speard = 5
    const currentYear = new Date().getFullYear()
    for (let y = currentYear - speard; y < currentYear + speard; y += 1) {
      years.push(y)
    }
    return years
  }

  getMonthFormat = (value: number) => {
    const s = String(value)
    if (s.length === 1) return `0${s}`
    return s
  }

  getYear = (month: number, year: number) => {
    return `${year}-${this.getMonthFormat(month)}-01`
  }

  deleteMilestone = async () => {
    const { milestone, deleteMilestone: deleteMilestoneAction } = this.props
    if (!milestone) {
      return
    }
    await deleteMilestoneAction(milestone.id)
  }

  createOrUpdateMilestone = async () => {
    const {
      milestone,
      createMilestone: createMilestoneAction,
      updateMilestone: updateMilestoneAction,
      triggerRefresh: triggerRefreshAction
    } = this.props
    /* eslint-disable camelcase */
    const { id, title, roadmap, endDate: end_date, user } = this.state
    const data = JSON.stringify({
      id,
      title,
      roadmap,
      end_date,
      user
    })
    /* eslint-enable camelcase */
    if (!milestone) {
      await createMilestoneAction(data)
    } else if (typeof id === 'number') {
      await updateMilestoneAction(data, id)
    }
    triggerRefreshAction(true)
  }

  handleChange = (value: { label: string; value: number }) => {
    const user = value.value
    this.setState({ user })
  }

  handleMonthChange = (year: number, month: number) => {
    const value = this.getYear(month, year)
    this.setState({ endDate: value })
  }

  handleMonthDissmis = (value: { year: number; month: number }) => {
    this.setState({ singleValue: value })
  }

  handleClickMonthBox = () => {
    this.pickMonth.current.show()
  }

  makeText = (m: { year: number; month: number }) => {
    if (m && m.year && m.month)
      return `${this.pickerLang.months[m.month - 1]} ${m.year}`
    return '?'
  }

  render() {
    const { milestone, t, trigger } = this.props
    const {
      title,
      singleValue,
      defaultUserOption,
      organizationUserOptions
    } = this.state
    const modalTitle = !milestone ? t('Add new milestone') : t('Edit milestone')
    return (
      <>
        <Popup trigger={trigger} position="top center" modal nested>
          {(close: {
            (): void
            (event: MouseEvent<HTMLButtonElement, MouseEvent>): void
          }) => (
            <div className="modal">
              <button type="button" className="close" onClick={close}>
                &times;
              </button>
              <div className="header"> {modalTitle} </div>
              <div className="content">
                <label htmlFor="milestone-title">{t('Title')}</label>
                <input
                  id="milestone-title"
                  value={title}
                  onChange={({ target: { value } }) =>
                    this.setState({ title: value })
                  }
                />

                <label htmlFor="milestone-end-date">{t('End Date')}</label>

                <div className="edit">
                  <Picker
                    ref={this.pickMonth}
                    years={this.years}
                    value={singleValue}
                    theme="light"
                    lang={this.pickerLang.months}
                    onChange={this.handleMonthChange}
                    onDismiss={this.handleMonthDissmis}
                  >
                    <div className="modal-input" id="milestone-end-date">
                      <MonthBox
                        value={this.makeText(singleValue)}
                        onClick={this.handleClickMonthBox}
                      />
                    </div>
                  </Picker>
                </div>

                <label htmlFor="milestone-user">{t('User')}</label>
                <Select
                  id="milestone-user"
                  options={organizationUserOptions}
                  placeholder={t('Select')}
                  defaultValue={defaultUserOption}
                  onChange={({ value }: { value: number }) =>
                    this.setState({ user: value })
                  }
                />
              </div>
              <div className="actions">
                <RemoveMilestoneModal milestone={milestone as MilestoneType} />

                <button
                  type="button"
                  className="button close"
                  onClick={() => {
                    close()
                  }}
                >
                  {t('Cancel')}
                </button>

                <button
                  type="button"
                  className="button"
                  onClick={() => {
                    this.createOrUpdateMilestone()
                    close()
                  }}
                >
                  {t('Save')}
                </button>
              </div>
            </div>
          )}
        </Popup>
      </>
    )
  }
}

const mapStateToProps = ({ organization: { users: data } }: RootState) => ({
  data
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      createMilestone,
      updateMilestone,
      deleteMilestone,
      triggerRefresh
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(MilestoneModal))

import React from 'react'
import { Provider } from 'react-redux'
import { initStore } from 'store/store'
import { GoogleOAuthProvider } from '@react-oauth/google'

import 'normalize.css'
import 'App.scss'
import 'typeface-roboto'

import '../node_modules/moment/locale/fi'
import Routes from './Routes'

export default () => {
  const root = (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID as string}>
      <Provider store={initStore()}>
        <Routes />
      </Provider>
    </GoogleOAuthProvider>
  )
  return root
}

import React, { Component } from 'react'
import styled, { DefaultTheme, withTheme } from 'styled-components'
import Icon from 'components/Icon'

const DraggableContainer = styled.div`
  position: absolute;
  width: 20px;
  height: 100%;
  top: 0;
  left: 0;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  cursor: grab;
`
const DragZone = styled.div`
  position: absolute;
  top: calc(100% + 2.5px);
  left: 0;
  right: 0;
  height: 15px;
  width: 80vw;
  &:hover {
    .background {
      display: ${(props: { isDragged: boolean }) =>
        props.isDragged ? 'block' : 'none'};
      width: 100%;
      height: 100%;
      background-color: #33333355;
      border-radius: 3px;
    }
  }
`

class Container extends Component<{
  header: JSX.Element | JSX.Element[]
  data: JSX.Element | JSX.Element[]
  style: React.CSSProperties
  color?: string
  darkColor?: string
  isDragged?: boolean
  onDragStart?: (coordinates: { x: number; y: number }) => void
  updateMainTaskIndex?: () => void
  disableBackground?: boolean
  theme: DefaultTheme
}> {
  startDrag = (e: { clientX: number; clientY: number }) => {
    const { onDragStart } = this.props
    if (!onDragStart) {
      return
    }
    onDragStart({ x: e.clientX, y: e.clientY })
  }

  updateIndex = () => {
    const { updateMainTaskIndex } = this.props
    if (!updateMainTaskIndex) {
      return
    }
    updateMainTaskIndex()
  }

  render() {
    const {
      header,
      data,
      style,
      color,
      darkColor,
      isDragged,
      theme,
      disableBackground
    } = this.props
    let draggableStyle = {}
    if (color && darkColor) {
      draggableStyle = {
        backgroundColor: color,
        borderColor: darkColor,
        borderRight: `3px solid ${darkColor}`
      }
    }
    const draggableContainer = (
      <DraggableContainer
        style={draggableStyle}
        onDragStart={(e: React.DragEvent<HTMLDivElement>) => e.preventDefault()}
        draggable
        onMouseDown={this.startDrag}
      >
        <Icon
          style={{
            position: 'absolute',
            top: '50%',
            margin: '0',
            msTransform: 'translateY(-50%)',
            transform: 'translateY(-50%)'
          }}
          color={darkColor}
          iconName="threeDots"
          size={15}
        />
        <DragZone isDragged={isDragged as boolean} onMouseUp={this.updateIndex}>
          <div className="background" />
        </DragZone>
      </DraggableContainer>
    )
    if (isDragged) {
      style.marginBottom = '20px'
    }
    if (!disableBackground) style.backgroundColor = theme.roadMap.rowBg
    return (
      <div style={{ ...style }}>
        <div style={{ float: 'left', width: '100%' }}>
          <div style={{ float: 'left', width: '15%' }}>{header}</div>
          <div style={{ float: 'left', width: '85%' }}>{data}</div>
        </div>
        {draggableContainer}
      </div>
    )
  }
}

export default withTheme(Container)

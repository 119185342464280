import React, { FC, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store/reducers'
import { useTranslation } from 'react-i18next'
import { Card } from 'components/blocks'
import styled from 'styled-components'
import { getForcedSystemMessages } from 'store/actions/systemMessage'
import { PRESENTATION_DATE_FORMAT } from './SystemMessageList'
import moment from 'moment'
import xss from 'xss'
import { SystemMessage } from 'store/reducers/systemMessage'

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999999;
  cursor: pointer;
`
const CardContainer = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    width: 100%;
    margin-bottom: 1.25rem;
    max-width: 1400px;
    flex-direction: column;
    @media screen and (min-width: 768px) {
      flex-direction: row;
    }
  }
`
const OverlayCard = styled(Card)`
  margin: auto;
  width: 80%;
`
const CardContent = styled.div`
  position: relative;
  padding: 1.25rem;
  flex-grow: 1;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  word-break: break-word;
  > div {
    overflow: hidden;
    max-height: ${(props: { expanded: boolean; contentHeight: number }) =>
      props.expanded ? `${props.contentHeight}px` : '100px'};
    min-height: 100px;
    transition: ${props =>
      props.expanded ? 'max-height 0.5s ease-in' : 'max-height 0.5s ease-out'};
    margin-bottom: 1.25rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    font-family: 'Open Sans';
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 100;
    letter-spacing: 0;
  }
  h4 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.813rem;
  }
  span.date {
    color: #b9c5c8;
    font-family: 'Open Sans';
    font-size: 0.813rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.125rem;
  }
`
const ButtonContainer = styled.div`
  margin: auto;
  width: 80%;
`
const Button = styled.span`
  padding: 10px 30px;
  width: 10%;
  margin-left: 90%;
  text-align: center;
  background: #ffd800;
  color: #000;
  border-radius: 0.875rem;
  font-size: 13px;
  font-weight: bold;
`

const LoginMessage: FC = () => {
  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [systemMessages, setSystemMessages] = useState<SystemMessage[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const systemMessage = useSelector((state: RootState) => state.systemMessage)
  useEffect(() => {
    const fetchData = async () => {
      if (!loading) {
        setLoading(true)
        await dispatch(getForcedSystemMessages())
      } else if (
        systemMessage.data.forcedMessages &&
        systemMessage.data.forcedMessages.length > 0
      ) {
        setOpen(true)
        setSystemMessages(systemMessage.data.forcedMessages)
      }
    }
    fetchData()
  }, [systemMessage])
  const getContentHeight = (id: string | number) => {
    const el = document.querySelector(`#content_${id}`)
    if (el) {
      return el.scrollHeight
    }

    return 0
  }
  return (
    <>
      {open && (
        <Overlay
          onClick={() => {
            setOpen(false)
            setSystemMessages([])
          }}
        >
          <CardContainer>
            {systemMessages &&
              systemMessages.map(d => (
                <OverlayCard key={d.id}>
                  <CardContent
                    expanded={true}
                    contentHeight={getContentHeight(d.id)}
                  >
                    <h4>{d.title}</h4>
                    <span className="date">
                      {moment(d.published).format(PRESENTATION_DATE_FORMAT)}
                    </span>
                    <div
                      id={`content_${d.id}`}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: xss(d.text_value)
                      }}
                    />
                  </CardContent>
                </OverlayCard>
              ))}
            <ButtonContainer>
              <Button>{t('close')}</Button>
            </ButtonContainer>
          </CardContainer>
        </Overlay>
      )}
    </>
  )
}

export default LoginMessage

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import styled, { DefaultTheme } from 'styled-components/macro'
import LogoLightTheme from 'assets/images/logo.png'
import LogoDarkTheme from 'assets/images/logo-light.png'

import EnvironmentSelector from 'components/EnvironmentSelector'
import Icon from 'components/Icon'
import UserDropdown from 'components/UserDropdown'
import { isGraphPage } from 'util/url'
import { RootState } from 'store/reducers'
import { SystemMessageButton } from 'components/system-message'

const MenuContainer = styled.div`
  display: flex;
  height: 4.688rem;
  background: ${(props: { isGraphPage: boolean; theme: DefaultTheme }) =>
    props.isGraphPage
      ? props.theme.graphPage.topMenuBg
      : props.theme.topMenu.background};
  border-bottom: ${props => props.theme.navigation.border};
  padding: 0.938rem 1.25rem;
  #hamburger {
    height: 100%;
    background-color: #aaa;
    margin-right: 0.938rem;
    user-select: none;
    cursor: pointer;
    @media screen and (min-width: 769px) {
      display: none;
    }
  }
`

const Logo = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 100%;
`

const LogoImage = styled.div`
  background: url(${props =>
    props.theme.id === 'dark' ? LogoDarkTheme : LogoLightTheme});
  height: 100%;
  width: 11.5rem;
  background-size: cover;
  cursor: pointer;
`

const MobileDropdowns = styled.div`
  display: ${(props: { isOpen: boolean; isGraphPage: boolean }) =>
    props.isOpen ? 'flex' : 'none'};
  position: absolute;
  left: 0;
  right: 0;
  top: 4.688rem;
  padding: 1.25rem;
  z-index: 999;
  background: ${props =>
    props.isGraphPage
      ? props.theme.graphPage.background
      : props.theme.topMenu.background};
  border-bottom: 1px solid
    ${(props: { isGraphPage: boolean }) =>
      props.isGraphPage ? '#333' : '#ddd'};
`

const Caret = styled.div`
  position: relative;
  top: calc(50% - 5px);
  width: 0.625rem;
  height: 0.625rem;
  border-style: solid;
  cursor: pointer;
  border-width: ${(props: { isOpen: boolean }) =>
    props.isOpen
      ? '0 0.625rem 0.625rem 0.625rem'
      : '0.625rem 0.625rem 0 0.625rem'};
  border-color: ${(props: { isOpen: boolean }) =>
    props.isOpen
      ? 'transparent transparent #606160'
      : '#606160 transparent transparent transparent'};
`

interface TopMenuProps {
  onOrganizationChange: () => void
  onMobileMenuOpen: () => void
}

interface TopMenuState {
  mobileDropdownOpen: boolean
}

type TopMenuReduxProps = Partial<ReturnType<typeof mapStateToProps>>

class TopMenu extends Component<
  TopMenuProps & TopMenuReduxProps & RouteComponentProps,
  TopMenuState
> {
  constructor(props: TopMenuProps & TopMenuReduxProps & RouteComponentProps) {
    super(props)
    this.state = {
      mobileDropdownOpen: false
    }
  }

  renderDropdowns = () => {
    const {
      onOrganizationChange,
      location,
      mappings,
      graphStyleOverride
    } = this.props
    const { mobileDropdownOpen } = this.state
    if (window.innerWidth <= 768) {
      if (!mappings) {
        return null
      }
      return (
        <>
          <SystemMessageButton mobile={true} />
          <Caret
            isOpen={mobileDropdownOpen}
            onClick={() =>
              this.setState({ mobileDropdownOpen: !mobileDropdownOpen })
            }
          />
          <MobileDropdowns
            isOpen={mobileDropdownOpen}
            isGraphPage={isGraphPage(location, mappings) && !graphStyleOverride}
          >
            <EnvironmentSelector onOrganizationChange={onOrganizationChange} />
            <UserDropdown remountApp={onOrganizationChange} />
          </MobileDropdowns>
        </>
      )
    }
    return (
      <>
        <SystemMessageButton mobile={false} />
        <EnvironmentSelector onOrganizationChange={onOrganizationChange} />
        <UserDropdown remountApp={onOrganizationChange} />
      </>
    )
  }

  render() {
    const {
      history,
      user,
      location,
      mappings,
      onMobileMenuOpen,
      graphStyleOverride
    } = this.props
    if (!mappings) {
      return null
    }
    return (
      <MenuContainer
        isGraphPage={isGraphPage(location, mappings) && !graphStyleOverride}
      >
        <Icon
          id="hamburger"
          iconName="hamburger"
          size={30}
          onClick={() => onMobileMenuOpen()}
        />
        <Logo>
          <LogoImage onClick={() => history.push('/')} />
        </Logo>
        {user && sessionStorage.getItem('API_TOKEN') && this.renderDropdowns()}
      </MenuContainer>
    )
  }
}

const mapStateToProps = ({
  auth,
  graph: { settings, graphStyleOverride }
}: RootState) => ({
  user: auth.user,
  mappings: settings.mappings,
  graphStyleOverride
})

export default withRouter(connect(mapStateToProps, null)(TopMenu))

import React, { Component } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { TFunction, withTranslation } from 'react-i18next'
import Empty from 'components/roadmap/Empty'
import Icon from 'components/Icon'
import { createTask } from 'store/actions/task'
import { updateMaintask, deleteMaintaskRow } from 'store/actions/maintask'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import RemoveMainTaskRowModal from 'components/modals/RemoveMainTaskRowModal'
import { ISO_FORMAT_DATE } from 'util/dates'
import Task, { TaskResizeDir } from './Task'
import { RootState } from 'store/reducers'
import { DateSelection } from './Roadmap'
import { TaskType } from 'store/reducers/task'
import { MainTaskType } from 'store/reducers/maintask'
import { AppDispatch } from 'store/store'

const RowContainer = styled.div`
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  height: 39px;
`

const AddButton = styled.div`
  width: ${(props: { width: string }) => props.width};
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  z-index: 1;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding: 10px 15px;
    border: 1px solid #b9c5c8;
    border-radius: 10px;
    background-color: #dfe8ea66;
    width: 100%;
  }
  &:hover {
    span {
      visibility: visible;
    }
  }
`

type RowReduxState = ReturnType<typeof mapStateToProps>
type RowReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface RowProps {
  tasks: { [key: string]: TaskType }
  color: string
  darkColor: string
  dateSelection: DateSelection
  addRowEnabled: boolean
  mainTask: MainTaskType
  rowIndex: number
  refreshMainTask: () => void
  reloadMainTask: () => void
  t: TFunction
  isRoadmapManager?: boolean
}

class Row extends Component<RowProps & RowReduxState & RowReduxDispatch> {
  getKey = (year: string, month: string) => {
    let m = '-'
    if (String(month).length === 1) m = '-0'
    return year + m + month
  }

  addRow = async (mainTask: MainTaskType) => {
    const { reloadMainTask, updateMaintask: updateMaintaskAction } = this.props
    await updateMaintaskAction(
      JSON.stringify({
        ...mainTask,
        row_count: mainTask.row_count + 1
      }),
      mainTask.id
    )
    reloadMainTask()
  }

  deleteRow = async (id: number | string, rowIndex: number) => {
    const {
      refreshMainTask,
      deleteMaintaskRow: deleteMaintaskRowAction
    } = this.props
    await deleteMaintaskRowAction(id, rowIndex)
    refreshMainTask()
  }

  isResizeAllowed = (task: TaskType, dir: TaskResizeDir) => {
    const { tasks } = this.props
    const newStartDate = moment(task.start_date)
    const newEndDate = moment(task.start_date).add(task.date_length, 'M')
    if (newStartDate.isSameOrAfter(newEndDate)) {
      return false
    }
    for (const key in tasks) {
      const t = tasks[key]
      if (t.id === task.id) continue
      const startDate = moment(t.start_date)
      const endDate = moment(t.start_date).add(t.date_length, 'M')
      if (dir === 'right') {
        if (
          newEndDate.isAfter(startDate) &&
          newStartDate.isSameOrBefore(startDate)
        ) {
          return false
        }
      } else if (dir === 'left') {
        if (
          (newStartDate.isSameOrAfter(startDate) &&
            newStartDate.isBefore(endDate)) ||
          (newStartDate.isBefore(startDate) &&
            newEndDate.isSameOrAfter(endDate))
        ) {
          return false
        }
      }
    }
    return true
  }

  render() {
    const {
      color,
      darkColor,
      tasks,
      dateSelection,
      addRowEnabled,
      rowIndex,
      t,
      createTask: createTaskAction,
      mainTask,
      refreshMainTask,
      isRoadmapManager
    } = this.props
    const data: JSX.Element[] = []
    if (dateSelection) {
      const startMoment = moment(
        `${dateSelection.startYear}-${dateSelection.startMonth}`,
        'YYYY-MM'
      )
      const endMoment = moment(
        `${dateSelection.endYear}-${dateSelection.endMonth}`,
        'YYYY-MM'
      )
      if (tasks) {
        Object.keys(tasks).forEach(taskKey => {
          const taskMoment = moment(taskKey, 'YYYY-MM').startOf('month')
          const task = tasks[taskKey]
          if (!task) {
            return
          }
          const taskStartMoment = moment(task.start_date).startOf('month')
          const taskEndMoment = moment(task.start_date)
            .add(task.date_length, 'months')
            .endOf('month')
          if (
            taskMoment.isValid() &&
            (taskStartMoment >= startMoment || taskEndMoment >= startMoment) &&
            taskStartMoment < endMoment
          ) {
            data.push(
              <Task
                key={`task-${taskKey}`}
                task={task}
                isResizeAllowed={this.isResizeAllowed}
                darkColor={darkColor}
                color={color}
                rowLength={dateSelection.rowLength}
                dateSelection={dateSelection}
                isRoadmapManager={isRoadmapManager}
              />
            )
          }
        })
      }
      for (let i = 0; i < dateSelection.rowLength; i += 1) {
        data.push(
          <Empty key={`row-container-${i}`} dateSelection={dateSelection}>
            {isRoadmapManager && (
              <AddButton
                width={`${100 / dateSelection.rowLength}%`}
                onClick={async () => {
                  await createTaskAction(
                    JSON.stringify({
                      date_length: 1,
                      start_date: moment(
                        `${dateSelection.startYear}-${dateSelection.startMonth}`,
                        'YYYY-MM'
                      )
                        .add(i, 'months')
                        .format(ISO_FORMAT_DATE),
                      end_date: moment(
                        `${dateSelection.startYear}-${dateSelection.startMonth}`,
                        'YYYY-MM'
                      )
                        .add(i, 'months')
                        .format(ISO_FORMAT_DATE),
                      title: t('new-task'),
                      main_task: mainTask.id,
                      row_index: rowIndex,
                      targets: [],
                      user: 1
                    })
                  )
                  refreshMainTask()
                }}
              >
                <span>
                  <Icon iconName="plusCircle" size={20} />
                </span>
              </AddButton>
            )}
          </Empty>
        )
      }
    }

    const actions: JSX.Element[] = []

    if (isRoadmapManager) {
      actions.push(
        <RemoveMainTaskRowModal
          trigger={
            <Icon
              style={{
                marginLeft: '10px',
                position: 'absolute',
                display: 'inline-block',
                width: '20px',
                right: '-10px',
                top: '10px',
                cursor: 'pointer'
              }}
              color="#B9C5C8"
              iconName="trash"
              size={15}
            />
          }
          maintask={mainTask}
          rowIndex={rowIndex}
        />
      )
    }

    if (addRowEnabled && isRoadmapManager)
      actions.push(
        <Icon
          style={{
            marginLeft: '10px',
            position: 'absolute',
            display: 'inline-block',
            width: '20px',
            right: '-40px',
            bottom: '-10px',
            cursor: 'pointer'
          }}
          color="#777777"
          iconName="newRow"
          size={15}
          onClick={() => this.addRow(mainTask)}
        />
      )

    return (
      <>
        <RowContainer>
          {data}
          <div>{actions}</div>
        </RowContainer>
      </>
    )
  }
}

const mapStateToProps = ({ organization }: RootState) => ({
  organization
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      createTask,
      deleteMaintaskRow,
      updateMaintask
    },
    dispatch
  )

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Row)
)

import React from 'react'
import { Card, Title } from 'components/blocks'
import styled from 'styled-components/macro'
import { useTranslation, withTranslation } from 'react-i18next'
import { Post } from 'api'
import { toast } from 'react-toastify'
import GoogleLoginButton from 'components/blocks/GoogleLoginButton'
import { GoogleLoginResponse } from './Login'

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 150px);
  > div {
    width: 100%;
    max-width: 600px;
  }
`

const CardContent = styled.div`
  padding: 0.938rem;
  display: flex;
  flex-direction: column;
  label {
    font-family: 'Open Sans';
    font-weight: 100;
    color: #4a4a4a;
    letter-spacing: 0;
    line-height: 1.5;
    font-size: 1rem;
    margin: 0;
  }
  input {
    margin-bottom: 0.938rem;
    padding: 0.625rem;
    border-radius: 5px;
    border: 1px solid #dedede;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  button {
    box-sizing: border-box;
    border: 1px solid #ffd800;
    border-radius: 6px;
    color: #8e8e8e;
    font-family: 'Open Sans';
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 2;
    text-align: center;
    cursor: pointer;
    margin-right: 0.625rem;
    background: #fff;
    margin-top: 0.938rem;
  }
  h1 {
    margin-top: 0;
  }
`

const ConnectGoogleAccount = () => {
  const { t } = useTranslation()
  const googleResponse = async (res: GoogleLoginResponse) => {
    const { access_token } = res
    if (!access_token) {
      return
    }
    Post<{ key?: string }>(
      '/api/auth/google/connect/',
      JSON.stringify({ access_token: access_token })
    )
      .then(response => {
        if (!response.key) {
          toast.error(t('googleConnectError'))
          return
        }
        toast.success(t('googleConnectSuccess'))
      })
      .catch(() => {
        toast.error(t('googleConnectError'))
      })
  }
  return (
    <CardContainer>
      <Card>
        <CardContent>
          <Title>{t('connectGoogle')}</Title>
          <GoogleLoginButton googleResponse={googleResponse} />
        </CardContent>
      </Card>
    </CardContainer>
  )
}

export default withTranslation()(ConnectGoogleAccount)

import React from 'react'
import styled from 'styled-components/macro'
import Hamburger from 'assets/icons/hamburger.svg'
import Home from 'assets/icons/home.svg'
import Flow from 'assets/icons/flow.svg'
import FlowChart from 'assets/icons/flow-chart.svg'
import Roadmap from 'assets/icons/roadmap.svg'
import Notes from 'assets/icons/notes.svg'
import Sale from 'assets/icons/sale.svg'
import Info from 'assets/icons/info.svg'
import PDF from 'assets/icons/pdf.svg'
import XLS from 'assets/icons/xls.svg'
import CSV from 'assets/icons/csv.svg'
import UnknownFile from 'assets/icons/unkown-file.svg'
import Expand from 'assets/icons/expand.svg'
import Collapse from 'assets/icons/collapse.svg'
import Profits from 'assets/icons/profits.svg'
import PiggyBank from 'assets/icons/piggy-bank.svg'
import Operation from 'assets/icons/operation.svg'
import Euro from 'assets/icons/euro.svg'
import Calendar from 'assets/icons/roadmap/calendar.svg'
import ChartLine from 'assets/icons/roadmap/chart_line.svg'
import Edit from 'assets/icons/roadmap/edit.svg'
import Link from 'assets/icons/roadmap/link.svg'
import Milestone from 'assets/icons/roadmap/milestone.svg'
import Milestones from 'assets/icons/roadmap/milestones.svg'
import MilestoneGrey from 'assets/icons/roadmap/milestone_grey.svg'
import NewMilestone from 'assets/icons/roadmap/new_milestone.svg'
import NewTask from 'assets/icons/roadmap/new_task.svg'
import PlusCircle from 'assets/icons/roadmap/plus-circle.svg'
import Times from 'assets/icons/roadmap/times.svg'
import Trash from 'assets/icons/roadmap/trash.svg'
import ThreeDots from 'assets/icons/roadmap/three_dots.svg'
import NewRow from 'assets/icons/roadmap/new_row.svg'
import ArrowUp from 'assets/icons/roadmap/arrow-up.svg'
import ArrowDown from 'assets/icons/roadmap/arrow-down.svg'
import DoubleArrowRight from 'assets/icons/roadmap/double-arrow-right.svg'
import Table from 'assets/icons/table.svg'
import LightMode from 'assets/icons/light-mode.svg'
import DarkMode from 'assets/icons/dark-mode.svg'
import Computer from 'assets/icons/computer.svg'

const IconComponent = styled.div`
  background-color: ${(props: {
    color?: string
    height?: number
    width?: number
    size?: number
    src: string
  }) => (props.color ? props.color : '#ddd')};
  width: ${props => (props.width ? props.width : props.size)}px;
  text-align: center;
  height: ${props => (props.height ? props.height : props.size)}px;
  mask: url(${props => props.src}) center center / auto
    ${props => (props.width ? props.width / 2 : props.size)}px no-repeat;
  -webkit-mask: url(${props => props.src}) center center / auto
    ${props => (props.width ? props.width / 2 : props.size)}px no-repeat;
`

const ICON_MAP: { [key: string]: string } = {
  home: Home,
  flow: Flow,
  roadmap: Roadmap,
  notes: Notes,
  flowChart: FlowChart,
  sale: Sale,
  info: Info,
  pdf: PDF,
  xls: XLS,
  csv: CSV,
  unknownFile: UnknownFile,
  expand: Expand,
  collapse: Collapse,
  profits: Profits,
  piggyBank: PiggyBank,
  operation: Operation,
  euro: Euro,
  hamburger: Hamburger,
  calendar: Calendar,
  chartLine: ChartLine,
  edit: Edit,
  link: Link,
  milestone: Milestone,
  milestones: Milestones,
  milestoneGrey: MilestoneGrey,
  newMilestone: NewMilestone,
  newTask: NewTask,
  plusCircle: PlusCircle,
  times: Times,
  trash: Trash,
  threeDots: ThreeDots,
  newRow: NewRow,
  arrowUp: ArrowUp,
  arrowDown: ArrowDown,
  doubleArrowRight: DoubleArrowRight,
  table: Table,
  lightMode: LightMode,
  darkMode: DarkMode,
  computer: Computer
}

export default function Icon({
  id,
  iconName,
  size,
  width,
  height,
  style,
  className,
  onClick,
  onFocus,
  onMouseMove,
  onMouseLeave,
  onMouseDown,
  color,
  ...rest
}: {
  id?: string
  iconName: string
  size?: number
  width?: number
  height?: number
  className?: string
  onClick?: React.MouseEventHandler
  onFocus?: React.FocusEventHandler
  onMouseMove?: React.MouseEventHandler
  onMouseLeave?: React.MouseEventHandler
  onMouseDown?: React.MouseEventHandler
  style?: React.CSSProperties
  color?: string
}) {
  return (
    <IconComponent
      {...rest} // eslint-disable-line
      id={id}
      onFocus={onFocus}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      onClick={onClick}
      size={size}
      width={width}
      height={height}
      src={ICON_MAP[iconName]}
      className={`icon${className ? ` ${className}` : ''}`}
      style={style}
      color={color}
    >
      &nbsp;
    </IconComponent>
  )
}

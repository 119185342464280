import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { TFunction, withTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Card } from 'components/blocks'
import { Targets } from 'components/strategy'
import { getTargetGroup, hideTargetGroup } from 'store/actions/targetgroup'
import Avatar from './Avatar'
import { RootState } from 'store/reducers'

const CardsContainer = styled.div`
  display: flex;
  justify-content: center;
  > div {
    flex-grow: 1;
    max-width: 655px;
    margin-right: 1.563rem;
    position: relative;
    &:last-of-type {
      margin-right: 0;
    }
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  > div:first-of-type {
    flex-grow: 1;
  }
  button {
    height: 1.25rem;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 0.875rem;
    background: transparent;
    padding-top: 0.938rem;
    margin-bottom: -1.25rem;
    width: 100%;
    color: #333;
    z-index: 2;
  }
`

const CardContent = styled.div`
  padding: 34px 0 1.25rem 0;
  h3 {
    margin: 0;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 0.625rem;
    color: ${props => props.theme.strategy.cardTitle};
    font-family: 'Open Sans';
    font-size: 0.875rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1.188rem;
    text-align: center;
  }
`

const TargetGroupsContainer = styled.div`
  margin-top: -5px;
  position: relative;
  min-width: ${(props: { groupAmount: number }) => props.groupAmount * 400}px;
`

const TopGroupText = styled.p`
  position: absolute;
  width: 250px;
  text-align: center;
  left: calc(50% - 8.5rem);
  top: -60px;
  color: ${props => props.theme.strategy.title};
  font-family: 'Open Sans';
  font-size: 0.938rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.25rem;
  text-align: center;
  text-transform: uppercase;
`

const BottomGroupText = styled.p`
  position: absolute;
  width: 250px;
  text-align: center;
  left: calc(50% - 8.5rem);
  bottom: -45px;
  color: ${props => props.theme.strategy.title};
  font-family: 'Open Sans';
  font-size: 0.938rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.25rem;
  text-align: center;
  text-transform: uppercase;
  z-index: 100;
`

const CollapseButton = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  width: 1.875rem;
  height: 1.875rem;
  align-self: center;
  color: white;
  background: #cfdbde;
  border-radius: 100%;
  text-align: center;
  z-index: 999;
  margin-top: -0.625rem;
  cursor: pointer;
  font-weight: 600;
  user-select: none;
`

interface TargetGroupsProps {
  targetGroups: RootState['targetGroup']['list']['results']
  t: TFunction
  targetGroupParams: { [key: string]: string }
}

type TargetGroupsReduxState = ReturnType<typeof mapStateToProps>
type TargetGroupsReduxDispatch = ReturnType<typeof mapDispatchToProps>

class TargetGroups extends Component<
  TargetGroupsProps & TargetGroupsReduxState & TargetGroupsReduxDispatch
> {
  constructor(
    props: TargetGroupsProps &
      TargetGroupsReduxState &
      TargetGroupsReduxDispatch
  ) {
    super(props)
  }

  componentDidUpdate(prevProps: TargetGroupsProps) {
    const { targetGroupParams: prevParams } = prevProps
    const {
      targetGroupParams: newParams,
      targetGroupData,
      targetGroups,
      getTargetGroup: getTargetGroupAction
    } = this.props
    if (
      prevParams.start_date !== newParams.start_date ||
      prevParams.end_date !== newParams.end_date
    ) {
      targetGroups.map(t => {
        if (t.has_children && targetGroupData[t.id]) {
          getTargetGroupAction(t.id, newParams)
        }
      })
    }
  }

  render() {
    const {
      targetGroups,
      t: translate,
      getTargetGroup: getTargetGroupAction,
      hideTargetGroup: hideTargetGroupAction,
      targetGroupData,
      targetGroupParams
    } = this.props
    return (
      <TargetGroupsContainer groupAmount={(targetGroups || []).length}>
        {targetGroups[0] && targetGroups[0].parent === null ? (
          <TopGroupText>{translate('mainTarget')}</TopGroupText>
        ) : null}
        <CardsContainer>
          {targetGroups.map(t => (
            <CardContainer key={t.id}>
              <Card data-parent={t.parent || 'root'} data-id={t.id}>
                <CardContent>
                  <Avatar user={t.user} />
                  <h3>{t.title}</h3>
                  <Targets group={t} />
                </CardContent>
              </Card>
              {t.has_children && !targetGroupData[t.id] ? (
                <CollapseButton
                  onClick={() => getTargetGroupAction(t.id, targetGroupParams)}
                >
                  +
                </CollapseButton>
              ) : null}
              {t.has_children && targetGroupData[t.id] ? (
                <CollapseButton onClick={() => hideTargetGroupAction(t.id)}>
                  -
                </CollapseButton>
              ) : null}
            </CardContainer>
          ))}
        </CardsContainer>
        {targetGroups[0] && targetGroups[0].parent === null ? (
          <BottomGroupText>{translate('focusAreas')}</BottomGroupText>
        ) : null}
      </TargetGroupsContainer>
    )
  }
}

const mapStateToProps = ({ targetGroup }: RootState) => ({
  targetGroupData: targetGroup.data
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getTargetGroup,
      hideTargetGroup
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TargetGroups))

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Title } from 'components/blocks'
import { connect } from 'react-redux'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { resetErrorMessage } from 'store/actions/error'
import { RootState } from 'store/reducers'
import { TFunction } from 'i18next'

class Home extends Component<{ t: TFunction }> {
  render() {
    const { t } = this.props
    return (
      <div className="App">
        <Title>{t('home')}</Title>
      </div>
    )
  }
}

const mapStateToProps = ({ error }: RootState) => ({
  error
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      resetErrorMessage
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Home))

import { DefaultTheme } from 'styled-components'

export const LIGHT_THEME = 'light'
export const DARK_THEME = 'dark'
export const SYSTEM = 'system'

export const lightTheme: DefaultTheme = {
  id: LIGHT_THEME,
  common: {
    background: '#F3F3F3'
  },
  text: {
    primary: '#575757',
    secondary: '#575757',
    title: '#4a4a4a',
    dropdown: '#575757'
  },
  dataMap: {
    primaryBg: '#fff',
    secondaryBg: '#f0f0f0'
  },
  navigation: {
    background: '#fff',
    icon: '#777',
    activeItemBg: '#F8F8F8',
    itemHoverBg: '#dbd7d7',
    resizeBg: '#E3E3E3',
    border: '1px solid #dbd7d7'
  },
  topMenu: {
    background: '#fff',
    labelColor: '#939393',
    dropdownColor: '#606160',
    dropdownBorder: '1px solid rgba(0,0,0,0)'
  },
  strategy: {
    title: '#575757',
    strokeColor: '#CFDBDE',
    cardBackground: '#fff',
    cardShadow: '5px 5px 5px 0 #dbdbdb',
    cardTitle: '#565656',
    targetTitle: '#6C6C6C'
  },
  graphPage: {
    background: '#fff',
    topMenuBg: '#fff',
    titleColor: '#4a4a4a',
    darkNumbers: true
  },
  numbers: {
    color: '#333'
  },
  roadMap: {
    tabBg: '#ddd',
    tabColor: '#2b2b2b',
    selectedTabColor: 'black',
    selectedTabBg: '#fdfdfd',
    newTab: '#eee',
    rowBg: '#fff',
    iconColor: '#243c72',
    line: '1px solid #b9c5c8',
    background: '#fdfdfd'
  },
  logic: {
    nodeBackground: '#fff',
    nodeShadow: '5px 5px 5px 0 #dbdbdb',
    nodeTitleColor: '#434343',
    linkColor: '#333'
  },
  notifications: {
    title: '#4a4a4a',
    showMore: '#2277ee',
    filesBorder: '1px solid #e9e9e9',
    fileName: '#6a6a6a',
    switchColor: '#444'
  },
  termTooltip: {
    title: '#2b2b2b',
    contentColor: '#626262'
  }
}

export const darkTheme: DefaultTheme = {
  id: DARK_THEME,
  common: {
    background: '#222222'
  },
  text: {
    primary: '#fff',
    secondary: '#DBDBDB',
    title: '#FFD800',
    dropdown: '#575757'
  },
  navigation: {
    background: '#333333',
    icon: '#777',
    activeItemBg: '#2B2B2B',
    itemHoverBg: '#4d4d4d',
    resizeBg: '#3a3a3a',
    border: '1px solid #4d4d4d'
  },
  dataMap: {
    primaryBg: '#333333',
    secondaryBg: '#4d4d4d'
  },
  topMenu: {
    background: '#2B2B2B',
    labelColor: '#AFAFAF',
    dropdownColor: '#DBDBDB',
    dropdownBorder: '1px solid rgba(255,255,255,0.4)'
  },
  strategy: {
    title: '#AFAFAF',
    strokeColor: '#333333',
    cardBackground: '#333333',
    cardShadow: '5px 5px 5px 0 #191919',
    cardTitle: '#AFAFAF',
    targetTitle: '#DBDBDB'
  },
  graphPage: {
    background: '#191919',
    topMenuBg: 'transparent',
    titleColor: '#FFD800',
    darkNumbers: false
  },
  numbers: {
    color: '#fff'
  },
  roadMap: {
    tabBg: '#2B2B2B',
    tabColor: '#AFAFAF',
    selectedTabBg: '#222222',
    selectedTabColor: '#DBDBDB',
    newTab: '#4F4F4F',
    rowBg: '#333333',
    iconColor: '#FFD800',
    line: '1px solid rgba(151, 151, 151, 0.2)',
    background: '#222222'
  },
  logic: {
    nodeBackground: '#333333',
    nodeShadow: '5px 5px 5px 0 #191919',
    nodeTitleColor: '#DBDBDB',
    linkColor: '#fff'
  },
  notifications: {
    title: '#AFAFAF',
    showMore: '#fff',
    filesBorder: '1px solid #414141',
    fileName: '#fff',
    switchColor: '#fff'
  },
  termTooltip: {
    title: '#fff',
    contentColor: '#DBDBDB'
  }
}

export const getLightTheme = () => lightTheme
export const getDarkTheme = () => darkTheme

import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { Redirect, RouteComponentProps } from 'react-router-dom'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { login } from 'store/actions/auth'
import { resetErrorMessage } from 'store/actions/error'
import Logo from 'assets/images/logo-light.png'
import { ReactComponent as MicrosoftLogo } from 'assets/icons/microsoft_logo.svg'
import ApiError from 'ApiError'
import { Post } from 'api'
import { RootState } from 'store/reducers'
import { AppDispatch } from 'store/store'
import GoogleLoginButton from 'components/blocks/GoogleLoginButton'
import { SSOLoginButtonStyle } from 'components/blocks/SSOLoginButton'

const Container = styled.div`
  text-align: center;
  border-radius: 3px;
  background-color: #3a3a3a;
  margin: 0 auto;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  width: 400px;
  align-items: center;
  form {
    width: 100%;
  }
  label {
    font-size: 13px;
    font-family: 'Open Sans';
    color: #fff;
    display: block;
    margin-bottom: 7px;
    text-transform: capitalize;
    font-weight: bold;
    text-align: left;
    margin-left: 15px;
  }
  input {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 4px;
    border: none;
    background: #4a4a4a;
    color: #fff;
  }
`

const Submit = styled.button`
  cursor: pointer;
  width: 100%;
  border: none;
  background: #ffd800;
  padding: 10px;
  font-size: 13px;
  font-weight: bold;
  border-radius: 4px;
  margin-top: 30px;

  :disabled {
    background: gray;
  }
`

const LogoComponent = styled.div`
  background: url(${Logo});
  background-size: 100%;
  background-repeat: no-repeat;
  width: 70%;
  height: 45px;
`

const LoginHelp = styled.span`
  font-size: 13px;
  font-family: 'Open Sans';
  color: #fff;
  margin: 30px 0;
`

const ErrorMessage = styled.div`
  position: fixed;
  top: ${(props: { error: string | null }) => (props.error ? '50px' : '-50px')};
  background: #aa233b;
  padding: 10px;
  font-size: 13px;
  font-family: 'Open Sans';
  color: #fff;
  p {
    margin-top: 0;
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

export const AzureLogin = styled.a`
  ${SSOLoginButtonStyle}
  color: rgba(0, 0, 0, 0.54);
  padding: 0px;
  box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
  font-family: Roboto, sans-serif;
  cursor: pointer;
  text-decoration: none;
  > span {
    padding: 10px;
    padding-left: 0;
  }
  > div {
    margin-right: 10px;
    background: rgb(255, 255, 255);
    padding: 10px;
    border-radius: 2px;
  }
`

type LoginReduxState = ReturnType<typeof mapStateToProps>
type LoginReduxDispatch = ReturnType<typeof mapDispatchToProps>

export type GoogleLoginResponse = {
  access_token: string
}

interface LoginState {
  username: string
  password: string
  redirectToReferrer: boolean
  loginErrorFi: string | null
  loginErrorEn: string | null
}

class Login extends Component<
  LoginReduxState & LoginReduxDispatch & RouteComponentProps,
  LoginState
> {
  /* eslint-disable */
  state = {
    username: '',
    password: '',
    redirectToReferrer: false,
    loginErrorFi: null,
    loginErrorEn: null
  }
  /* eslint-enable */

  fields = [
    { type: 'username', name: 'username' },
    { type: 'password', name: 'password' }
  ]

  componentDidMount() {
    const {
      location: { hash }
    } = this.props
    const query = hash.substr(1)
    const result: { [key: string]: string } = {}

    query.split('&').forEach((part: string) => {
      const item = part.split('=')
      result[item[0]] = decodeURIComponent(item[1])
    })

    if (result && result.id_token) {
      const { id_token: idToken } = result
      this.handleAzureRequests(idToken)
    }
  }

  componentDidUpdate() {
    const { error } = this.props

    if (error && error.messages && error.messages.non_field_errors[0]) {
      setTimeout(this.resetError, 5000)
    }
  }

  handleAzureRequests = (idToken: string) => {
    const { history } = this.props
    if (localStorage.getItem('attempt-azure-connect')) {
      const url = `/api/auth/azure/connect/`
      Post(url, JSON.stringify({ id_token: idToken })).then(data => {
        history.push('/connect-azure')
        const error = data instanceof ApiError
        localStorage.setItem('microsoft-connected', String(!error))
      })
      localStorage.removeItem('attempt-azure-connect')
      return
    }
    const url = `/api/auth/azure/`
    Post(url, JSON.stringify({ id_token: idToken })).then(data => {
      if (data instanceof ApiError) {
        this.setState({
          loginErrorFi:
            'Microsoft-tunnusta ei ole linkitetty Clarityn tunnukseen.',
          loginErrorEn:
            'The Microsoft account is not linked to any Clarity accounts.'
        })
        return
      }
      this.setState({ redirectToReferrer: true })
    })
  }

  resetError = () => {
    const { resetErrorMessage: resetErrorAction } = this.props
    resetErrorAction()
  }

  submitLogin = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const { username, password } = this.state
    const { login: loginAction } = this.props
    await loginAction({ username, password })
    this.setState({ redirectToReferrer: true })
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    this.setState({ ...this.state, [name]: value })
  }

  render() {
    const {
      error,
      auth: { isPending },
      location
    } = this.props

    const { from } = (location.state as { from: { pathname: string } }) || {
      from: { pathname: '/' }
    }
    const { redirectToReferrer, loginErrorFi, loginErrorEn } = this.state

    if (redirectToReferrer) return <Redirect to={from} />

    const inputs = this.fields.map(({ type, name }) => {
      return (
        <React.Fragment key={name}>
          <label>{name === 'username' ? 'email' : name}</label>
          <input
            key={`${type}-${name}`}
            type={type}
            name={name}
            onChange={this.handleChange}
            placeholder={`Write ${name === 'username' ? 'email' : name}...`}
          />
        </React.Fragment>
      )
    })

    const googleResponse = async (res: GoogleLoginResponse) => {
      const { access_token } = res

      if (access_token) {
        try {
          const { key } = await Post(
            '/api/auth/google/',
            JSON.stringify({ access_token: access_token })
          )
          if (key) {
            sessionStorage.setItem('API_TOKEN', key)
            this.setState({ redirectToReferrer: true })
          }
        } catch (err) {
          this.setState({
            loginErrorFi:
              'Google-tunnusta ei ole linkitetty Clarityn tunnukseen.',
            loginErrorEn:
              'The Google account is not linked to any Clarity accounts.'
          })
        }
      }
    }

    const nonFieldError =
      error && error.messages && error.messages.non_field_errors
        ? error.messages.non_field_errors[0]
        : null
    return (
      <Container>
        <LogoComponent />
        <LoginHelp>Type in your email and password to login.</LoginHelp>
        <form onSubmit={this.submitLogin}>
          {inputs}
          <Submit type="submit" disabled={isPending}>
            Login
          </Submit>
        </form>
        <ErrorMessage error={nonFieldError}>{nonFieldError}</ErrorMessage>
        <ErrorMessage error={loginErrorFi}>
          <p>{loginErrorFi}</p>
          <p>{loginErrorEn}</p>
        </ErrorMessage>
        <br />
        <GoogleLoginButton googleResponse={googleResponse} />
        <AzureLogin href={process.env.REACT_APP_AZURE_LOGIN_URL}>
          <div>
            <MicrosoftLogo width={18} height={18} />
          </div>
          <span>LOGIN WITH MICROSOFT</span>
        </AzureLogin>
      </Container>
    )
  }
}

const mapStateToProps = ({ auth, error }: RootState) => ({
  auth,
  error
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      login,
      resetErrorMessage
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Login)

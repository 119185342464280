import {
  CREATE_MILESTONE,
  UPDATE_MILESTONE,
  GET_MILESTONE,
  LIST_MILESTONE,
  DELETE_MILESTONE
} from 'store/actions/milestone'

import produce from 'immer'
import { AnyAction } from 'redux'

export type MilestoneType = {
  id: number
  title: string
  end_date: string
  roadmap: number
  user: number
}

interface MilestoneState {
  data: MilestoneType | null
  created: MilestoneType | null
  list: {
    results: Array<MilestoneType>
  }
  isPending: boolean
  error: Error | Array<Error> | null
}

const initialState: MilestoneState = {
  data: null,
  created: null,
  list: { results: [] },
  isPending: false,
  error: null
}

const milestone = (state = initialState, action: AnyAction) => {
  const { type, payload } = action

  switch (type) {
    case `${GET_MILESTONE}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${GET_MILESTONE}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${GET_MILESTONE}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.data = payload
      })

    case `${LIST_MILESTONE}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${LIST_MILESTONE}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${LIST_MILESTONE}_FULFILLED`:
      return produce(state, draft => {
        draft.list = payload
        draft.isPending = false
      })

    case `${CREATE_MILESTONE}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${CREATE_MILESTONE}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${CREATE_MILESTONE}_FULFILLED`:
      return produce(state, draft => {
        draft.created = payload
        draft.isPending = false
      })

    case `${UPDATE_MILESTONE}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${UPDATE_MILESTONE}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${UPDATE_MILESTONE}_FULFILLED`:
      return produce(state, draft => {
        draft.created = payload
        draft.isPending = false
      })

    case `${DELETE_MILESTONE}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${DELETE_MILESTONE}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${DELETE_MILESTONE}_FULFILLED`:
      return produce(state, draft => {
        draft.created = payload
        draft.isPending = false
      })

    default:
      return state
  }
}

export default milestone

import React from 'react'
import moment from 'moment'
import styled, { useTheme } from 'styled-components/macro'
import { formatFloat } from 'util/format'
import { MONTH_FORMAT } from 'util/dates'
import { toggleDataMapTermOpen } from 'store/actions/rawdata'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/reducers'

const FINANCIAL_MODEL_ENDING = ' (FM)'
export const EXPAND_SIGN = '+'
export const COLLAPSE_SIGN = '-'

const DataMapContainer = styled.div`
  display: inline-flex;
  min-width: 100%;
  > .title-container {
    display: flex;
    position: sticky;
    left: 0;
    background: #f0f0f0;
    align-items: center;
    min-width: 300px;
    flex-basis: 300px;
    font-family: 'Open Sans';
    font-size: 0.9rem;
    letter-spacing: 0;
    line-height: 1;
    padding: 0.5rem;
    cursor: pointer;
    padding-left: ${(props: { titleMargin?: number }) =>
      props.titleMargin || 0}px;
    border: 0.5px solid #aaa;
    border-top: none;
    word-break: break-word;
    .expand-collapse {
      text-align: center;
      margin-left: 0.5rem;
      min-width: 15px;
      min-height: 15px;
      background: ${props => props.theme.dataMap.primaryBg};
      border: 1px solid #ddd;
      margin-right: 5px;
      font-size: 0.8rem;
      line-height: 1.2;
      color: ${props => props.theme.text.primary};
    }
    &.no-children > span {
      margin-left: calc(1rem + 15px);
    }
    @media screen and (max-width: 768px) {
      min-width: 150px;
      flex-basis: 150px;
    }
  }
  .numbers {
    display: flex;
    width: 100%;
  }
`

const Number = styled.p`
  background: ${(props: { color: string; isVerified: boolean }) => props.color};
  border-bottom: 0.5px solid #aaa;
  border-right: 0.5px solid #aaa;
  min-width: 150px;
  margin: 0;
  white-space: nowrap;
  padding: 0.5rem;
  font-family: 'Open Sans';
  font-size: 0.9rem;
  letter-spacing: 0;
  line-height: 1;
  text-align: right;
  cursor: default;
  pointer-events: none;
  ${props => (props.isVerified === true ? 'font-weight: bold;' : '')}
  ${props => (props.isVerified === false ? 'font-style: italic;' : '')}
`

const DataMap: React.FC<{
  dataMap: RootState['rawData']['dataMap']
  titleMargin?: number
  color?: string
  key?: string
}> = ({ dataMap, titleMargin, color }) => {
  if (!dataMap) {
    return null
  }
  const theme = useTheme()
  const DEFAULT_COLORS = [theme.dataMap.primaryBg, theme.dataMap.secondaryBg]
  const { openedDataMapTerms } = useSelector(
    (state: RootState) => state.rawData
  )
  const dispatch = useDispatch()

  const renderNumbers = (term: string, index: number) => (
    <div className="numbers">
      {Object.keys(dataMap[term]).map(key => {
        const date = moment(key, MONTH_FORMAT)
        if (date.isValid()) {
          return (
            <Number
              key={key}
              color={color || DEFAULT_COLORS[index % DEFAULT_COLORS.length]}
              isVerified={dataMap[term][key]?.verified}
            >
              {typeof dataMap[term][key]?.value === 'number'
                ? formatFloat(dataMap[term][key]?.value)
                : '-'}
            </Number>
          )
        }
        return null
      })}
    </div>
  )

  const renderTitles = (term: string, index: number) => (
    <div
      className={`title-container${
        dataMap[term].children ? '' : ' no-children'
      }`}
      style={{
        backgroundColor: color || DEFAULT_COLORS[index % DEFAULT_COLORS.length]
      }}
      onClick={() => dispatch(toggleDataMapTermOpen(term))}
    >
      {dataMap[term].children ? (
        <span className="expand-collapse">
          {openedDataMapTerms.includes(term) ? COLLAPSE_SIGN : EXPAND_SIGN}
        </span>
      ) : null}{' '}
      <span>{term.replace(FINANCIAL_MODEL_ENDING, '')}</span>
    </div>
  )

  const renderChildren = (term: string, index: number) => (
    <>
      {openedDataMapTerms.includes(term) && dataMap[term].children ? (
        <DataMap
          key={Object.keys(dataMap[term].children).join(',')}
          dataMap={dataMap[term].children}
          titleMargin={(titleMargin || 0) + 15}
          color={color || DEFAULT_COLORS[index % DEFAULT_COLORS.length]}
        />
      ) : null}
    </>
  )

  return (
    <>
      {Object.keys(dataMap).map((term, index) => (
        <React.Fragment key={term}>
          <DataMapContainer titleMargin={titleMargin}>
            {renderTitles(term, index)}
            {renderNumbers(term, index)}
          </DataMapContainer>
          {renderChildren(term, index)}
        </React.Fragment>
      ))}
    </>
  )
}

export default DataMap

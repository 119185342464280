import React, { Component } from 'react'
import moment from 'moment'
import { TFunction, withTranslation } from 'react-i18next'
import { Title } from 'components/blocks'
import { connect } from 'react-redux'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { resetErrorMessage } from 'store/actions/error'
import {
  deleteNotification,
  listNotification
} from 'store/actions/notification'
import { setNotificationsRead } from 'store/actions/auth'
import { NotificationList } from 'components/notification'
import DateSelector, { Years } from 'components/notification/DateSelector'
import styled from 'styled-components/macro'
import { CreateNotificationModal } from 'components/modals'
import { RouteComponentProps, withRouter } from 'react-router'
import { Switch } from './GraphView'
import { RootState } from 'store/reducers'

const TitleArea = styled.div`
  display: flex;
  flex-direction: column;
  h1 {
    display: flex;
    align-items: center;
  }
  > div {
    margin-bottom: 1.25rem;
  }
`

const CreateButton = styled.span`
  cursor: pointer;
  border-radius: 3px;
  margin-left: 1.25rem;
  background: #b9c5c8;
  box-shadow: 2px 2px 0 0 #93a4a8;
  padding: 0 0.625rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.875rem;
  color: white;
  user-select: none;
`

type NotificationsReduxState = ReturnType<typeof mapStateToProps>
type NotificationsReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface NotificationsProps {
  t: TFunction
}

interface NotificationState {
  results: RootState['notification']['list']['results']
  createModalOpen: boolean
  dateObj: Years | null
}

class Notifications extends Component<
  NotificationsProps &
    NotificationsReduxState &
    NotificationsReduxDispatch &
    RouteComponentProps,
  NotificationState
> {
  constructor(
    props: NotificationsProps &
      NotificationsReduxState &
      NotificationsReduxDispatch &
      RouteComponentProps
  ) {
    super(props)
    this.state = {
      results: [],
      createModalOpen: false,
      dateObj: null
    }
  }

  async componentDidMount() {
    const {
      listNotification: listNotificationAction,
      setNotificationsRead: setNotificationsReadAction
    } = this.props
    await listNotificationAction()
    setNotificationsReadAction()
    const {
      notification: {
        list: { results }
      }
    } = this.props
    const { dateObj } = this.state
    if (dateObj) {
      this.updateList(dateObj, results)
    } else this.setState({ results })
  }

  updateList = (
    dateObj: Years,
    originalResults?: NotificationState['results']
  ) => {
    this.setState({ dateObj })
    const {
      notification: {
        list: { results }
      }
    } = this.props
    let newResults = [...results]
    if (originalResults) newResults = [...originalResults]
    if ((dateObj && Object.keys(dateObj).length === 0) || dateObj === null) {
      this.setState({ results })
      return
    }
    const months = [...Object.values(dateObj)[0]]
    if (months && months.length) {
      const sortedMonths = months.sort((a, b) =>
        moment(a, 'YYYY-MM') <= moment(b, 'YYYY-MM') ? -1 : 1
      )
      const startMonth = sortedMonths[0]
      const endMonth = sortedMonths[sortedMonths.length - 1]
      newResults = newResults.filter(
        r =>
          moment(r.published) < moment(endMonth).endOf('month') &&
          moment(r.published) > moment(startMonth).startOf('month')
      )
    }
    this.setState({ results: newResults })
  }

  openCreateModal = () => this.setState({ createModalOpen: true })

  closeCreateModal = (update: boolean) =>
    this.setState(
      { createModalOpen: false },
      update
        ? async () => {
            const { listNotification: listNotificationAction } = this.props
            await listNotificationAction()
            const {
              notification: {
                list: { results }
              }
            } = this.props
            this.setState({ results })
          }
        : () => null
    )

  deleteNotification = async (id: number | string) => {
    const { deleteNotification: deleteNotificationAction } = this.props
    await deleteNotificationAction(id)
    const { listNotification: listNotificationAction } = this.props
    await listNotificationAction()
    const {
      notification: {
        list: { results }
      }
    } = this.props
    this.setState({ results })
  }

  render() {
    const { createModalOpen } = this.state
    const { t, user, history } = this.props
    const { results } = this.state
    return (
      <div className="App">
        <TitleArea>
          <Title>
            {t('notifications')}{' '}
            {(user || {}).notification_manager ? (
              <CreateButton onClick={this.openCreateModal}>
                + {t('create-notification')}
              </CreateButton>
            ) : null}
          </Title>
          <DateSelector
            location="notifications"
            data={results}
            keyToDate="published"
            onDateChange={this.updateList}
            useCurrentYearAsYtd
            rangeSelect
            ytdEmpty
          />
        </TitleArea>
        {(user || {}).report_manager ? (
          <Switch>
            <span className="selected">{t('notifications')}</span>
            <span onClick={() => history.push('/reports')}>{t('reports')}</span>
          </Switch>
        ) : null}
        <NotificationList
          data={results}
          isNotificationManager={(user || {}).notification_manager || false}
          deleteNotification={this.deleteNotification}
          updateList={async () => {
            const { listNotification: listNotificationAction } = this.props
            await listNotificationAction()
            const {
              notification: {
                list: { results: notificationResults }
              }
            } = this.props
            this.setState({ results: notificationResults })
          }}
        />
        <CreateNotificationModal
          isOpen={createModalOpen}
          onClose={this.closeCreateModal}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ error, notification, auth }: RootState) => ({
  error,
  notification,
  user: auth.user
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      resetErrorMessage,
      listNotification,
      setNotificationsRead,
      deleteNotification
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(Notifications)))

import { Get } from 'api/index'
import { createAsyncAction } from 'redux-promise-middleware-actions'

export const GET_TERM_OF_CLASSIFICATION = 'GET_TERM_OF_CLASSIFICATION'
export const LIST_TERM_OF_CLASSIFICATION = 'LIST_TERM_OF_CLASSIFICATION'
export const GET_TIMELINE_FOR_DATA = 'GET_TIMELINE_FOR_DATA'

export const getTermOfClassification = createAsyncAction(
  GET_TERM_OF_CLASSIFICATION,
  async (id: number) => {
    const url = `/api/term_of_classification/${id}/`
    const data = await Get(url)
    return data
  }
)

export const listTermOfClassification = createAsyncAction(
  LIST_TERM_OF_CLASSIFICATION,
  async (showInTree = false) => {
    const url = `/api/term_of_classification/`
    const data = await Get(url, { for_tree: showInTree, no_fm: true })
    return data
  }
)

export const getTimelineForData = createAsyncAction(
  GET_TIMELINE_FOR_DATA,
  async (keys: string[]) => {
    const url = '/api/term_of_classification/get_timeline_for_data/'
    const data = await Get(url, { keys })
    return data
  }
)

import React, { Component } from 'react'
import moment from 'moment'
import { Card, Title } from 'components/blocks'
import { File } from 'components/notification'
import styled from 'styled-components'
import { TFunction, useTranslation, withTranslation } from 'react-i18next'
import { EditNotificationModal } from 'components/modals'
import xss from 'xss'
import { Notification } from 'store/reducers/notification'

export const PRESENTATION_DATE_FORMAT = 'DD/MM/YYYY'

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    width: 100%;
    margin-bottom: 1.25rem;
    max-width: 1400px;
    flex-direction: column;
    @media screen and (min-width: 768px) {
      flex-direction: row;
      &:nth-of-type(odd) {
        margin-right: 1.25rem;
      }
    }
  }
`
const CardContent = styled.div`
  position: relative;
  padding: 1.25rem;
  flex-grow: 1;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  word-break: break-word;
  > div {
    overflow: hidden;
    max-height: ${(props: { expanded: boolean; contentHeight: number }) =>
      props.expanded ? `${props.contentHeight}px` : '100px'};
    min-height: 100px;
    transition: ${props =>
      props.expanded ? 'max-height 0.5s ease-in' : 'max-height 0.5s ease-out'};
    margin-bottom: 1.25rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    font-family: 'Open Sans';
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 100;
    color: ${props => props.theme.notifications.title};
    letter-spacing: 0;
  }
  h4 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    color: ${props => props.theme.notifications.title};
    letter-spacing: 0;
    line-height: 1.813rem;
  }
  span.date {
    color: #b9c5c8;
    font-family: 'Open Sans';
    font-size: 0.813rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.125rem;
  }
`
const Files = styled.div`
  margin: 1.25rem;
  width: 100%;
  max-width: 200px;
  @media screen and (min-width: 768px) {
    padding: 0 1.25rem;
    border-left: ${props => props.theme.notifications.filesBorder};
  }
  p {
    margin: 0;
    margin-bottom: 0.938rem;
    font-weight: bold;
  }
  > div {
    display: flex;
    flex-grow: 1;
  }
`
const ShowMore = styled.p`
  font-size: 0.938rem;
  color: ${props => props.theme.notifications.showMore};
  font-weight: bold;
  cursor: pointer;
`
const NewIcon = styled.span`
  margin-left: 0.938rem;
  padding: 2px 0.938rem;
  background: #ff4545;
  color: white !important;
  border-radius: 0.875rem;
`
const NoNotification = styled.h3`
  margin: 0;
  font-size: 1.25rem;
  font-weight: 300;
  color: #4a4a4a;
  letter-spacing: 0;
  line-height: 1.813rem;
`
const DeleteButton = styled.button`
  padding: 5px 0.625rem;
  background: #ce2033;
  color: white;
  border-radius: 3px;
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
  outline: none;
  float: right;
`
const EditButton = styled.button`
  padding: 5px 0.625rem;
  background: #aaa;
  color: white;
  border-radius: 3px;
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
  outline: none;
`
const ModalContainer = styled.div`
  visibility: ${(props: { isOpen: boolean }) =>
    props.isOpen ? 'visible' : 'hidden'};
  pointer-events: ${props => (props.isOpen ? 'all' : 'none')};
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #33333366;
  z-index: 1000;
  > div {
    max-width: 500px;
    padding: 0.625rem 0.938rem;
    box-shadow: none;
    text-align: center;
    margin: 0 0.625rem;
    h1 {
      margin-top: 0;
    }
  }
`
const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  > button {
    padding: 5px 0.625rem;
    background: #bfbfbf;
    color: white;
    border-radius: 3px;
    border: none;
    font-size: 1.125rem;
    cursor: pointer;
    outline: none;
    margin-right: 0.625rem;
    &:last-of-type {
      margin-right: 0;
    }
  }
`

const DeleteModal = ({
  onClose,
  deleteNotification,
  isOpen
}: {
  onClose: () => void
  deleteNotification: () => void
  isOpen: boolean
}) => {
  const { t } = useTranslation()
  return (
    <ModalContainer isOpen={isOpen}>
      <Card>
        <Title>{t('delete-confirmation')}</Title>
        <ActionContainer>
          <button type="button" onClick={() => deleteNotification()}>
            {t('yes')}
          </button>
          <button type="button" onClick={onClose}>
            {t('no')}
          </button>
        </ActionContainer>
      </Card>
    </ModalContainer>
  )
}

interface NotificaitonListProps {
  data: Array<Notification>
  t: TFunction
  deleteNotification: (id: string | number) => void
  updateList: () => void
  isNotificationManager: boolean
}

interface NotificaitonListState {
  expanded: { [key: number]: boolean }
  showDeleteModal: string | number | boolean
  notificationToEdit: Notification | null
}

class NotificationList extends Component<
  NotificaitonListProps,
  NotificaitonListState
> {
  constructor(props: NotificaitonListProps) {
    super(props)
    this.state = {
      expanded: {},
      showDeleteModal: false,
      notificationToEdit: null
    }
  }

  getContentHeight = (id: string | number) => {
    const el = document.querySelector(`#content_${id}`)
    if (el) {
      return el.scrollHeight
    }

    return 0
  }

  openDeleteModal = (notificationId: string | number) =>
    this.setState({ showDeleteModal: notificationId })

  closeDeleteModal = () => this.setState({ showDeleteModal: false })

  render() {
    const {
      data,
      t,
      isNotificationManager,
      deleteNotification,
      updateList
    } = this.props
    const { expanded, showDeleteModal, notificationToEdit } = this.state

    if (data.length === 0) {
      return <NoNotification>{t('noNotifications')}</NoNotification>
    }

    return (
      <>
        <CardContainer>
          {data.map(d => (
            <Card key={d.id}>
              <CardContent
                expanded={expanded[d.id]}
                contentHeight={this.getContentHeight(d.id)}
              >
                <h4>
                  {d.title}
                  {d.read ? null : <NewIcon>{t('new')}</NewIcon>}
                </h4>
                <span className="date">
                  {moment(d.published).format(PRESENTATION_DATE_FORMAT)}
                </span>
                <div
                  id={`content_${d.id}`}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: xss(d.text_value)
                  }}
                />
                {!expanded[d.id] ? (
                  <ShowMore
                    onClick={() =>
                      this.setState({
                        expanded: { ...expanded, [d.id]: true }
                      })
                    }
                  >
                    {t('showMore')}
                  </ShowMore>
                ) : (
                  <ShowMore
                    onClick={() =>
                      this.setState({
                        expanded: { ...expanded, [d.id]: false }
                      })
                    }
                  >
                    {t('hide')}
                  </ShowMore>
                )}
                {isNotificationManager ? (
                  <>
                    <EditButton
                      onClick={() => this.setState({ notificationToEdit: d })}
                    >
                      {t('edit')}
                    </EditButton>
                    <DeleteButton onClick={() => this.openDeleteModal(d.id)}>
                      - {t('delete')}
                    </DeleteButton>
                  </>
                ) : null}
              </CardContent>
              <Files>
                <p>{t('attachments')}</p>
                {d.files.map(f => (
                  <File key={f.id} data={f} />
                ))}
                {d.files.length === 0 && (
                  <div>
                    <span>{t('noAttachments')}</span>
                  </div>
                )}
              </Files>
            </Card>
          ))}
        </CardContainer>
        <DeleteModal
          isOpen={showDeleteModal as boolean}
          onClose={this.closeDeleteModal}
          deleteNotification={() => {
            deleteNotification(showDeleteModal as string | number)
            this.closeDeleteModal()
          }}
        />
        <EditNotificationModal
          isOpen={notificationToEdit !== null}
          onClose={() =>
            this.setState({ notificationToEdit: null }, updateList)
          }
          notification={notificationToEdit}
        />
      </>
    )
  }
}

export default withTranslation()(NotificationList)

import React, { Component, MouseEvent } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Popup from 'reactjs-popup'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { createRoadmap } from 'store/actions/roadmap'
import { triggerRefresh } from 'store/actions/task'
import styled from 'styled-components/macro'
import Icon from 'components/Icon'
import { Tab } from 'react-tabs'
import { TFunction } from 'i18next'
import { RootState } from 'store/reducers'

const StyledTab = styled(Tab)`
  display: inline-flex;
  background-color: ${props => props.theme.roadMap.newTab};
  color: black;
  align-items: center;
  line-height: 1;
  border-bottom: 1px solid #dfe8ea;
  min-height: 30px;
  .icon {
    margin-left: 5px;
  }
`

type CreateRoadmapModalReduxState = ReturnType<typeof mapStateToProps>
type CreateRoadmapModalReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface CreateRoadmapModalProps {
  t: TFunction
}

class CreateRoadmapModal extends Component<
  CreateRoadmapModalProps &
    CreateRoadmapModalReduxState &
    CreateRoadmapModalReduxDispatch,
  { [key: string]: string }
> {
  constructor(
    props: CreateRoadmapModalProps &
      CreateRoadmapModalReduxState &
      CreateRoadmapModalReduxDispatch
  ) {
    super(props)
    this.state = {
      title: ''
    }
  }

  onFieldChange = (e: { target: { name: string; value: string } }) => {
    const {
      target: { name, value }
    } = e

    this.setState({
      [name]: value
    })
  }

  createRoadmap = async (close: {
    (): void
    (
      event: MouseEvent<
        HTMLButtonElement,
        MouseEvent<Element, globalThis.MouseEvent>
      >
    ): void
    (): void
  }) => {
    const {
      createRoadmap: createRoadmapAction,
      triggerRefresh: triggerRefreshAction,
      activeOrganization
    } = this.props
    await createRoadmapAction(
      JSON.stringify({ ...this.state, organization: activeOrganization })
    )
    triggerRefreshAction(true)
    close()
  }

  render() {
    const { t } = this.props
    const { title } = this.state
    return (
      <Popup
        position="top center"
        modal
        nested
        trigger={
          <StyledTab
            className="react-tabs__tab"
            onClick={(e: { stopPropagation: () => void }) => {
              e.stopPropagation()
            }}
          >
            <span>{t('Add new roadmap')} </span>
            <Icon iconName="plusCircle" color="#7B8F94" size={14} />
          </StyledTab>
        }
      >
        {(close: {
          (): void
          (event: MouseEvent<HTMLButtonElement, MouseEvent>): void
        }) => (
          <div className="modal">
            <button type="button" className="close" onClick={close}>
              &times;
            </button>
            <div className="header"> {t('create-roadmap')} </div>
            <div className="content">
              <label htmlFor="task-title">{t('roadmap-name')}</label>
              <input
                name="title"
                id="task-title"
                placeholder={t('type-roadmap-name')}
                onChange={this.onFieldChange}
                value={title}
              />
            </div>
            <div className="actions">
              <button
                type="button"
                className="button close"
                onClick={() => {
                  close()
                }}
              >
                {t('Cancel')}
              </button>

              <button
                type="button"
                className="button"
                onClick={() => this.createRoadmap(close)}
              >
                {t('Save')}
              </button>
            </div>
          </div>
        )}
      </Popup>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      triggerRefresh,
      createRoadmap
    },
    dispatch
  )

const mapStateToProps = ({
  organization: { users, activeOrganization },
  target
}: RootState) => ({
  users,
  activeOrganization,
  target
})

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CreateRoadmapModal)
)

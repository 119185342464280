import React, { Component } from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Card, Title } from 'components/blocks'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { createReportGraph } from 'store/actions/report'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toast } from 'react-toastify'
import DateSelector from 'components/notification/DateSelector'
import { getRawDataTimespan } from 'store/actions/rawdata'
import Select from 'react-select'
import timeLineFromDatesAndOrganization, { ISO_FORMAT_DATE } from 'util/dates'
import { RootState } from 'store/reducers'
import { GraphMapping } from 'store/reducers/graph'
import { AppDispatch } from 'store/store'

const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #33333366;
  visibility: ${(props: { isOpen: boolean }) =>
    props.isOpen ? 'visible' : 'hidden'};
  pointer-events: ${props => (props.isOpen ? 'all' : 'none')};
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    position: relative;
    box-shadow: none;
    width: 100%;
    max-width: 900px;
    max-height: 100vh;
    padding: 0.938rem;
    opacity: ${props => (props.isOpen ? '1' : '0')};
    transform: translateY(${props => (props.isOpen ? '0' : '-50px')});
    transition: transform, opacity 0.3s ease-in-out;
    > h1 {
      margin-top: 0;
    }
    > input {
      width: 100%;
      margin-bottom: 0.938rem;
      padding: 0.625rem;
      border-radius: 5px;
      border: 1px solid #dedede;
      &[type='file'] {
        margin-bottom: 0;
      }
    }
    label {
      display: block;
      font-family: 'Open Sans';
      margin-bottom: 1rem;
      font-weight: 700;
      &:not(:first-of-type) {
        margin-top: 0.75rem;
      }
    }
  }
`
const CloseButton = styled.span`
  position: absolute;
  right: 0.938rem;
  top: 0.938rem;
  font-size: 1.875rem;
  cursor: pointer;
`
const Button = styled.div`
  width: 100%;
  cursor: pointer;
  border-radius: 3px;
  background: #b9c5c8;
  box-shadow: 2px 2px 0 0 #93a4a8;
  padding: 0.938rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.875rem;
  color: white;
  user-select: none;
  margin-top: 0.938rem;
`

const CLOSE_DELAY = 2000

type CreateGraphModalReduxState = ReturnType<typeof mapStateToProps>
type CreateGraphModalReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface CreateGraphModalProps {
  isOpen: boolean
  t: TFunction
  onClose: (arg: boolean) => void
}

interface CreateGraphModalState {
  mappings: Array<number>
  startDate: string | null
  endDate: string | null
}

class CreateGraphModal extends Component<
  CreateGraphModalProps &
    CreateGraphModalReduxState &
    CreateGraphModalReduxDispatch,
  CreateGraphModalState
> {
  constructor(
    props: CreateGraphModalProps &
      CreateGraphModalReduxState &
      CreateGraphModalReduxDispatch
  ) {
    super(props)
    this.state = {
      mappings: [],
      startDate: null,
      endDate: null
    }
  }

  componentDidMount() {
    const { getRawDataTimespan: getRawDataTimespanAction } = this.props
    getRawDataTimespanAction(false)
  }

  createReportGraph = async () => {
    const { mappings, startDate, endDate } = this.state
    const {
      createReportGraph: createReportGraphAction,
      t,
      onClose
    } = this.props
    const properties = {
      start_date: startDate as string,
      end_date: endDate as string,
      filtered_keys: [] as string[]
    }
    mappings.forEach(mapping => {
      createReportGraphAction({ mapping, properties })
    })
    toast.success(t('report-graph-created'))
    setTimeout(() => onClose(true), CLOSE_DELAY)
  }

  render() {
    const {
      isOpen,
      t,
      onClose,
      graph: { settings },
      timespan,
      organization: { data, activeOrganization }
    } = this.props
    const mappings: GraphMapping[] = []
    settings.mappings.forEach(m => {
      m.children.forEach(m2 => {
        mappings.push(m2)
      })
      if (m.redirect) {
        return null
      }

      mappings.push(m)
    })
    return (
      <ModalContainer isOpen={isOpen}>
        <Card>
          <CloseButton onClick={() => onClose(true)}>&times;</CloseButton>
          <Title>{t('create-report-graph')}</Title>
          <label>{t('time-range')}</label>
          <DateSelector
            location="create-report-graph"
            onDateChange={value => {
              const { start, stop } = timeLineFromDatesAndOrganization(
                value,
                data,
                activeOrganization
              )
              this.setState({
                startDate: start.startOf('month').format(ISO_FORMAT_DATE),
                endDate: stop.endOf('month').format(ISO_FORMAT_DATE)
              })
            }}
            timeSpan={{
              start_date: timespan.start_date,
              end_date: timespan.end_date
            }}
            rangeSelect
            noYtd
          />
          <label>{t('views')}</label>
          <Select
            isMulti
            options={mappings.map(m => ({ value: m.id, label: m.view_title }))}
            placeholder={t('choose-views')}
            onChange={(values: { value: number; label: string }[]) =>
              this.setState({
                mappings: values.map(v => v.value)
              })
            }
          />
          <Button onClick={this.createReportGraph}>{t('create')}</Button>
        </Card>
      </ModalContainer>
    )
  }
}

const mapStateToProps = ({
  graph,
  rawData: { timespan },
  organization
}: RootState) => ({
  graph,
  timespan,
  organization
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      createReportGraph,
      getRawDataTimespan
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CreateGraphModal))

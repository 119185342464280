import {
  GET_ORGANIZATION_USERS,
  GET_ACTIVE_ORGANIZATION,
  GET_ORGANIZATIONS,
  ACTIVATE_ORGANIZATION
} from 'store/actions/organization'

import produce from 'immer'
import { RESET_STATE } from 'store/actions/reset'
import { AnyAction } from 'redux'

export type OrganizationUser = {
  id: number
  avatar: string | null
  full_name: string
  username: string
}

export type OrganizationObjective = {
  id: number
  title: string
  description: string
}

export type Organization = {
  id: number
  name: string
  objectives: OrganizationObjective[]
  start_of_fiscal_year: string
  children?: Organization[]
  parentId?: number
}

interface OrganizationState {
  data: Array<Organization>
  activeOrganization: number | null
  users: Array<OrganizationUser>
  isPending: boolean
  error: Error | Array<Error> | null
}

const initialState: OrganizationState = {
  data: [],
  activeOrganization: null,
  users: [],
  isPending: false,
  error: null
}

const organization = (state = initialState, action: AnyAction) => {
  const { type, payload } = action

  switch (type) {
    case `${GET_ORGANIZATION_USERS}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${GET_ORGANIZATION_USERS}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${GET_ORGANIZATION_USERS}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.users = payload.users
      })

    case `${GET_ACTIVE_ORGANIZATION}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${GET_ACTIVE_ORGANIZATION}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${GET_ACTIVE_ORGANIZATION}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.activeOrganization = payload.organization
      })

    case `${GET_ORGANIZATIONS}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${GET_ORGANIZATIONS}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${GET_ORGANIZATIONS}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.data = payload
      })

    case `${ACTIVATE_ORGANIZATION}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${ACTIVATE_ORGANIZATION}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${ACTIVATE_ORGANIZATION}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.activeOrganization = payload.organization
      })

    case RESET_STATE:
      return initialState

    default:
      return state
  }
}

export default organization

import React, { Component } from 'react'
import { debounce, DebouncedFunc } from 'lodash'
import styled from 'styled-components/macro'

export const LINE_LENGTH = 60

const LineContainer = styled.svg`
  width: ${(props: { width: number }) => props.width}px;
  height: ${LINE_LENGTH * 2}px;
  stroke: #cfdbde;
  stroke-width: 2px;
`

interface ConnectingLinesState {
  parents: Element[]
  children: Element[]
  offset: number
}

export default class ConnectingLines extends Component<
  Record<never, never>,
  ConnectingLinesState
> {
  updateDebounce: DebouncedFunc<() => void>
  constructor(props: Record<never, never>) {
    super(props)
    this.updateDebounce = debounce(this.updateLines, 500)
    this.state = {
      parents: [],
      children: [],
      offset: 0
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDebounce)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDebounce)
  }

  updateLines = () => this.forceUpdate()

  getConnections = () => {
    const { parents, children } = this.state
    const parentsArray = [...parents]
    const childrenArray = [...children]
    const connections: {
      parent: Element
      parentsChildren: Element[]
    }[] = []
    parentsArray.forEach(p => {
      const parentId = p.getAttribute('data-id')
      const parentsChildren = childrenArray.filter(
        c => c.getAttribute('data-parent') === parentId
      )
      connections.push({
        parent: p,
        parentsChildren
      })
    })
    return connections.filter(c => c.parentsChildren.length !== 0)
  }

  renderLines = () => {
    const connections = this.getConnections()
    const { offset } = this.state
    return connections.map((c, i) => {
      const parentBoundingClientRect = c.parent.getBoundingClientRect()
      const parentMiddle =
        parentBoundingClientRect.left +
        parentBoundingClientRect.width / 2 -
        offset
      const parentId = parseInt(c.parent.getAttribute('data-id') as string, 10)
      let leftBound =
        parentBoundingClientRect.left +
        parentBoundingClientRect.width / 2 -
        offset
      let rightBound =
        parentBoundingClientRect.right -
        parentBoundingClientRect.width / 2 -
        offset
      c.parentsChildren.forEach(child => {
        const { left, right, width } = child.getBoundingClientRect()
        const childLeftBound = left + width / 2 - offset
        const childRightBound = right - width / 2 - offset
        if (childLeftBound < leftBound) {
          leftBound = childLeftBound
        }
        if (childRightBound > rightBound) {
          rightBound = childRightBound
        }
      })
      const indexOffset = i * 5
      return (
        <React.Fragment key={parentId}>
          <line
            x1={parentMiddle}
            x2={parentMiddle}
            y1={0}
            y2={LINE_LENGTH + indexOffset}
          />
          <line
            x1={leftBound}
            x2={rightBound}
            y1={LINE_LENGTH + indexOffset}
            y2={LINE_LENGTH + indexOffset}
          />
          {c.parentsChildren.map(child => {
            const { left, width } = child.getBoundingClientRect()
            const childLeftBound = left + width / 2 - offset
            return (
              <line
                key={child.getAttribute('data-id')}
                x1={childLeftBound}
                x2={childLeftBound}
                y1={LINE_LENGTH + indexOffset}
                y2={LINE_LENGTH * 2}
              />
            )
          })}
        </React.Fragment>
      )
    })
  }

  render() {
    const strategyContainer = document.querySelector(
      '#strategy-content-container'
    )
    const width = strategyContainer ? strategyContainer.scrollWidth : 0
    return (
      <LineContainer
        width={width}
        ref={ref => {
          if (ref) {
            const {
              parents: prevParents,
              children: prevChildren,
              offset
            } = this.state
            const parents = ref.previousSibling
              ? Array.from((ref.previousSibling as HTMLElement).querySelectorAll('[data-id]'))
              : []
            const children = ref.nextSibling
              ? Array.from((ref.nextSibling as HTMLElement).querySelectorAll('[data-parent]'))
              : []
            if (
              prevParents.length !== parents.length ||
              prevChildren.length !== children.length
            ) {
              this.setState({ parents, children })
            }
            const { left } = ref.getBoundingClientRect()
            if (left !== offset) {
              this.setState({ offset: left })
            }
          }
        }}
      >
        {this.renderLines()}
      </LineContainer>
    )
  }
}

import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { TFunction, withTranslation } from 'react-i18next'
/* import Center from 'assets/icons/center.svg' */
import Expand from 'assets/icons/expand.svg'
import Collapse from 'assets/icons/collapse.svg'

const Container = styled.div`
  position: absolute;
  right: 0.625rem;
  top: 5px;
  h2 {
    color: #b9c5c8;
    font-family: 'Open Sans';
    font-size: 0.813rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.125rem;
    text-align: right;
    margin: 0 0 0.625rem 0;
  }
`

const ToolContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.625rem;
  cursor: pointer;
  &:hover {
    & > div {
      background-color: #919fa3;
    }
  }
  span {
    color: #8e8e8e;
    font-family: 'Open Sans';
    font-size: 0.625rem;
    letter-spacing: 0;
    line-height: 0.875rem;
    text-align: center;
    margin-top: 6px;
  }
`
const Tool = styled.div`
  height: 1.438rem;
  width: 45.42px;
  border-radius: 3px;
  background-color: #b9c5c8;
  box-shadow: 2px 2px 0 0 #93a4a8;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ToolIcon = styled.div`
  width: 75%;
  height: 75%;
  mask: url(${(props: { icon: string }) => props.icon});
  mask-repeat: no-repeat;
  mask-position: 50% 50%;
  background-color: #fff;
`

class LogicTools extends Component<{
  t: TFunction
  onToolActivate: (tool: string) => void
}> {
  render() {
    const { t, onToolActivate } = this.props
    return (
      <Container>
        <h2>{t('tools')}</h2>
        <ToolContainer onClick={() => onToolActivate('expand')}>
          <Tool>
            <ToolIcon icon={Expand} />
          </Tool>
          <span>{t('expand')}</span>
        </ToolContainer>
        <ToolContainer onClick={() => onToolActivate('collapse')}>
          <Tool>
            <ToolIcon icon={Collapse} />
          </Tool>
          <span>{t('collapse')}</span>
        </ToolContainer>
        {/* <ToolContainer onClick={() => onToolActivate('center')}>
          <Tool>
            <ToolIcon icon={Center} />
          </Tool>
          <span>100%</span>
        </ToolContainer> */}
      </Container>
    )
  }
}

export default withTranslation()(LogicTools)

import React, { Component } from 'react'
import styled, { DefaultTheme, withTheme } from 'styled-components'
import { TFunction, withTranslation } from 'react-i18next'
import Empty from 'components/roadmap/Empty'
import Container from 'components/roadmap/Container'
import HoverElement from 'components/roadmap/HoverElement'
import moment from 'moment'
import MilestoneModal from 'components/modals/MilestoneModal'
import Icon from 'components/Icon'
import Popup from 'reactjs-popup'
import { DateSelection } from './Roadmap'
import { MilestoneType } from 'store/reducers/milestone'
import { RoadmapType } from 'store/reducers/roadmap'

const Link = styled.button`
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  color: #354b7d;
  cursor: pointer;
  background: none;
  text-align: left;
  width: 100%;
  border: none;
  padding: 10px;
  &:hover {
    text-decoration: underline;
  }
`

const Info = styled.div`
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  color: #354b7d;
  cursor: pointer;
  padding: 10px;
`

const Button = styled.div`
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  color: #354b7d;
  cursor: pointer;
  padding: 10px;
  background-color: ${(props: { color?: string }) =>
    props.color ? props.color : ''};
`

const TooltipContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  white-space: pre-line;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  z-index: 1;
  position: absolute;
`

interface MilestonesProps {
  dateSelection: DateSelection
  milestones: MilestoneType[]
  roadmap: RoadmapType
  t: TFunction
  isRoadmapManager: boolean
  theme: DefaultTheme
}

class Milestones extends Component<
  MilestonesProps,
  { selectedMilestone: number | string }
> {
  constructor(props: MilestonesProps) {
    super(props)
    this.state = {
      selectedMilestone: ''
    }
  }

  getKey = (year: string | number, month: string | number) => {
    let m = '-'
    if (String(month).length === 1) m = '-0'
    return year + m + month
  }

  onChangeMilestone = (value: number) => {
    let id: string | number = value
    const { selectedMilestone } = this.state
    if (selectedMilestone === value) id = ''
    this.setState({ selectedMilestone: id })
  }

  render() {
    const {
      dateSelection,
      milestones,
      roadmap,
      t,
      isRoadmapManager,
      theme
    } = this.props
    const { selectedMilestone } = this.state
    const milestoneMap: { [key: string]: Array<MilestoneType> } = {}
    milestones.forEach(milestone => {
      const key = moment(milestone.end_date, moment.ISO_8601).format('YYYY-MM')
      if (!milestoneMap[key]) milestoneMap[key] = []
      milestoneMap[key].push(milestone)
    })
    let year = dateSelection.startYear
    let month = dateSelection.startMonth
    const arrowStyle: React.CSSProperties = {
      float: 'right',
      marginTop: '5px',
      marginLeft: '10px',
      verticalAlign: 'sub',
      display: 'inline-block'
    }
    const popupIcon: React.CSSProperties = {
      cursor: 'pointer',
      display: 'inline-block',
      verticalAlign: 'middle',
      marginLeft: '10px',
      float: 'right'
    }
    const data: JSX.Element[] = []

    for (let i = 0; i < dateSelection.rowLength; i += 1) {
      const key = this.getKey(year, month)
      const currentMilestones = milestoneMap[key]
      if (currentMilestones && currentMilestones.length > 0) {
        const links: JSX.Element[] = []
        currentMilestones.forEach(milestone => {
          links.push(
            <Link onClick={() => this.onChangeMilestone(milestone.id)}>
              {milestone.title}
              <Icon
                style={arrowStyle}
                color="#354B7D"
                iconName={
                  selectedMilestone === milestone.id ? 'arrowUp' : 'arrowDown'
                }
                width={10}
                height={6}
              />
            </Link>
          )
          if (selectedMilestone === milestone.id) {
            links.push(<Info>{milestone.end_date}</Info>)
            if (isRoadmapManager) {
              links.push(
                <MilestoneModal
                  roadmap={roadmap}
                  month={month}
                  milestone={milestone}
                  year={year}
                  trigger={
                    <Button>
                      {t('Edit milestone')}
                      <Icon
                        style={popupIcon}
                        color="#354B7D"
                        iconName="edit"
                        size={15}
                      />
                    </Button>
                  }
                />
              )
            }
          }
        })
        if (isRoadmapManager) {
          links.push(
            <MilestoneModal
              roadmap={roadmap}
              month={month}
              year={year}
              trigger={
                <Button color="#ffee90">
                  {t('Add new milestone')}
                  <Icon
                    style={popupIcon}
                    color="#354B7D"
                    iconName="plusCircle"
                    size={15}
                  />
                </Button>
              }
            />
          )
        }
        data.push(
          <Empty
            key={`milestone-empty-${roadmap.id}-${i}`}
            dateSelection={dateSelection}
          >
            <TooltipContainer>
              <Popup
                trigger={
                  <div>
                    <Icon
                      style={{
                        zIndex: 1,
                        cursor: 'pointer'
                      }}
                      color={theme.roadMap.iconColor}
                      iconName={
                        currentMilestones.length === 1
                          ? 'milestone'
                          : 'milestones'
                      }
                      size={20}
                    />
                  </div>
                }
                position={['bottom center']}
                nested
              >
                <div>{links}</div>
              </Popup>
            </TooltipContainer>
          </Empty>
        )
      } else {
        const trigger = (
          <Icon
            style={{ display: 'inline-block' }}
            color="#777777"
            iconName="newMilestone"
            size={20}
          />
        )
        data.push(
          <Empty
            key={`milestone-empty-${roadmap.id}-${i}`}
            dateSelection={dateSelection}
          >
            {isRoadmapManager && (
              <HoverElement
                width={20}
                height={20}
                style={{
                  position: 'absolute',
                  zIndex: 10,
                  top: '0',
                  left: '-9px'
                }}
              >
                <MilestoneModal
                  roadmap={roadmap}
                  month={month}
                  year={year}
                  trigger={trigger}
                />
              </HoverElement>
            )}
          </Empty>
        )
      }
      if (month < 12) month += 1
      else {
        month = 1
        year += 1
      }
    }

    return (
      <>
        <Container
          style={{ margin: '2px', padding: '15px' }}
          header={<div style={{ padding: '15px 20px' }}>{t('Milestones')}</div>}
          data={data}
          disableBackground
        />
      </>
    )
  }
}

export default withTranslation()(withTheme(Milestones))

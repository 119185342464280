import { Get } from 'api/index'
import { createAsyncAction } from 'redux-promise-middleware-actions'

export const GET_FORCED_SYSTEM_MESSAGES = 'GET_FORCED_SYSTEM_MESSAGES'
export const UNREAD_SYSTEM_MESSAGE_COUNT = 'UNREAD_SYSTEM_MESSAGE_COUNT'
export const LIST_SYSTEM_MESSAGE = 'LIST_SYSTEM_MESSAGE'

export const getForcedSystemMessages = createAsyncAction(
  GET_FORCED_SYSTEM_MESSAGES,
  async () => {
    const url = `/api/system_messages/forced_system_messages/`
    const data = await Get(url)
    return {
      forcedMessages: data
    }
  }
)
export const unreadSystemMessageCount = createAsyncAction(
  UNREAD_SYSTEM_MESSAGE_COUNT,
  async () => {
    const url = `/api/system_messages/unread_system_message_count/`
    const data = await Get(url)
    return {
      unreadMessageCount: data
    }
  }
)
export const listSystemMessages = createAsyncAction(
  LIST_SYSTEM_MESSAGE,
  async () => {
    const url = `/api/system_messages/`
    const data = await Get(url)
    return data
  }
)

import { Delete, Get, Post, Put } from 'api/index'
import { createAsyncAction } from 'redux-promise-middleware-actions'
import { AppDispatch } from 'store/store'

export const GET_TASK = 'GET_TASK'
export const LIST_TASK = 'LIST_TASK'
export const CREATE_TASK = 'CREATE_TASK'
export const UPDATE_TASK = 'UPDATE_TASK'
export const DELETE_TASK = 'DELETE_TASK'
export const SET_MODIFIED_START_DATE = 'SET_MODIFIED_START_DATE'
export const TRIGGER_REFRESH = 'TRIGGER_REFRESH'
export const SET_DRAG_IN_PROGRESS = 'SET_DRAG_IN_PROGRESS'

export const getTask = createAsyncAction(GET_TASK, async (id: number) => {
  const url = `/api/task/${id}/`
  const data = await Get(url)
  return data
})

export const listTask = createAsyncAction(
  LIST_TASK,
  async (maintaskId: number, rowIndex: number) => {
    const url = `/api/task/?maintaskId=${maintaskId}&rowIndex=${rowIndex}`
    const data = await Get(url)
    return data
  }
)

export const createTask = createAsyncAction(
  CREATE_TASK,
  async (body: string) => {
    const url = `/api/task/`
    const data = await Post(url, body)
    return data
  }
)

export const updateTask = createAsyncAction(
  UPDATE_TASK,
  async (id: number, body: string) => {
    const url = `/api/task/${id}/`
    const data = await Put(url, body)
    return data
  }
)

export const deleteTask = createAsyncAction(DELETE_TASK, async (id: number) => {
  const url = `/api/task/${id}/`
  const data = await Delete(url)
  return data
})

export const triggerRefresh = (shouldTrigger?: boolean) => (
  dispatch: AppDispatch
) =>
  dispatch({
    type: TRIGGER_REFRESH,
    payload: shouldTrigger
  })

export const setDragInProgress = (progress: boolean | number) => (
  dispatch: AppDispatch
) =>
  dispatch({
    type: SET_DRAG_IN_PROGRESS,
    payload: progress
  })

import React, { Component } from 'react'
import moment from 'moment'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'
import MonthSelector, { RangeValue } from 'components/blocks/MonthSelector'
import { setSelections } from 'store/actions/monthselector'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { processTimeSpan, Years } from 'components/notification/DateSelector'
import { RootState } from 'store/reducers'
import { RawData } from 'store/reducers/rawdata'
import { findOrg } from 'util/organization'

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
`

type OrganizationDateSelectorReduxState = ReturnType<typeof mapStateToProps>
type OrganizationDateSelectorReduxDispatch = ReturnType<
  typeof mapDispatchToProps
>

interface OrganizationDateSelectorProps {
  location: string
  data?: Array<RawData>
  keyToDate?: string
  noData?: boolean
  onDateChange: (dates: { [key: string]: Array<string> }) => void
  defaultValue?: string
  timeSpan?: { start_date: string; end_date: string }
}

interface OrganizationDateSelectorState {
  years: Years
}

class OrganizationDateSelector extends Component<
  OrganizationDateSelectorProps &
    OrganizationDateSelectorReduxState &
    OrganizationDateSelectorReduxDispatch,
  OrganizationDateSelectorState
> {
  static getDerivedStateFromProps(
    props: OrganizationDateSelectorProps &
      OrganizationDateSelectorReduxState &
      OrganizationDateSelectorReduxDispatch,
    state: OrganizationDateSelectorState
  ) {
    if (
      (props.keyToDate || props.noData) &&
      (props.data || props.noData) &&
      props.organization.activeOrganization
    ) {
      const years: Years = {}
      const activeOrganization = findOrg(
        props.organization.activeOrganization,
        props.organization.data
      )
      if (
        props.data &&
        activeOrganization &&
        activeOrganization.start_of_fiscal_year
      ) {
        const startOfFiscalYear = parseInt(
          activeOrganization.start_of_fiscal_year,
          10
        )
        const momentObj = moment()
        while (momentObj.month() + 1 !== startOfFiscalYear) {
          years.YTD = Array.from(
            new Set([...(years.YTD || []), momentObj.format('YYYY-MM')])
          )
          momentObj.subtract(1, 'month')
        }
        years.YTD = Array.from(
          new Set([...(years.YTD || []), momentObj.format('YYYY-MM')])
        )
        momentObj.subtract(1, 'month')
        if (props.timeline) {
          const { start_date: startDate, end_date: endDate } = props.timeline
          const start = moment(startDate)
          const end = moment(endDate)
          while (start < end) {
            const year =
              start.month() + 1 >= startOfFiscalYear
                ? `${start.format('YYYY')} - ${parseInt(
                    start.format('YYYY'),
                    10
                  ) + 1}`
                : `${parseInt(start.format('YYYY'), 10) - 1} - ${start.format(
                    'YYYY'
                  )}`
            const month = start.format('YYYY-MM')
            if (!years[year]) {
              years[year] = []
            }
            if (!years[year].includes(month)) {
              years[year].push(month)
            }
            start.add(1, 'month')
          }
        }
      }
      if (props.timeSpan && activeOrganization) {
        return {
          years: processTimeSpan(years, props.timeSpan, activeOrganization)
        }
      }
      if (
        !state.years ||
        Object.keys(years).length !== Object.keys(state.years).length
      ) {
        return { years }
      }
    }
    return null
  }

  constructor(
    props: OrganizationDateSelectorProps &
      OrganizationDateSelectorReduxState &
      OrganizationDateSelectorReduxDispatch
  ) {
    super(props)
    this.state = {
      years: {}
    }
  }

  selectYear = (year: string) => {
    const { onDateChange, defaultValue, location, monthselector } = this.props
    const selection = this.getSelection(location, monthselector).value
    const newSelection = { ...selection } as Years
    if (newSelection[year]) {
      delete newSelection[year]
      if (Object.keys(newSelection).length === 0 && defaultValue) {
        newSelection[defaultValue] = []
      }
      this.setSelections(newSelection)
      onDateChange(newSelection)
      return
    }

    newSelection[year] = []
    if (Object.keys(newSelection).length > 1) {
      Object.keys(newSelection).forEach(k => {
        newSelection[k] = []
      })
    }
    if (Object.keys(newSelection).length > 2) {
      Object.keys(newSelection)
        .filter(y => {
          const yStart = parseInt(
            y === 'YTD' ? moment().format('YYYY') : y.split('-')[0],
            10
          )
          const newYStart = parseInt(
            year === 'YTD' ? moment().format('YYYY') : year.split('-')[0],
            10
          )
          return yStart < newYStart
        })
        .forEach(y => {
          delete newSelection[y]
        })
    }
    this.setSelections(newSelection)
    onDateChange(newSelection)
  }

  selectMonth = (month: string) => {
    const { onDateChange, location, monthselector } = this.props
    const selection = this.getSelection(location, monthselector).value as Years
    const newSelection = { ...selection }
    const year = Object.keys(selection)[0]
    if (newSelection[year].includes(month)) {
      newSelection[year] = newSelection[year].filter(m => m !== month)
      this.setSelections(newSelection)
      onDateChange(newSelection)
      return
    }

    newSelection[year] = [...newSelection[year], month]
    if (newSelection[year].length > 2) {
      const sortedMonths = newSelection[year].sort((a: string, b: string) =>
        moment(a, 'YYYY-MM') <= moment(b, 'YYYY-MM') ? -1 : 1
      )
      newSelection[year] = [sortedMonths[0], month]
    }
    this.setSelections(newSelection)
    onDateChange(newSelection)
  }

  checkMonthBetweenSelections = (
    selection: { [key: string]: string[] },
    month: string
  ) => {
    const orderedSelections = Object.values(selection)[0].sort((a, b) =>
      moment(a, 'YYYY-MM') <= moment(b, 'YYYY-MM') ? -1 : 1
    )
    const monthMoment = moment(month, 'YYYY-MM')
    const firstSelection = moment(orderedSelections[0], 'YYYY-MM')
    const lastSelection = moment(
      orderedSelections[orderedSelections.length - 1],
      'YYYY-MM'
    )
    return monthMoment >= firstSelection && monthMoment <= lastSelection
  }

  checkYearBetweenSelections = (
    selection: { [key: string]: string[] },
    year: string
  ) => {
    const orderedSelections = Object.keys(selection)
      .map(y => (y === 'YTD' ? moment().format('YYYY') : y))
      .sort((a: string, b: string) =>
        parseInt(a.split('-')[0], 10) <= parseInt(b.split('-')[0], 10) ? -1 : 1
      )
    const yearInteger =
      year === 'YTD'
        ? parseInt(moment().format('YYYY'), 10)
        : parseInt(year.split('-')[0], 10)
    const firstSelection = parseInt(orderedSelections[0], 10)
    const lastSelection = parseInt(
      orderedSelections[orderedSelections.length - 1],
      10
    )
    return yearInteger >= firstSelection && yearInteger <= lastSelection
  }

  getSelection = (
    location: string | number,
    monthselector: RootState['monthselector']
  ) => {
    if (monthselector && monthselector.selections) {
      return monthselector.selections[location]
    }
    return {}
  }

  setSelections = (value: Years, rangeValue?: RangeValue) => {
    const {
      setSelections: setSelectionsAction,
      location,
      monthselector
    } = this.props
    const selections = {
      ...(monthselector.selections ? monthselector.selections : {})
    }
    selections[location] = { value, rangeValue }
    setSelectionsAction(selections)
  }

  onRangeChange = (value: Years, rangeValue: RangeValue) => {
    const { onDateChange } = this.props
    // only run the update-logic if both dates are set or when
    // clicking YTD again in order to remove both limits.
    let actuallySetSelections = false
    const fromKeys = rangeValue?.from ? Object.keys(rangeValue.from) : []
    const toKeys = rangeValue?.to ? Object.keys(rangeValue.to) : []
    const hasIntervals = ['year', 'month'].every(
      dateComponent =>
        fromKeys.includes(dateComponent) && toKeys.includes(dateComponent)
    )
    actuallySetSelections = hasIntervals || !rangeValue
    if (actuallySetSelections) {
      this.setSelections(value, rangeValue)
      onDateChange(value)
    }
  }

  onInit = () => {
    const { onDateChange, location, monthselector } = this.props
    const { value } = this.getSelection(location, monthselector) as {
      value: Years
    }
    const { years } = this.getSelection(location, monthselector) as {
      years: Years
    }
    this.setState({ years })
    onDateChange(value)
  }

  render() {
    const { years } = this.state
    const { organization, location, monthselector } = this.props
    const activeOrganization = findOrg(
      organization.activeOrganization,
      organization.data
    )
    const initialValue = this.getSelection(location, monthselector)
      ? this.getSelection(location, monthselector).rangeValue
      : undefined
    if (!activeOrganization) {
      return null
    }
    return (
      <DateContainer>
        <MonthSelector
          initialValue={initialValue}
          onRangeChange={(value, rangeValue) => {
            if (!value) {
              return
            }
            this.onRangeChange(value, rangeValue as RangeValue)
          }}
          onInit={() => this.onInit()}
          rangeSelect
          activeOrganization={activeOrganization}
          years={years}
        />
      </DateContainer>
    )
  }
}

const mapStateToProps = ({
  organization,
  term: { timeline },
  monthselector
}: RootState) => ({
  organization,
  timeline,
  monthselector
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setSelections
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationDateSelector)

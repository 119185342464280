import React, { Component } from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import styled, { withTheme, DefaultTheme } from 'styled-components/macro'
import { Card, Title } from 'components/blocks'
import moment from 'moment'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  deleteReport,
  deleteReportDataMap,
  deleteReportGraph,
  refreshReport,
  retrieveReport
} from 'store/actions/report'
import {
  EditReportItemContentModal,
  EditCreateReportModal,
  SendReportModal
} from 'components/modals'
import LoadingIndicator from 'components/blocks/LoadingIndicator'
import LazyGraphImage from './LazyGraphImage'
import { Report, ReportDataMap, ReportGraph } from 'store/reducers/report'
import { AppDispatch } from 'store/store'
import { Get } from 'api'

const ReportsContainer = styled.div`
  display: flex;
  height: calc(100vh - 14rem);
  h1 {
    margin: 15px 15px 0 15px;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`
const ReportListContainer = styled.div`
  height: 100%;
  flex-basis: 75%;
  @media screen and (max-width: 1280px) {
    flex-basis: 60%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  > div {
    padding: 15px;
    width: 100%;
    height: auto;
    h3 {
      margin: 0;
      font-size: 1.25rem;
      font-weight: 300;
      color: ${props => props.theme.text.title};
      letter-spacing: 0;
      line-height: 1.813rem;
    }
    .report {
      margin: 15px;
      border: 1px solid #ddd;
      &:not(:last-of-type) {
        margin-bottom: 0;
      }
      @media screen and (max-width: 768px) {
        margin: 0;
      }
      .report-content {
        padding: 15px;
        font-family: 'Open Sans';
        p {
          margin: 0.5rem 0 0 0;
          font-size: 0.7rem;
        }
        .actions {
          margin-top: 15px;
          display: flex;
          @media screen and (max-width: 768px) {
            flex-direction: column;
          }
        }
        button {
          position: relative;
          padding: 5px 0.625rem;
          border: 1px solid #9a9a9a;
          background: transparent;
          color: ${props => props.theme.text.primary};
          border-radius: 3px;
          font-size: 0.875rem;
          outline: none;
          transition: all 0.2s ease-in-out;
          &:not(:last-of-type) {
            margin-right: 0.7rem;
            @media screen and (max-width: 768px) {
              margin-right: 0;
            }
          }
          &:disabled {
            background: #ddd;
          }
          @media screen and (max-width: 768px) {
            margin-top: 0.7rem;
          }
          &:not(:disabled) {
            cursor: pointer;
            &:hover {
              background: #9a9a9a;
              color: white;
            }
          }
          &.success {
            border: 1px solid #6abb6a;
            &:hover {
              background: #6abb6a;
            }
            min-height: 1.75rem;
            min-width: 10rem;
            .bounce1,
            .bounce2,
            .bounce3 {
              width: 0.5rem;
              height: 0.5rem;
            }
          }
        }
        button.delete {
          margin-left: auto;
          border: 1px solid #ce2033;
          color: #ce2033;
          @media screen and (max-width: 768px) {
            margin-left: 0;
          }
          &:hover {
            background: #ce2033;
            color: white;
          }
        }
      }
    }
  }
`
const ReportGraphListContainer = styled.div`
  height: 100%;
  flex-basis: 25%;
  margin-left: 25px;
  max-width: 400px;
  @media screen and (max-width: 1280px) {
    flex-basis: 40%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    margin-top: 25px;
    max-width: none;
  }
  > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .report-graph-list {
    margin: 15px;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 0.625rem;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #00000099;
      border-radius: 0.625rem;
      border: solid 3px transparent;
    }
    ::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 5px #00000099;
      border-radius: 0.625rem;
      border: solid 3px transparent;
    }
    @media screen and (max-width: 768px) {
      margin: 0;
    }
  }
  .graph-result {
    margin: 15px;
    border: 1px solid #ddd;
    &:not(:last-of-type) {
      margin-bottom: 0;
    }
    img {
      width: 100%;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      cursor: pointer;
    }
    .graph-content {
      padding: 15px;
      font-family: 'Open Sans';
      p {
        margin: 0;
      }
    }
    button.delete {
      float: right;
      padding: 5px 0.625rem;
      border: 1px solid #ce2033;
      background: transparent;
      color: #ce2033;
      border-radius: 3px;
      font-size: 0.875rem;
      cursor: pointer;
      outline: none;
      transition: all 0.2s ease-in-out;
      &:hover {
        background: #ce2033;
        color: white;
      }
    }
  }
`

type ReportListReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface ReportListProps {
  results: Array<Report>
  graphResults: Array<ReportGraph>
  dataMapResults: Array<ReportDataMap>
  t: TFunction
  updateList: () => void
  theme: DefaultTheme
}

interface ReportListState {
  graphToEdit: ReportGraph | ReportDataMap | null
  reportToSend: Report | null
  reportToEdit: Report | null
  reportsRefreshing: Array<number>
}

class ReportList extends Component<
  ReportListProps & ReportListReduxDispatch,
  ReportListState
> {
  updateInterval: ReturnType<typeof setInterval> | null

  constructor(props: ReportListProps & ReportListReduxDispatch) {
    super(props)
    this.updateInterval = null
    this.state = {
      graphToEdit: null,
      reportToSend: null,
      reportToEdit: null,
      reportsRefreshing: []
    }
  }

  componentDidMount() {
    this.updateInterval = setInterval(this.updateReports, 1500)
  }

  componentWillUnmount() {
    if (this.updateInterval !== null) {
      clearInterval(this.updateInterval)
    }
  }

  updateReports = async () => {
    const { reportsRefreshing } = this.state
    if (!reportsRefreshing.length) {
      return
    }
    const { updateList } = this.props
    const newReportsRefreshing: number[] = []
    await updateList()
    const { graphResults } = this.props
    await Promise.all(
      reportsRefreshing.map(async r => {
        const url = `/api/report/${r}/`
        const report = await Get<Report>(url)
        const graphs = graphResults.filter(g =>
          report.report_graphs.includes(g.id)
        )
        if (!graphs.every(g => g.image_file !== null)) {
          newReportsRefreshing.push(r)
        }
      })
    )
    if (
      reportsRefreshing.length === newReportsRefreshing.length &&
      reportsRefreshing.every(r => newReportsRefreshing.includes(r))
    ) {
      return
    }
    this.setState({ reportsRefreshing: newReportsRefreshing })
  }

  render() {
    const {
      results,
      graphResults,
      dataMapResults,
      t,
      updateList,
      deleteReportGraph: deleteReportGraphAction,
      deleteReportDataMap: deleteReportDataMapAction,
      deleteReport: deleteReportAction,
      refreshReport: refreshReportAction,
      theme
    } = this.props
    const {
      graphToEdit,
      reportToSend,
      reportsRefreshing,
      reportToEdit
    } = this.state
    const reportItemResults: Array<ReportDataMap | ReportGraph> = [
      ...graphResults,
      ...dataMapResults
    ]
    reportItemResults.sort((a, b) =>
      moment(b.created).diff(moment(a.created), 'milliseconds')
    )

    return (
      <ReportsContainer>
        <ReportListContainer>
          <Card>
            {results.length ? (
              results.map(r => (
                <Card className="report" key={r.id}>
                  <div className="report-content">
                    <h3>{r.title}</h3>
                    <p>
                      {t('created')}: {r.created}
                    </p>
                    <div className="actions">
                      <button
                        type="button"
                        className="success"
                        onClick={
                          reportsRefreshing.includes(r.id)
                            ? undefined
                            : async () => {
                                this.setState({
                                  reportsRefreshing: [
                                    ...reportsRefreshing,
                                    r.id
                                  ]
                                })
                                await refreshReportAction(r.id, theme.id)
                                updateList()
                              }
                        }
                      >
                        {reportsRefreshing.includes(r.id) ? (
                          <LoadingIndicator />
                        ) : (
                          t('refresh-report-graphs')
                        )}
                      </button>
                      <button
                        type="button"
                        onClick={() => this.setState({ reportToEdit: r })}
                      >
                        {t('edit-report')}
                      </button>
                      <button
                        type="button"
                        disabled={r.report_graphs.some(rg =>
                          graphResults.some(
                            g => g.id === rg && g.image_file === null
                          )
                        )}
                        onClick={() =>
                          window.open(
                            `/api/report/${r.id}/download_pdf/?theme=${theme.id}`,
                            '__blank'
                          )
                        }
                      >
                        {r.report_graphs.some(rg =>
                          graphResults.some(
                            g => g.id === rg && g.image_file === null
                          )
                        )
                          ? t('report-is-processed')
                          : t('download-pdf')}
                      </button>
                      <button
                        type="button"
                        onClick={() => this.setState({ reportToSend: r })}
                      >
                        {t('send-report')}
                      </button>
                      <button
                        type="button"
                        className="delete"
                        onClick={async () => {
                          await deleteReportAction(r.id)
                          updateList()
                        }}
                      >
                        {t('delete')}
                      </button>
                    </div>
                  </div>
                </Card>
              ))
            ) : (
              <h3>{t('empty-reports')}</h3>
            )}
          </Card>
        </ReportListContainer>
        <ReportGraphListContainer>
          <Card>
            <Title>{t('report_graphs')}</Title>
            <div className="report-graph-list">
              {reportItemResults.map(g => (
                <Card className="graph-result" key={`${g.id}_${g.image_file}`}>
                  <LazyGraphImage
                    item={g}
                    onClick={() => this.setState({ graphToEdit: g })}
                  />
                  <div className="graph-content">
                    <button
                      className="delete"
                      type="button"
                      onClick={async () => {
                        if ('mapping' in g && g.mapping) {
                          await deleteReportGraphAction(g.id)
                        } else {
                          await deleteReportDataMapAction(g.id)
                        }
                        updateList()
                      }}
                    >
                      {t('delete')}
                    </button>
                    <p>
                      <strong>{t('view-title')}</strong>:{' '}
                      {'mapping' in g && g.mapping
                        ? g.mapping.view_title
                        : t('data-map')}
                    </p>
                    <p>
                      <strong>{t('created')}</strong>:{' '}
                      {moment(g.created).format('DD.MM.YYYY HH:mm')}
                    </p>
                  </div>
                </Card>
              ))}
            </div>
          </Card>
        </ReportGraphListContainer>
        <EditCreateReportModal
          isOpen={reportToEdit !== null}
          report={reportToEdit}
          onClose={() => this.setState({ reportToEdit: null }, updateList)}
        />
        <EditReportItemContentModal
          isOpen={graphToEdit !== null}
          item={graphToEdit}
          onClose={() => this.setState({ graphToEdit: null }, updateList)}
        />
        <SendReportModal
          isOpen={reportToSend !== null}
          report={reportToSend}
          onClose={() => this.setState({ reportToSend: null })}
        />
      </ReportsContainer>
    )
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      deleteReportGraph,
      deleteReport,
      refreshReport,
      retrieveReport,
      deleteReportDataMap
    },
    dispatch
  )

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(withTheme(ReportList)))

import React, { useEffect } from 'react'
import { Card, Title } from 'components/blocks'
import styled from 'styled-components/macro'
import { useTranslation, withTranslation } from 'react-i18next'
import { ReactComponent as MicrosoftLogo } from 'assets/icons/microsoft_logo.svg'
import { toast } from 'react-toastify'
import { AzureLogin } from './Login'

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 150px);
  > div {
    width: 100%;
    max-width: 600px;
  }
`

const CardContent = styled.div`
  padding: 0.938rem;
  display: flex;
  flex-direction: column;
  label {
    font-family: 'Open Sans';
    font-weight: 100;
    color: #4a4a4a;
    letter-spacing: 0;
    line-height: 1.5;
    font-size: 1rem;
    margin: 0;
  }
  input {
    margin-bottom: 0.938rem;
    padding: 0.625rem;
    border-radius: 5px;
    border: 1px solid #dedede;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  button {
    box-sizing: border-box;
    border: 1px solid #ffd800;
    border-radius: 6px;
    color: #8e8e8e;
    font-family: 'Open Sans';
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 2;
    text-align: center;
    cursor: pointer;
    margin-right: 0.625rem;
    background: #fff;
    margin-top: 0.938rem;
  }
  h1 {
    margin-top: 0;
  }
`

const ConnectAzureAccount = () => {
  const { t, i18n } = useTranslation()
  useEffect(() => {
    const isConnected = localStorage.getItem('microsoft-connected')
    if (isConnected === 'true' && i18n.language) {
      const msg = t('azureConnectedSuccessfully')
      toast.success(msg)
      localStorage.removeItem('microsoft-connected')
    } else if (isConnected === 'false' && i18n.language) {
      const msg = t('azureConnectionError')
      toast.error(msg)
      localStorage.removeItem('microsoft-connected')
    }
  }, [t, i18n])

  return (
    <CardContainer>
      <Card>
        <CardContent>
          <Title>{t('connectAzure')}</Title>
          <AzureLogin
            onClick={() =>
              localStorage.setItem('attempt-azure-connect', 'true')
            }
            href={process.env.REACT_APP_AZURE_LOGIN_URL}
          >
            <div>
              <MicrosoftLogo width={18} height={18} />
            </div>
            <span>LOGIN WITH MICROSOFT</span>
          </AzureLogin>
        </CardContent>
      </Card>
    </CardContainer>
  )
}

export default withTranslation()(ConnectAzureAccount)

import {
  CREATE_TASK,
  UPDATE_TASK,
  GET_TASK,
  LIST_TASK,
  DELETE_TASK,
  TRIGGER_REFRESH,
  SET_DRAG_IN_PROGRESS
} from 'store/actions/task'

import produce from 'immer'
import { AnyAction } from 'redux'

export type TaskType = {
  date_length: number
  end_date: string
  id: number
  main_task: number
  progress: number
  row_index: number
  start_date: string
  targets: Array<number>
  title: string
  user: number
  type: string
}

interface TaskState {
  data: TaskType | null
  created: TaskType | null
  list: {
    results: Array<TaskType>
  }
  refresh: boolean
  dragInProgress: boolean | number
  isPending: boolean
  error: Array<Error> | Error | null
}

const initialState: TaskState = {
  data: null,
  created: null,
  list: { results: [] },
  refresh: false,
  dragInProgress: false,
  isPending: false,
  error: null
}

const task = (state = initialState, action: AnyAction) => {
  const { type, payload } = action

  switch (type) {
    case `${GET_TASK}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${GET_TASK}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${GET_TASK}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.data = payload
      })

    case `${LIST_TASK}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${LIST_TASK}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${LIST_TASK}_FULFILLED`:
      return produce(state, draft => {
        draft.list = payload
        draft.isPending = false
      })

    case `${CREATE_TASK}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${CREATE_TASK}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${CREATE_TASK}_FULFILLED`:
      return produce(state, draft => {
        draft.created = payload
        draft.isPending = false
      })

    case `${UPDATE_TASK}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${UPDATE_TASK}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${UPDATE_TASK}_FULFILLED`:
      return produce(state, draft => {
        draft.created = payload
        draft.isPending = false
      })

    case `${DELETE_TASK}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${DELETE_TASK}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${DELETE_TASK}_FULFILLED`:
      return produce(state, draft => {
        draft.created = payload
        draft.isPending = false
      })

    case TRIGGER_REFRESH:
      return produce(state, draft => {
        draft.refresh = payload
      })

    case SET_DRAG_IN_PROGRESS:
      return produce(state, draft => {
        draft.dragInProgress = payload
      })

    default:
      return state
  }
}

export default task

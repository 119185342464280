import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { TFunction, withTranslation } from 'react-i18next'
import Picker from 'react-month-picker'
import MonthBox from 'components/blocks/MonthBox'
import Icon from 'components/Icon'
import styled from 'styled-components'
import { triggerRefresh } from 'store/actions/task'
import { setSelections } from 'store/actions/monthselector'
import { RootState } from 'store/reducers'
import { RangeValue, SingleValue } from 'components/blocks/MonthSelector'
import { DateSelection, getDefaultEnd, getDefaultStart } from './Roadmap'

const DateSelectorContainer = styled.div`
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #ffd800;
  border-radius: 5px;
  cursor: pointer;
`

type DateSelectorReduxState = ReturnType<typeof mapStateToProps>
type DateSelectorReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface DateSelectorProps {
  location: string
  onChange: (selection: DateSelection) => void
  t: TFunction
}

interface DateSelectorState {
  rangeValue: RangeValue
}

export const getDefaultRange = () => {
  const start = getDefaultStart()
  const end = getDefaultEnd()
  return {
    from: { year: start.format('YYYY'), month: start.format('M') },
    to: { year: end.format('YYYY'), month: end.format('M') }
  }
}

class DateSelector extends Component<
  DateSelectorProps & DateSelectorReduxState & DateSelectorReduxDispatch,
  DateSelectorState
> {
  pickerLang: { months: string[]; from: string; to: string }
  pickRange: React.RefObject<typeof Picker>

  constructor(
    props: DateSelectorProps &
      DateSelectorReduxState &
      DateSelectorReduxDispatch
  ) {
    super(props)
    const { t } = this.props
    let rangeValue: RangeValue = getDefaultRange()
    const selections = props.monthselector?.selections || {}
    if (selections && selections[props.location]) {
      rangeValue = selections[props.location].value as RangeValue
    }
    this.state = {
      rangeValue
    }

    this.pickerLang = {
      months: [
        t('Jan'),
        t('Feb'),
        t('Mar'),
        t('Apr'),
        t('May'),
        t('Jun'),
        t('Jul'),
        t('Aug'),
        t('Sep'),
        t('Oct'),
        t('Nov'),
        t('Dec')
      ],
      from: t('From'),
      to: t('To')
    }
    this.pickRange = React.createRef()
  }

  getMonthFormat = (value?: string | number) => {
    const s = String(value)
    if (s.length === 1) return `0${s}`
    return s
  }

  handleRangeChange = (year: string, month: string, listIndex: number) => {
    const { onChange, triggerRefresh: triggerRefreshAction } = this.props
    const { rangeValue } = this.state
    const fromYear = listIndex === 0 ? year : rangeValue.from.year
    const toYear = listIndex === 1 ? year : rangeValue.to.year
    const month1 = this.getMonthFormat(
      listIndex === 0 ? month : rangeValue.from.month
    )
    const month2 = this.getMonthFormat(
      listIndex === 1 ? month : rangeValue.to.month
    )
    const rv = {
      from: { year: fromYear, month: parseInt(month1, 10) },
      to: { year: toYear, month: parseInt(month2, 10) }
    }
    this.setState({
      rangeValue: rv
    })
    let rowLength = 0
    const y1 = parseInt(fromYear as string, 10)
    const y2 = parseInt(toYear as string, 10)
    const m1 = parseInt(month1, 10)
    const m2 = parseInt(month2, 10)
    for (let y = y1; y <= y2; y += 1) {
      let add = 0
      if (y === y1) {
        if (y1 === y2) add = m2 - m1 + 1
        else add = 13 - m1
      } else if (y === y2) {
        add = m2
      } else {
        add = 12
      }
      rowLength += add
    }
    const dateSelection = {
      startYear: y1,
      startMonth: m1,
      endYear: y2,
      endMonth: m2,
      rowLength
    }
    onChange(dateSelection)
    this.setSelections(rv, dateSelection)
    triggerRefreshAction()
  }

  _handleClickRangeBox = () => {
    this.pickRange.current.show()
  }

  handleRangeDissmis = (value: RangeValue) => {
    this.setState({ rangeValue: value })
  }

  getText = () => {
    const { rangeValue } = this.state
    const makeText = (m: SingleValue) => {
      if (m && m.year && m.month)
        return `${this.pickerLang.months[(m.month as number) - 1]}. ${m.year}`
      return '?'
    }
    return `${makeText((rangeValue as RangeValue).from)} ~ ${makeText(
      (rangeValue as RangeValue).to
    )}`
  }

  setSelections = (value: RangeValue, dateSelection: DateSelection) => {
    const {
      setSelections: setSelectionsAction,
      location,
      monthselector
    } = this.props
    const selections = {
      ...(monthselector.selections ? monthselector.selections : {})
    }
    selections[location] = { value, dateSelection }
    setSelectionsAction(selections)
  }

  render() {
    const { t } = this.props
    const { rangeValue } = this.state
    const label = (
      <div>
        {t('Period of time')}
        <Icon
          style={{
            marginLeft: '10px',
            display: 'inline-block',
            cursor: 'pointer'
          }}
          color="#777777"
          iconName="calendar"
          size={15}
        />
      </div>
    )
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1
    const allowedYears = 10
    return (
      <>
        <div className="edit" style={{ cursor: 'pointer' }}>
          <Picker
            style={{ cursor: 'pointer' }}
            ref={this.pickRange}
            years={{
              min: { year: currentYear - 10, month: currentMonth },
              max: { year: currentYear + allowedYears, month: 12 }
            }}
            value={rangeValue}
            lang={this.pickerLang}
            theme="light"
            onChange={this.handleRangeChange}
            onDismiss={this.handleRangeDissmis}
          >
            <DateSelectorContainer>
              <MonthBox value={label} onClick={this._handleClickRangeBox} />
            </DateSelectorContainer>
          </Picker>
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ monthselector }: RootState) => ({
  monthselector
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setSelections,
      triggerRefresh
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DateSelector))

import { Get } from 'api/index'
import { createAsyncAction } from 'redux-promise-middleware-actions'
import { RootState } from 'store/reducers'

export const LIST_COLORPAIR = 'LIST_COLORPAIR'

export const listColorpair = createAsyncAction(LIST_COLORPAIR, async () => {
  const url = `/api/colorpair/`
  const data = await Get<RootState['colorpair']['list']>(url)
  return data
})

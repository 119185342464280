import { Get } from 'api/index'
import { createAsyncAction } from 'redux-promise-middleware-actions'
import { RootState } from 'store/reducers'
import { AppDispatch } from 'store/store'

export const GET_TARGET_GROUP = 'GET_TARGET_GROUP'
export const LIST_TARGET_GROUP = 'LIST_TARGET_GROUP'
export const HIDE_TARGET_GROUP = 'HIDE_TARGET_GROUP'

export const getTargetGroup = createAsyncAction(
  GET_TARGET_GROUP,
  async (id: number, params = {}) => {
    const url = `/api/target_group/${id}/`
    const data = await Get<RootState['targetGroup']['data'][0]>(url, params)
    return data
  }
)

export const listTargetGroup = createAsyncAction(
  LIST_TARGET_GROUP,
  async (params = {}) => {
    const url = `/api/target_group/`
    const data = await Get<RootState['targetGroup']['list']>(url, params)
    return data
  }
)

export const hideTargetGroup = (id: number | string) => (
  dispatch: AppDispatch
) =>
  dispatch({
    type: HIDE_TARGET_GROUP,
    payload: {
      id
    }
  })

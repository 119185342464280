import { Get } from 'api/index'
import { createAsyncAction } from 'redux-promise-middleware-actions'
import { RootState } from 'store/reducers'
import { AppDispatch } from 'store/store'

export const GET_GRAPH_SETTINGS = 'GET_GRAPH_SETTINGS'
export const SET_GRAPH_STYLE_OVERRIDE = 'SET_GRAPH_STYLE_OVERRIDE'
export const CALCULATE_NUMBER_FROM_FORMULA = 'CALCULATE_NUMBER_FROM_FORMULA'

export const getGraphSettings = createAsyncAction(GET_GRAPH_SETTINGS, async () => {
  const url = `/api/graph_settings/get_graph_settings/`
  const data = await Get<RootState['graph']['settings']>(url)
  return data
})

export const setGraphStyleOverride = (state: boolean) => (dispatch: AppDispatch) =>
  dispatch({
    type: SET_GRAPH_STYLE_OVERRIDE,
    payload: state
  })

export const calculateNumberFromFormula = createAsyncAction(
  CALCULATE_NUMBER_FROM_FORMULA,
  async (key: string, start: string, end: string) => {
    const url = `/api/formula/calculate_number_from_formula/`
    const data = await Get<RootState['graph']['numbersFromFormula'][0]>(url, {
      key,
      start_date: start,
      end_date: end
    })
    return data
  }
)

import React, { createRef } from 'react'
import moment from 'moment'
import styled from 'styled-components/macro'
import Arrow from 'assets/icons/arrow.svg'
import { connect } from 'react-redux'
import { formatFloat, formatPercent, getGraphPathName } from 'util/format'
import LineGraph from 'assets/icons/line-graph.svg'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { TargetMeter } from 'components/strategy'
import { TFunction, withTranslation } from 'react-i18next'
import LoadingIndicator from 'components/blocks/LoadingIndicator'
import { RootState } from 'store/reducers'
import { ReactD3TreeItem } from 'react-d3-tree'
import { TermOfClassification } from 'store/reducers/term'
import { Target } from 'store/reducers/target'
import { RawData } from 'store/reducers/rawdata'
import { Get } from 'api'

const Container = styled.div`
  position: fixed;
  border-radius: 8px 9px 8px 8px;
  background-color: ${props => props.theme.logic.nodeBackground};
  box-shadow: ${props => props.theme.logic.nodeShadow};
  padding: 1rem;
  width: 273px;
  h2 {
    color: ${props => props.theme.logic.nodeTitleColor};
    font-family: 'Open Sans';
    font-size: 0.875rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1.188rem;
    margin: 0 0 5px 0;
  }
`

const OpenButton = styled.div`
  position: absolute;
  height: 1.625rem;
  width: 1.625rem;
  background-color: ${(props: { isCollapsed: boolean }) =>
    props.isCollapsed ? '#b9c5c8' : '#DEEAEC'};
  border-radius: 100%;
  right: -0.813rem;
  top: calc(50% - 0.813rem);
  transition: background-color 0.2 ease-in;
`

const Indicator = styled.div`
  width: 100%;
  height: 100%;
  mask: url(${Arrow});
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  background: ${(props: { isCollapsed: boolean }) =>
    props.isCollapsed ? '#fff' : '#B9C5C8'};
  mask: #b9c5c8;
  transform: ${props => (props.isCollapsed ? 'rotate(0)' : 'rotate(180deg)')};
  transition: transform 0.2s ease-in, background 0.2s ease-in;
`

const ValueLabel = styled.p`
  color: ${(props: { value: number | string }) =>
    props.value < 0 ? '#ff6060' : '#75c60e'};
  font-family: 'Open Sans';
  font-size: 1.063rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.438rem;
  margin: 0 0 5px 0;
  span {
    color: #777777;
    font-family: 'Open Sans';
    font-size: 0.813rem;
    letter-spacing: 0;
    line-height: 1.5;
    font-weight: 100;
    margin-left: 5px;
  }
`

const GraphButton = styled.span`
  display: flex;
  align-items: center;
  color: #b9c5c8;
  font-family: 'Open Sans';
  font-size: 0.813rem;
  letter-spacing: 0;
  line-height: 0.813rem;
  margin-bottom: 5px;
  &:last-of-type {
    margin-bottom: 0;
  }
  &:hover {
    color: ${props => props.theme.logic.linkColor};
  }
`

const LineIcon = styled.span`
  display: inline-block;
  height: 0.938rem;
  width: 1.125rem;
  background: url(${LineGraph});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin-right: 7px;
`

const RootLabel = styled.div`
  margin-bottom: 0.938rem;
  padding-bottom: 0.938rem;
  border-bottom: 1px solid #ddd;
  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`

const TargetContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.625rem;
  > div {
    display: flex;
    align-items: center;
    h5 {
      margin: 0;
      color: #999999;
      font-family: 'Open Sans';
      font-size: 0.813rem;
      letter-spacing: 0;
      line-height: 1.125rem;
      font-weight: 300;
      margin-right: 5px;
    }
    span {
      color: #575757;
      font-family: 'Open Sans';
      font-size: 0.813rem;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 1.25rem;
    }
  }
`
const LoaderContainer = styled.div`
  padding: 1.25rem 0;
`

type NodeLabelReduxState = Partial<ReturnType<typeof mapStateToProps>>

interface NodeLabelProps {
  nodeData?: ReactD3TreeItem
  dateTimeline: Array<string>
  onTranslate: (translation: { x: number; y: number }) => void
  t: TFunction
  isYTD: boolean
}

interface NodeLabelState {
  inGraphs?: string[] | { [key: string]: string[] } | null
  currentTimeline?: Array<string>
  updateData?: boolean
  value?: { [key: string]: number } | number | null
  percentage?: number | { [key: string]: number } | null
  targets?: Array<Target>
}

class NodeLabel extends React.PureComponent<
  NodeLabelProps & NodeLabelReduxState & RouteComponentProps,
  NodeLabelState
> {
  rootContainer: React.RefObject<HTMLDivElement>
  normalContainer: React.RefObject<HTMLDivElement>

  static getDerivedStateFromProps(
    props: NodeLabelProps & NodeLabelReduxState & RouteComponentProps,
    state: NodeLabelState
  ) {
    const newState: NodeLabelState = {}
    const { settings, nodeData, dateTimeline } = props
    const { currentTimeline } = state
    if (
      state.inGraphs === null &&
      settings &&
      (settings.mappings || []).length
    ) {
      const { mappings } = settings
      let inGraphs: string[] | { [key: string]: Array<string> } = []
      if (nodeData?.name === 'root' && nodeData?.attributes) {
        const { rootTerms } = nodeData?.attributes
        const rootTermsAsArray = rootTerms as TermOfClassification[]
        const inGraphsObj: { [key: string]: Array<string> } = {}
        rootTermsAsArray.forEach(nd => {
          const { key } = nd
          inGraphsObj[key] = []
          mappings.forEach(m => {
            if (m.terms_used.map(t => t.key).includes(key)) {
              inGraphsObj[key].push(m.view_title)
            }
            if (m.children.length) {
              m.children.forEach(m2 => {
                if (m2.terms_used.map(t => t.key).includes(key)) {
                  inGraphsObj[key].push(m2.view_title)
                }
              })
            }
          })
        })
        inGraphs = inGraphsObj
      } else {
        if (!nodeData?.attributes) {
          return {}
        }
        const { key } = nodeData?.attributes
        const inGraphsArray: string[] = []
        mappings.forEach(m => {
          if (m.terms_used.map(t => t.key).includes(key as string)) {
            inGraphsArray.push(m.view_title)
          }
          if (m.children.length) {
            m.children.forEach(m2 => {
              if (m2.terms_used.map(t => t.key).includes(key as string)) {
                inGraphsArray.push(m2.view_title)
              }
            })
          }
        })
        inGraphs = inGraphsArray
      }
      newState.inGraphs = inGraphs
    }
    if (
      ((currentTimeline && currentTimeline[0] !== dateTimeline[0]) ||
        (currentTimeline && currentTimeline[1] !== dateTimeline[1])) &&
      !state.updateData
    ) {
      newState.currentTimeline = dateTimeline
      newState.updateData = true
    }
    return newState
  }

  constructor(
    props: NodeLabelProps & NodeLabelReduxState & RouteComponentProps
  ) {
    super(props)
    this.rootContainer = createRef()
    this.normalContainer = createRef()
    this.state = {
      value: null,
      percentage: null,
      inGraphs: null,
      currentTimeline: [],
      targets: [],
      updateData: false
    }
  }

  componentDidMount() {
    const { updateData } = this.state
    if (updateData) {
      this.updateData()
    }
  }

  componentDidUpdate() {
    const { updateData } = this.state
    if (updateData) {
      this.updateData()
    }
    if (
      this.rootContainer.current &&
      this.rootContainer.current.parentElement
    ) {
      const parent = this.rootContainer.current.parentElement
      parent.setAttribute(
        'y',
        `-${this.rootContainer.current.offsetHeight / 2}px`
      )
      parent.setAttribute(
        'height',
        (this.rootContainer.current.offsetHeight + 50).toString()
      )
    }
    if (
      this.normalContainer.current &&
      this.normalContainer.current.parentElement
    ) {
      const parent = this.normalContainer.current.parentElement
      parent.setAttribute(
        'y',
        `-${this.normalContainer.current.offsetHeight / 2}px`
      )
    }
  }

  fetchRootNodeResults = async () => {
    const data: { value: RootState['rawData']['list'] } = {
      value: {
        results: []
      }
    }
    const { nodeData, isYTD } = this.props
    const { currentTimeline } = this.state
    const maxKeys = 100
    let offset = 0
    const slices = []
    if (!nodeData?.attributes || !currentTimeline) {
      return
    }
    const termLength = nodeData?.attributes.rootTerms
      ? nodeData?.attributes.rootTerms.length
      : 0
    while (offset < termLength) {
      slices.push(
        nodeData?.attributes.rootTerms?.slice(offset, offset + maxKeys)
      )
      offset += maxKeys
    }
    await Promise.all(
      slices.map(async s => {
        const url = `/api/data/`
        const sliceData = await Get<RootState['rawData']['list']>(url, {
          keys: s?.map(s2 => s2.key) || [],
          start_date: currentTimeline[0],
          end_date: currentTimeline[1],
          with_percentage: true,
          only_verified: isYTD
        })
        const { results } = sliceData
        data.value.results = [...data.value.results, ...results]
      })
    )
    this.updateRootNodeResultsToState(data.value.results)
  }

  fetchTargetsForRoot = async () => {
    const { nodeData } = this.props
    const { currentTimeline } = this.state
    let targets: Target[] = []
    nodeData?.attributes.rootTerms?.forEach(n => {
      targets = [...targets, ...n.targets]
    })
    if (!currentTimeline) {
      return
    }
    const newTargets = await Promise.all(
      targets.map(target =>
        Get<RootState['target']['data']>(`/api/target/${target.id}/`, {
          start_date: currentTimeline[0],
          end_date: currentTimeline[1]
        })
      )
    )
    this.setState({ targets: newTargets as Target[] })
  }

  updateRootNodeResultsToState = async (results: RawData[]) => {
    const { nodeData } = this.props
    const value: { [key: string]: number } = {}
    const percentage: { [key: string]: number } = {}
    nodeData?.attributes.rootTerms?.forEach(r => {
      const filteredResults = results
        .filter(r2 => r.key === r2.key)
        .sort((a, b) => (moment(a.start_date) < moment(b.start_date) ? 1 : -1))
      const filteredPercentageValues = filteredResults.filter(
        f => f.percentage_value
      )
      const resultsValue = filteredResults[0] ? filteredResults[0].value : 0
      value[r.key] = r.sum_values_in_tree
        ? filteredResults.reduce((a, b) => a + (b ? b.value : 0), 0)
        : resultsValue
      percentage[r.key] =
        filteredPercentageValues.reduce(
          (a, b) => a + (b.percentage_value as number),
          0
        ) / filteredPercentageValues.length
    })
    await this.fetchTargetsForRoot()
    this.setState({
      value,
      percentage
    })
  }

  updateData = async () => {
    this.setState({ updateData: false, value: null })
    const { nodeData, isYTD } = this.props
    const { currentTimeline } = this.state
    if (nodeData?.name === 'root') {
      this.fetchRootNodeResults()
      return
    }
    if (!currentTimeline) {
      return
    }
    const url = `/api/data/`
    const data = await Get<RootState['rawData']['list']>(url, {
      keys: [nodeData?.attributes.key as string],
      start_date: currentTimeline[0],
      end_date: currentTimeline[1],
      with_percentage: true,
      only_verified: isYTD
    })
    const { results } = data
    const percentageResults = results.filter(f => f.percentage_value)
    const sortedResults = results.sort((a, b) =>
      moment(a.start_date) < moment(b.start_date) ? 1 : -1
    )
    this.setState({
      /* eslint-disable no-nested-ternary */
      value: nodeData?.attributes.sum_values_in_tree
        ? results.reduce((a, b) => a + (b ? b.value : 0), 0)
        : sortedResults && sortedResults[0]
        ? sortedResults[0].value
        : 0,
      /* eslint-enable no-nested-ternary */
      percentage:
        percentageResults.reduce(
          (a, b) => a + (b.percentage_value as number),
          0
        ) / percentageResults.length
    })
  }

  renderTargets = (term: TermOfClassification) => {
    const { t: trans } = this.props
    const { targets } = this.state
    const termTargets = term.targets.map(t => (t || {}).id)
    const fetchedTargets = targets?.filter(t =>
      termTargets.includes((t || {}).id)
    )
    return fetchedTargets?.map(t => (
      <TargetContainer key={t.id}>
        <div>
          <h5>{trans('target')}:</h5>{' '}
          <span>
            {formatFloat(t.target_value)} {term.unit}
          </span>
        </div>
        <TargetMeter target={t} />
      </TargetContainer>
    ))
  }

  centerOnContainer = () => {
    const {
      rootContainer: { current: root },
      normalContainer: { current: normal }
    } = this
    const { onTranslate } = this.props
    let parentElement: SVGGElement | null = null
    if (root && root.parentElement) {
      parentElement = root.parentElement.parentElement as SVGGElement | null
    } else if (normal && normal.parentElement) {
      parentElement = normal.parentElement.parentElement as SVGGElement | null
    }
    if (parentElement) {
      const { matrix } = parentElement.transform.baseVal[0]
      const translate = {
        x: matrix.e + 273 / 2,
        y: matrix.f
      }
      onTranslate(translate)
    }
  }

  render() {
    const { value, inGraphs, percentage } = this.state
    const { nodeData, history, t } = this.props
    if (value === null || value === undefined) {
      return (
        <Container>
          <LoaderContainer>
            <LoadingIndicator />
          </LoaderContainer>
        </Container>
      )
    }
    if (nodeData?.name === 'root' && typeof value !== 'number') {
      return (
        <Container ref={this.rootContainer} onClick={this.centerOnContainer}>
          {!Object.keys(value).length && !nodeData?._children ? (
            <h2>{t('noTreeDisclaimer')}</h2>
          ) : null}
          {Object.keys(value).map(v => {
            const term = nodeData?.attributes.rootTerms?.find(r => r.key === v)
            const numericValue = parseFloat(value[v].toString())
            const percentageValue =
              percentage && typeof percentage !== 'number'
                ? percentage[v]
                : null
            return (
              <RootLabel key={v}>
                <h2>{term?.title}</h2>
                {typeof numericValue === 'number' ? (
                  <ValueLabel value={numericValue}>
                    {formatFloat(numericValue)} {term?.unit}
                    <span>
                      {percentageValue
                        ? `(${formatPercent(percentageValue)})`
                        : ''}
                    </span>
                  </ValueLabel>
                ) : null}
                {this.renderTargets(term as TermOfClassification)}
                {inGraphs && !Array.isArray(inGraphs) && inGraphs[v]
                  ? inGraphs[v].map((g: string) => (
                      <GraphButton
                        key={g}
                        onClick={(e: { stopPropagation: () => void }) => {
                          e.stopPropagation()
                          history.push(getGraphPathName(g))
                        }}
                      >
                        <LineIcon />
                        {g}
                      </GraphButton>
                    ))
                  : null}
                {nodeData?._children && (
                  <OpenButton isCollapsed={nodeData?._collapsed as boolean}>
                    <Indicator isCollapsed={nodeData?._collapsed as boolean} />
                  </OpenButton>
                )}
              </RootLabel>
            )
          })}
        </Container>
      )
    }
    return (
      <Container ref={this.normalContainer} onClick={this.centerOnContainer}>
        <h2>{nodeData?.name}</h2>
        {value ? (
          <ValueLabel value={value as number}>
            {formatFloat(value as number)} {nodeData?.attributes.unit}
            <span>
              {percentage ? `(${formatPercent(percentage as number)})` : ''}
            </span>
          </ValueLabel>
        ) : null}
        {inGraphs && Array.isArray(inGraphs)
          ? inGraphs.map(g => (
              <GraphButton
                key={g}
                onClick={(e: { stopPropagation: () => void }) => {
                  e.stopPropagation()
                  history.push(getGraphPathName(g))
                }}
              >
                <LineIcon />
                {g}
              </GraphButton>
            ))
          : null}
        {nodeData?._children && (
          <OpenButton isCollapsed={nodeData?._collapsed as boolean}>
            <Indicator isCollapsed={nodeData?._collapsed as boolean} />
          </OpenButton>
        )}
      </Container>
    )
  }
}

const mapStateToProps = ({ graph }: RootState) => ({
  settings: graph.settings
})

export default connect(
  mapStateToProps,
  null
)(withRouter(withTranslation()(NodeLabel)))

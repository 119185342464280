import React from 'react'
import styled from 'styled-components/macro'
import { useSelector, useDispatch } from 'react-redux'
import Icon from 'components/Icon'
import { DARK_THEME, LIGHT_THEME, SYSTEM } from 'theme'
import { saveActiveTheme } from 'store/actions/app'
import { RootState } from 'store/reducers'

const IconRow = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: #222222;
  border-radius: 0 0 8px 8px;
`
const ThemeIcon = styled(Icon)`
  flex-grow: 1;
  &:hover {
    background-color: #fff;
  }
  &.active {
    background-color: #ffd800;
  }
`

const icons = [
  { id: LIGHT_THEME, iconName: 'lightMode' },
  { id: DARK_THEME, iconName: 'darkMode' },
  { id: SYSTEM, iconName: 'computer' }
]

const ThemeSelector = () => {
  const dispatch = useDispatch()
  const activeId = useSelector((state: RootState) => state.app.activeTheme)

  const onSelect = (id: string) => {
    dispatch(saveActiveTheme(id))
    localStorage.setItem('activeTheme', id)
  }

  return (
    <IconRow>
      {icons.map(({ id, iconName }) => (
        <ThemeIcon
          key={id}
          size={16}
          className={activeId === id ? 'active' : undefined}
          onClick={() => onSelect(id)}
          iconName={iconName}
        />
      ))}
    </IconRow>
  )
}

export default ThemeSelector

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import styled, { withTheme, DefaultTheme } from 'styled-components/macro'
import { bindActionCreators } from 'redux'
import { triggerRefresh, updateTask } from 'store/actions/task'
import { connect } from 'react-redux'
import { TaskTooltip } from 'components/roadmap'
import { ISO_FORMAT_DATE } from 'util/dates'
import { DARK_THEME } from 'theme'
import { RootState } from 'store/reducers'
import { DateSelection } from './Roadmap'
import { TaskType } from 'store/reducers/task'
import { AppDispatch } from 'store/store'

const getTaskBorderRadius = ({
  continues,
  beginsOutside
}: {
  continues: boolean
  beginsOutside: boolean
}) => {
  if (continues && beginsOutside) {
    return '0'
  }
  if (continues && !beginsOutside) {
    return '10px 0 0 10px'
  }
  if (!continues && beginsOutside) {
    return '0 10px 10px 0'
  }
  return '10px'
}

const TaskBarContainer = styled.div`
  width: ${(props: { width: string; left: string | number }) => props.width};
  display: inline-block;
  position: absolute;
  left: ${props => props.left};
  margin-bottom: 10px;
  z-index: 2;
`

const TaskBar = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  margin-right: 2px;
  padding: 10px 0;
  cursor: pointer;
  user-select: none;
  border: ${(props: {
    darkColor: string
    continues: boolean
    beginsOutside: boolean
    type: string
    color: string
  }) => (props.type === 'empty' ? 'none' : `1px solid ${props.darkColor}`)};
  border-radius: ${props => getTaskBorderRadius(props)};
  background-color: ${props =>
    props.type === 'empty' ? 'transparent' : props.color};
  ${props => (props.type === 'empty' ? 'display: inline-block;' : '')}
  box-shadow: 0 0 0 0
      ${props => (props.type === 'empty' ? 'transparent' : props.color)};
  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 0 10px 0
      ${props => (props.type === 'empty' ? 'transparent' : props.color)};
  }
  &.dark {
    background-color: transparent;
    border: ${props =>
      props.type === 'empty' ? 'none' : `2px solid ${props.darkColor}`};
    box-shadow: inset 0 0 2px ${(props: { darkColor: string }) =>
      props.darkColor};
  }
`
const DragHandle = styled.div`
  position: absolute;
  width: 15px;
  height: 100%;
  cursor: w-resize;
  ${(props: { position: string }) => props.position}: 1px;
  top: 0;
  z-index: 999;
`

export type TaskResizeDir = 'left' | 'right'
type TaskReduxState = ReturnType<typeof mapStateToProps>
type TaskReduxDispatch = ReturnType<typeof mapDispatchToProps>
interface TaskProps {
  rowLength: number
  color: string
  darkColor: string
  task: TaskType
  dateSelection: DateSelection
  isRoadmapManager?: boolean
  theme: DefaultTheme
  isResizeAllowed: (task: TaskType, dir: TaskResizeDir) => boolean
}

interface TaskState {
  startDrag: number | null
  startLength: number | null
  modifiedLength: number | null
  fromLeft: boolean
  beginStartDate: string | null
  modifiedStart: string | null
  tooltipOpen: boolean,
  tooltipPosition: { x: number; y: number } | null
}

const CURSOR_TOOLTIP_OFFSET_X = 5
const CURSOR_TOOLTIP_OFFSET_Y = 5

class Task extends Component<
  TaskProps & TaskReduxState & TaskReduxDispatch,
  TaskState
> {

  // Instead of state variables, class variables are used for hoverTimer and tooltipVisited.
  // This is to keep the timed callbacks functioning properly.
  hoverTimer: ReturnType<typeof setTimeout> | null = null;
  tooltipVisited = false;

  constructor(props: TaskProps & TaskReduxState & TaskReduxDispatch) {
    super(props)
    this.state = {
      startDrag: null,
      startLength: null,
      modifiedLength: null,
      fromLeft: false,
      beginStartDate: null,
      modifiedStart: null,
      tooltipOpen: false,
      tooltipPosition: null,
    }
  }

  componentDidMount() {
    document.addEventListener('mousemove', this.resizeTask)
    document.addEventListener('mouseup', this.stopResize)
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.resizeTask)
    document.removeEventListener('mouseup', this.stopResize)
  }

  startResize = (
    e: MouseEvent | React.MouseEvent<HTMLDivElement, MouseEvent>,
    fromLeft: boolean
  ) => {
    const { modifiedLength, modifiedStart } = this.state
    const {
      task: { date_length: dateLength, start_date: startDate }
    } = this.props
    const root = document.getElementById('root')
    if (root) {
      root.style.pointerEvents = 'none'
    }
    document.body.style.cursor = 'w-resize'
    this.setState({
      startDrag: e.clientX,
      startLength: modifiedLength || dateLength,
      beginStartDate: modifiedStart || startDate,
      fromLeft
    })
  }

  stopResize = async () => {
    const { startDrag } = this.state
    if (startDrag === null) {
      return
    }
    this.setState({ startDrag: null })
    const root = document.getElementById('root')
    if (root) {
      root.style.pointerEvents = 'all'
    }
    document.body.style.cursor = 'default'
    const {
      updateTask: updateTaskAction,
      task,
      triggerRefresh: triggerRefreshAction
    } = this.props
    const { modifiedStart, modifiedLength } = this.state

    await updateTaskAction(
      task.id,
      JSON.stringify({
        ...task,
        start_date: modifiedStart || task.start_date,
        date_length: modifiedLength
      })
    )
    triggerRefreshAction(true)
  }

  resizeTask = (e: { clientX: number }) => {
    const { startDrag, startLength, fromLeft, beginStartDate } = this.state
    const { task, isResizeAllowed } = this.props
    if (startDrag) {
      const offset = 15
      const moveDiff = e.clientX + offset - startDrag
      const emptyCellWidth =
        document.querySelector('.empty-cell')?.clientWidth || 0
      const diffInColumns = Math.round(moveDiff / emptyCellWidth)

      if (!fromLeft) {
        const taskLength = startLength || 0
        if (
          isResizeAllowed(
            {
              ...task,
              date_length: taskLength + diffInColumns
            },
            'right'
          )
        ) {
          this.setState({ modifiedLength: taskLength + diffInColumns })
        }
      } else {
        const newDate = moment(beginStartDate).add(diffInColumns, 'months')
        const modifiedLengthDiff = moment(beginStartDate).diff(
          newDate,
          'months'
        )
        const taskLength = startLength || 0
        if (
          isResizeAllowed(
            {
              ...task,
              start_date: newDate.format(ISO_FORMAT_DATE),
              date_length: taskLength + modifiedLengthDiff
            },
            'left'
          )
        ) {
          this.setState({
            modifiedStart: newDate.format(ISO_FORMAT_DATE),
            modifiedLength: taskLength + modifiedLengthDiff
          })
        }
      }
    }
  }

  render() {
    const {
      color,
      darkColor,
      rowLength,
      task,
      dateSelection,
      target: {
        list: { results }
      },
      isRoadmapManager,
      theme
    } = this.props
    const {
      modifiedLength,
      modifiedStart,
      tooltipOpen,
      tooltipPosition
    } = this.state

    const dateLength = modifiedLength || task.date_length
    const startMoment = moment(modifiedStart || task.start_date)
    const endMoment = moment(startMoment).add(dateLength, 'months')
    const selectionStartMoment = moment(
      `${dateSelection.startYear}-${dateSelection.startMonth}`
    ).startOf('month')
    const selectionEndMoment = moment(
      `${dateSelection.endYear}-${dateSelection.endMonth}`
    ).endOf('month')
    const amountFromRight = Math.abs(
      startMoment.diff(
        moment(`${dateSelection.endYear}-${dateSelection.endMonth}`),
        'months'
      )
    )
    const clampedDateLength =
      endMoment > selectionEndMoment ? amountFromRight : dateLength
    let width = `${(100 / rowLength) * clampedDateLength}%`
    const amountFromLeft = startMoment.diff(
      moment(`${dateSelection.startYear}-${dateSelection.startMonth}`),
      'months'
    )
    let left: string | number = `${(100 / rowLength) * amountFromLeft}%`
    if ((100 / rowLength) * amountFromLeft < 0) {
      width = `${(100 / rowLength) * clampedDateLength +
        (100 / rowLength) * amountFromLeft}%`
      left = 0
    }
    const progressContainer: JSX.Element[] = []
    const titleContainer: JSX.Element[] = []

    let title = `${task.title}`

    if (task.type === 'empty') {
      title = ' '
    } else {
      const titleStyle: React.CSSProperties = {
        backgroundColor: 'transparent',
        position: 'relative',
        padding: '0 15px'
      }
      const titleContainerKey = `titleContainer-${task.id}`
      titleContainer.push(
        <div key={titleContainerKey} style={titleStyle}>
          {title}
        </div>
      )
    }
    const targets = results.filter(r => task.targets.includes(r.id))
    const average =
      targets.reduce((a, b) => a + b.current_value / b.target_value, 0) /
      targets.length
    const progress = task.targets.length ? average * 100 : task.progress
    if (progress) {
      const progressStyle: React.CSSProperties = {
        width: `${Math.min(progress, 100)}%`,
        backgroundColor: darkColor,
        position: 'absolute',
        opacity: theme.id === DARK_THEME ? 0.3 : 1,
        top: '0',
        left: '0',
        height: '100%',
        border: '2px solid transparent',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
        borderBottomLeftRadius: '10px'
      }
      if (progress < 99 || endMoment > selectionEndMoment) {
        progressStyle.borderTopRightRadius = '0'
        progressStyle.borderBottomRightRadius = '0'
      }
      if (startMoment < selectionStartMoment) {
        progressStyle.borderTopLeftRadius = '0'
        progressStyle.borderBottomLeftRadius = '0'
      }
      const progressKey = `progressKey-${task.id}-${dateLength}`
      progressContainer.push(<div key={progressKey} style={progressStyle} />)
    }
    return (
      <>
        <TaskBarContainer width={width} left={left}>
          {isRoadmapManager && (
            <DragHandle
              position="left"
              onMouseDown={e => this.startResize(e, true)}
              draggable
              onDragStart={e => e.preventDefault()}
            />
          )}
          <TaskBar
            className={theme.id === DARK_THEME ? 'dark' : ''}
            color={color}
            darkColor={darkColor}
            type={task.type}
            continues={endMoment > selectionEndMoment}
            beginsOutside={startMoment < selectionStartMoment}
            onClick={event => {
              this.setState({
                tooltipOpen: true,
                tooltipPosition: {
                  x: event.clientX - CURSOR_TOOLTIP_OFFSET_X,
                  y: event.clientY - CURSOR_TOOLTIP_OFFSET_Y
                }
              })
            }}
            onMouseMove={event => {
              if(!tooltipOpen)
                this.setState({
                  tooltipPosition: {
                    x: event.clientX - CURSOR_TOOLTIP_OFFSET_X,
                    y: event.clientY - CURSOR_TOOLTIP_OFFSET_Y
                  }})
            }}
            onMouseEnter={() => {
              if (!tooltipOpen) {
                this.hoverTimer = setTimeout(() => {
                  this.setState({
                  tooltipOpen: true
                  })
                }, 250)
              }
            }}
            onMouseLeave={() => {
              if (this.hoverTimer) {
                clearTimeout(this.hoverTimer);
              }
              if (tooltipOpen) {
                setTimeout(() => {
                  // If tooltip hasn't been touched by cursor,
                  // it can be closed by a countdown triggered after leaving the task Bar
                  if (tooltipOpen && !this.tooltipVisited ) {
                    this.setState({ tooltipOpen: false })
                  }
                }, 40);
              }
            }}
          >
            {progressContainer}
            {titleContainer}
          </TaskBar>
          {isRoadmapManager && (
            <DragHandle
              position="right"
              onMouseDown={e => this.startResize(e, false)}
              draggable
              onDragStart={e => e.preventDefault()}
            />
          )}
        </TaskBarContainer>
        <TaskTooltip
          show={tooltipOpen}
          position={tooltipPosition}
          onClose={() => {
            this.setState({ tooltipOpen: false})
            this.tooltipVisited = false;
          }}
          onHover={() => {
            this.tooltipVisited = true
          }}
          task={task}
          isRoadmapManager={isRoadmapManager}
        />
      </>
    )
  }
}

const mapStateToProps = ({ target }: RootState) => ({
  target
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      updateTask,
      triggerRefresh
    },
    dispatch
  )

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withTheme(Task))
)

import {
  GET_TARGET,
  GET_TARGET_AS_GRAPH,
  LIST_TARGET,
  SET_TARGET_TO_GRAPH
} from 'store/actions/target'

import produce from 'immer'
import { RESET_STATE } from 'store/actions/reset'
import { AnyAction } from 'redux'

export type Target = {
  additional_information: string
  compare_formula_str: string
  compare_value: number
  created: string
  current_value: number
  end_date: string
  formula_str: string
  id: number
  is_active: boolean
  link_tag: string
  link_term: number
  measurement_type_prefix: string
  modified: string
  order_in_group: number
  reversed_target: boolean
  start_date: string
  target_formula_str: string
  target_groups: number[]
  target_value: number
  title: string
  value_numeric_type: string | null
  minimum_value: number
  data_available_for_current_value: boolean
}

type TargetAsGraph = {
  [key: string]: {
    compare_value: number | null
    current_value: number | null
    target_value: number | null
  }
}

interface TargetState {
  data: Target | null
  list: {
    results: Array<Target>
  }
  graphTarget: Target | null
  targetAsGraph: TargetAsGraph | null
  isPending: boolean
  error: Array<Error> | Error | null
}

const initialState: TargetState = {
  data: null,
  list: {
    results: []
  },
  graphTarget: null,
  targetAsGraph: null,
  isPending: false,
  error: null
}

const target = (state = initialState, action: AnyAction) => {
  const { type, payload } = action

  switch (type) {
    case `${GET_TARGET}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${GET_TARGET}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${GET_TARGET}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.data = payload
      })

    case `${GET_TARGET_AS_GRAPH}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${GET_TARGET_AS_GRAPH}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${GET_TARGET_AS_GRAPH}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.targetAsGraph = payload
      })

    case `${LIST_TARGET}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${LIST_TARGET}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${LIST_TARGET}_FULFILLED`:
      return produce(state, draft => {
        draft.list = payload
        draft.isPending = false
      })

    case SET_TARGET_TO_GRAPH:
      return produce(state, draft => {
        draft.graphTarget = payload
      })

    case RESET_STATE:
      return initialState

    default:
      return state
  }
}

export default target

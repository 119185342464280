import React, { Component } from 'react'
import Icon from 'components/Icon'
import { TFunction, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import Popup from 'reactjs-popup'
import { bindActionCreators } from 'redux'
import { triggerRefresh, updateTask } from 'store/actions/task'
import { RootState } from 'store/reducers'
import { TaskType } from 'store/reducers/task'
import { AppDispatch } from 'store/store'

type EditTaskModalReduxState = ReturnType<typeof mapStateToProps>
type EditTaskModalReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface EditTaskModalProps {
  task: TaskType
  t: TFunction
}

class EditTaskModal extends Component<
  EditTaskModalProps & EditTaskModalReduxState & EditTaskModalReduxDispatch,
  TaskType
> {
  constructor(
    props: EditTaskModalProps &
      EditTaskModalReduxState &
      EditTaskModalReduxDispatch
  ) {
    super(props)
    this.state = {
      ...props.task
    }
  }

  onFieldChange = (e: {
    target: { name: string; value: string | Array<string> }
  }) => {
    const { name, value } = e.target
    this.setState({
      ...this.state,
      [name]: value
    })
  }

  saveTask = async (close: () => void) => {
    const {
      updateTask: updateTaskAction,
      triggerRefresh: triggerRefreshAction
    } = this.props
    const { id } = this.state
    await updateTaskAction(id, JSON.stringify(this.state))
    triggerRefreshAction(true)
    close()
  }

  render() {
    const {
      t,
      users,
      target: {
        list: { results }
      }
    } = this.props
    const { title, user, targets, progress } = this.state
    const userObj = users.find(u => u.id === user)
    return (
      <Popup
        position="top center"
        modal
        nested
        trigger={
          <div className="edit-link">
            <span>{t('edit-task-details')}</span>
            <Icon iconName="edit" size={12} color="#7B8F94" />
          </div>
        }
      >
        {(close: () => void) => (
          <div className="modal">
            <button type="button" className="close" onClick={close}>
              &times;
            </button>
            <div className="header"> {t('edit-task')} </div>
            <div className="content">
              <label htmlFor="task-title">{t('task-name')}</label>
              <input
                name="title"
                id="task-title"
                placeholder={t('type-task-name')}
                onChange={this.onFieldChange}
                value={title}
              />
              <label htmlFor="task-user">{t('responsible')}</label>
              <Select
                name="user"
                id="task-user"
                placeholder={t('type-user-name')}
                onChange={(opt: { value: string; label: string }) =>
                  this.onFieldChange({
                    target: { name: 'user', value: opt.value }
                  })
                }
                options={users.map(u => ({
                  label: u.full_name || u.username,
                  value: u.id
                }))}
                value={
                  userObj
                    ? {
                        label: userObj.full_name || userObj.username,
                        value: userObj.id
                      }
                    : null
                }
              />
              <label htmlFor="task-targets">{t('Targets')}</label>
              <Select
                name="targets"
                id="task-targets"
                placeholder={t('type-task-target')}
                isMulti
                onChange={(opt: Array<{ value: string; label: string }>) =>
                  this.onFieldChange({
                    target: { name: 'targets', value: opt.map(o => o.value) }
                  })
                }
                options={results.map(r => ({ value: r.id, label: r.title }))}
                value={results
                  .filter(r => targets.includes(r.id))
                  .map(r => ({ value: r.id, label: r.title }))}
              />
              <label htmlFor="task-progress">{t('task-progress')}</label>
              <input
                name="progress"
                id="task-progress"
                placeholder={t('type-task-progress')}
                onChange={this.onFieldChange}
                value={progress}
                type="range"
              />
              <p>
                <strong>{progress} %</strong>
              </p>
            </div>
            <div className="actions">
              <button
                type="button"
                className="button close"
                onClick={() => {
                  close()
                }}
              >
                {t('Cancel')}
              </button>

              <button
                type="button"
                className="button"
                onClick={() => this.saveTask(close)}
              >
                {t('Save')}
              </button>
            </div>
          </div>
        )}
      </Popup>
    )
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      updateTask,
      triggerRefresh
    },
    dispatch
  )

const mapStateToProps = ({ organization: { users }, target }: RootState) => ({
  users,
  target
})

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(EditTaskModal)
)

import React, { Component, Key } from 'react'
import { connect } from 'react-redux'
import { RootState } from 'store/reducers'
import { OrganizationObjective } from 'store/reducers/organization'
import styled from 'styled-components/macro'
import { findOrg } from 'util/organization'

const CARD_SPACING = 30
const LINE_LENGTH = 70

const Container = styled.div`
  margin-top: 40px;
  display: flex;
  > div {
    flex-grow: 1;
    margin-right: ${CARD_SPACING}px;
    &:last-of-type {
      margin-right: 0;
    }
  }
`

const CardTitle = styled.h2`
  padding: 0 1rem;
  position: absolute;
  margin: 0;
  text-align: right;
  font-family: 'Open Sans';
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 2.063rem;
  text-align: right;
  text-transform: uppercase;
  top: -1.25rem;
  left: 1.25rem;
  background: ${props => props.theme.common.background};
  color: ${props => props.theme.text.title};
  text-align: center;
`

const CardDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 1.313rem 1rem;
  white-space: pre-wrap;
  height: 100%;
  text-align: center;
`

const LineContainer = styled.svg`
  stroke: ${props => props.theme.strategy.strokeColor};
  stroke-width: 2px;
  width: 100%;
  height: ${LINE_LENGTH * 2}px;
`

const Objective = styled.div`
  position: relative;
  border: 2px solid ${props => props.theme.strategy.strokeColor};
  border-radius: 8px;
`

type OrganizationObjectivesProps = ReturnType<typeof mapStateToProps>
interface OrganizationObjectivesState {
  linePoints: number[]
  bottomLine: string[]
}

class OrganizationObjectives extends Component<
  OrganizationObjectivesProps,
  OrganizationObjectivesState
> {
  constructor(props: OrganizationObjectivesProps) {
    super(props)
    this.state = {
      linePoints: [],
      bottomLine: []
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateLines)
    this.forceUpdate()
  }

  componentDidUpdate() {
    const {
      organization: { data, activeOrganization }
    } = this.props
    const { linePoints } = this.state
    const activeOrg = findOrg(activeOrganization, data)
    if (activeOrg && activeOrg.objectives.length !== linePoints.length) {
      this.updateLines()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateLines)
  }

  updateLines = () => {
    const cards = document.querySelectorAll('.objective-card')
    const newLinePoints: number[] = []
    const offset = cards.length * 30
    cards.forEach(c => {
      const rect = c.getBoundingClientRect()
      const width = rect.right - rect.left
      newLinePoints.push(rect.left + width / 2 - offset)
    })
    this.setState({ linePoints: newLinePoints }, this.connectLines)
  }

  connectLines = () => {
    const bottomLine: string[] = []
    const lines = document.querySelectorAll('.line-objectives')
    lines.forEach(l => {
      bottomLine.push(l.getAttribute('x1') as string)
    })
    this.setState({ bottomLine })
  }

  render() {
    const {
      organization: { data, activeOrganization }
    } = this.props
    const { linePoints, bottomLine } = this.state
    const activeOrg = findOrg(activeOrganization, data)
    if (!activeOrg) {
      return null
    }

    return (
      <>
        <Container>
          {activeOrg.objectives.map((o: OrganizationObjective) => (
            <Objective key={o.id} className="objective-card">
              <CardTitle>{o.title}</CardTitle>
              <CardDescription>{o.description}</CardDescription>
            </Objective>
          ))}
        </Container>
        <LineContainer>
          {linePoints.map((l: Key) => (
            <line
              className="line-objectives"
              key={l}
              x1={l}
              y1={0}
              x2={l}
              y2={LINE_LENGTH}
            />
          ))}
          {bottomLine.map((l: Key, i: number) => (
            <line
              key={l}
              x1={l}
              y1={LINE_LENGTH - 2}
              x2={bottomLine[i + 1] || l}
              y2={LINE_LENGTH - 2}
            />
          ))}
          <line
            x1="50%"
            y1={LINE_LENGTH - 2}
            x2="50%"
            y2={LINE_LENGTH * 2 - 2}
          />
        </LineContainer>
      </>
    )
  }
}

const mapStateToProps = ({ organization }: RootState) => ({
  organization
})

export default connect(mapStateToProps)(OrganizationObjectives)

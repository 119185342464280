import React, { Component } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { TFunction, withTranslation } from 'react-i18next'
import { formatFloat, formatInt } from 'util/format'
import Icon from 'components/Icon'
import { TermTooltip } from 'components/modals'
import timeLineFromDatesAndOrganization, { ISO_FORMAT_DATE } from 'util/dates'
import { RootState } from 'store/reducers'
import { TermOfClassification } from 'store/reducers/term'
import { RawData } from 'store/reducers/rawdata'
import { GraphMapping } from 'store/reducers/graph'
import { Get } from 'api'

const Container = styled.div`
  max-width: 200px;
  border-right: 1px solid #cfdbde;
  padding: 0.625rem 1.25rem;
  min-width: 300px;
  &:last-of-type {
    border-right: none;
  }
`
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
  p {
    color: ${props => props.theme.text.primary};
    font-family: 'Open Sans';
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.188rem;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .icon {
    flex-basis: 1.25rem;
    margin-left: 0.625rem;
    cursor: pointer;
    transition: background-color 0.2s ease-out;
    user-select: none;
    &:hover {
      background-color: #b9c5c8;
    }
  }
`
const Color = styled.div`
  flex-basis: 1.75rem;
  width: 0.813rem;
  height: 0.813rem;
  max-width: 0.813rem;
  background-color: ${(props: { color: string; type: string }) => props.color};
  border-radius: ${props =>
    props.type === 'bar' || props.type === 'stacked' ? '0' : '100%'};
  margin-right: 0.938rem;
`
const NumberContainer = styled.div`
  p {
    margin: 0;
  }
  p:first-of-type {
    color: ${props => props.theme.text.primary};
    font-family: 'Open Sans';
    font-size: 0.813rem;
    letter-spacing: 0;
    line-height: 1.125rem;
  }
  p:nth-of-type(2) {
    color: ${props => props.theme.text.secondary};
    font-family: 'Open Sans';
    font-size: 1.188rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1.625rem;
  }
`

interface NumberProps {
  term: TermOfClassification
  data: Array<RawData>
  properties?: GraphMapping['graph_properties']
  dates: ReturnType<typeof timeLineFromDatesAndOrganization>
  t: TFunction
}

interface NumberState {
  infoModalPosition: { x: number; y: number } | null
  number: number | null
}

class Number extends Component<NumberProps, NumberState> {
  constructor(props: NumberProps) {
    super(props)
    this.state = {
      infoModalPosition: null,
      number: null
    }
  }

  componentDidMount() {
    this.calculateNumber()
  }

  componentDidUpdate(prevProps: NumberProps) {
    const {
      dates: { start: prevStart, stop: prevStop }
    } = prevProps
    const {
      dates: { start, stop }
    } = this.props
    const startChanged =
      start &&
      prevStart &&
      start.format(ISO_FORMAT_DATE) !== prevStart.format(ISO_FORMAT_DATE)
    const endChanged =
      stop &&
      prevStop &&
      stop.format(ISO_FORMAT_DATE) !== prevStop.format(ISO_FORMAT_DATE)
    if (startChanged || endChanged) {
      this.calculateNumber()
    }
  }

  calculateNumber = async () => {
    const { data, term, properties } = this.props
    if (properties && properties[term.key].calc_formula_for_numbers) {
      const {
        dates: { start: startDate, stop: endDate }
      } = this.props
      const url = `/api/formula/calculate_number_from_formula/`
      const response = await Get<RootState['graph']['numbersFromFormula'][0]>(
        url,
        {
          key: term.key,
          start_date: startDate.format(ISO_FORMAT_DATE),
          end_date: endDate.format(ISO_FORMAT_DATE)
        }
      )
      this.setState({ number: response || 0 })
      return
    }
    const dataForTerm = data
      .filter(r => r.key === term.key)
      .sort((a, b) => (moment(b.start_date) > moment(a.start_date) ? 1 : -1))
    const number = term.sum_values_in_tree
      ? dataForTerm.reduce((a, b) => b.value + a, 0)
      : dataForTerm[0]
      ? dataForTerm[0].value
      : 0
    this.setState({ number })
  }

  render() {
    const { data, term, properties, t } = this.props
    const { infoModalPosition, number } = this.state
    const dataForTerm = data
      .filter(r => r.key === term.key)
      .sort((a, b) => (moment(b.start_date) > moment(a.start_date) ? 1 : -1))
    const isVerified = dataForTerm.every(d => d.is_verified)
    if (!properties) {
      return null
    }
    const formatFunction = properties.show_decimals ? formatFloat : formatInt
    const unit = properties[term.key].second_axis
      ? properties.u_right
      : properties.unit
    return (
      <Container>
        <TitleContainer>
          <Color
            type={properties[term.key].type}
            color={properties[term.key].color}
          />
          <p>{term.title}</p>
          <Icon
            onFocus={e => e.preventDefault()}
            onMouseMove={e => {
              const { x, y } = e.currentTarget.getBoundingClientRect()
              this.setState({
                infoModalPosition: {
                  x: x + 10,
                  y: y + 10
                }
              })
            }}
            onMouseLeave={() => this.setState({ infoModalPosition: null })}
            color="#575757"
            iconName="info"
            size={15}
          />
        </TitleContainer>
        <NumberContainer>
          {number !== null ? (
            <>
              <p>{isVerified ? t('actual') : t('estimate')}</p>
              <p>
                {formatFunction(number)} {unit}
              </p>
            </>
          ) : null}
        </NumberContainer>
        {infoModalPosition ? (
          <TermTooltip term={term} position={infoModalPosition} />
        ) : null}
      </Container>
    )
  }
}

export default withTranslation()(Number)

import React, { Component, MouseEvent } from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Popup from 'reactjs-popup'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { deleteMilestone } from 'store/actions/milestone'
import { triggerRefresh } from 'store/actions/task'
import { MilestoneType } from 'store/reducers/milestone'

type RemoveMilestoneModalReduxState = ReturnType<typeof mapDispatchToProps>

interface RemoveMilestoneModalProps {
  t: TFunction
  milestone: MilestoneType
}

class RemoveMilestoneModal extends Component<
  RemoveMilestoneModalProps & RemoveMilestoneModalReduxState
> {
  deleteMilestone = async (close: () => void) => {
    const {
      deleteMilestone: deleteMilestoneAction,
      triggerRefresh: triggerRefreshAction,
      milestone: { id }
    } = this.props
    await deleteMilestoneAction(id)
    triggerRefreshAction(true)
    close()
  }

  render() {
    const { t } = this.props
    return (
      <Popup
        position="top center"
        modal
        nested
        trigger={
          <button type="button" className="button delete">
            {t('Delete milestone')}
          </button>
        }
      >
        {(close: {
          (): void
          (event: MouseEvent<HTMLButtonElement, MouseEvent>): void
        }) => (
          <div className="modal">
            <button type="button" className="close" onClick={close}>
              &times;
            </button>
            <div className="header">{t('remove-milestone')} </div>
            <div className="content">
              <p>{t('delete-milestone-disclaimer')}</p>
            </div>
            <div className="actions">
              <button
                type="button"
                className="button close"
                onClick={() => {
                  close()
                }}
              >
                {t('Cancel')}
              </button>

              <button
                type="button"
                className="button"
                onClick={() => this.deleteMilestone(close)}
              >
                {t('remove-milestone')}
              </button>
            </div>
          </div>
        )}
      </Popup>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      deleteMilestone,
      triggerRefresh
    },
    dispatch
  )

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(RemoveMilestoneModal))

import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { resetErrorMessage } from 'store/actions/error'
import { Graph, AdditionalData } from 'components/graph'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getGraphPathName } from 'util/format'
import LoadingIndicator from 'components/blocks/LoadingIndicator'
import styled from 'styled-components/macro'
import { getRawDataTimespan, listRawData } from 'store/actions/rawdata'
import { TFunction, withTranslation } from 'react-i18next'
import { getTimelineForData } from 'store/actions/term'
import timeLineFromDatesAndOrganization, { ISO_FORMAT_DATE } from 'util/dates'
import { RootState } from 'store/reducers'

const GraphViewContainer = styled.div`
  overflow: hidden;
`

export const Switch = styled.div`
  position: fixed;
  width: 200px;
  top: 1.25rem;
  left: calc(50% - 100px);
  display: flex;
  user-select: none;
  @media screen and (max-width: 1000px) {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 1.563rem;
  }
  span {
    font-family: 'Open Sans';
    font-size: 0.938rem;
    flex-grow: 1;
    border: 1px solid #ffd800;
    text-align: center;
    color: ${props => props.theme.text.primary};
    display: inline-block;
    border-right: none;
    padding: 5px;
    cursor: pointer;

    &:hover,
    &.selected {
      background: #ffd800;
      color: #444;
    }

    &:first-of-type {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      border-right: 1px solid #ffd800;
    }

    &:last-of-type {
      border-right: 1px solid #ffd800;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
`

type GraphViewReduxState = ReturnType<typeof mapStateToProps>
type GraphViewReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface GraphViewProps {
  t: TFunction
}

interface GraphViewState {
  keys: Array<{ key: string; title: string }>
  activeView: string
  start: string | null
  end: string | null
  dates: { [key: string]: Array<string> }
}

class GraphView extends Component<
  GraphViewProps &
    GraphViewReduxState &
    GraphViewReduxDispatch &
    RouteComponentProps,
  GraphViewState
> {
  constructor(
    props: GraphViewProps &
      GraphViewReduxState &
      GraphViewReduxDispatch &
      RouteComponentProps
  ) {
    super(props)
    this.state = {
      keys: [],
      activeView: 'graph',
      start: null,
      end: null,
      dates: {}
    }
  }

  componentDidUpdate(
    prevProps: GraphViewProps &
      GraphViewReduxState &
      GraphViewReduxDispatch &
      RouteComponentProps
  ) {
    const {
      timeGrouping: { selectedGrouping: oldGrouping }
    } = prevProps
    const {
      timeGrouping: { selectedGrouping: grouping }
    } = this.props
    if (oldGrouping !== grouping) {
      this.fetchData()
    }
  }

  fetchData = async () => {
    const {
      settings,
      listRawData: listRawDataAction,
      location,
      getTimelineForData: getTimelineForDataAction,
      timeGrouping: { selectedGrouping }
    } = this.props
    const { activeView, dates } = this.state
    let mapping = settings.mappings.find(
      m => location.pathname.replace('/', '') === getGraphPathName(m.view_title)
    )
    if (!mapping) {
      settings.mappings.forEach(m => {
        if (mapping) {
          return
        }
        mapping = m.children.find(
          c =>
            location.pathname.replace('/', '') ===
            getGraphPathName(c.view_title)
        )
      })
    }
    const title = mapping ? mapping.view_title : ''
    const dataKey = getGraphPathName(title)
    let data = settings.mappings.find(
      m => getGraphPathName(m.view_title) === dataKey
    )
    if (!data) {
      settings.mappings.forEach(m => {
        if (data) {
          return
        }
        data = m.children.find(c => getGraphPathName(c.view_title) === dataKey)
      })
    }
    if (!data) {
      return
    }
    const keys = data.terms_used.map(t => t.key)
    const { start, end } = this.state

    const startDate =
      data.graph_properties.past_months.length === ISO_FORMAT_DATE.length &&
      moment(data.graph_properties.past_months, ISO_FORMAT_DATE).isValid() &&
      !start
        ? data.graph_properties.past_months
        : start ||
          moment()
            .subtract(data.graph_properties.past_months, 'months')
            .startOf('month')
            .format(ISO_FORMAT_DATE)
    const endDate =
      data.graph_properties.future_months.length === ISO_FORMAT_DATE.length &&
      moment(data.graph_properties.future_months, ISO_FORMAT_DATE).isValid() &&
      !end
        ? data.graph_properties.future_months
        : end ||
          moment()
            .add(data.graph_properties.future_months, 'months')
            .endOf('month')
            .format(ISO_FORMAT_DATE)

    await listRawDataAction({
      keys,
      mapping: data.id,
      start_date: startDate,
      end_date: endDate,
      grouping: selectedGrouping,
      only_verified:
        Object.keys(dates).length === 1 &&
        Object.keys(dates)[0] === 'YTD' &&
        dates[Object.keys(dates)[0]].length === 0 &&
        activeView === 'numbers'
    })

    const { getRawDataTimespan: getRawDataTimespanAction } = this.props
    getRawDataTimespanAction()

    this.setState({
      keys: data.terms_used.map(t => ({
        key: t.key,
        title: t.title
      })),
      start: startDate,
      end: endDate
    })
    getTimelineForDataAction(keys)
  }

  dateChange = (
    dates: { [key: string]: Array<string> } | null,
    overrideValues?: { start: string; end: string }
  ) => {
    const {
      organization: { activeOrganization, data },
      timespan
    } = this.props
    if (dates === null && overrideValues) {
      this.setState(overrideValues, this.fetchData)
      return
    }
    if (dates === null) {
      return
    }
    if (Object.keys(dates).length === 0) {
      this.setState(
        {
          start: null,
          end: null
        },
        this.fetchData
      )
      return
    }
    const { start, stop } = timeLineFromDatesAndOrganization(
      dates,
      data,
      activeOrganization,
      null,
      timespan.end_date
    )

    this.setState(
      {
        start: start.format(ISO_FORMAT_DATE),
        end: stop.endOf('month').format(ISO_FORMAT_DATE),
        dates
      },
      this.fetchData
    )
  }

  renderActiveView = (view: string) => {
    const {
      location,
      settings: { mappings }
    } = this.props
    const { keys } = this.state
    let mapping = mappings.find(
      m => location.pathname.replace('/', '') === getGraphPathName(m.view_title)
    )
    if (!mapping) {
      mappings.forEach(m => {
        if (mapping) {
          return
        }
        mapping = m.children.find(
          c =>
            location.pathname.replace('/', '') ===
            getGraphPathName(c.view_title)
        )
      })
    }
    const title = mapping ? mapping.view_title : ''
    switch (view) {
      case 'graph': {
        return (
          <Graph
            dataKey={getGraphPathName(title)}
            keys={keys}
            onDateChange={this.dateChange}
          />
        )
      }
      case 'numbers': {
        return (
          <AdditionalData
            dataKey={getGraphPathName(title)}
            onDateChange={this.dateChange}
          />
        )
      }
      default: {
        return null
      }
    }
  }

  render() {
    const { t, isPending } = this.props
    const { activeView } = this.state
    return (
      <GraphViewContainer className="App">
        <Switch>
          <span
            onClick={() => this.setState({ activeView: 'graph' })}
            className={activeView === 'graph' ? 'selected' : ''}
          >
            {t('graph_view')}
          </span>
          <span
            onClick={() => this.setState({ activeView: 'numbers' })}
            className={activeView === 'numbers' ? 'selected' : ''}
          >
            {t('numbers')}
          </span>
        </Switch>
        {isPending ? <LoadingIndicator /> : null}
        {this.renderActiveView(activeView)}
      </GraphViewContainer>
    )
  }
}

const mapStateToProps = ({
  error,
  graph,
  rawData,
  organization,
  timeGrouping
}: RootState) => ({
  error,
  settings: graph.settings,
  isPending: rawData.isPending,
  data: rawData.list.results,
  organization,
  timespan: rawData.timespan,
  timeGrouping
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getTimelineForData,
      resetErrorMessage,
      listRawData,
      getRawDataTimespan
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(GraphView)))

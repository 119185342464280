import React, { FC, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { RootState } from 'store/reducers'
import { unreadSystemMessageCount } from 'store/actions/systemMessage'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import ButtonActive from 'assets/images/bell-active.png'
import ButtonEmpty from 'assets/images/bell-inactive.png'

const Icon = styled.div`
  background: url(${(props: { isActive: boolean; mobile: boolean }) =>
    props.isActive ? ButtonActive : ButtonEmpty});
  background-size: 100%;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  display: block;
  margin-top: ${(props: { isActive: boolean; mobile: boolean }) =>
    props.mobile ? '8px' : '-5px'};
  margin-right: ${(props: { isActive: boolean; mobile: boolean }) =>
    props.mobile ? '15px' : '0px'};
  margin-left: auto;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  p {
    margin: 0;
    color: ${props => props.theme.topMenu.labelColor};
    font-family: 'Open Sans';
    font-size: 0.813rem;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 1.125rem;
    margin-bottom: 5px;
    text-align: right;
  }
  > div {
    display: flex;
    align-items: center;
    text-align: right;
  }
`

export interface Props {
  mobile: boolean
}

const SystemMessageButton: FC<Props> = props => {
  const { mobile } = props
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [isActive, setIsActive] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const systemMessage = useSelector((state: RootState) => state.systemMessage)
  useEffect(() => {
    const fetchData = async () => {
      if (!loading) {
        setLoading(true)
        await dispatch(unreadSystemMessageCount())
      } else if (systemMessage?.data?.unreadMessageCount) {
        const count = systemMessage.data.unreadMessageCount ?? 0
        setIsActive(count > 0)
      }
    }
    fetchData()
  }, [systemMessage])
  return (
    <>
      <Container onClick={() => history.push('/system-messages')}>
        {!mobile && <p>{t('systemMessages')}</p>}
        <div>
          <Icon isActive={isActive} mobile={mobile} />
        </div>
      </Container>
    </>
  )
}

export default SystemMessageButton

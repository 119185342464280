import { useGoogleLogin } from '@react-oauth/google'
import { GoogleLoginResponse } from 'pages/Login'
import styled from 'styled-components'
import { ReactComponent as GoogleLogo } from 'assets/icons/google_logo.svg'
import { SSOLoginButtonStyle } from './SSOLoginButton'

const StyledGoogleLoginButton = styled.button`
  ${SSOLoginButtonStyle}
  padding: 10px;
  border: none !important;
`

type GoogleLoginButtonProps = {
  googleResponse: (response: GoogleLoginResponse) => void
}

const GoogleLoginButton = (props: GoogleLoginButtonProps) => {
  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => props.googleResponse(tokenResponse),
    onError: errorResponse => console.error(errorResponse)
  })

  return (
    <StyledGoogleLoginButton onClick={() => googleLogin()}>
      <GoogleLogo width={22} height={22} style={{ marginRight: '16px' }} />
      LOGIN WITH GOOGLE
    </StyledGoogleLoginButton>
  )
}

export default GoogleLoginButton

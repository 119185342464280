import React, { Component } from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import { Title } from 'components/blocks'
import styled from 'styled-components/macro'
import moment from 'moment'
import { OrganizationObjectives, TargetTree } from 'components/strategy'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getOrganizations } from 'store/actions/organization'
import {
  getTargetGroup,
  hideTargetGroup,
  listTargetGroup
} from 'store/actions/targetgroup'
import { resetErrorMessage } from 'store/actions/error'
import LoadingIndicator from 'components/blocks/LoadingIndicator'
import { getRawDataTimespan } from 'store/actions/rawdata'
import DateSelector, { Years } from 'components/notification/DateSelector'
import Icon from 'components/Icon'
import { ISO_FORMAT_DATE } from 'util/dates'
import { RootState } from 'store/reducers'
import { AppDispatch } from 'store/store'
import { findOrg } from 'util/organization'

const LoaderContainer = styled.div`
  height: 80vh;
`

const ContentContainer = styled.div`
  max-width: calc(
    100vw - 5px -
      ${(props: { offset: number; topOffset: number }) => props.offset}px
  );
  padding: 0 1.875rem;
  overflow: auto;
  height: calc(100vh - ${props => props.topOffset || 0}px);
  @media screen and (max-width: 768px) {
    max-width: calc(100vw - 1.25rem);
  }
  ::-webkit-scrollbar {
    width: 1.25rem;
    height: 1.25rem;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px #00000099;
    border-radius: 0.625rem;
    border: solid 3px transparent;
  }
  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 3px #00000099;
    border-radius: 0.625rem;
    border: solid 3px transparent;
  }
`

const DateSelectorContainer = styled.div`
  padding: 0 1.875rem;
  > p {
    color: #b9c5c8;
    font-family: 'Open Sans';
    font-size: 0.813rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.125rem;
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`

const ContentWrapper = styled.div`
  min-width: 900px;
  @media screen and (max-width: 768px) {
    zoom: 0.5;
  }
`

const TopContentContainer = styled.div`
  display: flex;
  > div:first-of-type {
    flex-grow: 1;
    @media screen and (max-width: 768px) {
      h1 {
        padding: 0;
        margin-top: 0;
      }
    }
  }
  > div:nth-of-type(2) {
    height: 100%;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const ExpandCollapseButton = styled.div`
  text-align: center;
  margin-top: 1rem;
  border-radius: 3px;
  background-color: #b9c5c8;
  box-shadow: 2px 2px 0 0 #93a4a8;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: #fff;
  cursor: pointer;
  margin-right: 1.875rem;
  .icon {
    margin-right: 5px;
    background: #fff;
  }
  @media screen and (max-width: 768px) {
    margin-right: 0;
    margin-top: 0;
    margin: 2.5px 0 5px 0px;
  }
`

const LoadOverlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #33333355;
  z-index: 1000;
`

type StrategyReduxState = ReturnType<typeof mapStateToProps>
type StrategyReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface StrategyProps {
  t: TFunction
}

class Strategy extends Component<
  StrategyProps & StrategyReduxState & StrategyReduxDispatch,
  { initialLoad: boolean; date: string | null; loading: boolean }
> {
  constructor(
    props: StrategyProps & StrategyReduxState & StrategyReduxDispatch
  ) {
    super(props)
    this.state = {
      initialLoad: false,
      date: null,
      loading: false
    }
  }

  componentDidMount = async () => {
    const { getRawDataTimespan: getRawDataTimespanAction } = this.props
    await getRawDataTimespanAction(true, true)
    const { timespan: timeSpan } = this.props
    const endDate = timeSpan ? timeSpan.end_date : null
    await this.setState({
      date: moment(endDate)
        .startOf('month')
        .format(ISO_FORMAT_DATE)
    })
    this.loadData()
  }

  componentDidUpdate(
    prevProps: StrategyProps & StrategyReduxState & StrategyReduxDispatch
  ) {
    const {
      organization: { activeOrganization }
    } = this.props
    const {
      organization: { activeOrganization: oldActiveOrganization }
    } = prevProps

    if (oldActiveOrganization && activeOrganization !== oldActiveOrganization) {
      this.loadData()
    }
  }

  getTargetGroupParams = () => {
    const { date } = this.state
    const params: { [key: string]: string } = {}
    if (date) {
      params.start_date = date
      params.end_date = moment(date)
        .endOf('month')
        .format(ISO_FORMAT_DATE)
    }
    return params
  }

  loadData = async () => {
    const {
      getOrganizations: getOrganizationsAction,
      listTargetGroup: listTargetGroupAction
    } = this.props
    const params = this.getTargetGroupParams()
    await getOrganizationsAction()
    await listTargetGroupAction(params)
    this.setState({
      initialLoad: true
    })
  }

  updateDate = (dates: Years) => {
    const {
      timespan: { end_date: endDate }
    } = this.props
    if (dates === null) {
      this.setState(
        {
          date: moment(endDate)
            .startOf('month')
            .format(ISO_FORMAT_DATE)
        },
        this.loadData
      )
      return
    }

    if (Object.values(dates)[0] && Object.values(dates)[0].length === 0) {
      const {
        organization: { data, activeOrganization: aid }
      } = this.props
      const activeOrganization = findOrg(aid, data)
      if (activeOrganization) {
        const year = Object.keys(dates)[0].split(' - ')[0]
        const month = activeOrganization.start_of_fiscal_year
        let dateString = moment(`${year}-${month}`, 'YYYY-MM')
          .startOf('month')
          .add(1, 'year')
          .subtract(1, 'month')
          .format(ISO_FORMAT_DATE)
        if (moment(dateString) > moment()) {
          dateString = moment(endDate)
            .startOf('month')
            .format(ISO_FORMAT_DATE)
        }
        this.setState({ date: dateString }, this.loadData)
      }
      return
    }

    if (Object.values(dates)[0] && Object.values(dates)[0].length > 0) {
      const dateString = moment(Object.values(dates)[0], 'YYYY-MM')
        .startOf('month')
        .format(ISO_FORMAT_DATE)
      this.setState({ date: dateString }, this.loadData)
      return
    }

    this.setState(
      {
        date: moment(endDate)
          .startOf('month')
          .format(ISO_FORMAT_DATE)
      },
      this.loadData
    )
  }

  expandAllTargetGroups = async () => {
    this.setState({ loading: true })
    const groupsToOpen: number[] = []
    const {
      targetGroup: {
        list: { results }
      },
      getTargetGroup: getTargetGroupAction
    } = this.props
    results.forEach(r => {
      r.children.forEach(rc => {
        if (rc.has_children) {
          groupsToOpen.push(rc.id)
        }
      })
    })
    const openGroup = async (groupId: number) => {
      const childGroupsToOpen: number[] = []
      const params = this.getTargetGroupParams()
      await getTargetGroupAction(groupId, params)
      const {
        targetGroup: { data }
      } = this.props
      const group = data[groupId]
      group.children.forEach(c => {
        if (c.has_children) {
          childGroupsToOpen.push(c.id)
        }
      })
      if (childGroupsToOpen.length) {
        childGroupsToOpen.forEach(cgId => openGroup(cgId))
      }
    }
    await Promise.all(groupsToOpen.map(gId => openGroup(gId)))
    this.setState({ loading: false })
  }

  hideAllTargetGroups = () => {
    const {
      hideTargetGroup: hideTargetGroupAction,
      targetGroup: { data }
    } = this.props
    Object.keys(data).forEach(id => {
      hideTargetGroupAction(id)
    })
  }

  getTopOffset = () => {
    const container = document.querySelector('#strategy-content-container')
    if (container) {
      const { top } = container.getBoundingClientRect()
      return top + 15
    }

    return 100
  }

  hasChildTargetGroups = () => {
    const groupsToOpen: number[] = []
    const {
      targetGroup: {
        list: { results }
      }
    } = this.props

    if (!results) {
      return false
    }

    results.forEach(r => {
      r.children.forEach(rc => {
        if (rc.has_children) {
          groupsToOpen.push(rc.id)
        }
      })
    })
    return groupsToOpen.length > 0
  }

  render() {
    const {
      t,
      timespan,
      targetGroup: { data }
    } = this.props
    const { initialLoad, loading, date } = this.state
    const targetGroupParams = this.getTargetGroupParams()
    const menu = document.querySelector<HTMLDivElement>('#main-menu-container')
    const offset = menu ? menu.offsetWidth : 0
    if (!initialLoad) {
      return (
        <LoaderContainer>
          <LoadingIndicator />
        </LoaderContainer>
      )
    }
    return (
      <div className="App">
        {loading && (
          <LoadOverlay>
            <LoadingIndicator />
          </LoadOverlay>
        )}
        <TopContentContainer>
          <div>
            <Title isStrategy>{t('strategy_and_targets')}</Title>
            <DateSelectorContainer>
              <DateSelector
                location="strategy"
                onDateChange={this.updateDate}
                timeSpan={{
                  start_date: timespan.start_date,
                  end_date: timespan.end_date
                }}
                singleSelect
                useCurrentYearAsYtd
                ytdEmpty
              />
              <p>
                {t('selected')}: {moment(date).format('DD.MM.YYYY')}
              </p>
            </DateSelectorContainer>
          </div>
          {this.hasChildTargetGroups() ? (
            <div>
              {Object.keys(data).length === 0 ? (
                <ExpandCollapseButton onClick={this.expandAllTargetGroups}>
                  <Icon iconName="expand" size={16} />
                  {t('expand-all')}
                </ExpandCollapseButton>
              ) : (
                <ExpandCollapseButton onClick={this.hideAllTargetGroups}>
                  <Icon iconName="collapse" size={16} />
                  {t('collapse-all')}
                </ExpandCollapseButton>
              )}
            </div>
          ) : null}
        </TopContentContainer>
        <ContentContainer
          topOffset={this.getTopOffset()}
          offset={offset}
          id="strategy-content-container"
        >
          <ContentWrapper>
            <OrganizationObjectives />
            <TargetTree targetGroupParams={targetGroupParams} />
          </ContentWrapper>
        </ContentContainer>
      </div>
    )
  }
}

const mapStateToProps = ({
  error,
  organization,
  rawData: { timespan },
  targetGroup
}: RootState) => ({
  error,
  organization,
  timespan,
  targetGroup
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      resetErrorMessage,
      getOrganizations,
      getTargetGroup,
      hideTargetGroup,
      listTargetGroup,
      getRawDataTimespan
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Strategy))

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import MainTask from 'components/roadmap/MainTask'
import Timeline from 'components/roadmap/Timeline'
import Milestones from 'components/roadmap/Milestones'
import styled from 'styled-components'
import { listMaintask } from 'store/actions/maintask'
import { listMilestone } from 'store/actions/milestone'
import DateSelector from 'components/roadmap/DateSelector'
import 'reactjs-popup/dist/index.css'
import 'assets/scss/modal.scss'
import { RootState } from 'store/reducers'
import { RoadmapType } from 'store/reducers/roadmap'
import { AppDispatch } from 'store/store'
import moment from 'moment'

const RoadmapContainer = styled.div`
  color: ${props => props.theme.text.secondary};
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  width: 100%;
  min-height: calc(100vh - 290px);
`

type RoadmapReduxState = ReturnType<typeof mapStateToProps>
type RoadmapReduxDispatch = ReturnType<typeof mapDispatchToProps>
export type DateSelection = {
  startYear: number
  startMonth: number
  endYear: number
  endMonth: number
  rowLength: number
}

interface RoadmapProps {
  roadmap: RoadmapType
  roadmaps: RoadmapType[]
  updateTime: number
  setTab: (roadmapId: number) => void
  isRoadmapManager: boolean
}

interface RoadmapState {
  milestoneResults: RoadmapReduxState['milestone']['list']['results']
  maintaskResults: RoadmapReduxState['maintask']['list']['results']
  dateSelection: DateSelection
}

export const getDefaultStart = () => {
  const year = moment().year()
  const month = 1
  return moment(`${month}-${year}`, 'MM-YYYY').subtract(6, 'months')
}

export const getDefaultEnd = () => {
  const year = moment().year()
  const month = 12
  return moment(`${month}-${year}`, 'MM-YYYY').add(6, 'months')
}

export const getDefaultSelection = (): DateSelection => {
  const start = getDefaultStart()
  const end = getDefaultEnd()
  return {
    startYear: parseInt(start.format('Y')),
    startMonth: parseInt(start.format('M')),
    endYear: parseInt(end.format('Y')),
    endMonth: parseInt(end.format('M')),
    rowLength: end.diff(start, 'months') + 1
  }
}

class Roadmap extends Component<
  RoadmapProps & RoadmapReduxState & RoadmapReduxDispatch,
  RoadmapState
> {
  constructor(props: RoadmapProps & RoadmapReduxState & RoadmapReduxDispatch) {
    super(props)
    let dateSelection: DateSelection = getDefaultSelection()
    const selections = props.monthselector?.selections || null
    if (selections) {
      const location = `roadmap-${props.roadmap.id}`
      if (selections[location]) {
        dateSelection = selections[location].dateSelection as DateSelection
      }
    }
    this.state = {
      milestoneResults: [],
      maintaskResults: [],
      dateSelection
    }
  }

  componentDidMount() {
    this.refresh()
  }

  componentDidUpdate(
    prevProps: RoadmapProps & RoadmapReduxState & RoadmapReduxDispatch
  ) {
    const { updateTime } = prevProps
    const { updateTime: newUpdateTime } = this.props
    if (updateTime !== newUpdateTime) {
      this.refresh()
    }
  }

  refresh = async () => {
    const { listMilestone: listMilestoneAction } = this.props
    const { listMaintask: listMaintaskAction } = this.props
    const { roadmap } = this.props
    await listMilestoneAction(roadmap.id)
    await listMaintaskAction(roadmap.id)
    const {
      milestone: {
        list: { results: milestoneResults }
      },
      maintask: {
        list: { results: maintaskResults }
      }
    } = this.props
    this.setState({ milestoneResults, maintaskResults })
  }

  render() {
    const {
      roadmap,
      roadmaps,
      updateTime,
      setTab,
      isRoadmapManager
    } = this.props
    const {
      milestoneResults: milestones,
      maintaskResults: maintasks,
      dateSelection
    } = this.state
    const data: JSX.Element[] = []

    if (maintasks) {
      maintasks.forEach(mainTask => {
        data.push(
          <MainTask
            key={`maintask-${mainTask.id}`}
            dateSelection={dateSelection}
            mainTask={mainTask}
            roadmaps={roadmaps}
            addMainTask={false}
            updateTime={updateTime}
            setTab={setTab}
            isRoadmapManager={isRoadmapManager}
          />
        )
      })
      if (isRoadmapManager) {
        data.push(
          <MainTask
            key={`add-maintask-container-${roadmap.id}`}
            dateSelection={dateSelection}
            roadmaps={roadmaps}
            roadmap={roadmap}
            addMainTask
            updateTime={updateTime}
            setTab={setTab}
          />
        )
      }
    }
    return (
      <>
        <RoadmapContainer key={`roadmap-${roadmap.id}`} id="roadmap-container">
          <Timeline
            dateSelector={
              <DateSelector
                location={`roadmap-${roadmap.id}`}
                onChange={value => this.setState({ dateSelection: value })}
              />
            }
            dateSelection={dateSelection}
            roadmap={roadmap}
          />
          <Milestones
            dateSelection={dateSelection}
            milestones={milestones}
            roadmap={roadmap}
            isRoadmapManager={isRoadmapManager}
          />
          {data}
        </RoadmapContainer>
      </>
    )
  }
}

const mapStateToProps = ({
  error,
  milestone,
  maintask,
  monthselector
}: RootState) => ({
  error,
  milestone,
  maintask,
  monthselector
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      listMilestone,
      listMaintask
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Roadmap))

import React, { Component, MouseEvent } from 'react'
import Icon from 'components/Icon'
import { TFunction, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Popup from 'reactjs-popup'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { deleteTask, triggerRefresh } from 'store/actions/task'
import { TaskType } from 'store/reducers/task'

type RemoveTaskModalReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface RemoveTaskModalProps {
  t: TFunction
  task: TaskType
}

export class RemoveTaskModal extends Component<
  RemoveTaskModalProps & RemoveTaskModalReduxDispatch
> {
  deleteTask = async (close: () => void) => {
    const {
      deleteTask: deleteTaskAction,
      triggerRefresh: triggerRefreshAction,
      task: { id }
    } = this.props
    await deleteTaskAction(id)
    triggerRefreshAction(true)
    close()
  }

  render() {
    const { t } = this.props
    return (
      <Popup
        position="top center"
        modal
        nested
        trigger={
          <div className="remove-link">
            <span>{t('remove-task')}</span>
            <Icon iconName="trash" size={12} color="#ce2033" />
          </div>
        }
      >
        {(close: {
          (): void
          (event: MouseEvent<HTMLButtonElement, MouseEvent>): void
        }) => (
          <div className="modal">
            <button type="button" className="close" onClick={close}>
              &times;
            </button>
            <div className="header">{t('remove-task')} </div>
            <div className="content">
              <p>{t('delete-task-disclaimer')}</p>
            </div>
            <div className="actions">
              <button
                type="button"
                className="button close"
                onClick={() => {
                  close()
                }}
              >
                {t('Cancel')}
              </button>

              <button
                type="button"
                className="button"
                onClick={() => this.deleteTask(close)}
              >
                {t('remove-task')}
              </button>
            </div>
          </div>
        )}
      </Popup>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      deleteTask,
      triggerRefresh
    },
    dispatch
  )

export default withTranslation()(
  connect(null, mapDispatchToProps)(RemoveTaskModal)
)

import React, { Component } from 'react'
import { Post } from 'api'
import ApiError from 'ApiError'
import { Card, Title } from 'components/blocks'
import { TFunction, withTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 150px);
  > div {
    width: 100%;
    max-width: 600px;
  }
`

const CardContent = styled.div`
  padding: 0.938rem;
  display: flex;
  flex-direction: column;
  label {
    font-family: 'Open Sans';
    font-weight: 100;
    color: #4a4a4a;
    letter-spacing: 0;
    line-height: 1.5;
    font-size: 1rem;
    margin: 0;
  }
  input {
    margin-bottom: 0.938rem;
    padding: 0.625rem;
    border-radius: 5px;
    border: 1px solid #dedede;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  button {
    box-sizing: border-box;
    border: 1px solid #ffd800;
    border-radius: 6px;
    color: #8e8e8e;
    font-family: 'Open Sans';
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 2;
    text-align: center;
    cursor: pointer;
    margin-right: 0.625rem;
    background: #fff;
    margin-top: 0.938rem;
  }
  h1 {
    margin-top: 0;
  }
`

interface ChangePasswordProps {
  t: TFunction
}

class ChangePassword extends Component<
  ChangePasswordProps,
  { password1: string; password2: string; oldPassword: string }
> {
  constructor(props: ChangePasswordProps) {
    super(props)
    this.state = {
      password1: '',
      password2: '',
      oldPassword: ''
    }
  }

  changePassword = async () => {
    const { t } = this.props
    const { password1, password2, oldPassword } = this.state
    const url = '/api/auth/password/change/'
    Post<{ detail: string }>(
      url,
      JSON.stringify({
        old_password: oldPassword,
        new_password1: password1,
        new_password2: password2
      })
    )
      .then(data => {
        const { detail } = data
        toast.success(detail)
        this.setState({ password1: '', password2: '' })
      })
      .catch(data => {
        if (data instanceof ApiError && data && data.messages) {
          const messages = Object.keys(data.messages).map(
            m => `${t(m)}: ${data.messages ? data.messages[m][0] : ''}\n`
          )
          messages.forEach(m => toast.error(m))
          return
        }
      })
  }

  render() {
    const { password1, password2, oldPassword } = this.state
    const { t } = this.props
    return (
      <CardContainer>
        <Card>
          <CardContent>
            <Title>{t('change-password')}</Title>
            <input
              onChange={({ target: { value } }) =>
                this.setState({ oldPassword: value })
              }
              placeholder={t('old_password')}
              type="password"
              value={oldPassword}
            />
            <input
              onChange={({ target: { value } }) =>
                this.setState({ password1: value })
              }
              placeholder={t('new-password')}
              type="password"
              value={password1}
            />
            <input
              onChange={({ target: { value } }) =>
                this.setState({ password2: value })
              }
              placeholder={t('new-password-confirm')}
              type="password"
              value={password2}
            />
            <button type="button" onClick={this.changePassword}>
              {t('confirm')}
            </button>
          </CardContent>
        </Card>
      </CardContainer>
    )
  }
}

export default withTranslation()(ChangePassword)

import { Delete, Get, Patch, Post } from 'api/index'
import { createAsyncAction } from 'redux-promise-middleware-actions'
import { RootState } from 'store/reducers'
import { Notification } from 'store/reducers/notification'

export const GET_NOTIFICATION = 'GET_NOTIFICATION'
export const LIST_NOTIFICATION = 'LIST_NOTIFICATION'
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const UPLOAD_NOTIFICATION_FILES = 'UPLOAD_NOTIFICATION_FILES'
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'

export const getNotification = createAsyncAction(
  GET_NOTIFICATION,
  async (id: number) => {
    const url = `/api/notification/${id}/`
    const data = await Get(url)
    return data
  }
)

export const listNotification = createAsyncAction(
  LIST_NOTIFICATION,
  async () => {
    const url = `/api/notification/`
    const data = await Get(url)
    return data
  }
)

export const createNotification = createAsyncAction(
  CREATE_NOTIFICATION,
  async (body: string) => {
    const url = `/api/notification/`
    const data = await Post<RootState['notification']['created']>(url, body)
    return data
  }
)

export const updateNotification = createAsyncAction(
  UPDATE_NOTIFICATION,
  async (id: number, body: string) => {
    const url = `/api/notification/${id}/`
    const data = await Patch<Notification>(url, body)
    return data
  }
)

export const deleteNotification = createAsyncAction(
  DELETE_NOTIFICATION,
  async (id: number | string) => {
    const url = `/api/notification/${id}/`
    const data = await Delete(url)
    return data
  }
)

export const uploadNotificationFiles = createAsyncAction(
  UPLOAD_NOTIFICATION_FILES,
  async (
    id: string | number,
    files: File[] | null,
    filesToRetain?: number[]
  ) => {
    const url = `/api/notification/${id}/upload_files/`
    const token = sessionStorage.getItem('API_TOKEN')
    if (filesToRetain) {
      const data = await Post(
        url,
        JSON.stringify({
          files_to_retain: filesToRetain
        })
      )
      return data
    }
    const body = new FormData()
    files?.forEach((f: Blob) => body.append('files[]', f))
    const requestHeaders: HeadersInit = new Headers()
    if (token) {
      requestHeaders.set('Authorization', `Token ${token}`)
    }
    const data = await fetch(url, {
      method: 'POST',
      headers: requestHeaders,
      body
    })
    return data
  }
)

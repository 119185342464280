import { RangeValue, SingleValue } from 'components/blocks/MonthSelector'
import { Years } from 'components/notification/DateSelector'
import { DateSelection } from 'components/roadmap/Roadmap'
import produce from 'immer'
import { AnyAction } from 'redux'
import { SET_SELECTIONS } from 'store/actions/monthselector'

interface MonthSelectorState {
  selections: {
    [key: string]: {
      rangeValue?: RangeValue
      value?: Years | SingleValue | RangeValue,
      dateSelection?: DateSelection
    }
  } | null
  isPending: boolean
}

const initialState: MonthSelectorState = {
  selections: null,
  isPending: false
}

const monthselector = (state = initialState, action: AnyAction) => {
  const { type } = action

  switch (type) {
    case SET_SELECTIONS:
      return produce(state, draft => {
        draft.isPending = false
        draft.selections = action.payload.selections
      })

    default:
      return state
  }
}

export default monthselector

import produce from 'immer'
import { AnyAction } from 'redux'
import { SELECT_TIME_GROUPING } from 'store/actions/timeGrouping'

export const GROUPING_MONTH = 'month'
export const GROUPING_QUARTER = 'quarter'
export const GROUPING_FISCAL = 'fiscal'
export const DEFAULT_TIME_GROUPING = GROUPING_MONTH

const search = new URLSearchParams(window.location.search)
const initialState = {
  selectedGrouping: search.get('grouping') ?? DEFAULT_TIME_GROUPING
}

const timeGrouping = (state = initialState, action: AnyAction) => {
  const { type } = action

  switch (type) {
    case SELECT_TIME_GROUPING:
      return produce(state, draft => {
        draft.selectedGrouping = action.payload.newGrouping
      })

    default:
      return state
  }
}

export default timeGrouping

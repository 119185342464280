import React, { Component } from 'react'
import { debounce, DebouncedFunc } from 'lodash'
import { connect } from 'react-redux'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { TFunction, withTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import Icon from 'components/Icon'
import { setMenuState } from 'store/actions/app'
import { getGraphPathName } from 'util/format'
import LoadingIndicator from 'components/blocks/LoadingIndicator'
import MenuButton from './MenuButton'
import { RootState } from 'store/reducers'
import { User } from 'store/reducers/auth'

const getMenuWidth = (desiredWidth: string, mobileOpen: boolean) => {
  if (window.innerWidth <= 768) {
    return mobileOpen ? '180px' : '0'
  }

  return desiredWidth
}

const Container = styled.div`
  position: relative;
  min-width: ${(props: { menuState: string; mobileOpen: boolean }) =>
    props.menuState === 'collapsed'
      ? getMenuWidth('70px', props.mobileOpen)
      : getMenuWidth('180px', props.mobileOpen)};
  background: ${props => props.theme.navigation.background};
  height: 100vh;
  border-right: ${props => props.theme.navigation.border};
  @media screen and (max-width: 768px) {
    position: fixed;
    z-index: 999;
    width: 0;
  }
`

const MenuButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #333;
    border-radius: 0.625rem;
  }
  ::-webkit-scrollbar-thumb {
    background: #777777;
    border-radius: 0.625rem;
  }
`

const SubMenu = styled.div`
  position: absolute;
  left: ${(props: {
    menuState: string
    mobileOpen: boolean
    position: number | string
  }) =>
    props.menuState === 'collapsed'
      ? getMenuWidth('70px', props.mobileOpen)
      : getMenuWidth('180px', props.mobileOpen)};
  ${props => (props.position ? props.position : '')}
  border-radius: 0 8px 8px 0;
  background-color: ${props => props.theme.navigation.background};
  display: flex;
  flex-direction: column;
  z-index: 1000;
  ${props =>
    props.mobileOpen
      ? `
    max-width: 11.875rem;
    > a {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `
      : ''}
  a {
    transition: background 0.2s ease-in-out;
    color: ${props => props.theme.text.primary};
    padding: 0.625rem 1.25rem;
    font-family: 'Open Sans';
    font-size: 0.875rem;
    letter-spacing: 0;
    line-height: 0.938rem;
    text-decoration: none;
    white-space: nowrap;
    &:first-of-type {
      padding-top: 1.25rem;
    }
    &:last-of-type {
      padding-bottom: 1.25rem;
      margin-bottom: 0;
    }
    &:hover {
      background-color: ${props => props.theme.navigation.itemHoverBg};
    }
  }
`

const SubMenuTriangle = styled.svg`
  position: absolute;
  right: 0;
  bottom: 0;
  fill: #5f5f5f;
  transition: fill 0.2s ease-in-out;
  pointer-events: none;
`

const ResizeTriangle = styled.svg`
  position: absolute;
  right: 0;
  top: 0;
  fill: ${props => props.theme.navigation.resizeBg};
  transition: fill 0.2s ease-in-out;
  pointer-events: none;
  z-index: -1;
`

const CollapseButton = styled.div`
  width: 1.563rem;
  height: 1.563rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 100%;
  z-index: 999;
  cursor: pointer;
  .icon {
    background-color: #777777;
  }
  &:hover {
    svg {
      fill: #2b2b2b;
    }
  }
`

const RedDot = styled.span`
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #ff4545 !important;
  border-radius: 100%;
  margin-left: 5px;
  vertical-align: top;
`

const LoadingIndicatorContainer = styled.div`
  > div {
    align-items: flex-start;
    padding-top: 1rem;
  }
  .bounce1,
  .bounce2,
  .bounce3 {
    height: 0.8rem;
    width: 0.8rem;
  }
`

type MainMenuReduxState = ReturnType<typeof mapStateToProps>
type MainMenuReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface MainMenuProps {
  t: TFunction
  mobileMenuOpen: boolean
  onMobileMenuClose: () => void
}

interface MainMenuState {
  subMenu: string | null
  subMenuPos: number | string
}

class MainMenu extends Component<
  MainMenuProps & MainMenuReduxState & MainMenuReduxDispatch,
  MainMenuState
> {
  handleResize: () => void
  resizeDebounce: DebouncedFunc<() => void>

  constructor(
    props: MainMenuProps & MainMenuReduxState & MainMenuReduxDispatch
  ) {
    super(props)
    this.state = {
      subMenu: null,
      subMenuPos: 0
    }
    this.handleResize = () => this.forceUpdate()
    this.resizeDebounce = debounce(this.handleResize, 500)
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resizeDebounce)
    document.addEventListener('click', this.handleOutsideClick)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resizeDebounce)
    document.removeEventListener('click', this.handleOutsideClick)
  }

  handleOutsideClick = (e: { clientX: number }) => {
    const { onMobileMenuClose } = this.props
    if (window.innerWidth > 768) {
      return
    }

    if (e.clientX > 160) {
      onMobileMenuClose()
    }
  }

  renderSubMenu = () => {
    const { subMenu, subMenuPos } = this.state
    const {
      menuState,
      settings: { mappings, additional_information: additionalInformation },
      mobileMenuOpen
    } = this.props
    const mapping = mappings.find(m => m.view_title === subMenu)
    if (mapping) {
      const children = [...mapping.children]
      return (
        <SubMenu
          position={subMenuPos}
          menuState={menuState as string}
          mobileOpen={mobileMenuOpen}
          onClick={() => this.setState({ subMenu: null })}
        >
          {children
            .sort((a: { id: number }, b: { id: number }) =>
              additionalInformation
                ? additionalInformation[a.id].sort_order -
                  additionalInformation[b.id].sort_order
                : a.id - b.id
            )
            .map(c => (
              <Link key={c.id} to={getGraphPathName(c.view_title)}>
                {c.view_title}
              </Link>
            ))}
        </SubMenu>
      )
    }

    return null
  }

  toggleMenu = () => {
    const { setMenuState: setMenuStateAction } = this.props
    const currentValue = localStorage.getItem('menu')
    const newValue = currentValue === 'collapsed' ? 'extended' : 'collapsed'
    localStorage.setItem('menu', newValue)
    setMenuStateAction(newValue)
  }

  getPosition = (target: Element) => {
    const { y, height } = target.getBoundingClientRect()
    if (y > window.innerHeight / 2) {
      return `bottom: ${window.innerHeight - y - height}px;`
    }
    return `top: ${y}px;`
  }

  render() {
    const { subMenu } = this.state
    const {
      t,
      menuState,
      user,
      notificationsRead,
      settings: {
        mappings,
        additional_information: additionalInformation,
        restricted_views: restrictedViews
      },
      isSettingsPending,
      mobileMenuOpen
    } = this.props

    const { unread_notifications: unreadNotifications } = user as User

    if (!additionalInformation || isSettingsPending) {
      return (
        <Container
          id="main-menu-container"
          onMouseLeave={() => this.setState({ subMenu: null })}
          menuState={menuState as string}
          mobileOpen={mobileMenuOpen}
        >
          <LoadingIndicatorContainer>
            <LoadingIndicator />
          </LoadingIndicatorContainer>
        </Container>
      )
    }
    return (
      <Container
        id="main-menu-container"
        onMouseLeave={() => this.setState({ subMenu: null })}
        menuState={menuState as string}
        mobileOpen={mobileMenuOpen}
      >
        <MenuButtonContainer>
          {window.innerWidth > 768 && (
            <CollapseButton onClick={this.toggleMenu}>
              <Icon
                iconName={menuState === 'collapsed' ? 'expand' : 'collapse'}
                size={15}
              />
              <ResizeTriangle height="45" width="45">
                <polygon points="0, 0, 45, 0, 45, 40" />
              </ResizeTriangle>
            </CollapseButton>
          )}
          {!(restrictedViews || []).includes('strategy') && (
            <MenuButton
              icon="flow"
              to="/"
              onHover={() =>
                this.setState({
                  subMenu: null
                })
              }
            >
              {t('strategy')}
            </MenuButton>
          )}
          {!(restrictedViews || []).includes('roadmap') && (
            <MenuButton
              icon="roadmap"
              to="/roadmap"
              onHover={() =>
                this.setState({
                  subMenu: null
                })
              }
            >
              {t('roadmap')}
            </MenuButton>
          )}
          {!(restrictedViews || []).includes('logic') && (
            <MenuButton
              icon="flowChart"
              to="/logic"
              onHover={() =>
                this.setState({
                  subMenu: null
                })
              }
            >
              {t('logic')}
            </MenuButton>
          )}
          {!(restrictedViews || []).includes('notifications') && (
            <MenuButton
              icon="notes"
              to="/notifications"
              onHover={() =>
                this.setState({
                  subMenu: null
                })
              }
            >
              {t('notifications')}
              {unreadNotifications && !notificationsRead ? <RedDot /> : null}
            </MenuButton>
          )}
          {!(restrictedViews || []).includes('data-map') && (
            <MenuButton
              icon="table"
              to="/data-map"
              onHover={() =>
                this.setState({
                  subMenu: null
                })
              }
            >
              {t('data-map')}
            </MenuButton>
          )}
          {(mappings || [])
            .filter(m => !m.redirect || (m.redirect && m.children.length > 0))
            .sort((a: { id: number }, b: { id: number }) =>
              additionalInformation
                ? (additionalInformation[a.id]?.sort_order || 0) -
                  (additionalInformation[b.id]?.sort_order || 0)
                : a.id - b.id
            )
            .map(m => (
              <MenuButton
                key={m.id}
                icon={m.view_icon}
                to={
                  m.redirect
                    ? getGraphPathName(
                        [
                          ...m.children
                        ].sort((a: { id: number }, b: { id: number }) =>
                          additionalInformation
                            ? (additionalInformation[a.id]?.sort_order || 0) -
                              (additionalInformation[b.id]?.sort_order || 0)
                            : a.id - b.id
                        )[0]?.view_title
                      )
                    : getGraphPathName(m.view_title)
                }
                onHover={
                  m.children.length
                    ? e =>
                        this.setState({
                          subMenu: m.view_title,
                          subMenuPos: this.getPosition(e.target as Element)
                        })
                    : undefined
                }
              >
                {m.view_title}
                {m.children.length > 0 && (
                  <SubMenuTriangle height="15" width="15">
                    <polygon points="0, 15, 15, 0, 15, 15" />
                  </SubMenuTriangle>
                )}
              </MenuButton>
            ))}
        </MenuButtonContainer>
        {subMenu !== null && this.renderSubMenu()}
      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setMenuState
    },
    dispatch
  )

const mapStateToProps = (state: RootState) => ({
  menuState: state.app.menuState,
  user: state.auth.user,
  notificationsRead: state.auth.notificationsRead,
  settings: state.graph.settings,
  isSettingsPending: state.graph.isPending
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(MainMenu))

import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  getOrganizations,
  getActiveOrganization,
  activateOrganization
} from 'store/actions/organization'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import { saveTreeState } from 'store/actions/app'
import { RootState } from 'store/reducers'
import { Organization } from 'store/reducers/organization'
import { getOrgName } from 'util/organization'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-right: 70px;
  min-width: 150px;
  p {
    margin: 0;
    color: ${(props: { theme: { topMenu: { labelColor: string } } }) =>
      props.theme.topMenu.labelColor};
    font-family: 'Open Sans';
    font-size: 0.813rem;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 1.125rem;
    margin-bottom: 5px;
    text-align: right;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    p {
      color: ${(props: { theme: { topMenu: { dropdownColor: string } } }) =>
        props.theme.topMenu.dropdownColor};
      font-size: 0.875rem;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 1.188rem;
      margin-bottom: 0;
      user-select: none;
    }
  }
`

const Caret = styled.div`
  margin-top: 2px;
  margin-left: 5px;
  width: 5px;
  height: 5px;
  border-style: solid;
  border-width: ${(props: { isOpen: boolean }) =>
    props.isOpen ? '0 5px 5px 5px' : '5px 5px 0 5px'};
  border-color: ${(props: { isOpen: boolean }) =>
    props.isOpen
      ? 'transparent transparent #606160'
      : '#606160 transparent transparent transparent'};
`

const DropdownContainer = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  max-height: ${(props: { isOpen: boolean }) => (props.isOpen ? '500px' : '0')};
  opacity: ${(props: { isOpen: boolean }) => (props.isOpen ? '1' : '0')};
  visibility: ${(props: { isOpen: boolean }) =>
    props.isOpen ? 'visible' : 'hidden '};
  overflow-y: auto;
  transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
  border-radius: 8px;
  background-color: #3b3b3b;
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  z-index: 899;
  span {
    width: 100%;
    border-radius: 8px;
    cursor: pointer;
    color: #ffffff;
    font-family: 'Open Sans';
    font-size: 0.688rem;
    letter-spacing: 0;
    line-height: 0.938rem;
    padding: 0.938rem;
    &:hover {
      background-color: #656565;
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #333;
    border-radius: 0.625rem;
  }
  ::-webkit-scrollbar-thumb {
    background: #777777;
    border-radius: 0.625rem;
  }
`

const EnvironmentSelector: React.FC<{
  onOrganizationChange: () => void
}> = ({ onOrganizationChange }) => {
  const [isOpen, setOpen] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const org = useSelector((state: RootState) => state.organization)
  const { t } = useTranslation()
  const dropdownContainerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    dispatch(getActiveOrganization())
    dispatch(getOrganizations())
  }, [org.activeOrganization]) // eslint-disable-line

  useEffect(() => {
    const checkOutsideClick = (e: { clientX: number; clientY: number }) => {
      const { clientX, clientY } = e
      const { current } = dropdownContainerRef
      if (current) {
        const { top, left, right, bottom } = current.getBoundingClientRect()
        if (
          clientX < left ||
          clientY < top ||
          clientX > right ||
          clientY > bottom
        ) {
          setOpen(false)
        }
      }
    }
    document.addEventListener('mousedown', checkOutsideClick)

    // returned function will be called on component unmount
    return () => {
      document.removeEventListener('mousedown', checkOutsideClick)
    }
  }, [])

  if (!org.activeOrganization) {
    return null
  }

  const renderOrgs = (org: Organization[], level: number) => {
    return org.map((o: Organization) => (
      <React.Fragment key={o.id}>
        <span
          style={{ paddingLeft: level * 10 + 'px' }}
          key={o.id}
          onClick={() => {
            dispatch(activateOrganization(o.id))
            if (window.dataLayer) {
              window.dataLayer.push({
                organizationId: o.id
              })
            }
            setOpen(state => !state)
            onOrganizationChange()
            dispatch(saveTreeState(null))
            history.push('/')
          }}
        >
          {o.name}
        </span>
        {o.children && o.children.length > 0 && (
          <>{renderOrgs(o.children, level + 1)}</>
        )}
      </React.Fragment>
    ))
  }

  return (
    <Container>
      <p>{t('selectedEnvironment')}</p>
      <div onClick={() => setOpen(state => !state)}>
        <p>{getOrgName(org)}</p>
        <Caret isOpen={isOpen} />
      </div>
      <DropdownContainer isOpen={isOpen} ref={dropdownContainerRef}>
        {renderOrgs(org.data, 1)}
      </DropdownContainer>
    </Container>
  )
}

export default EnvironmentSelector

import React, { useState } from 'react';
import { toggleDataMapOpenAll } from 'store/actions/rawdata'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { YellowButtonMargin } from 'components/blocks/YellowButton'
import { EXPAND_SIGN, COLLAPSE_SIGN } from './DataMap'


const ExpandToggleButton = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [isExpanded, toggle] = useState(false)

  const toggleCallback = () => {
    toggle(!isExpanded)
    dispatch(toggleDataMapOpenAll(isExpanded))
  }
  return (
    <YellowButtonMargin onClick={() => toggleCallback()}>
      {isExpanded ? `${COLLAPSE_SIGN} ${t('collapse-all-rows')}` : `${EXPAND_SIGN} ${t('expand-all-rows')}`}
    </YellowButtonMargin>
  )
}

export default ExpandToggleButton

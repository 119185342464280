import React, { Component } from 'react'
import { TermOfClassification } from 'store/reducers/term'
import styled from 'styled-components/macro'

const Container = styled.div`
  display: flex;
  width: 100%;
  > div {
    flex-grow: 1;
    margin-right: 0.938rem;
    &:last-of-type {
      margin-right: 0;
    }
  }
`

const Content = styled.div`
  padding: 1.25rem;
  p {
    color: #626262;
    font-family: 'Open Sans';
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1.25rem;
    letter-spacing: 0;
    line-height: 1.125rem;
  }
`

export default class MetaData extends Component<{
  keys: Array<TermOfClassification>
}> {
  renderValue = (key: TermOfClassification) => {
    return (
      <Content>
        <p>{key.title}</p>
      </Content>
    )
  }

  render() {
    const { keys } = this.props
    return <Container>{keys.map(k => this.renderValue(k))}</Container>
  }
}

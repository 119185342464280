import React, { Component } from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Row from 'components/roadmap/Row'
import Container from 'components/roadmap/Container'
import Icon from 'components/Icon'
import moment from 'moment'
import { listTask, setDragInProgress, triggerRefresh } from 'store/actions/task'
import { listColorpair } from 'store/actions/colorpair'
import MainTaskModal from 'components/modals/MainTaskModal'
import styled, { DefaultTheme, withTheme } from 'styled-components'
import Popup from 'reactjs-popup'
import { updateMaintask } from 'store/actions/maintask'
import { RootState } from 'store/reducers'
import { DateSelection } from './Roadmap'
import { MainTaskType } from 'store/reducers/maintask'
import { RoadmapType } from 'store/reducers/roadmap'
import { TaskType } from 'store/reducers/task'
import { ColorPair } from 'store/reducers/colorpair'
import { AppDispatch } from 'store/store'

const TooltipContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  white-space: pre-line;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  z-index: 1;
  position: absolute;
`

const TooltipLink = styled.div`
  padding: 10px;
  padding-bottom: 5px;
  font-family: Font Awesome 5 Pro;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 13px;
  cursor: pointer;
`

const Header = styled.div`
  padding: 5px 10px;
  color: #434343;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
`

export type ColorOption = { value: number; label: string; color: string }

type MainTaskReduxState = ReturnType<typeof mapStateToProps>
type MainTaskReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface MainTaskProps {
  mainTask?: MainTaskType
  roadmap?: RoadmapType
  roadmaps: RoadmapType[]
  dateSelection: DateSelection
  addMainTask: boolean
  updateTime: number
  t: TFunction
  setTab: (roadmapId: number) => void
  isRoadmapManager?: boolean
  theme: DefaultTheme
}

interface MainTaskState {
  tasks: { [key: string]: TaskType }[]
  colorpairs: Array<ColorPair>
  colorOptions: ColorOption[]
  roadmapTitles: Array<string>
  dragCoords: { x: number; y: number } | null
}

class MainTask extends Component<
  MainTaskProps & MainTaskReduxState & MainTaskReduxDispatch,
  MainTaskState
> {
  constructor(
    props: MainTaskProps & MainTaskReduxState & MainTaskReduxDispatch
  ) {
    super(props)
    this.state = {
      tasks: [],
      colorpairs: [],
      colorOptions: [],
      roadmapTitles: [],
      dragCoords: null
    }
  }

  async componentDidMount() {
    const { roadmaps, listColorpair: listColorpairAction } = this.props
    document.addEventListener('mousemove', this.dragMove)
    document.addEventListener('mouseup', this.dragEnd)

    await listColorpairAction()
    const {
      colorpair: {
        list: { results: colorpairResults }
      }
    } = this.props
    const colorpairs: Array<typeof colorpairResults[0]> = []
    const colorOptions: ColorOption[] = []
    colorpairResults.forEach(colorpair => {
      colorpairs[colorpair.id] = colorpair
      colorOptions.push({
        value: colorpair.id,
        label: colorpair.title,
        color: colorpair.color
      })
    })
    const roadmapTitles: Array<string> = []
    roadmaps.forEach(r => {
      roadmapTitles[r.id] = r.title
    })
    this.setState({ roadmapTitles, colorpairs, colorOptions })
    this.refresh()
  }

  componentDidUpdate(
    prevProps: MainTaskProps & MainTaskReduxState & MainTaskReduxDispatch
  ) {
    const { updateTime } = prevProps
    const { updateTime: newUpdateTime } = this.props
    if (updateTime !== newUpdateTime) {
      this.refresh()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.dragMove)
    document.removeEventListener('mouseup', this.dragEnd)
  }

  dragMove = (e: { clientX: number; clientY: number }) => {
    const { dragCoords } = this.state
    if (dragCoords) {
      this.setState({
        dragCoords: { x: e.clientX, y: e.clientY }
      })
    }
  }

  reload = async () => {
    const { triggerRefresh: triggerRefreshAction } = this.props
    triggerRefreshAction(true)
  }

  refresh = async () => {
    const { mainTask, listTask: listTaskAction } = this.props
    const tasks: { [key: string]: TaskType }[] = []
    if (mainTask) {
      for (let rowIndex = 0; rowIndex < mainTask.row_count; rowIndex += 1) {
        /* eslint-disable */
        await listTaskAction(mainTask.id, rowIndex)
        /* eslint-enable */
        const {
          task: {
            list: { results }
          }
        } = this.props
        results.forEach((task: TaskType) => {
          const startDate = moment(task.start_date, moment.ISO_8601).format(
            'YYYY-MM'
          )
          if (!tasks[rowIndex]) tasks[rowIndex] = {}
          tasks[rowIndex][startDate] = task
        })
      }
    }
    this.setState({ tasks })
  }

  getColor = (maintask?: RootState['maintask']['data']) => {
    if (typeof maintask === 'undefined') return '#E3E3E3'
    const { colorpairs } = this.state
    if (colorpairs[maintask?.color_pair || 0])
      return colorpairs[maintask?.color_pair || 0].color
    return ''
  }

  getDarkColor = (maintask?: RootState['maintask']['data']) => {
    if (typeof maintask === 'undefined') return '#7B8F94'
    const { colorpairs } = this.state
    if (colorpairs[maintask?.color_pair || 0])
      return colorpairs[maintask?.color_pair || 0].dark_color
    return ''
  }

  dragStart = (coordinates: { x: number; y: number }) => {
    const { setDragInProgress: setDragInProgressAction, mainTask } = this.props
    if (!mainTask) {
      return
    }
    this.setState({ dragCoords: coordinates })
    setDragInProgressAction(mainTask.id)
  }

  dragEnd = () => {
    const { setDragInProgress: setDragInProgressAction } = this.props
    this.setState({ dragCoords: null })
    setDragInProgressAction(false)
  }

  updateMainTaskIndex = async () => {
    const {
      mainTask,
      mainTasks,
      task: { dragInProgress },
      updateMaintask: updateMaintaskAction,
      triggerRefresh: triggerRefreshAction
    } = this.props
    let newIndex = 0
    let mainTasksToUpdate = mainTasks.map((m, i) => {
      const newMainTask = { ...m }
      newMainTask.index = i
      if (newMainTask.id === mainTask?.id) {
        newIndex = newMainTask.index + 1
      }
      return newMainTask
    })

    mainTasksToUpdate = mainTasksToUpdate.map(m => {
      const newMainTask = { ...m }
      if (newMainTask.id !== dragInProgress && newMainTask.index >= newIndex) {
        newMainTask.index += 1
      }
      return newMainTask
    })

    mainTasksToUpdate = mainTasksToUpdate.map(m => {
      const newMainTask = { ...m }
      if (newMainTask.id === dragInProgress) {
        newMainTask.index = newIndex
      }
      return newMainTask
    })

    await Promise.all(
      mainTasksToUpdate.map(m => updateMaintaskAction(JSON.stringify(m), m.id))
    )
    triggerRefreshAction(true)
  }

  render() {
    const {
      mainTask,
      mainTasks,
      dateSelection,
      roadmaps,
      roadmap,
      addMainTask,
      t,
      setTab,
      isRoadmapManager,
      task: { dragInProgress },
      theme
    } = this.props
    const {
      roadmapTitles,
      tasks,
      colorOptions,
      colorpairs,
      dragCoords
    } = this.state
    const divStyle: React.CSSProperties = {
      margin: '2px',
      padding: '10px 15px',
      backgroundColor: addMainTask ? '#DFE8EA' : '#fff',
      color: addMainTask ? '#7B8F94' : 'inherit',
      borderRadius: '10px',
      float: 'left',
      width: '100%',
      position: 'relative'
    }
    const iconStyle: React.CSSProperties = {
      display: 'inline-block',
      marginLeft: '20px',
      cursor: 'pointer',
      backgroundColor: theme.roadMap.iconColor
    }
    if (dragCoords) {
      divStyle.position = 'fixed'
      divStyle.left = dragCoords.x
      divStyle.top = dragCoords.y
      divStyle.zIndex = 999
      divStyle.opacity = 0.5
    }

    const rows: JSX.Element[] = []
    if (tasks && mainTask) {
      for (let rowIndex = 0; rowIndex < mainTask.row_count; rowIndex += 1) {
        const rowTasks = tasks[rowIndex]
        rows.push(
          <Row
            key={`maintask-row-${mainTask.id}-${rowIndex}`}
            tasks={rowTasks}
            darkColor={this.getDarkColor(mainTask)}
            color={this.getColor(mainTask)}
            dateSelection={dateSelection}
            addRowEnabled={rowIndex === mainTask.row_count - 1}
            mainTask={mainTask}
            refreshMainTask={() => this.refresh()}
            reloadMainTask={async () => {
              await this.reload()
              this.refresh()
            }}
            rowIndex={rowIndex}
            isRoadmapManager={isRoadmapManager}
          />
        )
      }
    }

    const addNewMainTask = addMainTask ? (
      <MainTaskModal
        iconStyle={iconStyle}
        roadmaps={roadmaps}
        colorOptions={colorOptions}
        colorpairs={colorpairs}
        iconName="plusCircle"
        roadmap={roadmap as RoadmapType}
        mainTasksCount={mainTasks.length}
      />
    ) : (
      ''
    )

    const links: JSX.Element[] = []
    if (mainTask && mainTask.link_to_roadmap.length > 0) {
      links.push(<Header>{t('Linked roadmaps')}</Header>)
      mainTask.link_to_roadmap.forEach((link: number) => {
        const roadmapTitle = roadmapTitles[link]
        links.push(
          <TooltipLink onClick={() => setTab(link)}>
            {roadmapTitle}{' '}
            <Icon
              style={iconStyle}
              color="#354B7D"
              iconName="doubleArrowRight"
              size={9}
            />
          </TooltipLink>
        )
      })
    }

    const actions: JSX.Element[] = []
    if (!addMainTask) {
      if (links.length > 0) {
        actions.push(
          <TooltipContainer>
            <Popup
              trigger={
                <div>
                  <Icon
                    style={{
                      zIndex: 1,
                      cursor: 'pointer'
                    }}
                    color="#243c72"
                    iconName="link"
                    size={15}
                  />
                </div>
              }
              position={['bottom center']}
              nested
            >
              <div>{links}</div>
            </Popup>
          </TooltipContainer>
        )
      }
      if (isRoadmapManager) {
        actions.push(
          <MainTaskModal
            mainTask={mainTask}
            iconStyle={iconStyle}
            roadmaps={roadmaps}
            colorOptions={colorOptions}
            colorpairs={colorpairs}
            iconName="edit"
            roadmap={roadmap as RoadmapType}
            mainTasksCount={mainTasks.length}
          />
        )
      }
    }

    return (
      <>
        <Container
          darkColor={this.getDarkColor(mainTask)}
          color={this.getColor(mainTask)}
          onDragStart={isRoadmapManager ? this.dragStart : () => null}
          style={divStyle}
          isDragged={
            (dragInProgress && mainTask && dragCoords === null) as boolean
          }
          updateMainTaskIndex={this.updateMainTaskIndex}
          header={
            <div style={{ margin: '10px 0', position: 'relative' }}>
              <div style={{ marginLeft: '15px' }}>
                {mainTask ? mainTask.title : t('Add new maintask')}{' '}
                {addNewMainTask}
              </div>
              <div style={{ position: 'absolute', top: '0', right: '15px' }}>
                {actions}
              </div>
            </div>
          }
          data={rows}
        />
      </>
    )
  }
}

const mapStateToProps = ({ error, task, colorpair, maintask }: RootState) => ({
  error,
  task,
  colorpair,
  mainTasks: maintask.list.results
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      listTask,
      listColorpair,
      triggerRefresh,
      setDragInProgress,
      updateMaintask
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withTheme(MainTask)))

import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import {
  ChartArea,
  DataSwitch,
  OrganizationDateSelector,
  TimeGroupingSelector
} from 'components/graph'
import { Title } from 'components/blocks'
import styled, { withTheme, DefaultTheme } from 'styled-components/macro'
import { TFunction, withTranslation } from 'react-i18next'
import { getGraphPathName } from 'util/format'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { createReportGraph } from 'store/actions/report'
import LoadingIndicator from 'components/blocks/LoadingIndicator'
import YellowButton from 'components/blocks/YellowButton'
import timeLineFromDatesAndOrganization, {
  ISO_FORMAT_DATE,
  MONTH_FORMAT,
  PRESENTATION_MONTH_FORMAT
} from 'util/dates'
import { RootState } from 'store/reducers'

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 260px;
  > span {
    margin: 0;
    font-family: 'Open Sans';
    font-size: 0.813rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.125rem;
    margin-bottom: 6px;
  }
`

const TitleArea = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  h1 {
    margin-top: 0;
    margin-right: 1.25rem;
  }
`
const SelectorContainer = styled.div`
  margin-right: 1.25rem;
  min-width: 240px;
  @media screen and (max-width: 768px) {
    margin-bottom: 0.938rem;
  }
  p {
    margin: 0;
    font-family: 'Open Sans';
    font-size: 0.813rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.125rem;
    margin-bottom: 6px;
  }
`
const RelatedPagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    margin-bottom: 0.938rem;
  }
  > span {
    margin: 0;
    color: #b9c5c8;
    font-family: 'Open Sans';
    font-size: 0.813rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.125rem;
    margin-bottom: 6px;
  }
  > div {
    a {
      color: #67a7d7;
      font-family: 'Open Sans';
      font-size: 0.75rem;
      letter-spacing: 0;
      line-height: 0.813rem;
      text-decoration: none;
      margin-right: 0.625rem;
    }
  }
`
const SaveButton = styled(YellowButton)`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 170px;
  @media screen and (max-width: 1100px) {
    position: relative;
    right: 0;
    top: 0;
    margin-top: 1rem;
  }
`
const DataMapButton = styled(YellowButton)`
  position: absolute;
  top: 45px;
  right: 15px;
  width: 170px;
  @media screen and (max-width: 1100px) {
    position: relative;
    right: 0;
    top: 0;
    margin-top: 1rem;
  }
`
const LoadingContainer = styled.div`
  position: absolute;
  right: 50px;
  top: 30px;
  width: calc(1rem * 3);
  .bounce1,
  .bounce2,
  .bounce3 {
    width: 1rem;
    height: 1rem;
  }
`

interface GraphProps {
  dataKey: string
  t: TFunction
  keys: Array<{ key: string; title: string }>
  onDateChange: (
    dates: { [key: string]: string[] } | null,
    override?: { start: string; end: string }
  ) => void
  theme: DefaultTheme
}

interface GraphState {
  dates: { [key: string]: Array<string> } | null
  cumulative: boolean
  offset: number
  filteredKeys: Array<string>
}

type GraphReduxState = ReturnType<typeof mapStateToProps>
type GraphReduxDispatch = ReturnType<typeof mapDispatchToProps>

class Graph extends Component<
  GraphProps & GraphReduxState & RouteComponentProps & GraphReduxDispatch,
  GraphState
> {
  constructor(
    props: GraphProps &
      GraphReduxState &
      RouteComponentProps &
      GraphReduxDispatch
  ) {
    super(props)
    const search = new URLSearchParams(window.location.search)
    this.state = {
      dates: {},
      cumulative: search.get('cumulative') === 'true',
      offset: 0,
      filteredKeys: []
    }
  }

  componentDidMount() {
    const { dates } = this.state
    const { onDateChange, dateSelections } = this.props
    const urlParams = new URLSearchParams(window.location.search)
    const start = urlParams.get('start-date')
    const end = urlParams.get('end-date')
    if (start && end) {
      onDateChange(null, { start, end })
      return
    }
    if (
      dateSelections &&
      dateSelections.graph &&
      dateSelections.graph.rangeValue &&
      dateSelections.graph.rangeValue.from &&
      dateSelections.graph.rangeValue.to
    ) {
      const {
        graph: {
          rangeValue: { from, to }
        }
      } = dateSelections
      onDateChange(null, {
        start: moment(`${from.year}-${from.month}`, MONTH_FORMAT)
          .startOf('month')
          .format(ISO_FORMAT_DATE),
        end: moment(`${to.year}-${to.month}`, MONTH_FORMAT)
          .endOf('month')
          .format(ISO_FORMAT_DATE)
      })
      return
    }
    onDateChange(dates)
  }

  calculateChartOffset = () => {
    this.setState({
      offset: (document.querySelector('.legend')?.clientHeight || 0) + 250
    })
  }

  saveGraph = () => {
    const {
      createReportGraph: createReportGraphAction,
      dataKey,
      settings,
      activeOrganization,
      organizations,
      theme,
      timeGrouping
    } = this.props
    const { filteredKeys, dates, cumulative } = this.state
    let mapping = settings.mappings.find(
      m => m.view_title && getGraphPathName(m.view_title) === dataKey
    )
    if (!mapping) {
      settings.mappings.forEach(m => {
        if (mapping) {
          return
        }
        mapping = m.children.find(
          c => c.view_title && getGraphPathName(c.view_title) === dataKey
        )
      })
    }
    if (!dates || !mapping) {
      return
    }

    const { start, stop } = timeLineFromDatesAndOrganization(
      dates,
      organizations,
      activeOrganization,
      mapping.graph_properties
    )
    const body = {
      properties: {
        filtered_keys: filteredKeys,
        start_date: start.startOf('month').format(ISO_FORMAT_DATE),
        end_date: stop.endOf('month').format(ISO_FORMAT_DATE),
        theme: theme.id,
        grouping: timeGrouping.selectedGrouping,
        cumulative
      },
      mapping: mapping.id
    }
    createReportGraphAction(body)
  }

  showInDataMap = () => {
    const { history, keys } = this.props
    history.push(`/data-map?keys=${keys.map(key => key.key).join(',')}`)
  }

  renderSaveButton = () => {
    const { user, t } = this.props
    const reportManager = user?.report_manager
    if (reportManager) {
      return (
        <SaveButton onClick={() => this.saveGraph()}>
          {t('save-for-a-report')}
        </SaveButton>
      )
    }

    return null
  }

  renderDataMapButton = () => {
    const {
      settings: { restricted_views: restrictedViews },
      t
    } = this.props
    if (restrictedViews && !restrictedViews.includes('data-map')) {
      return (
        <DataMapButton onClick={() => this.showInDataMap()}>
          {t('show-in-data-map')}
        </DataMapButton>
      )
    }

    return null
  }

  render() {
    const { dates, cumulative, offset } = this.state
    const {
      dataKey,
      rawData: {
        list: { results }
      },
      settings,
      t,
      keys,
      onDateChange,
      report: { isPending },
      activeOrganization,
      organizations,
      theme
    } = this.props
    let mapping = settings.mappings.find(
      m => m.view_title && getGraphPathName(m.view_title) === dataKey
    )
    if (!mapping) {
      settings.mappings.forEach(m => {
        if (mapping) {
          return
        }
        mapping = m.children.find(
          c => c.view_title && getGraphPathName(c.view_title) === dataKey
        )
      })
    }
    let newestDate: moment.Moment | null = null
    results.forEach(r => {
      const startMoment = moment(r.start_date)
      if (r.is_verified && (newestDate === null || startMoment > newestDate)) {
        newestDate = startMoment
      }
    })
    if (!dates || !mapping?.graph_properties) {
      return
    }
    const { start, stop } = timeLineFromDatesAndOrganization(
      dates,
      organizations,
      activeOrganization,
      mapping?.graph_properties
    )
    keys.sort((a: { key: string | number }, b: { key: string | number }) => {
      const graphProperties = mapping?.graph_properties
      if (!graphProperties) {
        return 0
      }
      const typeA = graphProperties ? graphProperties[a.key].type : 'line'
      const typeB = graphProperties ? graphProperties[b.key].type : 'line'
      if (typeA === 'line' && typeB !== 'line') {
        return 1
      }
      if (typeA !== 'line' && typeB === 'line') {
        return -1
      }
      if (typeA === 'bar' && typeB !== 'bar') {
        return -1
      }
      if (typeA === 'stacked' && typeB !== 'stacked') {
        return 1
      }
      const aColor = graphProperties[a.key] ? graphProperties[a.key].color : ''
      const bColor = graphProperties[b.key] ? graphProperties[b.key].color : ''
      if (typeA === 'stacked' && typeB === 'stacked') {
        const stackAId = graphProperties[a.key].stack_id
        const stackBId = graphProperties[b.key].stack_id
        if (stackAId && stackBId && stackAId !== stackBId) {
          return stackAId < stackBId ? 1 : -1
        }
      }
      if (aColor === bColor) {
        return 0
      }
      return aColor < bColor ? 1 : -1
    })
    return (
      <>
        <TitleArea>
          <TitleContainer>
            <span>
              {t('updated')}:{' '}
              {moment(newestDate).format(PRESENTATION_MONTH_FORMAT)}
            </span>
            <Title>{mapping?.view_title}</Title>
          </TitleContainer>
          <SelectorContainer>
            <p>
              {t('graphicalTimeline')}:{' '}
              {start.format(PRESENTATION_MONTH_FORMAT)} -{' '}
              {stop.format(PRESENTATION_MONTH_FORMAT)}
            </p>
            <OrganizationDateSelector
              location="graph"
              data={results}
              keyToDate="start_date"
              onDateChange={d =>
                this.setState({ dates: d }, () => onDateChange(d))
              }
            />
          </SelectorContainer>
          <SelectorContainer>
            <p>{t('timeGroupingSelectionText')}</p>
            <TimeGroupingSelector />
          </SelectorContainer>
          {mapping?.graph_properties &&
          mapping.graph_properties.related_pages ? (
            <RelatedPagesContainer>
              <span>
                {mapping.graph_properties
                  ? mapping.graph_properties.related_pages_title ||
                    t('relatedPages')
                  : t('relatedPages')}
              </span>
              <div>
                {mapping.graph_properties.related_pages.map((r: string) => (
                  <Link key={r} to={getGraphPathName(r)}>
                    {r} &gt;&gt;
                  </Link>
                ))}
              </div>
            </RelatedPagesContainer>
          ) : null}
        </TitleArea>
        <DataSwitch
          onChange={(position: string) =>
            this.setState({ cumulative: position === 'cumulative' })
          }
        />
        {isPending ? (
          <LoadingContainer>
            <LoadingIndicator />
          </LoadingContainer>
        ) : (
          <>
            {this.renderSaveButton()}
            {this.renderDataMapButton()}
          </>
        )}
        <ChartArea
          onChartLoaded={this.calculateChartOffset}
          data={results}
          keys={keys}
          graphProperties={mapping.graph_properties}
          dates={dates}
          cumulative={cumulative}
          height={`calc(100vh - ${offset}px)`}
          darkNumbers={theme.graphPage.darkNumbers}
          onFilteredKeysChange={filteredKeys => this.setState({ filteredKeys })}
          theme={theme}
        />
      </>
    )
  }
}

const mapStateToProps = ({
  graph,
  rawData,
  report,
  organization: { data, activeOrganization },
  monthselector: { selections },
  auth,
  timeGrouping
}: RootState) => ({
  settings: graph.settings,
  rawData,
  report,
  activeOrganization,
  organizations: data,
  user: auth.user,
  dateSelections: selections,
  timeGrouping
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      createReportGraph
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(withTheme(Graph)))
)

import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import PrivateRoute from 'auth/PrivateRoute'
import GraphRoutes from 'auth/GraphRoutes'

import {
  Login,
  ChangePassword,
  /* Home, */
  ConnectGoogleAccount,
  Strategy,
  Logic,
  Notifications,
  Roadmaps,
  Reports,
  DataMap,
  ConnectAzureAccount,
  SystemMessages
} from 'pages'

import Layout from 'components/layouts/Layout'
import { connect } from 'react-redux'
import { resetState } from 'store/actions/reset'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { RootState } from 'store/reducers'

type RoutesProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

interface RoutesState {
  remount: boolean
}

class Routes extends Component<RoutesProps, RoutesState> {
  constructor(props: RoutesProps) {
    super(props)
    this.state = {
      remount: false
    }
  }

  componentDidUpdate() {
    const { remount } = this.state
    if (remount) {
      this.remountApp()
    }
  }

  remountApp = async () => {
    const { remount } = this.state
    const { resetState: resetStateAction } = this.props
    resetStateAction()
    this.setState({ remount: !remount })
  }

  render() {
    const { remount } = this.state
    const {
      settings: { restricted_views: restrictedViews },
      isPending
    } = this.props
    if (remount) {
      return null
    }
    return (
      <Router>
        <Layout onOrganizationChange={() => this.remountApp()}>
          <Switch>
            <Route component={Login} path="/login" />
            <PrivateRoute component={ChangePassword} path="/change-password" />
            {!isPending && !(restrictedViews || []).includes('data-map') && (
              <PrivateRoute component={DataMap} path="/data-map" />
            )}
            {!isPending && !(restrictedViews || []).includes('strategy') && (
              <PrivateRoute exact component={Strategy} path="/" />
            )}
            {!isPending && !(restrictedViews || []).includes('roadmap') && (
              <PrivateRoute component={Roadmaps} path="/roadmap" />
            )}
            {!isPending && !(restrictedViews || []).includes('logic') && (
              <PrivateRoute component={Logic} path="/logic" />
            )}
            {!isPending &&
              !(restrictedViews || []).includes('notifications') && (
                <PrivateRoute component={Notifications} path="/notifications" />
              )}
            {!isPending &&
              !(restrictedViews || []).includes('system-messages') && (
                <PrivateRoute
                  component={SystemMessages}
                  path="/system-messages"
                />
              )}
            {!isPending &&
              !(restrictedViews || []).includes('notifications') && (
                <PrivateRoute
                  component={Reports}
                  path="/reports"
                  userPermission="report_manager"
                />
              )}
            <PrivateRoute
              component={ConnectGoogleAccount}
              path="/connect-google"
            />
            <PrivateRoute
              component={ConnectAzureAccount}
              path="/connect-azure"
            />
            {/* <PrivateRoute exact component={Home} path="/" /> */}
            <GraphRoutes />
          </Switch>
        </Layout>
      </Router>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  settings: state.graph.settings,
  isPending: state.graph.isPending
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ resetState }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Routes)

import React, { Component, MouseEvent } from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Popup from 'reactjs-popup'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { updateRoadmap } from 'store/actions/roadmap'
import { triggerRefresh } from 'store/actions/task'
import Icon from 'components/Icon'
import { RootState } from 'store/reducers'
import { RoadmapType } from 'store/reducers/roadmap'

type EditRoadmapModalReduxState = ReturnType<typeof mapStateToProps>
type EditRoadmapModalReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface EditRoadMapModalState {
  title: string
}

interface EditRoadmapModalProps {
  t: TFunction
  roadmap: RoadmapType
}

class EditRoadmapModal extends Component<
  EditRoadmapModalProps &
    EditRoadmapModalReduxState &
    EditRoadmapModalReduxDispatch,
  EditRoadMapModalState
> {
  constructor(
    props: EditRoadmapModalProps &
      EditRoadmapModalReduxState &
      EditRoadmapModalReduxDispatch
  ) {
    super(props)
    this.state = {
      ...props.roadmap
    }
  }

  onFieldChange = (e: { target: { name: string; value: string } }) => {
    const {
      target: { name, value }
    } = e

    this.setState({
      ...this.state,
      [name]: value
    })
  }

  updateRoadmap = async (close: {
    (): void
    (
      event: MouseEvent<
        HTMLButtonElement,
        MouseEvent<Element, globalThis.MouseEvent>
      >
    ): void
    (): void
  }) => {
    const {
      updateRoadmap: updateRoadmapAction,
      triggerRefresh: triggerRefreshAction,
      roadmap: { id }
    } = this.props
    await updateRoadmapAction(id, JSON.stringify({ ...this.state }))
    triggerRefreshAction(true)
    close()
  }

  render() {
    const { t } = this.props
    const { title } = this.state
    return (
      <Popup
        position="top center"
        modal
        nested
        trigger={
          <Icon
            iconName="edit"
            color="#777"
            size={14}
            className="edit-button"
          />
        }
      >
        {(close: {
          (): void
          (event: MouseEvent<HTMLButtonElement, MouseEvent>): void
        }) => (
          <div className="modal">
            <button type="button" className="close" onClick={close}>
              &times;
            </button>
            <div className="header"> {t('edit-roadmap')} </div>
            <div className="content">
              <label htmlFor="roadmap-title">{t('roadmap-name')}</label>
              <input
                name="title"
                id="roadmap-title"
                placeholder={t('type-roadmap-name')}
                onChange={this.onFieldChange}
                value={title}
              />
            </div>
            <div className="actions">
              <button
                type="button"
                className="button close"
                onClick={() => {
                  close()
                }}
              >
                {t('Cancel')}
              </button>

              <button
                type="button"
                className="button"
                onClick={() => this.updateRoadmap(close)}
              >
                {t('Save')}
              </button>
            </div>
          </div>
        )}
      </Popup>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      triggerRefresh,
      updateRoadmap
    },
    dispatch
  )

const mapStateToProps = ({
  organization: { users, activeOrganization },
  target
}: RootState) => ({
  users,
  activeOrganization,
  target
})

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(EditRoadmapModal)
)

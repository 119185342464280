import React, { Component } from 'react'
import styled from 'styled-components/macro'
import LoadingIndicator from 'components/blocks/LoadingIndicator'
import { connect } from 'react-redux'
import {
  retrieveReportDataMap,
  retrieveReportGraph
} from 'store/actions/report'
import { bindActionCreators } from 'redux'
import { RootState } from 'store/reducers'
import { ReportDataMap, ReportGraph } from 'store/reducers/report'
import { AppDispatch } from 'store/store'

const LoadingIndicatorContainer = styled.div`
  height: 250px;
  position: relative;
`

const LOAD_CHECK_TIME = 75000

type LazyImageReduxState = ReturnType<typeof mapStateToProps>
type LazyImageReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface LazyImageProps {
  item: ReportGraph | ReportDataMap
  onClick: React.MouseEventHandler
}

interface LazyImageState {
  imageLoaded: boolean
}

class LazyImage extends Component<
  LazyImageProps & LazyImageReduxState & LazyImageReduxDispatch,
  LazyImageState
> {
  retrieveInterval?: NodeJS.Timer

  constructor(
    props: LazyImageProps & LazyImageReduxState & LazyImageReduxDispatch
  ) {
    super(props)
    this.state = {
      imageLoaded: false
    }
  }

  componentDidMount = async () => {
    const {
      item,
      retrieveReportGraph: retrieveReportGraphAction,
      retrieveReportDataMap: retrieveReportDataMapAction
    } = this.props
    if ('mapping' in item && item.mapping) {
      await retrieveReportGraphAction(item.id)
    } else {
      await retrieveReportDataMapAction(item.id)
    }
    const { graphData, dataMapData } = this.props
    const itemData =
      'mapping' in item && item.mapping
        ? graphData[item.id]
        : dataMapData[item.id]
    if (itemData && itemData.image_file) {
      this.setState({ imageLoaded: true })
      return
    }
    this.retrieveInterval = setInterval(async () => {
      if ('mapping' in item && item.mapping) {
        await retrieveReportGraphAction(item.id)
      } else {
        await retrieveReportDataMapAction(item.id)
      }
      if (
        window.location.href.indexOf('reports') === -1 &&
        this.retrieveInterval
      ) {
        clearInterval(this.retrieveInterval)
        return
      }
      const {
        graphData: newGraphData,
        dataMapData: newDataMapData
      } = this.props
      const newItemData =
        'mapping' in item && item.mapping
          ? newGraphData[item.id]
          : newDataMapData[item.id]
      if (newItemData && newItemData.image_file && this.retrieveInterval) {
        this.setState({ imageLoaded: true })
        clearInterval(this.retrieveInterval)
      }
    }, LOAD_CHECK_TIME)
  }

  componentWillUnmount = () => {
    if (this.retrieveInterval) {
      clearInterval(this.retrieveInterval)
    }
  }

  render() {
    const { graphData, dataMapData, item, ...rest } = this.props
    const { imageLoaded } = this.state
    if (!imageLoaded) {
      return (
        <LoadingIndicatorContainer>
          <LoadingIndicator />
        </LoadingIndicatorContainer>
      )
    }
    const itemData =
      'mapping' in item && item.mapping
        ? graphData[item.id]
        : dataMapData[item.id]
    return (
      <img
        src={itemData.image_file}
        alt={itemData.image_file}
        {...rest} // eslint-disable-line
      />
    )
  }
}

const mapStateToProps = ({ report }: RootState) => ({
  graphData: report.graphData,
  dataMapData: report.dataMapData
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      retrieveReportGraph,
      retrieveReportDataMap
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(LazyImage)

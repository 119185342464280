import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { logout, setLanguage } from 'store/actions/auth'

import styled from 'styled-components/macro'
import { resetState } from 'store/actions/reset'
import ThemeSelector from './menu/ThemeSelector'
import { RootState } from 'store/reducers'
import { useHistory } from 'react-router'
import { AsyncAction } from 'redux-promise-middleware'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  p {
    margin: 0;
    color: ${props => props.theme.topMenu.labelColor};
    font-family: 'Open Sans';
    font-size: 0.813rem;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 1.125rem;
    margin-bottom: 5px;
    text-align: right;
  }
  > div {
    display: flex;
    align-items: center;
    cursor: pointer;
    p {
      color: ${props => props.theme.topMenu.dropdownColor};
      font-size: 0.875rem;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 1.188rem;
      margin-bottom: 0;
      user-select: none;
    }
  }
`

const AvatarCircle = styled.div`
  height: 0.938rem;
  width: 0.938rem;
  border: 1px solid #aaa;
  margin-right: 5px;
  border-radius: 100%;
  background: url("${(props: { src: string }) => props.src}");
  background-size: cover;
`

const Caret = styled.div`
  margin-top: 2px;
  margin-left: 5px;
  width: 5px;
  height: 5px;
  border-style: solid;
  border-width: ${(props: { isOpen: boolean }) =>
    props.isOpen ? '0 5px 5px 5px' : '5px 5px 0 5px'};
  border-color: ${(props: { isOpen: boolean }) =>
    props.isOpen
      ? 'transparent transparent #606160'
      : '#606160 transparent transparent transparent'};
`

const DropdownContainer = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  opacity: ${(props: { isOpen: boolean }) => (props.isOpen ? '1' : '0')};
  max-height: ${(props: { isOpen: boolean }) => (props.isOpen ? '500px' : '0')};
  visibility: ${(props: { isOpen: boolean }) =>
    props.isOpen ? 'visible' : 'hidden '};
  transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
  border-radius: 8px;
  background-color: #3b3b3b;
  display: flex;
  flex-direction: column;
  z-index: 999;
  border: ${props => props.theme.topMenu.dropdownBorder};
  div:first-of-type {
    padding: 1.25rem 0.7rem 0.5rem 0.7rem;
  }
  span {
    width: 100%;
    border-radius: 8px;
    cursor: pointer;
    color: #ffffff;
    font-family: 'Open Sans';
    font-size: 0.688rem;
    letter-spacing: 0;
    line-height: 0.938rem;
    padding: 0.938rem;
    &:hover {
      background-color: #656565;
    }
  }
`

const UserDropdown: React.FC<{
  remountApp: () => void
}> = ({ remountApp }) => {
  const [isOpen, setOpen] = useState(false)
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null)
  const dispatch = useDispatch<(t: AsyncAction) => Promise<unknown>>()
  const syncDispatch = useDispatch()
  const history = useHistory()
  const {
    auth: { user }
  } = useSelector((state: RootState) => state)
  const dropdownContainerRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  useEffect(() => {
    const checkOutsideClick = (e: MouseEvent) => {
      const { clientX, clientY } = e
      const { current } = dropdownContainerRef
      if (current) {
        const { top, left, right, bottom } = current.getBoundingClientRect()
        if (
          clientX < left ||
          clientY < top ||
          clientX > right ||
          clientY > bottom
        ) {
          setOpen(false)
        }
      }
    }
    document.addEventListener('mousedown', checkOutsideClick)

    // returned function will be called on component unmount
    return () => {
      document.removeEventListener('mousedown', checkOutsideClick)
    }
  }, [])
  useEffect(() => {
    if (user && user.avatar) {
      fetch(user.avatar).then(res => {
        if (res.status === 200 && user.avatar) {
          setAvatarSrc(user.avatar)
        }
      })
    }
  }, [user])
  if (!user) {
    return null
  }

  return (
    <Container>
      <p>{t('signedIn')}</p>
      <div onClick={() => setOpen(state => !state)}>
        {avatarSrc && window.innerWidth > 768 && (
          <AvatarCircle src={avatarSrc} />
        )}
        <p>{user.name || user.email}</p>
        <Caret isOpen={isOpen} />
      </div>
      <DropdownContainer isOpen={isOpen} ref={dropdownContainerRef}>
        <div>
          <span
            onClick={() => dispatch(setLanguage('en')).then(() => remountApp())}
          >
            EN
          </span>
          <span
            onClick={() => dispatch(setLanguage('fi')).then(() => remountApp())}
          >
            FI
          </span>
        </div>
        <span
          onClick={() => {
            history.push('/change-password')
            setOpen(false)
          }}
        >
          {t('changePassword')}
        </span>
        <span
          onClick={() => {
            history.push('/connect-google')
            setOpen(false)
          }}
        >
          {t('connectGoogle')}
        </span>
        <span
          onClick={() => {
            history.push('/connect-azure')
            setOpen(false)
          }}
        >
          {t('connectAzure')}
        </span>
        <span
          onClick={() =>
            dispatch(logout()).then(() => syncDispatch(resetState()))
          }
        >
          {t('logOut')}
        </span>
        <ThemeSelector />
      </DropdownContainer>
    </Container>
  )
}

export default UserDropdown

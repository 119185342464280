import { encodeQueryParams } from 'util/url'
import ApiError from 'ApiError'

import 'unfetch/polyfill'

const ApiCall = (path: RequestInfo, method: string, body?: string | null) => {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }

  // For authentication
  const token = sessionStorage.getItem('API_TOKEN')
  if (token) {
    headers.Authorization = `Token ${token}`
  }

  return fetch(path, {
    headers,
    method,
    mode: 'cors',
    body: body || undefined
  }).then(response =>
    response[response.status === 204 ? 'text' : 'json']()
      .then(json => {
        if (!response.ok) {
          const Error = new ApiError(response, json)

          return Promise.reject(Error)
        }

        return json
      })
      .catch(error => Promise.reject(error))
  )
}

export function Get<T>(path: string, params= {}) {
  return ApiCall(path + encodeQueryParams(params), 'GET', null) as Promise<T>
}

export function Post<T>(path: string, body?: string | null) {
  return ApiCall(path, 'POST', body) as Promise<T>
}

export function Put<T>(path: string, body?: string | null) {
  return ApiCall(path, 'PUT', body) as Promise<T>
}

export function Patch<T>(path: string, body?: string | null) {
  return ApiCall(path, 'PATCH', body) as Promise<T>
}

export function Delete<T>(path: string) {
  return ApiCall(path, 'DELETE', null) as Promise<T>
}

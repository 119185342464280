import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import styled from 'styled-components/macro'
import 'react-toastify/dist/ReactToastify.css'
import { MainMenu, TopMenu } from 'components/menu'
import { useLocation, useHistory } from 'react-router-dom'
import { isGraphPage } from 'util/url'
import ActiveOrganizationPolling from 'components/ActiveOrganizationPolling'
import {
  getDarkTheme,
  getLightTheme,
  SYSTEM,
  LIGHT_THEME,
  DARK_THEME
} from 'theme'
import useDarkMode from 'util/use-dark-mode'

import { DefaultTheme, ThemeProvider } from 'styled-components'
import { RootState } from 'store/reducers'
import { userRead } from 'store/actions/auth'
import { useTranslation } from 'react-i18next'
import { getGraphSettings } from 'store/actions/graph'
import { AsyncAction } from 'redux-promise-middleware'
import { User } from 'store/reducers/auth'
import OrganizationHotLinks from 'components/menu/OrganizationHotLinks'
import LoginMessage from 'components/system-message/LoginMessage'

const AppContainer = styled.div`
  display: flex;
  background: ${(props: { isGraphPage: boolean; theme: DefaultTheme }) =>
    props.isGraphPage
      ? props.theme.graphPage.background
      : props.theme.common.background};
  transition: background 0.3s ease-in-out;
  overflow: hidden;
  color: ${props => props.theme.text.primary};
`

const Content = styled.div`
  height: 100vh;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const PageContainer = styled.div`
  position: relative;
  margin: auto;
`

const ContentWrapper = styled.div`
  height: calc(100vh - 70px);
  padding: 0.625rem
    ${(props: {
      isStrategyPage: boolean
      theme: DefaultTheme
      isRoadmapPage: boolean
    }) => (props.isStrategyPage ? '0' : '1.875rem')};
  overflow-y: ${props => (props.isStrategyPage ? 'none' : 'scroll')};
  @media screen and (max-width: 768px) {
    padding: 0.625rem 0.938rem;
    width: 100vw;
  }
  [class*='container'] [class*='menu'] {
    color: ${props => props.theme.text.dropdown};
  }
  ${props =>
    props.isRoadmapPage
      ? ''
      : `
  ::-webkit-scrollbar {
    width: 0.625rem;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #00000099;
    border-radius: 0.625rem;
    border: solid 3px transparent;
  }
  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 5px #00000099;
    border-radius: 0.625rem;
    border: solid 3px transparent;
  }
  `}
`

const LoginPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #333;
  width: 100vw;
  height: 100vh;
`

/**
 * The order of priority for selecting the theme:
 * 1. Valid query parameter, "light" or "dark"
 * 2. Browser preference, if given
 * 3. User's choice
 * @param selectedThemeId
 * @param prefersDark
 * @returns
 */
const getTheme = (selectedThemeId: string | null, prefersDark: boolean) => {
  switch (selectedThemeId) {
    case SYSTEM:
      return prefersDark ? getDarkTheme() : getLightTheme()
    case LIGHT_THEME:
      return getLightTheme()
    case DARK_THEME:
      return getDarkTheme()
    default:
      return getLightTheme()
  }
}

const Layout: React.FC<{
  children?: React.ReactNode | React.ReactNode[]
  onOrganizationChange: () => void
}> = ({ children, onOrganizationChange }) => {
  const {
    app: { activeTheme },
    graph: {
      settings: { mappings },
      settings,
      graphStyleOverride,
      isPending: isGraphPending
    },
    organization: { activeOrganization }
  } = useSelector((state: RootState) => state)
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch<
    (action: AsyncAction) => Promise<{ value: User }>
  >()
  const { i18n } = useTranslation()
  let selectedThemeId = activeTheme
  const queriedTheme = new URLSearchParams(window.location.search).get('theme')
  if (queriedTheme && [LIGHT_THEME, DARK_THEME].includes(queriedTheme)) {
    selectedThemeId = queriedTheme
  }
  const prefersDark = useDarkMode()
  const theme = getTheme(selectedThemeId, prefersDark)

  const apiToken = sessionStorage.getItem('API_TOKEN')
  useEffect(() => {
    if (sessionStorage.getItem('API_TOKEN')) {
      dispatch(userRead()).then(({ value: user }) => {
        if (user instanceof Error) {
          sessionStorage.removeItem('API_TOKEN')
          history.push('/login')
          return
        }
        if (user) {
          const { language, id, active_organization, status } = user
          if (language) {
            i18n.changeLanguage(language)
            moment.locale(language)
          }
          if (id && window.dataLayer) {
            window.dataLayer.push({
              userId: id
            })
            window.dataLayer.push({
              organizationId: active_organization
            })
            window.dataLayer.push({
              status
            })
          }
        }
      })
    }
  }, [apiToken]) // eslint-disable-line
  useEffect(() => {
    if (settings.organization === null && !isGraphPending) {
      dispatch(getGraphSettings())
      return
    }
    if (
      activeOrganization &&
      settings.organization !== null &&
      settings.organization !== activeOrganization &&
      !isGraphPending
    ) {
      dispatch(getGraphSettings())
    }
  }, [settings.organization, activeOrganization])
  const { user } = useSelector((state: RootState) => state.auth)
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false)
  if (location.pathname === '/login') {
    return <LoginPage>{children}</LoginPage>
  }
  return (
    <ThemeProvider theme={theme}>
      <AppContainer
        isGraphPage={isGraphPage(location, mappings) && !graphStyleOverride}
      >
        {user && (
          <MainMenu
            mobileMenuOpen={mobileMenuOpen}
            onMobileMenuClose={() => setMobileMenuOpen(false)}
            key={activeOrganization}
          />
        )}
        <ActiveOrganizationPolling onOrganizationChange={onOrganizationChange}>
          <Content>
            <TopMenu
              onMobileMenuOpen={() => setMobileMenuOpen(true)}
              onOrganizationChange={onOrganizationChange}
            />
            <ContentWrapper
              id="content-wrapper"
              isStrategyPage={location.pathname === '/'}
              isRoadmapPage={location.pathname === '/roadmap'}
            >
              <PageContainer>{children}</PageContainer>
            </ContentWrapper>
            <ToastContainer position="top-center" />
            <OrganizationHotLinks onOrganizationChange={onOrganizationChange} />
          </Content>
        </ActiveOrganizationPolling>
        <LoginMessage />
      </AppContainer>
    </ThemeProvider>
  )
}

export default Layout

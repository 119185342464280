import {
  GET_RAW_DATA,
  LIST_RAW_DATA,
  GET_RAW_DATA_TIMESPAN,
  GET_PERCENTAGE_VALUE,
  GET_DATA_MAP,
  SET_DATA_MAP_DATE_RANGE,
  TOGGLE_DATAMAP_TERM_OPEN,
  TOGGLE_DATAMAP_TERMS_OPEN_ALL
} from 'store/actions/rawdata'

import produce from 'immer'
import { RESET_STATE } from 'store/actions/reset'
import { AnyAction } from 'redux'
import { DataMap as DataMapType } from 'store/reducers/rawdata'

const urlParams = new URLSearchParams(window.location.search)

export type RawData = {
  created: string
  cumulative_value: number | null
  end_date: string
  id: number
  is_verified: boolean
  key: string
  modified: string
  organization: number
  percentage_value: number | null
  start_date: string
  term: number
  value: number
}

type DataMapLine = { [key: string]: { value: number; verified: boolean } }

export type DataMap = {
  [key: string]: DataMapLine & { children: DataMap }
}

type PercentageValues = {
  [key: number]: number | string
}

interface RawDataState {
  data: RawData | null
  dataMap: DataMap | null
  dataMapDateRange: {
    endMonth: number | string | null
    endYear: number | string | null
    rowLength: number | string | null
    startMonth: number | string | null
    startYear: number | string | null
  }
  list: {
    results: Array<RawData>
  }
  timespan: {
    start_date: string
    end_date: string
  }
  percentages: PercentageValues
  pendingPercentages: Array<number>
  openedDataMapTerms: Array<string>
  isPending: boolean
  error: Error | Array<Error> | null
  isDataMapPending: boolean
}

const initialState: RawDataState = {
  data: null,
  dataMap: null,
  dataMapDateRange: {
    endMonth: null,
    endYear: null,
    rowLength: null,
    startMonth: null,
    startYear: null
  },
  list: {
    results: []
  },
  timespan: {
    start_date: '',
    end_date: ''
  },
  percentages: {},
  pendingPercentages: [],
  openedDataMapTerms: (urlParams.get('opened-terms') || '').split(','),
  isPending: false,
  error: null,
  isDataMapPending: false
}

/**
 * Returns all of the terms in the given data map
 * @param dataMap The data map
 * @returns Array of terms in the given data map
 */
const getAllTerms = (dataMap: DataMapType | null): string[] => {
  if (!dataMap) {
    return []
  }

  let terms: string[] = []

  for (const term in dataMap) {
    terms.push(term)
    terms = terms.concat(getAllTerms(dataMap[term].children))
  }

  return terms
}

const rawData = (state = initialState, action: AnyAction) => {
  const { type, payload } = action

  switch (type) {
    case `${GET_RAW_DATA}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${GET_RAW_DATA}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${GET_RAW_DATA}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.data = payload
      })

    case `${LIST_RAW_DATA}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${LIST_RAW_DATA}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${LIST_RAW_DATA}_FULFILLED`:
      return produce(state, draft => {
        draft.list = payload
        draft.isPending = false
      })

    case `${GET_RAW_DATA_TIMESPAN}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${GET_RAW_DATA_TIMESPAN}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${GET_RAW_DATA_TIMESPAN}_FULFILLED`:
      return produce(state, draft => {
        draft.timespan = payload
        draft.isPending = false
      })

    case `${GET_PERCENTAGE_VALUE}_PENDING`:
      return produce(state, draft => {
        draft.percentages = {}
        draft.pendingPercentages = [
          ...draft.pendingPercentages,
          ...action.meta.ids
        ]
      })

    case `${GET_PERCENTAGE_VALUE}_FULFILLED`:
      return produce(state, draft => {
        draft.percentages = payload
      })

    case `${GET_DATA_MAP}_PENDING`:
      return produce(state, draft => {
        draft.isDataMapPending = true
      })

    case `${GET_DATA_MAP}_FULFILLED`:
      return produce(state, draft => {
        draft.dataMap = payload
        draft.isDataMapPending = false
      })

    case SET_DATA_MAP_DATE_RANGE:
      return produce(state, draft => {
        draft.dataMapDateRange = payload
      })

    case TOGGLE_DATAMAP_TERM_OPEN:
      return produce(state, draft => {
        if (draft.openedDataMapTerms.includes(action.payload)) {
          draft.openedDataMapTerms = draft.openedDataMapTerms.filter(
            term => term !== action.payload
          )
        } else {
          draft.openedDataMapTerms = [
            ...draft.openedDataMapTerms,
            action.payload
          ]
        }
      })

    case TOGGLE_DATAMAP_TERMS_OPEN_ALL:
      return produce(state, draft => {
        draft.openedDataMapTerms = action.payload ? [] : getAllTerms(state.dataMap)
      })

    case RESET_STATE:
      return initialState

    default:
      return state
  }
}

export default rawData

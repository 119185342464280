import React, { Component } from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import { Title } from 'components/blocks'
import { connect } from 'react-redux'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { resetErrorMessage } from 'store/actions/error'
import { listSystemMessages } from 'store/actions/systemMessage'
import { SystemMessageList } from 'components/system-message'
import styled from 'styled-components/macro'
import { RouteComponentProps, withRouter } from 'react-router'
import { RootState } from 'store/reducers'

const TitleArea = styled.div`
  display: flex;
  flex-direction: column;
  h1 {
    display: flex;
    align-items: center;
  }
  > div {
    margin-bottom: 1.25rem;
  }
`

type SystemMessagesReduxState = ReturnType<typeof mapStateToProps>
type SystemMessagesReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface SystemMessagesProps {
  t: TFunction
}

interface SystemMessageState {
  results: RootState['systemMessage']['list']['results']
}

class SystemMessages extends Component<
  SystemMessagesProps &
    SystemMessagesReduxState &
    SystemMessagesReduxDispatch &
    RouteComponentProps,
  SystemMessageState
> {
  constructor(
    props: SystemMessagesProps &
      SystemMessagesReduxState &
      SystemMessagesReduxDispatch &
      RouteComponentProps
  ) {
    super(props)
    this.state = {
      results: []
    }
  }
  async componentDidMount() {
    const { listSystemMessages: listSystemMessagesAction } = this.props
    await listSystemMessagesAction()
    const {
      systemMessage: {
        list: { results }
      }
    } = this.props
    this.setState({ results })
  }
  render() {
    const { t } = this.props
    const { results } = this.state
    return (
      <div className="App">
        <TitleArea>
          <Title>{t('systemMessages')} </Title>
        </TitleArea>
        <SystemMessageList data={results} />
      </div>
    )
  }
}

const mapStateToProps = ({ error, systemMessage, auth }: RootState) => ({
  error,
  systemMessage,
  user: auth.user
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      resetErrorMessage,
      listSystemMessages
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(SystemMessages)))

const mutationObserver = (
  targetNode: Node,
  handler: (value: string | null) => void
) => {
  const config = {
    attributes: true,
    characterData: true,
    childList: true
  }

  const callback: MutationCallback = mutationsList => {
    mutationsList.forEach(mutation => {
      handler(mutation.oldValue)
    })
  }

  const observer = new MutationObserver(callback)
  observer.observe(targetNode, config)
}

export default mutationObserver

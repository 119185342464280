import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import styled from 'styled-components/macro'
import Icon from 'components/Icon'

import { getGraphPathName } from 'util/format'
import { RootState } from 'store/reducers'

const LinkContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: ${(props: { menuState: string; active: boolean }) =>
    props.menuState === 'collapsed' && window.innerWidth > 768
      ? 'column'
      : 'row'};
  align-items: center;
  padding: ${props =>
    props.menuState === 'collapsed' && window.innerWidth > 768
      ? '0.625rem 0'
      : '1.375rem 0.938rem'};
  width: ${props =>
    props.menuState === 'collapsed' && window.innerWidth > 768
      ? '70px'
      : '180px'};
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  background: ${props =>
    props.active ? props.theme.navigation.activeItemBg : 'none'};
  div {
    background-color: ${props =>
      props.active ? '#FFD800' : props.theme.navigation.icon};
  }
  p {
    color: ${props => props.theme.text.primary};
    font-family: 'Open Sans';
    margin-left: ${(props: { menuState: string }) =>
      props.menuState === 'collapsed' && window.innerWidth > 768
        ? '0'
        : '1.063rem'};
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.875rem;
    letter-spacing: 0;
    line-height: 0.938rem;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0;
    flex-grow: 1;
  }
  &:hover {
    background-color: ${props => props.theme.navigation.itemHoverBg};
    svg {
      fill: ${props => (props.active ? '#ffd800' : '#6f6f6f')};
    }
  }
  .icon {
    min-width: 1.875rem;
    min-height: 1.875rem;
  }
  ${props =>
    props.active
      ? `
    svg {
      fill: #ffd800;
    }
  `
      : ''};
`

const MenuButton = ({
  icon,
  to,
  onClick,
  children,
  onHover
}: {
  icon: string
  to: string
  children: React.ReactNode | React.ReactNode[]
  onClick?: React.MouseEventHandler
  onHover?: React.MouseEventHandler
}) => {
  const {
    app: { menuState },
    graph: {
      settings: { mappings }
    }
  } = useSelector((state: RootState) => state)
  const history = useHistory()
  const checkActive = () => {
    let selected =
      location.pathname === to ||
      (to.length > 1 && location.pathname.startsWith(to))
    if (!selected) {
      selected = location.pathname === `/${to}`
    }
    if (!selected) {
      const mapping = (mappings || []).find(m => {
        const pathNames = m.children.map(c => getGraphPathName(c.view_title))
        return pathNames.includes(location.pathname.replace('/', ''))
      })
      if (mapping) {
        selected = mapping.redirect
          ? getGraphPathName(mapping.children[0].view_title) === to
          : getGraphPathName(mapping.view_title) === to
      }
    }
    return selected
  }
  return (
    <LinkContainer
      active={checkActive()}
      onMouseEnter={onHover}
      onClick={event => {
        if (onClick) onClick(event)
        history.push(to)
      }}
      menuState={menuState as string}
    >
      <Icon iconName={icon} size={30} />
      <p>{children}</p>
    </LinkContainer>
  )
}

export default MenuButton

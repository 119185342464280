import React, { Component, createRef } from 'react'
import styled from 'styled-components/macro'
import { Card, Title } from 'components/blocks'
import { TFunction, withTranslation } from 'react-i18next'
import { formatFloat, formatInt } from 'util/format'
import { Target } from 'store/reducers/target'

const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #33333355;
  z-index: 999;
  visibility: ${(props: { isOpen: boolean }) =>
    props.isOpen ? 'visible' : 'hidden'};
  pointer-events: ${props => (props.isOpen ? 'auto' : 'none')};
  > div {
    transform: ${props => (props.isOpen ? '0' : 'translateY(-1.25rem)')};
    opacity: ${props => (props.isOpen ? '1' : '0')};
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
`
const ModalContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  padding: 0.625rem;
  > div {
    padding: 0.938rem;
    h1 {
      margin-top: 0;
    }
    p {
      color: #626262;
      font-family: 'Open Sans';
      margin-top: 0;
      margin-bottom: 0.875rem;
      font-size: 0.813rem;
      letter-spacing: 0;
      line-height: 1.125rem;
      &.additional {
        margin-bottom: 5px;
      }
    }
    > div strong {
      color: #626262;
      font-family: 'Open Sans';
      margin-top: 0;
      font-size: 0.813rem;
      letter-spacing: 0;
      line-height: 1.125rem;
    }
  }
`
const CloseButton = styled.span`
  color: #6a6a6a;
  cursor: pointer;
  position: absolute;
  font-size: 0.938rem;
  right: 1.875rem;
  top: 1.875rem;
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid #6a6a6a;
  border-radius: 100%;
  text-align: center;
  line-height: 1.25;
  &:hover {
    color: #aaa;
    border-color: #aaa;
  }
`

interface TargetModalProps {
  target: Target
  isOpen: boolean;
  onClose: () => void
  t: TFunction
}

class TargetModal extends Component<TargetModalProps> {
  modalContainerRef: React.RefObject<HTMLDivElement>
  constructor(props: TargetModalProps) {
    super(props)
    this.modalContainerRef = createRef()
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.checkOutboundClick)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.checkOutboundClick)
  }

  checkOutboundClick = (e: MouseEvent) => {
    const { current } = this.modalContainerRef
    const { onClose } = this.props
    if (current) {
      const { clientX, clientY } = e
      const { top, left, right, bottom } = current.getBoundingClientRect()
      if (
        clientX < left ||
        clientY < top ||
        clientX > right ||
        clientY > bottom
      ) {
        onClose()
      }
    }
  }

  getPercentDone = (target: Target) => {
    if (target.reversed_target) {
      return `${((target.target_value / target.current_value) * 100).toFixed(2)}
      %`
    }
    return `${((target.current_value / target.target_value) * 100).toFixed(2)}
    %`
  }

  getCompareDone = (target: Target) => {
    if (target.reversed_target) {
      return `${(
        ((target.compare_value || target.target_value) / target.current_value) *
        100
      ).toFixed(2)}
      %`
    }
    return `${(
      (target.current_value / (target.compare_value || target.target_value)) *
      100
    ).toFixed(2)}
    %`
  }

  render() {
    const { target, isOpen, onClose, t } = this.props
    return (
      <ModalContainer isOpen={isOpen}>
        <ModalContent ref={this.modalContainerRef}>
          <Card noShadow>
            {target ? (
              <>
                <Title>{target.title}</Title>
                <p>
                  {t('current_value')}:{' '}
                  <strong>
                    {target.value_numeric_type === 'int'
                      ? formatInt(target.current_value)
                      : formatFloat(target.current_value)}{' '}
                    {target.measurement_type_prefix}
                  </strong>
                </p>
                <p>
                  {t('target_value')}:{' '}
                  <strong>
                    {target.value_numeric_type === 'int'
                      ? formatInt(target.target_value)
                      : formatFloat(target.target_value)}{' '}
                    {target.measurement_type_prefix}
                  </strong>
                </p>
                <p>
                  {t('compare_value')}:{' '}
                  <strong>
                    {target.value_numeric_type === 'int'
                      ? formatInt(target.compare_value || target.target_value)
                      : formatFloat(
                          target.compare_value || target.target_value
                        )}{' '}
                    {target.measurement_type_prefix}
                  </strong>
                </p>
                <p>
                  {t('percent_done')}:{' '}
                  <strong>{this.getPercentDone(target)}</strong>
                </p>
                <p>
                  {t('percent_from_compare')}:{' '}
                  <strong>{this.getCompareDone(target)}</strong>
                </p>
                <p>
                  {t('formula')}: <strong>{target.formula_str}</strong>
                </p>
                <p>
                  {t('target_formula')}:{' '}
                  <strong>{target.target_formula_str}</strong>
                </p>
                <p>
                  {t('compare_formula')}:{' '}
                  <strong>{target.compare_formula_str}</strong>
                </p>
                {target.additional_information ? (
                  <>
                    <p className="additional">{t('additional_information')}:</p>
                    <div>
                      <strong>{target.additional_information}</strong>
                    </div>
                  </>
                ) : null}
              </>
            ) : null}
          </Card>
          <CloseButton onClick={() => onClose()}>&times;</CloseButton>
        </ModalContent>
      </ModalContainer>
    )
  }
}

export default withTranslation()(TargetModal)

import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { listTermOfClassification } from 'store/actions/term'
import { bindActionCreators } from 'redux'
import styled from 'styled-components/macro'
import Tree, {
  ReactD3TreeItem,
  ReactD3TreeProps,
  ReactD3TreeTranslate
} from 'react-d3-tree'
import { NodeLabel, LogicTools } from 'components/logic'
import LoadingIndicator from 'components/blocks/LoadingIndicator'
import { saveTreeState } from 'store/actions/app'
import { RootState } from 'store/reducers'
import { AppDispatch } from 'store/store'
import { TermOfClassification } from 'store/reducers/term'

const UPDATE_INTERVAL = 1500

const Container = styled.div`
  width: 100%;
  height: calc(
    100vh -
      ${(props: { treeContainerTop?: number }) => props.treeContainerTop || 0}px
  );
`

interface LogicBaseProps {
  dateTimeline: Array<string>
  isYTD: boolean
}

interface LogicBaseState {
  modifiedTerms: Array<ReactD3TreeItem>
  translate: {
    x: number
    y: number
  } | null
  remountD3Tree: boolean
  scale: number | null
}

type LogicBaseReduxState = ReturnType<typeof mapStateToProps>
type LogicBaseReduxDispatch = ReturnType<typeof mapDispatchToProps>

class LogicBase extends Component<
  LogicBaseProps & LogicBaseReduxState & LogicBaseReduxDispatch,
  LogicBaseState
> {
  treeContainer: React.RefObject<HTMLDivElement>
  treeRef: React.RefObject<
    Component<
      ReactD3TreeProps,
      { data: unknown; d3: { translate: ReactD3TreeTranslate } }
    >
  >

  constructor(
    props: LogicBaseProps & LogicBaseReduxState & LogicBaseReduxDispatch
  ) {
    super(props)
    this.treeContainer = createRef<HTMLDivElement>()
    this.treeRef = createRef()
    this.state = {
      modifiedTerms: [],
      translate: null,
      remountD3Tree: false,
      scale: null
    }
  }

  async componentDidMount() {
    const {
      listTermOfClassification: listTermOfClassificationAction
    } = this.props
    await listTermOfClassificationAction(true)
    this.generateTree()
    if (!this.treeContainer.current) {
      return
    }
    const dimensions = this.treeContainer.current.getBoundingClientRect()
    const { treeState } = this.props
    this.setState({
      translate: {
        x: treeState ? treeState.translate.x : dimensions.width / 2 - 273 / 2,
        y: treeState ? treeState.translate.y : dimensions.height / 2
      },
      scale: treeState ? treeState.scale : 1
    })
    /* if (treeState) {
      this.initializeTree()
    } */
  }

  componentDidUpdate() {
    const { remountD3Tree } = this.state
    if (remountD3Tree) {
      this.resetRemountD3Tree()
    }
  }

  initializeTree = () => {
    const { treeState } = this.props
    const { current } = this.treeRef
    if (current) {
      current.setState({
        d3: {
          ...current.state.d3,
          translate: treeState.translate
        }
      })
    }
  }

  saveTreeState = () => {
    const { current } = this.treeRef
    const { current: treeContainer } = this.treeContainer
    const { saveTreeState: saveTreeStateAction, treeUpdated } = this.props
    if (current && treeContainer) {
      const {
        state: { data }
      } = current
      const translatedElement = treeContainer.querySelector<SVGGElement>(
        'svg > g'
      )
      const translateMatrix = translatedElement
        ? translatedElement.transform.baseVal[0].matrix
        : null
      const scale = translatedElement
        ? translatedElement.transform.baseVal[1].matrix.a
        : 1
      const translate = {
        x: translateMatrix?.e,
        y: translateMatrix?.f
      }
      if (Date.now() - treeUpdated > UPDATE_INTERVAL) {
        saveTreeStateAction({
          translate,
          scale,
          data
        })
      }
    }
  }

  resetRemountD3Tree = () => this.setState({ remountD3Tree: false })

  activateTool = (tool: string) => {
    switch (tool) {
      case 'expand': {
        const dimensions = this.treeContainer.current?.getBoundingClientRect()
        this.generateTree(false, true)
        this.setState({
          translate: { x: 100, y: (dimensions?.height || 0) / 2 + 1 },
          scale: 0.5
        })
        break
      }
      case 'collapse': {
        const dimensions = this.treeContainer.current?.getBoundingClientRect()
        this.generateTree(true, true)
        this.setState({
          translate: { x: 100, y: (dimensions?.height || 0) / 2 - 1 },
          scale: 1.0
        })
        break
      }
      case 'center': {
        this.setState({ remountD3Tree: true })
        break
      }
      default:
        break
    }
  }

  generateTree = (collapsed = true, omitTreeState = false) => {
    const {
      term: {
        list: { results }
      },
      treeState
    } = this.props
    if (!omitTreeState && treeState) {
      this.setState({ modifiedTerms: treeState.data })
      return
    }
    const modifiedTerms = (results || []).filter(
      r => r.parent === null && !r.title.endsWith('%')
    )
    const rootTreeItem: ReactD3TreeItem = {
      name: 'root',
      _collapsed: collapsed,
      attributes: { rootTerms: modifiedTerms },
      nodeSvgShape: {
        shape: 'circle',
        shapeProps: {
          r: 3,
          fill: 'transparent',
          stroke: 'none'
        }
      },
      children: (results || [])
        .filter(
          tc =>
            modifiedTerms.map(t => t.id).includes(tc.parent) &&
            !tc.title.endsWith('%')
        )
        .map(tc => this.generateTreeItem(tc, collapsed))
    }
    this.setState({
      modifiedTerms: [rootTreeItem]
    })
  }

  generateTreeItem = (t: TermOfClassification, collapsed = true) => {
    const {
      term: {
        list: { results }
      }
    } = this.props
    const treeItem: ReactD3TreeItem = {
      name: t.title,
      _collapsed: collapsed,
      attributes: t,
      nodeSvgShape: {
        shape: 'circle',
        shapeProps: {
          r: 3,
          fill: 'transparent',
          stroke: 'none'
        }
      },
      children: results
        .filter(
          tc =>
            tc.parent === t.id ||
            (tc.additional_parents &&
              tc.additional_parents.length > 0 &&
              tc.additional_parents.includes(t.id) &&
              tc.id !== t.id)
        )
        .map(tc => this.generateTreeItem(tc, collapsed))
    }
    return treeItem
  }

  getTreeContainerTop = () => {
    const { current } = this.treeContainer
    if (current) {
      const { top } = current.getBoundingClientRect()
      return top + 50
    }
    return 100
  }

  translateToNode = (translate: { x: number; y: number }) => {
    const { current } = this.treeContainer
    if (current) {
      const { width, height } = current.getBoundingClientRect()
      const translatedElement = current.querySelector<SVGGElement>('svg > g')
      const scale = translatedElement
        ? translatedElement.transform.baseVal[1].matrix.a
        : 1
      this.setState({
        scale,
        translate: {
          x: width / 2 - translate.x * scale,
          y: height / 2 - translate.y * scale
        }
      })
    }
  }

  render() {
    const {
      dateTimeline,
      term: { isPending },
      isYTD
    } = this.props
    const { modifiedTerms, translate, remountD3Tree, scale } = this.state
    if (isPending) {
      return (
        <Container>
          <LoadingIndicator />
        </Container>
      )
    }
    return (
      <Container
        ref={this.treeContainer}
        treeContainerTop={this.getTreeContainerTop()}
        onClick={this.saveTreeState}
      >
        {!remountD3Tree && modifiedTerms.length > 0 && (
          <Tree
            allowForeignObjects
            nodeLabelComponent={{
              render: (
                <NodeLabel
                  dateTimeline={dateTimeline}
                  onTranslate={this.translateToNode}
                  isYTD={isYTD}
                />
              ),
              foreignObjectWrapper: {
                width: '300px',
                y: '-1.563rem'
              }
            }}
            styles={{
              links: {
                stroke: '#B9C5C8',
                strokeWidth: 2
              }
            }}
            nodeSize={{ x: 300, y: 200 }}
            translate={{ ...translate }}
            zoom={scale || 1}
            data={modifiedTerms}
            separation={{ nonSiblings: 0.5, siblings: 0.5 }}
            ref={this.treeRef}
          />
        )}
        <LogicTools onToolActivate={this.activateTool} />
      </Container>
    )
  }
}

const mapStateToProps = ({
  term,
  app: { treeState, treeUpdated }
}: RootState) => ({
  term,
  treeState,
  treeUpdated
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      listTermOfClassification,
      saveTreeState
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(LogicBase)

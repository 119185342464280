import React, { Component } from 'react'
import moment from 'moment'
import styled, { withTheme, DefaultTheme } from 'styled-components/macro'
import {
  Number,
  ChartArea,
  OrganizationDateSelector,
  TimeGroupingSelector
} from 'components/graph'
import { connect } from 'react-redux'
import { setGraphStyleOverride } from 'store/actions/graph'
import { getGraphPathName } from 'util/format'
import { TFunction, withTranslation } from 'react-i18next'

import { Card } from 'components/blocks'
import timeLineFromDatesAndOrganization, {
  ISO_FORMAT_DATE,
  MONTH_FORMAT
} from 'util/dates'
import { bindActionCreators } from 'redux'
import { debounce, DebouncedFunc } from 'lodash'
import LoadingIndicator from 'components/blocks/LoadingIndicator'
import { DARK_THEME } from 'theme'
import { RootState } from 'store/reducers'
import { TermOfClassification } from 'store/reducers/term'
import { Years } from 'components/notification/DateSelector'
import { AppDispatch } from 'store/store'

const GRAPH_MIN_HEIGHT = 700

const Container = styled.div`
  left: -1.875rem;
  right: -1.875rem;
  top: -0.938rem;
  padding: 1.25rem;
  min-height: calc(100vh - 60px);
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`
const TitleArea = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  h1 {
    margin-top: 0;
    margin-right: 1.25rem;
  }
`

const NumbersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 1.563rem;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
`
const DateSelectorContainer = styled.div`
  margin-bottom: 1.25rem;
  min-width: 240px;
  p {
    margin: 0;
    color: #b9c5c8;
    font-family: 'Open Sans';
    font-size: 0.813rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.125rem;
    margin-bottom: 6px;
  }
`

const TimeGroupingSelectorContainer = styled.div`
  margin-bottom: 1.25rem;
  min-width: 240px;
  p {
    margin: 0;
    color: #b9c5c8;
    font-family: 'Open Sans';
    font-size: 0.813rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.125rem;
    margin-bottom: 6px;
  }
`

const GraphsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 50%;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
`
const CardContainer = styled.div`
  margin-top: 35px;
  padding: 0.938rem;
`
const CardContent = styled.div`
  padding: 1.563rem;
  min-height: ${GRAPH_MIN_HEIGHT}px;
`

interface AdditionalDataProps {
  dataKey: string
  onDateChange: (
    dates: Years | null,
    overrideDates?: { start: string; end: string }
  ) => void
  t: TFunction
  theme: DefaultTheme
}

interface AdditionalDataState {
  dates: {
    [key: string]: Array<string>
  }
  termsToShow: Array<number>
}

class AdditionalData extends Component<
  AdditionalDataProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>,
  AdditionalDataState
> {
  graphRefs: { [key: string]: HTMLDivElement | null }
  debouncedTermUpdate: DebouncedFunc<() => void>

  constructor(
    props: AdditionalDataProps &
      ReturnType<typeof mapStateToProps> &
      ReturnType<typeof mapDispatchToProps>
  ) {
    super(props)
    this.graphRefs = {}
    this.debouncedTermUpdate = debounce(this.updateTermsShown, 500)
    this.state = {
      dates: {
        YTD: []
      },
      termsToShow: []
    }
  }

  componentDidMount() {
    const { setGraphStyleOverride: setGraphStyleOverrideAction } = this.props
    setGraphStyleOverrideAction(true)
    const { dates } = this.state
    document
      .getElementById('content-wrapper')
      ?.addEventListener('scroll', this.debouncedTermUpdate)
    this.updateTermsShown()

    const { onDateChange, dateSelections } = this.props
    if (
      dateSelections?.numbers?.rangeValue?.from &&
      dateSelections?.numbers?.rangeValue?.to
    ) {
      const {
        numbers: {
          rangeValue: { from, to }
        }
      } = dateSelections
      onDateChange(null, {
        start: moment(`${from.year}-${from.month}`, MONTH_FORMAT)
          .startOf('month')
          .format(ISO_FORMAT_DATE),
        end: moment(`${to.year}-${to.month}`, MONTH_FORMAT)
          .endOf('month')
          .format(ISO_FORMAT_DATE)
      })
      return
    }
    onDateChange(dates)
  }

  componentWillUnmount() {
    const { setGraphStyleOverride: setGraphStyleOverrideAction } = this.props
    setGraphStyleOverrideAction(false)
    document
      .getElementById('content-wrapper')
      ?.removeEventListener('scroll', this.debouncedTermUpdate)
  }

  updateTermsShown = () => {
    const termsToShow: Array<number> = []
    const { termsToShow: termsToShowInState } = this.state
    Object.keys(this.graphRefs).forEach(termId => {
      const graphRef = this.graphRefs[termId]
      if (!graphRef) {
        return
      }
      const { top, bottom } = graphRef.getBoundingClientRect()
      if (top - GRAPH_MIN_HEIGHT * 2 - 35 < 0 && bottom > 0) {
        termsToShow.push(parseInt(termId, 10))
      }
    })
    if (
      termsToShow.length === termsToShowInState.length &&
      termsToShowInState.every(t => termsToShow.includes(t))
    ) {
      return
    }
    this.setState({ termsToShow })
  }

  getTimeline = () => {
    const { dates } = this.state
    const {
      organization: { activeOrganization, data },
      rawData: { timespan }
    } = this.props
    return timeLineFromDatesAndOrganization(
      dates,
      data,
      activeOrganization,
      null,
      timespan.end_date
    )
  }

  getUsedData = () => {
    const {
      rawData: {
        list: { results: data }
      }
    } = this.props
    const { start, stop } = this.getTimeline()
    return data.filter(
      d2 =>
        // ( same year as start with valid month
        ((moment(d2.start_date).month() >= start.month() &&
          moment(d2.start_date).year() === start.year()) ||
          // OR later year than start )
          moment(d2.start_date).year() > start.year()) &&
        // AND ( same year as stop with valid month
        ((moment(d2.end_date).month() <= stop.month() &&
          moment(d2.end_date).year() === stop.year()) ||
          // OR earlier year than stop )
          moment(d2.end_date).year() < stop.year())
    )
  }

  render() {
    const { dates, termsToShow } = this.state
    const {
      settings,
      dataKey,
      t,
      rawData: {
        list: { results },
        timespan
      },
      onDateChange,
      theme
    } = this.props
    let mapping = settings.mappings.find(
      m => m.view_title && getGraphPathName(m.view_title) === dataKey
    )

    if (!mapping) {
      settings.mappings.forEach(m => {
        if (mapping) {
          return
        }

        mapping = m.children.find(
          c => c.view_title && getGraphPathName(c.view_title) === dataKey
        )
      })
    }

    if (!mapping) {
      return null
    }

    return (
      <Container>
        <TitleArea>
          <DateSelectorContainer>
            <p>{t('numericalTimeline')}</p>
            <OrganizationDateSelector
              data={results}
              keyToDate="start_date"
              defaultValue="YTD"
              location="numbers"
              onDateChange={d => {
                this.setState({
                  dates: d
                })
                onDateChange(d)
              }}
            />
          </DateSelectorContainer>
          <TimeGroupingSelectorContainer>
            <p>{t('timeGroupingSelectionText')}</p>
            <TimeGroupingSelector />
          </TimeGroupingSelectorContainer>
        </TitleArea>
        <NumbersContainer>
          {mapping.terms_used.map(term => (
            <Number
              key={`${term.id}_${this.getUsedData().length}`}
              term={term}
              properties={mapping?.graph_properties}
              data={this.getUsedData()}
              dates={this.getTimeline()}
            />
          ))}
        </NumbersContainer>
        <GraphsContainer>
          {mapping.terms_used
            // filter radar-graphs out
            // eslint-disable-next-line
            .filter(term => {
              mapping?.graph_properties.target.type !== 'radar'
            })
            .map(term => (
              <CardContainer
                key={term.id}
                ref={ref => {
                  this.graphRefs[term.id] = ref
                }}
              >
                <Card>
                  <CardContent>
                    {termsToShow.includes(term.id) && mapping ? (
                      <ChartArea
                        data={results}
                        keys={mapping?.terms_used as TermOfClassification[]}
                        graphProperties={mapping.graph_properties}
                        dates={dates}
                        activeOnStart={term}
                        darkNumbers={theme.id !== DARK_THEME}
                        defaultYTDEndDate={timespan.end_date}
                        theme={theme}
                      />
                    ) : (
                      <LoadingIndicator />
                    )}
                  </CardContent>
                </Card>
              </CardContainer>
            ))}
        </GraphsContainer>
      </Container>
    )
  }
}

const mapStateToProps = ({
  graph,
  rawData,
  organization,
  monthselector: { selections }
}: RootState) => ({
  settings: graph.settings,
  organization,
  rawData,
  dateSelections: selections
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      setGraphStyleOverride
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withTheme(AdditionalData)))

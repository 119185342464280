import React, { Component, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Card, Title } from 'components/blocks'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { convertToRaw, EditorState } from 'draft-js'
import { uploadNotificationFiles } from 'store/actions/notification'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import toHtml from 'draftjs-to-html'
import ApiError from 'ApiError'
import { toast } from 'react-toastify'
import Select from 'react-select'
import { RootState } from 'store/reducers'
import { TFunction } from 'i18next'
import { Notification } from 'store/reducers/notification'
import { AppDispatch } from 'store/store'
import { Get, Post } from 'api'
import { OrganizationUser } from 'store/reducers/organization'

const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #33333366;
  visibility: ${(props: { isOpen: boolean }) =>
    props.isOpen ? 'visible' : 'hidden'};
  pointer-events: ${props => (props.isOpen ? 'all' : 'none')};
  display: flex;
  overflow-y: auto;
  > div {
    position: relative;
    margin: auto;
    box-shadow: none;
    width: 100%;
    max-width: 600px;
    padding: 0.938rem;
    opacity: ${props => (props.isOpen ? '1' : '0')};
    transform: translateY(${props => (props.isOpen ? '0' : '-50px')});
    transition: transform, opacity 0.3s ease-in-out;
    > h1 {
      margin-top: 0;
    }
    > input {
      width: 100%;
      margin-bottom: 0.938rem;
      padding: 0.625rem;
      border-radius: 5px;
      border: 1px solid #dedede;
      &[type='file'] {
        margin-bottom: 0;
      }
    }
    .editor-wrapper {
      border: 1px solid #ddd;
      min-height: 300px;
      padding: 0.625rem;
      margin-bottom: 0.938rem;
    }
  }
`
const CloseButton = styled.span`
  position: absolute;
  right: 0.938rem;
  top: 0.938rem;
  font-size: 1.875rem;
  cursor: pointer;
`
const Button = styled.div`
  width: 100%;
  cursor: pointer;
  border-radius: 3px;
  background: #b9c5c8;
  box-shadow: 2px 2px 0 0 #93a4a8;
  padding: 0.938rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.875rem;
  color: white;
  user-select: none;
  margin-top: 0.938rem;
`
const AddButton = styled.div`
  width: 100%;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid #b9c5c8;
  background: transparent;
  padding: 0.938rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.875rem;
  color: #b9c5c8;
  user-select: none;
  margin-top: 0.938rem;
`

export interface FileObject {
  id: number
  file: File | null
  file_title?: string
}

export const Files = ({
  onChange
}: {
  onChange: (files: FileObject[]) => void
}) => {
  const { t } = useTranslation()
  const [files, setFiles] = useState<FileObject[]>([])
  const onFileChange = (id: number, value: File) => {
    const newFiles = files.map(f => {
      const newFile = { ...f }
      if (newFile.id === id) {
        newFile.file = value
      }
      return newFile
    })
    setFiles(newFiles)
    onChange(newFiles)
  }

  return (
    <>
      {files.map(file => (
        <input
          key={file.id}
          type="file"
          onChange={({ target: { files: fs } }) =>
            fs !== null ? onFileChange(file.id, fs[0]) : null
          }
        />
      ))}
      <AddButton
        onClick={() => setFiles(f => [...f, { file: null, id: Date.now() }])}
      >
        + {t('add-file')}
      </AddButton>
    </>
  )
}

type CreateNotificationModalReduxState = ReturnType<typeof mapStateToProps>
type CreateNotificationModalReduxDispatch = ReturnType<
  typeof mapDispatchToProps
>

interface CreateNotificationModalProps {
  isOpen: boolean
  t: TFunction
  onClose: (update: boolean) => void
}

interface CreateNotificationModalState {
  editorState: EditorState
  title: string
  files: FileObject[]
  options: { label: string; value: number }[]
  notificationRecipients: Notification['notification_recipients']
  sendNotifications: boolean
}

class CreateNotificationModal extends Component<
  CreateNotificationModalProps &
    CreateNotificationModalReduxState &
    CreateNotificationModalReduxDispatch,
  CreateNotificationModalState
> {
  constructor(
    props: CreateNotificationModalProps &
      CreateNotificationModalReduxState &
      CreateNotificationModalReduxDispatch
  ) {
    super(props)
    this.state = {
      editorState: EditorState.createEmpty(),
      title: '',
      files: [],
      options: [],
      notificationRecipients: [],
      sendNotifications: false
    }
  }

  componentDidUpdate(prevProps: CreateNotificationModalProps) {
    const { isOpen } = this.props
    if (prevProps.isOpen === false && isOpen === true) {
      this.resetDetails()
    }
  }

  onEditorStateChange = (state: EditorState) => {
    this.setState({ editorState: state })
  }

  resetDetails = () => {
    this.setState({
      editorState: EditorState.createEmpty(),
      title: ''
    })
  }

  createNotification = async () => {
    const {
      title,
      editorState,
      files,
      sendNotifications,
      notificationRecipients
    } = this.state
    const filesToUpload = files.filter(f => f.file).map(f => f.file)
    const {
      uploadNotificationFiles: uploadNotificationFilesAction,
      t,
      onClose
    } = this.props
    const raw = toHtml(convertToRaw(editorState.getCurrentContent()))
    const url = `/api/notification/`
    const data = await Post<RootState['notification']['created']>(
      url,
      JSON.stringify({
        title,
        files: [],
        text_value: raw,
        send_notifications: sendNotifications,
        notification_recipients: notificationRecipients
      })
    )
    if (data instanceof ApiError && data.messages) {
      const messages = Object.keys(data.messages).map(
        m => `${t(m)}: ${data.messages ? data.messages[m][0] : ''}\n`
      )
      messages.forEach(m => toast.error(m))
      return
    }

    if (data) {
      await uploadNotificationFilesAction(data.id, filesToUpload as File[])
    }

    toast.success(t('notification-created'))
    onClose(true)
  }

  onFilesChange = (files: FileObject[]) => this.setState({ files })

  sendNotifications = async (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const target = event.target as HTMLInputElement
    if (target && target.checked) {
      this.setState({ sendNotifications: target.checked })
    }
    const url = '/api/user/get_organization_users/'
    const data = await Get<{ users: OrganizationUser[] }>(url)
    const options: { value: number; label: string }[] = []
    data.users.forEach(user => {
      options.push({ value: user.id, label: user.username })
    })
    this.setState({ options })
  }

  handleChange = (value: Array<{ label: string; value: number }>) => {
    if (value && value.length > 0) {
      const notificationRecipients: Array<number> = []
      value.forEach(user => {
        notificationRecipients.push(user.value)
      })
      this.setState({ notificationRecipients })
    }
  }

  render() {
    const { editorState, title, sendNotifications, options } = this.state
    const { isOpen, t, onClose } = this.props
    let userSelection: string | React.ReactNode = ''
    if (sendNotifications) {
      userSelection = (
        <Select
          isMulti
          onChange={this.handleChange}
          options={options}
          placeholder="Notification recipients"
        />
      )
    }
    return (
      <ModalContainer isOpen={isOpen}>
        <Card>
          <CloseButton onClick={() => onClose(true)}>&times;</CloseButton>
          <Title>{t('create-notification')}</Title>
          <input
            value={title}
            onChange={({ target: { value } }) =>
              this.setState({ title: value })
            }
            placeholder={t('write-notification-title')}
          />
          <Editor
            editorState={editorState}
            editorClassName="editor-wrapper"
            onEditorStateChange={this.onEditorStateChange}
            editorStyle={{
              maxHeight: 200
            }}
            toolbar={{
              options: [
                'inline',
                'blockType',
                'fontSize',
                'list',
                'textAlign',
                'link',
                'history'
              ]
            }}
          />
          <input
            id="send-notifications"
            onClick={event => this.sendNotifications(event)}
            type="checkbox"
            style={{
              display: 'inline-block',
              marginRight: '15px',
              width: 'auto'
            }}
          />
          <label
            style={{ display: 'inline-block' }}
            htmlFor="send-notifications"
          >
            {t('send-notification')}
          </label>
          {userSelection}
          <Files onChange={this.onFilesChange} />
          <Button onClick={this.createNotification}>{t('create')}</Button>
        </Card>
      </ModalContainer>
    )
  }
}

const mapStateToProps = ({ organization: { users: data } }: RootState) => ({
  data
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      uploadNotificationFiles
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CreateNotificationModal))

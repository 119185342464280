import React, { Component, MouseEvent } from 'react'
import Icon from 'components/Icon'
import { TFunction, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Popup from 'reactjs-popup'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { deleteRoadmap } from 'store/actions/roadmap'
import { triggerRefresh } from 'store/actions/task'
import { RoadmapType } from 'store/reducers/roadmap'

type RemoveRoadmapModalReduxState = ReturnType<typeof mapDispatchToProps>

interface RemoveRoadmapModalProps {
  t: TFunction
  roadmap: RoadmapType
}

class RemoveRoadmapModal extends Component<
  RemoveRoadmapModalProps & RemoveRoadmapModalReduxState
> {
  deleteRoadmap = async (close: () => void) => {
    const {
      deleteRoadmap: deleteRoadmapAction,
      triggerRefresh: triggerRefreshAction,
      roadmap: { id }
    } = this.props
    await deleteRoadmapAction(id)
    triggerRefreshAction(true)
    close()
  }

  render() {
    const { t } = this.props
    return (
      <Popup
        position="top center"
        modal
        nested
        trigger={
          <Icon iconName="trash" size={14} color="rgba(206, 32, 51, 0.6)" />
        }
      >
        {(close: {
          (): void
          (event: MouseEvent<HTMLButtonElement, MouseEvent>): void
        }) => (
          <div className="modal">
            <button type="button" className="close" onClick={close}>
              &times;
            </button>
            <div className="header">{t('remove-roadmap')} </div>
            <div className="content">
              <p>{t('delete-roadmap-disclaimer')}</p>
            </div>
            <div className="actions">
              <button
                type="button"
                className="button close"
                onClick={() => {
                  close()
                }}
              >
                {t('Cancel')}
              </button>

              <button
                type="button"
                className="button"
                onClick={() => this.deleteRoadmap(close)}
              >
                {t('remove-roadmap')}
              </button>
            </div>
          </div>
        )}
      </Popup>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      deleteRoadmap,
      triggerRefresh
    },
    dispatch
  )

export default withTranslation()(
  connect(null, mapDispatchToProps)(RemoveRoadmapModal)
)

import React, { Component, MouseEvent } from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Popup from 'reactjs-popup'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { triggerRefresh } from 'store/actions/task'
import { deleteMaintaskRow } from 'store/actions/maintask'
import { MainTaskType } from 'store/reducers/maintask'

type RemoveMainTaskRowModalReduxState = ReturnType<typeof mapDispatchToProps>

interface RemoveMainTaskRowModalProps {
  t: TFunction,
  maintask: MainTaskType;
  rowIndex: number,
  trigger: JSX.Element;
}

export class RemoveMainTaskRowModal extends Component<RemoveMainTaskRowModalProps & RemoveMainTaskRowModalReduxState> {
  deleteRow = async (close: () => void, rowIndex: number) => {
    const {
      maintask,
      deleteMaintaskRow: deleteMaintaskRowAction,
      triggerRefresh: triggerRefreshAction
    } = this.props
    await deleteMaintaskRowAction(maintask.id, rowIndex)
    triggerRefreshAction(true)
    close()
  }

  render() {
    const { t, rowIndex, trigger } = this.props
    return (
      <Popup position="top center" modal nested trigger={trigger}>
        {(close: { (): void; (event: MouseEvent<HTMLButtonElement,MouseEvent>): void }) => (
          <div className="modal">
            <button type="button" className="close" onClick={close}>
              &times;
            </button>
            <div className="header">{t('delete-maintask-row')} </div>
            <div className="content">
              <p>{t('delete-maintask-row-disclaimer')}</p>
            </div>
            <div className="actions">
              <button
                type="button"
                className="button close"
                onClick={() => {
                  close()
                }}
              >
                {t('Cancel')}
              </button>

              <button
                type="button"
                className="button"
                onClick={() => this.deleteRow(close, rowIndex)}
              >
                {t('delete')}
              </button>
            </div>
          </div>
        )}
      </Popup>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      deleteMaintaskRow,
      triggerRefresh
    },
    dispatch
  )

export default withTranslation()(
  connect(null, mapDispatchToProps)(RemoveMainTaskRowModal)
)

import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getActiveOrganization,
  activateOrganization
} from 'store/actions/organization'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { RootState } from 'store/reducers'

const POLL_INTERVAL_IN_SECONDS = 15

/**
 * See https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * @param {*} callback
 * @param {*} delay
 */
function useInterval(callback: () => Promise<void>, delay: number) {
  const savedCallback = useRef<typeof callback>()

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current()
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
    return undefined
  }, [delay])
}

const ActiveOrganizationPolling: React.FC<{
  children: React.ReactNode | React.ReactNode[]
  onOrganizationChange: () => void
}> = ({ children, onOrganizationChange }) => {
  const authenticated = sessionStorage.getItem('API_TOKEN') !== null
  const dispatch = useDispatch()
  const { activeOrganization } = useSelector(
    (state: RootState) => state.organization
  )
  const { t } = useTranslation()
  const history = useHistory()
  useInterval(async () => {
    if (authenticated) {
      const currentOrganization: ReturnType<typeof dispatch> = await dispatch(
        getActiveOrganization()
      )
      if (activeOrganization !== currentOrganization.value.organization) {
        dispatch(activateOrganization(currentOrganization.value.organization))
        if (window.dataLayer) {
          window.dataLayer.push({
            organizationId: currentOrganization.value.organization
          })
        }
        history.push('/')
        onOrganizationChange()
        toast.info(t('active-organization-changed-outside'))
      }
    }
  }, POLL_INTERVAL_IN_SECONDS * 1000)
  return <>{children}</>
}

export default ActiveOrganizationPolling

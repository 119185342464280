import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TFunction, withTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { selectTimeGrouping } from 'store/actions/timeGrouping'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import {
  GROUPING_FISCAL,
  GROUPING_MONTH,
  GROUPING_QUARTER
} from 'store/reducers/timeGrouping'
import YellowButton from 'components/blocks/YellowButton'
import { RootState } from 'store/reducers'

const Button = styled(YellowButton)`
  margin-bottom: 5px;
  padding: 0 10px;
  &:last-of-type {
    margin-right: 0;
  }
  &:hover {
    background-color: #ffd800;
    color: #000;
  }
`

type TimeGroupingSelectorReduxState = ReturnType<typeof mapStateToProps>
type TimeGroupingSelectorReduxDispatch = ReturnType<typeof mapDispatchToProps>
type TimeGroupingSelectorProps = {
  t: TFunction
}

class TimeGroupingSelector extends Component<
  TimeGroupingSelectorProps &
    TimeGroupingSelectorReduxDispatch &
    TimeGroupingSelectorReduxState
> {
  selectTimeGrouping = (newGrouping: string) => {
    const { selectTimeGrouping: selectTimeGroupingAction } = this.props
    selectTimeGroupingAction(newGrouping)
  }

  render() {
    const { t, timeGrouping } = this.props
    return (
      <div className="edit">
        <Button
          selected={timeGrouping.selectedGrouping === GROUPING_MONTH}
          onClick={() => this.selectTimeGrouping(GROUPING_MONTH)}
        >
          {t('timeGroupingOptionMonth')}
        </Button>
        <Button
          selected={timeGrouping.selectedGrouping === GROUPING_QUARTER}
          onClick={() => this.selectTimeGrouping(GROUPING_QUARTER)}
        >
          {t('timeGroupingOptionQuarter')}
        </Button>
        <Button
          selected={timeGrouping.selectedGrouping === GROUPING_FISCAL}
          onClick={() => this.selectTimeGrouping(GROUPING_FISCAL)}
        >
          {t('timeGroupingOptionFiscal')}
        </Button>
      </div>
    )
  }
}

const mapStateToProps = ({ timeGrouping }: RootState) => ({
  timeGrouping
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      selectTimeGrouping
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TimeGroupingSelector))

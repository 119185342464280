import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  getDataMap,
  getRawDataTimespan,
  setDataMapDateRange
} from 'store/actions/rawdata'
import LoadingIndicator from 'components/blocks/LoadingIndicator'
import { DataMap as DataMapComponent } from 'components/data-map'
import styled from 'styled-components/macro'
import { DefaultTheme, withTheme } from 'styled-components'
import moment from 'moment'
import { Title } from 'components/blocks'
import { TFunction, withTranslation } from 'react-i18next'
import timeLineFromDatesAndOrganization, {
  MONTH_FORMAT,
  ISO_FORMAT_DATE,
  PRESENTATION_MONTH_FORMAT
} from 'util/dates'
import { getOrgName } from 'util/organization'
import { createReportDataMap } from 'store/actions/report'
import { OrganizationDateSelector } from 'components/graph'
import { RootState } from 'store/reducers'
import { DataMap as DataMapType } from 'store/reducers/rawdata'
import { AppDispatch } from 'store/store'
import ExpandToggleButton from 'components/data-map/ExpandToggleButton'
import { YellowButtonMargin } from 'components/blocks/YellowButton'

const Container = styled.div`
  display: flex;
  height: 80vh;
  width: 100%;
`
const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const DataMapsContainer = styled.div`
  max-width: calc(
    100vw - ${(props: { offset: number }) => props.offset}px - (1.875rem * 2)
  );
  max-height: calc(100vh - 210px);
  overflow: auto;
  margin-top: 1rem;
  padding-bottom: 2px;
  ::-webkit-scrollbar {
    height: 12px;
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px #333;
    border-radius: 0.625rem;
  }
  ::-webkit-scrollbar-thumb {
    background: #777777;
    border-radius: 0.625rem;
  }
  @media screen and (max-width: 768px) {
    max-width: none;
  }
`
const DatesContainer = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
  > p {
    &:first-of-type {
      min-width: 300px;
    }
    background: ${props => props.theme.common.background};
    margin: 0;
    padding: 0.5rem;
    min-width: 150px;
    text-align: right;
    font-family: 'Open Sans';
    font-size: 0.9rem;
    letter-spacing: 0;
    line-height: 1;
    border-bottom: 0.5px solid #aaa;
    border-right: 0.5px solid #aaa;
  }
`
const DataMapPage = styled.div`
  .month-picker {
    font-family: 'Open Sans';
    font-size: 0.75rem;
    letter-spacing: 0;
    line-height: 1;
  }
  .legend {
    margin-top: 0.5rem;
    p {
      margin: 0;
      white-space: nowrap;
      padding-top: 0.5rem;
      font-family: 'Open Sans';
      font-size: 0.9rem;
      letter-spacing: 0;
      line-height: 1;
      cursor: default;
      pointer-events: none;
      &.actual {
        font-weight: bold;
      }
      &.estimate {
        font-style: italic;
      }
    }
  }
  .date-selector {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: calc(
      100vw - ${(props: { offset: number }) => props.offset}px - (1.875rem * 2)
    );
    [class*='YellowButton'] {
      padding: 5px 10px;
      position: relative;
    }
  }
`

type DataMapReduxState = ReturnType<typeof mapStateToProps>
type DataMapReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface DataMapProps {
  t: TFunction
  theme: DefaultTheme
}

const CSV_EMPTY_VALUE = '-'
const CSV_SEPARATOR = ','

class DataMap extends Component<
  DataMapProps & DataMapReduxState & DataMapReduxDispatch
> {
  dataMapRef: HTMLDivElement | null
  constructor(props: DataMapProps & DataMapReduxState & DataMapReduxDispatch) {
    super(props)
    this.dataMapRef = null
  }

  componentDidMount() {
    const {
      getDataMap: getDataMapAction,
      setDataMapDateRange: setDataMapDateRangeAction,
      getRawDataTimespan: getRawDataTimespanAction,
      dateSelections
    } = this.props
    getRawDataTimespanAction(false)
    const searchParams = new URLSearchParams(window.location.search)
    let start = null
    let end = null
    if (searchParams.get('start-date') && searchParams.get('end-date')) {
      start = moment(searchParams.get('start-date'))
      end = moment(searchParams.get('end-date'))
    } else {
      const savedEnd = dateSelections?.graph?.rangeValue?.to
      const savedStart = dateSelections?.graph?.rangeValue?.from
      start = savedStart
        ? moment(`${savedStart.year}-${savedStart.month}`, 'YYYY-MM').startOf(
            'month'
          )
        : moment()
            .subtract(1, 'year')
            .startOf('month')
      end = savedEnd
        ? moment(`${savedEnd.year}-${savedEnd.month}`, 'YYYY-MM').endOf('month')
        : moment().endOf('month')
    }
    setDataMapDateRangeAction({
      endMonth: end.month() + 1,
      endYear: end.year(),
      rowLength: end.diff(start, 'months'),
      startMonth: start.month() + 1,
      startYear: start.year()
    })

    const keys = searchParams.get('keys')
    const rootKeys = keys ? keys.split(',') : null

    if (start.isValid() && end.isValid()) {
      getDataMapAction({
        startDate: start.startOf('month').format(ISO_FORMAT_DATE),
        endDate: end.endOf('month').format(ISO_FORMAT_DATE),
        rootKeys
      })
    }
    const wrapper = document.getElementById('content-wrapper')
    if (wrapper) {
      wrapper.style.overflowY = 'hidden'
    }
  }

  componentWillUnmount() {
    const wrapper = document.getElementById('content-wrapper')
    if (wrapper) {
      wrapper.style.overflowY = ''
    }
  }

  onDateChange = async (unformattedDates: { [key: string]: Array<string> }) => {
    const {
      getDataMap: getDataMapAction,
      setDataMapDateRange: setDataMapDateRangeAction,
      organization: { data, activeOrganization }
    } = this.props
    const {
      start: startDate,
      stop: stopDate
    } = timeLineFromDatesAndOrganization(
      unformattedDates,
      data,
      activeOrganization
    )
    const dates = {
      startYear: startDate.format('YYYY'),
      startMonth: startDate.format('MM'),
      endYear: stopDate.format('YYYY'),
      endMonth: stopDate.format('MM')
    }
    setDataMapDateRangeAction(dates)
    const start = moment(`${dates.startYear}-${dates.startMonth}`, MONTH_FORMAT)
    const end = moment(`${dates.endYear}-${dates.endMonth}`, MONTH_FORMAT)
    const searchParams = new URLSearchParams(window.location.search)
    const keys = searchParams.get('keys')
    const rootKeys = keys ? keys.split(',') : null
    if (start.isValid() && end.isValid()) {
      getDataMapAction({
        startDate: start.startOf('month').format(ISO_FORMAT_DATE),
        endDate: end.endOf('month').format(ISO_FORMAT_DATE),
        rootKeys
      })
    }
  }

  getDates = (): string[] => {
    const { dataMapDateRange } = this.props

    if (!dataMapDateRange.startYear || !dataMapDateRange.endYear) {
      return []
    }

    const dates: string[] = []
    const start = moment(
      `${dataMapDateRange.startYear}-${dataMapDateRange.startMonth}`,
      MONTH_FORMAT
    )
    const end = moment(
      `${dataMapDateRange.endYear}-${dataMapDateRange.endMonth}`,
      MONTH_FORMAT
    )

    while (start <= end) {
      const formattedDate = start.format(PRESENTATION_MONTH_FORMAT)
      dates.push(formattedDate)
      start.add(1, 'month')
    }

    return dates
  }

  renderDates = () => {
    const dates = this.getDates()

    if (!dates.length) {
      return null
    }

    return (
      <DatesContainer>
        {[<p key="empty" />].concat(
          dates.map(date => <p key={date}>{date}</p>)
        )}
      </DatesContainer>
    )
  }

  saveDataMap = () => {
    const {
      createReportDataMap: createReportDataMapAction,
      dataMapDateRange,
      theme,
      openedDataMapTerms
    } = this.props
    if (!dataMapDateRange.startYear || !dataMapDateRange.endYear) {
      return
    }
    const start = moment(
      `${dataMapDateRange.startYear}-${dataMapDateRange.startMonth}`,
      MONTH_FORMAT
    )
    const end = moment(
      `${dataMapDateRange.endYear}-${dataMapDateRange.endMonth}`,
      MONTH_FORMAT
    )
    const keys = new URLSearchParams(window.location.search).get('keys')
    const rootKeys = keys ? keys.split(',') : null
    if (this.dataMapRef) {
      const { left, top } = this.dataMapRef.getBoundingClientRect()
      const dataMapPadding = 30
      const properties = {
        start_date: start.format('YYYY-MM'),
        end_date: end.format('YYYY-MM'),
        keys: rootKeys,
        map_width: left + this.dataMapRef.scrollWidth + dataMapPadding,
        map_height: this.dataMapRef.scrollHeight + top,
        opened_terms: openedDataMapTerms,
        theme: theme.id
      }
      createReportDataMapAction({
        properties
      })
    }
  }

  /**
   * Encloses the given value in double quotes so that in case the value
   * contains the separator character, it won't be in interpreted as a new
   * column
   * @param value The value to be written into the CSV file
   * @returns The value enclosed in double quotes
   */
  getCSVString = (value: number | string) =>
    `"${value.toString().replace(/"/g, '""')}"`

  /**
   * Recursively gets the rows to be written into the CSV file from the data map
   * @param data The data to write to the CSV file
   * @returns rows to be written to the CSV file
   */
  getCSVRows = (data: DataMapType | null): string => {
    if (!data) {
      return ''
    }

    let rows: string[] = []

    for (const term in data) {
      const row = [this.getCSVString(term)]

      for (const column in data[term]) {
        if (column !== 'children') {
          row.push(
            data[term][column]
              ? this.getCSVString(data[term][column].value)
              : CSV_EMPTY_VALUE
          )
        }
      }

      rows.push(row.join(CSV_SEPARATOR))

      if (data[term].children && this.props.openedDataMapTerms.includes(term)) {
        rows = rows.concat(this.getCSVRows(data[term].children))
      }
    }

    return rows.join('\n')
  }

  /**
   * Creates the CSV file and starts downloading it
   * @param filename
   */
  downloadCSV = (filename: string) => {
    const link = document.createElement('a')
    const url = URL.createObjectURL(
      new Blob(
        [
          `sep=${CSV_SEPARATOR}\n${CSV_SEPARATOR}${this.getDates().join(
            CSV_SEPARATOR
          )}\n${this.getCSVRows(this.props.dataMap)}`
        ],
        {
          type: 'text/csv;charset=utf-8;'
        }
      )
    )

    link.setAttribute('href', url)
    link.setAttribute('download', filename)
    link.click()
    URL.revokeObjectURL(url)
  }

  render() {
    const {
      dataMap,
      t,
      isDataMapPending,
      isReportsPending,
      timespan,
      organization
    } = this.props
    if (!dataMap) {
      return (
        <Container>
          <LoadingIndicator />
        </Container>
      )
    }
    const mainMenu = document.querySelector<HTMLDivElement>(
      '#main-menu-container'
    )
    const offset = mainMenu ? mainMenu.offsetWidth : 0

    return (
      <DataMapPage offset={offset}>
        <Title>{t('data-map')}</Title>
        <div className="date-selector">
          <ButtonContainer>
            <ExpandToggleButton />
            <OrganizationDateSelector
              noData
              location="graph"
              onDateChange={this.onDateChange}
              timeSpan={{
                start_date: timespan.start_date,
                end_date: timespan.end_date
              }}
            />
          </ButtonContainer>
          <ButtonContainer>
            <YellowButtonMargin
              onClick={() =>
                this.downloadCSV(
                  `${(getOrgName(organization) || 'datamap')
                    .toLowerCase()
                    .replace(/ä/g, 'a')
                    .replace(/ö/g, 'o')
                    .replace(/å/g, 'a')
                    .replace(/[^a-zA-Z0-9_]+/g, '_')}.csv`
                )
              }
            >
              {t('download-csv')}
            </YellowButtonMargin>
            <YellowButtonMargin onClick={() => this.saveDataMap()}>
              {isReportsPending ? <LoadingIndicator /> : t('save-for-a-report')}
            </YellowButtonMargin>
          </ButtonContainer>
        </div>
        {isDataMapPending ? (
          <Container>
            <LoadingIndicator />
          </Container>
        ) : (
          <>
            <DataMapsContainer
              offset={offset}
              ref={ref => {
                this.dataMapRef = ref
              }}
            >
              {this.renderDates()}
              <DataMapComponent dataMap={dataMap} />
            </DataMapsContainer>
            <div className="legend">
              <p className="actual">{t('actual')}</p>
              <p className="estimate">{t('estimate')}</p>
            </div>
          </>
        )}
      </DataMapPage>
    )
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      getDataMap,
      setDataMapDateRange,
      createReportDataMap,
      getRawDataTimespan
    },
    dispatch
  )

const mapStateToProps = ({
  rawData: {
    dataMap,
    dataMapDateRange,
    isDataMapPending,
    openedDataMapTerms,
    timespan
  },
  monthselector: { selections },
  report: { isPending },
  organization
}: RootState) => ({
  dataMap,
  dataMapDateRange,
  isDataMapPending,
  dateSelections: selections,
  isReportsPending: isPending,
  organization,
  openedDataMapTerms,
  timespan
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withTheme(DataMap)))

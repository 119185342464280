import styled, { DefaultTheme } from 'styled-components/macro'

const YellowButton = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 1.5rem;
  border: 1px solid #ffd800;
  border-radius: 6px;
  color: ${(props: { theme: DefaultTheme; selected?: boolean }) =>
    props.theme.text.secondary};
  font-family: 'Open Sans';
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 2;
  cursor: pointer;
  margin-right: 0.625rem;
  &:last-of-type {
    margin-right: 0;
  }
  &:hover {
    background-color: #ffd800;
    color: #000;
  }
  ${props =>
    props.selected
      ? `
background-color: #ffd800;
color: #000;
`
      : ''}
`

export default YellowButton

export const YellowButtonMargin = styled(YellowButton)`
  margin-bottom: 5px;
  padding: 0 10px;
`

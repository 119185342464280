import React, { Component, createRef } from 'react'
import { get } from 'lodash'
import moment from 'moment'
import styled, { DefaultTheme } from 'styled-components/macro'
import {
  ComposedChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  ResponsiveContainer,
  Bar,
  ReferenceLine,
  Area,
  Label,
  TooltipProps,
  TooltipPayload,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis
} from 'recharts'
import { connect } from 'react-redux'
import { formatFloat, formatInt } from 'util/format'
import timeLineFromDatesAndOrganization, {
  dateToGroupKey,
  ISO_FORMAT_DATE,
  PRESENTATION_MONTH_FORMAT,
  LONG_PRESENTATION_FORMAT,
  dateToNextQuarterStartMonth,
  dateToPreviousQuarterEndMonth,
  dateToNextFiscalStartMonth,
  dateToPreviousFiscalEndMonth,
  isDataForMonthVerified,
  isDataForFiscalVerified,
  isDataForQuarterVerified,
  isFutureDataVerified,
  quarterStartDateFromLabel,
  fiscalStartDateFromLabel
} from 'util/dates'
import { bindActionCreators } from 'redux'
import { setTargetToGraph, getTargetAsGraph } from 'store/actions/target'
import { getPercentageValue } from 'store/actions/rawdata'
import {
  GROUPING_FISCAL,
  GROUPING_MONTH,
  GROUPING_QUARTER
} from 'store/reducers/timeGrouping'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { RootState } from 'store/reducers'
import { RawData } from 'store/reducers/rawdata'
import { GraphMapping } from 'store/reducers/graph'
import { Target } from 'store/reducers/target'
import { AppDispatch } from 'store/store'
import { findOrg } from 'util/organization'

const CardContainer = styled.div`
  margin-top: 1.25rem;
  ${(props: { isAnimationActive: boolean }) =>
    props.isAnimationActive
      ? ''
      : `
      height: calc(100vh - 200px);
      display: flex;
      flex-direction: column;
  `}
`
const ChartContainer = styled.div`
  ${(props: { isAnimationActive: boolean; height: number | string }) =>
    props.isAnimationActive ? `height: ${props.height};` : 'height: 100%;'}
  svg path {
    filter: drop-shadow(0px 0px 1.5px black);
    opacity: 0.8;
  }
  svg g.recharts-bar-rectangle path {
    outline: 0.25px solid ${props => props.theme.common.background};
  }
`
const LegendContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 1.25rem;
  padding: 0 10.938rem;
  @media screen and (max-width: 768px) {
    padding: 0 40px;
  }
`
const LegendText = styled.span`
  user-select: none;
  ${(props: { width: number; active: boolean }) =>
    props.width ? `width: ${props.width}px;` : ''}
  white-space: nowrap;
  display: flex;
  align-items: center;
  color: ${props => props.theme.numbers.color};
  font-family: 'Open Sans';
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.063rem;
  margin-right: 39px;
  margin-bottom: 5px;
  cursor: pointer;
  > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  opacity: ${(props: { width: number; active: boolean }) =>
    props.active ? '1' : '0.45'};
  &:last-of-type {
    margin-right: 0;
  }
`
const GraphCard = styled.div`
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(5, 5, 5, 0.5);
  padding: 1.25rem 0.938rem;
  width: 300px;
  .title-container {
    display: flex;
    justify-content: space-between;
    h5 {
      color: #565656;
      font-family: 'Open Sans';
      font-size: 0.75rem;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 1.188rem;
      margin-top: 0;
      margin-bottom: 0.625rem;
      &:first-of-type {
        text-transform: capitalize;
      }
    }
    h6 {
      color: #565656;
      font-family: 'Open Sans';
      font-size: 0.6rem;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 1.188rem;
      margin-top: 0;
      margin-bottom: 0.625rem;
      &:first-of-type {
        text-transform: capitalize;
      }
    }
  }
  > div {
    display: flex;
    align-items: center;
  }
  > div span {
    color: #333333;
    font-family: 'Open Sans';
    font-size: 0.625rem;
    letter-spacing: 0;
    line-height: 1.75rem;
    margin-top: 0;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:first-of-type {
      max-width: 150px;
      flex-grow: 1;
      padding-right: 0.938rem;
    }
    &:last-of-type {
      max-width: 10.625rem;
      font-weight: bold;
      text-align: right;
      flex-grow: 1;
    }
    &.percentage {
      color: #999;
      padding: 0;
    }
  }
`
const Block = styled.div`
  position: relative;
  min-width: 0.625rem;
  min-height: 0.625rem;
  background: ${props => props.color};
  margin-right: 0.625rem;
  ${(props: { circle: boolean; stacked: boolean }) =>
    props.circle ? 'border-radius: 100%;' : ''}
  ${props =>
    props.stacked ? 'border-bottom: 0.3rem solid #33333355;' : ''}
  > p {
    position: absolute;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: center;
    line-height: 0;
    font-size: 10px;
    top: 0;
    left: 0;
    right: -3px;
    bottom: -0.3rem;
    color: #000 !important;
    font-weight: 600;
    margin: 0;
  }
`
interface IndicatorProps {
  data: {
    color: string
    type: string
  }
  darkNumbers: boolean
}

const Indicator = styled.div`
  position: relative;
  min-height: 0.875rem;
  min-width: 0.875rem;
  flex-basis: 0.875rem;
  background: ${(props: IndicatorProps) => props.data.color};
  border-radius: ${props => (props.data.type === 'line' ? '100%' : '0')};
  margin-right: 0.625rem;
  ${props =>
    props.data.type === 'stacked'
      ? 'border-bottom: 0.4325rem solid #33333355;'
      : ''}
  > p {
    position: absolute;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: center;
    line-height: 0;
    font-size: 10px;
    top: 0;
    left: 0;
    right: -2px;
    bottom: -0.4325rem;
    color: ${props => (props.darkNumbers ? '#000' : '#fff')};
    font-weight: 600;
    margin: 0;
  }
`

interface BarShape {
  fill?: string
  fillOpacity?: string
  stroke?: string
  mask?: string
}

const getBarShape = (
  radius: Array<number>,
  xStart?: number,
  yStart?: number,
  widthStart?: number,
  heightStart?: number
) => {
  const [tl, tr, bl, br] = radius
  const x = xStart || 0
  const y = yStart || 0
  const width = widthStart || 0
  const height = heightStart || 0
  const d = `M${x},${y + tl}
    a${tl},${tl} 0 0 1 ${tl},${-tl}
    h${width - tl - tr}
    a${tr},${tr} 0 0 1 ${tr},${tr}
    v${height - tr - br}
    a${br},${br} 0 0 1 ${-br},${br}
    h${bl + (br - width)}
    a${bl},${bl} 0 0 1 ${-bl},${-bl}
    z`
  const shape = ({ fill, fillOpacity, stroke, mask }: BarShape) => (
    <path
      mask={mask}
      d={d}
      fill={fill}
      fillOpacity={fillOpacity}
      stroke={stroke}
    />
  )
  return shape
}

const CustomShape = ({
  x,
  y,
  width,
  height,
  term,
  name,
  rawData,
  startOfFiscal,
  grouping,
  cumulative
}: {
  x?: number
  y?: number
  width?: number
  height?: number
  term: { key: string; title: string }
  name?: string
  rawData: Array<RawData>
  startOfFiscal: number
  grouping: string
  cumulative?: boolean
}) => {
  const BarShape = getBarShape([0, 0, 0, 0], x, y, width, height)
  let isVerified: boolean | number = false

  if (!name) {
    return null
  }
  if (grouping === GROUPING_QUARTER) {
    isVerified = isDataForQuarterVerified(name, rawData, term, startOfFiscal)
  } else if (grouping === GROUPING_FISCAL) {
    isVerified = isDataForFiscalVerified(name, rawData, term, startOfFiscal)
  } else {
    isVerified = isDataForMonthVerified(name, rawData, term)
    if (!isVerified && cumulative) {
      isVerified = isFutureDataVerified(name, rawData, term)
    }
  }

  return (
    <g>
      {!isVerified ? (
        <BarShape fill={`url(#color${term.key})`} mask="url(#mask-stripe)" />
      ) : (
        <BarShape fillOpacity="1" fill={`url(#color${term.key})`} />
      )}
    </g>
  )
}

type ChartAreaReduxState = ReturnType<typeof mapStateToProps>
type ChartAreaReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface ChartAreaProps {
  data: RawData[]
  dates: { [key: string]: Array<string> }
  keys: Array<{ title: string; key: string }>
  cumulative?: boolean
  graphProperties: GraphMapping['graph_properties']
  activeOnStart?: { id: number; key: string }
  onChartLoaded?: () => void
  darkNumbers: boolean
  height?: number | string
  defaultYTDEndDate?: string
  onFilteredKeysChange?: (keys: Array<string>) => void
  t: TFunction
  theme: DefaultTheme
}

interface ChartAreaState {
  filteredKeys?: Array<string>
  initialized: boolean
  graphTarget: Target | null
  legendWidth: number
}

type DataInput = {
  actual?: number | null
  cumulative?: number | null
  estimate?: number | null
}

type DataEntry = Partial<RawData> & {
  title: string
}

type DataByDate = Record<string, string | DataInput>

class ChartArea extends Component<
  ChartAreaProps & ChartAreaReduxState & ChartAreaReduxDispatch,
  ChartAreaState
> {
  chartRef: React.RefObject<ComposedChart>
  containerRef: React.RefObject<HTMLDivElement>

  constructor(
    props: ChartAreaProps & ChartAreaReduxState & ChartAreaReduxDispatch
  ) {
    super(props)
    this.chartRef = createRef<ComposedChart>()
    this.containerRef = createRef<HTMLDivElement>()
    const urlParams = new URLSearchParams(window.location.search)
    const filteredKeys = urlParams.get('filtered-keys')
      ? urlParams.get('filtered-keys')?.split(',')
      : []
    this.state = {
      filteredKeys,
      initialized: false,
      graphTarget: null,
      legendWidth: 0
    }
  }

  static getDerivedStateFromProps(
    props: ChartAreaProps & ChartAreaReduxState & ChartAreaReduxDispatch,
    state: ChartAreaState
  ) {
    if (props.activeOnStart && !state.initialized) {
      return {
        filteredKeys: [props.activeOnStart.key],
        initialized: true
      }
    }
    if (
      props.graphTarget &&
      props.dates &&
      props.organization &&
      props.graphProperties &&
      state.graphTarget === null &&
      props.data
    ) {
      const {
        dates,
        organization: { activeOrganization, data },
        graphProperties
      } = props

      const { start, stop } = timeLineFromDatesAndOrganization(
        dates,
        data,
        activeOrganization,
        graphProperties
      )
      props.getTargetAsGraph(
        props.graphTarget.id,
        start.format(ISO_FORMAT_DATE),
        stop.format(ISO_FORMAT_DATE)
      )
      props.setTargetToGraph(null)
      return {
        graphTarget: props.graphTarget
      }
    }
    return null
  }

  componentDidMount() {
    const {
      onChartLoaded,
      getPercentageValue: getPercentageValueAction,
      data,
      pendingPercentages,
      cumulative,
      graphProperties,
      timeGrouping: { selectedGrouping: grouping }
    } = this.props
    if (onChartLoaded) {
      onChartLoaded()
    }

    // Since the recharts library doesn't offer a functionality
    // to individually style horizontal lines in CartesianGrid,
    // adjusting of the 0 in y-axis must be done by injecting
    // the styles after rendering has been done.
    setTimeout(this.boldYAxisZero)

    const ids = data.map(d => d.id)
    if (!ids.every(i => pendingPercentages.includes(i))) {
      getPercentageValueAction(
        ids,
        cumulative || false,
        graphProperties,
        grouping
      )
    }
  }

  componentDidUpdate(
    prevProps: ChartAreaProps & ChartAreaReduxDispatch & ChartAreaReduxState,
    prevState: ChartAreaState
  ) {
    // Since the recharts library doesn't offer a functionality
    // to individually style horizontal lines in CartesianGrid,
    // adjusting of the 0 in y-axis must be done by injecting
    // the styles after rendering has been done.
    setTimeout(this.boldYAxisZero)
    this.getLongestWordWidth()

    const {
      getPercentageValue: getPercentageValueAction,
      data,
      pendingPercentages,
      cumulative,
      graphProperties,
      timeGrouping: { selectedGrouping: grouping }
    } = this.props
    const {
      timeGrouping: { selectedGrouping: prevGrouping }
    } = prevProps
    const ids = data.map(d => d.id)
    if (
      prevProps.cumulative !== cumulative ||
      grouping !== prevGrouping ||
      !ids.every(i => pendingPercentages.includes(i))
    ) {
      getPercentageValueAction(
        ids,
        cumulative || false,
        graphProperties,
        grouping
      )
    }

    const { filteredKeys } = this.state
    if (
      filteredKeys?.length === prevState.filteredKeys?.length &&
      prevState.filteredKeys?.every(k => filteredKeys?.includes(k))
    ) {
      return
    }
    const { onFilteredKeysChange } = this.props
    if (onFilteredKeysChange && filteredKeys) {
      onFilteredKeysChange(filteredKeys)
    }
  }

  boldYAxisZero = () => {
    const container = this.containerRef.current
    if (!container) {
      return
    }

    const axisLabels = container.querySelectorAll<SVGTSpanElement>(
      '.recharts-yAxis .recharts-cartesian-axis-ticks tspan'
    )
    const lines = container.querySelectorAll<SVGLineElement>(
      '.recharts-cartesian-grid-horizontal line'
    )
    lines.forEach(line => {
      line.style.strokeWidth = '1px' // eslint-disable-line
    })
    axisLabels.forEach(label => {
      label.style.fontWeight = '400' // eslint-disable-line
      if (label.innerHTML.startsWith('0')) {
        if (label.innerHTML.match(/[1-9]/g)) {
          return
        }
        label.style.fontWeight = '1000' // eslint-disable-line
        const parent = label.parentElement
        const parentY = parent?.getAttribute('y')
        lines.forEach(line => {
          const lineY = line.getAttribute('y1')
          if (lineY === parentY) {
            line.style.strokeWidth = '7.5px' // eslint-disable-line
          }
        })
      }
    })
  }

  getLongestWordWidth = () => {
    const { legendWidth } = this.state
    let width = 0
    document.querySelectorAll<HTMLSpanElement>('.legend-text').forEach(el => {
      if (el.offsetWidth + 1 > width) {
        width = el.offsetWidth + 1
      }
    })
    if (width > legendWidth + 2) {
      this.setState({ legendWidth: width })
    }
  }

  renderLegend = () => {
    const { keys, graphProperties, darkNumbers } = this.props
    const { filteredKeys, legendWidth } = this.state
    const keysClone = [...keys]
    keysClone.reverse()
    return (
      <LegendContainer className="legend">
        {keysClone.map(k => (
          <LegendText
            className="legend-text"
            width={legendWidth}
            title={k.title}
            key={k.key}
            onClick={() => {
              if (filteredKeys?.includes(k.key)) {
                this.setState({
                  filteredKeys: filteredKeys.filter(fk => fk !== k.key)
                })
                return
              }

              this.setState({ filteredKeys: [...(filteredKeys || []), k.key] })
            }}
            active={filteredKeys?.includes(k.key) || filteredKeys?.length === 0}
          >
            <Indicator data={graphProperties[k.key]} darkNumbers={darkNumbers}>
              {graphProperties[k.key] &&
              graphProperties[k.key].type === 'stacked' ? (
                <p>{graphProperties[k.key].stack_id}</p>
              ) : null}
            </Indicator>
            <span>{k.title}</span>
          </LegendText>
        ))}
      </LegendContainer>
    )
  }

  compareTooltipData = (dataA: TooltipPayload, dataB: TooltipPayload) => {
    const { graphProperties } = this.props
    if (!dataA || !dataB) {
      return 0
    }
    const dataAKey = dataA.dataKey
      ?.toString()
      .replace('-estimated', '') as string
    const dataBKey = dataB.dataKey
      ?.toString()
      .replace('-estimated', '') as string
    const typeA = (graphProperties[dataAKey] || {}).type
    const typeB = (graphProperties[dataBKey] || {}).type
    const stackAId = graphProperties[dataAKey]
      ? graphProperties[dataAKey].stack_id
      : ''
    const stackBId = graphProperties[dataBKey]
      ? graphProperties[dataBKey].stack_id
      : ''
    if (dataAKey === 'targetValue') {
      return 1
    }
    if (dataBKey === 'targetValue') {
      return -1
    }
    if (typeA === 'line' && typeB === 'line') {
      return dataA.value > dataB.value ? 1 : -1
    }
    if (typeA !== 'line' && typeB === 'line') {
      return -1
    }
    if (typeA === 'stacked' && typeB === 'stacked' && stackAId === stackBId) {
      if (dataA.value === 0 && dataB.value > 0) {
        return -1
      }
      if (dataA.value < 0) {
        return -1
      }
    }
    return 0
  }

  getGroupingStartFromLabel = (label: TooltipProps['label']) => {
    if (!label) {
      return null
    }

    const { timeGrouping } = this.props
    const { selectedGrouping: grouping } = timeGrouping
    const {
      organization: { activeOrganization, data: orgData }
    } = this.props
    const org = findOrg(activeOrganization, orgData)
    const startOfFiscalYear = parseInt(org?.start_of_fiscal_year || '1', 10)

    switch (grouping) {
      case GROUPING_QUARTER:
        return quarterStartDateFromLabel(
          label as string,
          startOfFiscalYear
        ).format(ISO_FORMAT_DATE)
      case GROUPING_FISCAL:
        return fiscalStartDateFromLabel(
          label as string,
          startOfFiscalYear
        ).format(ISO_FORMAT_DATE)
      default:
        return moment(label, PRESENTATION_MONTH_FORMAT)
          .startOf('month')
          .format(ISO_FORMAT_DATE)
    }
  }

  /**
   * Function that takes currently iterated label's data and index with the whole
   * iterated array, and determines if a sum of a stacked bar graph should be shown
   * or not.
   *
   * @param {Object} labelData          Currently iterated tooltip label data.
   * @param {Number} labelIndex         The index of the currently iterated tooltip label.
   * @param {Array<Object>} labels      Array of tooltip label data for the current tooltip.
   * @returns {JSX.Element | null}      Returns a label that indicates the sum of a stacked bar graph.
   */
  getStackedSumLabel = (
    labelData: TooltipPayload,
    labelIndex: number,
    labels: TooltipPayload[]
  ) => {
    if (!labels || !labelData) {
      return
    }

    const { graphProperties, t } = this.props
    const labelKey = labelData.dataKey?.toString().replace('-estimated', '')
    const previousLabel = labels[labelIndex - 1] || {}
    const previousLabelKey = previousLabel.dataKey
      ?.toString()
      .replace('-estimated', '')

    if (!labelKey) {
      return
    }

    const graphProps = graphProperties[labelKey]
    const previousGraphProps = graphProperties[previousLabelKey || '']
    if (
      (graphProps.type === 'stacked' &&
        previousGraphProps?.type !== 'stacked') ||
      (graphProps.type === 'stacked' &&
        previousGraphProps?.stack_id !== graphProps.stack_id)
    ) {
      const stackLabels = labels.filter(label => {
        const key =
          label && label.dataKey
            ? label.dataKey.toString().replace('-estimated', '')
            : ''
        return (
          graphProperties[key]?.type === 'stacked' &&
          graphProperties[key]?.stack_id === graphProperties[labelKey].stack_id
        )
      })
      const stackSum = stackLabels.reduce((b, a) => b + (a.value as number), 0)
      return (
        <div>
          <span>
            {t('stack-sum').replace('stack_id', graphProps.stack_id as string)}
          </span>
          <span>{`${formatFloat(stackSum)} ${
            graphProps.second_axis
              ? graphProperties.u_right || ''
              : graphProperties.unit || ''
          }`}</span>
        </div>
      )
    }
    return null
  }

  renderColorfulTooltipText = (props: TooltipProps) => {
    const {
      keys,
      graphProperties,
      percentages,
      data,
      timeGrouping,
      t
    } = this.props
    const { graphTarget, filteredKeys } = this.state
    const payload: TooltipPayload[] = props.payload ? [...props.payload] : []
    const date = this.getGroupingStartFromLabel(props.label)
    const { selectedGrouping: grouping } = timeGrouping

    keys
      .map(k => k.key)
      .forEach(k => {
        if (
          payload.some(
            p => p.dataKey?.toString().replace('-estimated', '') === k
          )
        ) {
          return
        }

        if (
          filteredKeys &&
          filteredKeys.length > 0 &&
          !filteredKeys.includes(k)
        ) {
          return
        }

        payload.push({
          name: k,
          dataKey: k,
          value: 0
        })
      })
    payload.sort((a, b) => this.compareTooltipData(a, b))
    let graphCardHeaderText = ''
    if (grouping === GROUPING_MONTH) {
      graphCardHeaderText = moment(
        props.label,
        PRESENTATION_MONTH_FORMAT
      ).format(LONG_PRESENTATION_FORMAT)
    } else {
      graphCardHeaderText = props.label as string
    }

    return (
      <GraphCard>
        <div className="title-container">
          <h5>{graphCardHeaderText}</h5>
        </div>
        <div className="title-container">
          <h6>{t('tooltip-term-label')}</h6>
          <h6>{t('tooltip-value-label')}</h6>
        </div>
        {payload.reverse().map((p, i: number) => {
          const dataObj = data.find(
            d =>
              d.key === p.dataKey?.toString().replace('-estimated', '') &&
              d.start_date === date
          )
          if (p.dataKey === 'targetValue') {
            return (
              <div key="targetValue">
                <Block
                  color={(graphProperties.target || {}).color || '#ffd800'}
                  circle={graphProperties.target.type === 'line'}
                  stacked={graphProperties.target.type === 'stacked'}
                />
                <span>{graphTarget?.title}</span>
                <span>
                  {formatFloat(p.value as number)}
                  {graphProperties.unit ? ` ${graphProperties.unit}` : ''}
                </span>
              </div>
            )
          }
          const sumLabel = this.getStackedSumLabel(p, i, payload)
          return typeof p.dataKey === 'string' &&
            p.dataKey.endsWith('-estimated') &&
            props.payload?.some(
              p2 =>
                typeof p.dataKey === 'string' &&
                p2.dataKey === p.dataKey.replace('-estimated', '')
            ) ? null : (
            <React.Fragment key={p.dataKey as string}>
              {sumLabel || null}
              <div>
                <Block
                  color={
                    graphProperties[
                      (p.dataKey as string).replace('-estimated', '')
                    ].color
                  }
                  circle={
                    graphProperties[
                      (p.dataKey as string).replace('-estimated', '')
                    ].type === 'line'
                  }
                  stacked={
                    graphProperties[
                      (p.dataKey as string).replace('-estimated', '')
                    ].type === 'stacked'
                  }
                >
                  {graphProperties[
                    (p.dataKey as string).replace('-estimated', '')
                  ].type === 'stacked' ? (
                    <p>
                      {
                        graphProperties[
                          (p.dataKey as string).replace('-estimated', '')
                        ].stack_id
                      }
                    </p>
                  ) : null}
                </Block>
                <span>
                  {
                    keys.find(
                      k =>
                        k.key ===
                        (p.dataKey as string).replace('-estimated', '')
                    )?.title
                  }
                </span>
                <span>
                  {formatFloat(p.value as number)}
                  {graphProperties.unit &&
                  !graphProperties[
                    (p.dataKey as string).replace('-estimated', '')
                  ].second_axis
                    ? ` ${graphProperties.unit}`
                    : ''}
                  {graphProperties.u_right &&
                  graphProperties[
                    (p.dataKey as string).replace('-estimated', '')
                  ].second_axis
                    ? ` ${graphProperties.u_right}`
                    : ''}
                  {dataObj &&
                  percentages[dataObj.id] &&
                  typeof percentages[dataObj.id] === 'number' ? (
                    <span className="percentage">
                      {' '}
                      ({formatFloat((percentages[dataObj.id] as number) * 100)}
                      %)
                    </span>
                  ) : null}
                </span>
              </div>
            </React.Fragment>
          )
        })}
      </GraphCard>
    )
  }

  getTimeline = () => {
    const {
      dates,
      organization: { activeOrganization, data },
      graphProperties,
      defaultYTDEndDate
    } = this.props
    return timeLineFromDatesAndOrganization(
      dates,
      data,
      activeOrganization,
      graphProperties,
      defaultYTDEndDate
    )
  }

  handleFilteredData = (
    inputDataEntry: RawData | DataEntry,
    dateData: DataByDate,
    filteredData: Array<RawData | DataEntry>
  ) => {
    const { graphProperties } = this.props

    let dataByDate = { ...dateData }
    const type = get(graphProperties, `[${inputDataEntry.key}].type`, '')
    const usedKey =
      ('is_verified' in inputDataEntry && inputDataEntry.is_verified) ||
      ['bar', 'stacked'].includes(type as string)
        ? inputDataEntry.key
        : `${inputDataEntry.key}-estimated`

    const values: { actual?: number; cumulative?: number } = {}

    values.actual = inputDataEntry.value
    values.cumulative = this.handleFilteredDataCumulative(
      inputDataEntry,
      filteredData
    )
    if (usedKey) {
      dataByDate[usedKey] = values
    }

    dataByDate = this.handleFilteredDataLastVerified(
      inputDataEntry,
      dataByDate,
      filteredData
    )

    return dataByDate
  }

  handleFilteredDataCumulative = (
    inputDataEntry: RawData | DataEntry,
    filteredData: Array<RawData | DataEntry>
  ) => {
    const {
      cumulative,
      timeGrouping,
      organization: { activeOrganization, data: orgData }
    } = this.props
    const org = findOrg(activeOrganization, orgData)
    const startOfFiscalYear = org
      ? parseInt(org.start_of_fiscal_year, 10) - 1
      : 0

    let returnValue = 0

    // select the correct field to use
    const grouping = timeGrouping.selectedGrouping
    let cumulativeField: 'cumulative_value' | 'value' = 'cumulative_value'
    if (grouping === GROUPING_MONTH) {
      cumulativeField = 'cumulative_value'
    }
    if ([GROUPING_FISCAL, GROUPING_QUARTER].includes(grouping)) {
      if (cumulative) {
        cumulativeField = 'cumulative_value'
      } else {
        cumulativeField = 'value'
      }
    }

    // use the selected field for formula/cumulative value if possible, otherwise
    // cumulate on fly:
    if (inputDataEntry[cumulativeField] !== null && inputDataEntry[cumulativeField] !== undefined) {
      returnValue = inputDataEntry[cumulativeField] as number
    } else {
      const startDate = moment(inputDataEntry.start_date)
      const cumulationStartDate =
        startDate.month() < startOfFiscalYear
          ? startDate.subtract(1, 'year').set('month', startOfFiscalYear)
          : startDate.set('month', startOfFiscalYear)
      const cumulationEndDate = moment(inputDataEntry.start_date)
      let sum = 0
      const relatedFilteredData = filteredData.filter(
        fd => fd.key === inputDataEntry.key
      )

      const dataToCumulate = relatedFilteredData.filter(
        fd =>
          cumulationStartDate.unix() <= moment(fd.start_date).unix() &&
          moment(fd.start_date).unix() <= cumulationEndDate.unix()
      )

      dataToCumulate.forEach(fd => {
        sum += fd?.value || 0
      })
      returnValue += sum
    }
    return returnValue
  }

  handleFilteredDataLastVerified = (
    inputDataEntry: RawData | DataEntry,
    dataByDate: DataByDate,
    filteredData: Array<RawData | DataEntry>
  ) => {
    const { graphProperties } = this.props
    const type = get(graphProperties, `[${inputDataEntry.key}].type`, '')
    const usedKey =
      inputDataEntry.is_verified || ['bar', 'stacked'].includes(type as string)
        ? (inputDataEntry.key as string)
        : `${inputDataEntry.key}-estimated`
    const lastVerified = filteredData.some(
      d3 =>
        d3.key === inputDataEntry.key &&
        'is_verified' in d3 &&
        !d3.is_verified &&
        inputDataEntry.is_verified &&
        inputDataEntry.start_date ===
          moment(d3.start_date)
            .subtract(1, 'month')
            .format(ISO_FORMAT_DATE)
    )
    const returnValue = dataByDate

    if (
      inputDataEntry.is_verified &&
      lastVerified &&
      !['bar', 'stacked'].includes(type as string) &&
      filteredData.some(
        element =>
          'is_verified' in element &&
          !element.is_verified &&
          element.key === inputDataEntry.key
      )
    ) {
      returnValue[`${inputDataEntry.key}-estimated`] = {
        ...(dataByDate[usedKey] as DataInput)
      }
    }
    return returnValue
  }

  generateData = () => {
    const { data } = this.props
    const { filteredKeys } = this.state

    let { start, stop } = this.getTimeline()
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('start-date') && urlParams.get('end-date')) {
      start = moment(urlParams.get('start-date'))
      stop = moment(urlParams.get('end-date'))
    }

    const dates = this.buildDates(start, stop)
    const filteredData = data.filter(
      d =>
        (filteredKeys && filteredKeys.length === 0) ||
        (filteredKeys && filteredKeys.includes(d.key))
    )

    const mappedData: Array<DataByDate> = []
    dates.forEach(currentDateEntry => {
      const dataForDate = this.generateDataForDate(
        currentDateEntry,
        filteredData
      )
      mappedData.push(dataForDate)
    })
    return mappedData
  }

  generateDataForDate = (
    currentDateEntry: string,
    filteredData: Array<RawData | DataEntry>
  ) => {
    const { targetAsGraph } = this.props
    const { graphTarget } = this.state
    let dataByDate: DataByDate = {
      name: currentDateEntry
    }
    if (
      graphTarget !== null &&
      targetAsGraph &&
      targetAsGraph[currentDateEntry] &&
      targetAsGraph[currentDateEntry].target_value
    ) {
      dataByDate.targetValue = {
        actual: targetAsGraph[currentDateEntry].target_value,
        cumulative: targetAsGraph[currentDateEntry].target_value
      }
    }

    let filteredDataForDate = filteredData.filter(
      element => element.start_date === currentDateEntry
    )

    filteredDataForDate = this.addMissingEntries(
      currentDateEntry,
      filteredDataForDate
    )

    filteredDataForDate.forEach(filteredDataEntry => {
      dataByDate = this.handleFilteredData(
        filteredDataEntry,
        dataByDate,
        filteredData
      )
    })

    return dataByDate
  }

  addMissingEntries = (
    currentDateEntry: string,
    filteredDataForDate: Array<RawData | DataEntry>
  ) => {
    const { cumulative, keys } = this.props
    const { filteredKeys } = this.state

    if (cumulative) {

      let missingTerms = []
      if (filteredDataForDate.length !== 0) {
        missingTerms = keys.filter(
          currentKey =>
            !filteredDataForDate.some(element => element.key === currentKey.key)
        )
      }
      else {
        missingTerms = keys
      }
      missingTerms.forEach(mt => {
        if (
          filteredKeys &&
          filteredKeys.length &&
          !filteredKeys.includes(mt.key)
        ) {
          return
        }
        const entry: DataEntry = {
          key: mt.key,
          title: mt.title,
          value: 0,
          start_date: currentDateEntry
        }
        filteredDataForDate.push(entry)
      })
    }
    return filteredDataForDate
  }

  buildDates = (start: moment.Moment, stop: moment.Moment) => {
    stop.format(ISO_FORMAT_DATE)
    const {
      timeGrouping,
      organization: { activeOrganization, data: orgData }
    } = this.props
    const dates = []
    const counter = moment(start)

    while (counter <= stop) {
      dates.push(counter.format(ISO_FORMAT_DATE))
      if (counter.year() === stop.year() && counter.month() === stop.month()) {
        break
      }
      counter.add(1, 'month')
    }

    // filter partial time periods out
    // according to the selected time grouping:
    // this is done by "squeezing" the start/end dates inwards
    // towards the start/end of month/quarter/fiscal
    const grouping = timeGrouping.selectedGrouping
    if (grouping === GROUPING_MONTH) {
      return dates
    }

    const org = findOrg(activeOrganization, orgData)
    const fiscalStartMonth = org ? parseInt(org.start_of_fiscal_year, 10) : 1
    let adjustedMinDate: moment.Moment | null = null
    let adjustedMaxDate: moment.Moment | null = null

    // note that the toEnd/Start-functions below keep their input as is
    // if it already is in the start/end month of the desired time period.
    if (grouping === GROUPING_FISCAL) {
      adjustedMinDate = dateToNextFiscalStartMonth(start, fiscalStartMonth)
      adjustedMaxDate = dateToPreviousFiscalEndMonth(stop, fiscalStartMonth)
    }
    if (grouping === GROUPING_QUARTER) {
      adjustedMinDate = dateToNextQuarterStartMonth(start, fiscalStartMonth)
      adjustedMaxDate = dateToPreviousQuarterEndMonth(stop, fiscalStartMonth)
    }

    const filteredDates = dates.filter(element => {
      if (adjustedMaxDate === null || adjustedMinDate === null) {
        return
      }

      return (
        adjustedMinDate.unix() <= moment(element, ISO_FORMAT_DATE).unix() &&
        moment(element, ISO_FORMAT_DATE).unix() <= adjustedMaxDate.unix()
      )
    })

    // after "squeezing" we may be in a situation where we have some filtered
    // dates but not enough to cover a full quarter/fiscal:

    // no full fiscals => return empty list
    if (grouping === GROUPING_FISCAL && filteredDates.length < 12) {
      return []
    }

    // no full quarters => return empty list
    if (grouping === GROUPING_QUARTER && filteredDates.length < 3) {
      return []
    }

    return filteredDates
  }

  groupMappedData = (mappedData: Array<DataByDate>) => {
    const {
      timeGrouping,
      cumulative,
      organization: { activeOrganization, data: orgData }
    } = this.props
    const grouping = timeGrouping.selectedGrouping

    const org = findOrg(activeOrganization, orgData)
    const fiscalStartMonth = org ? parseInt(org.start_of_fiscal_year, 10) : 1

    // init all the relevant sums.
    // we'll use two loops in order to have all the initialization done
    // before the actual summing loop.
    const sums = this.initGroupSums(mappedData, grouping, fiscalStartMonth)

    // perform the actual grouping/summing on sorted data.
    // the data is sorted alphabetically by currentRow.name, ascending.
    mappedData.sort().forEach(currentRow => {
      const groupKey = dateToGroupKey(
        grouping,
        currentRow.name as string,
        fiscalStartMonth
      )

      Object.keys(currentRow).forEach(currentKey => {
        if (currentKey === 'name') {
          return
        }
        const keyWithoutEstimated = currentKey.replace('-estimated', '')
        // building on top of keyWithoutEstimated prevents double-estimated
        const keyWithEstimated = `${keyWithoutEstimated}-estimated`

        const groupHasEstimate = Object.prototype.hasOwnProperty.call(
          sums[groupKey],
          keyWithEstimated
        )
        const groupHasVerified = Object.prototype.hasOwnProperty.call(
          sums[groupKey],
          keyWithoutEstimated
        )
        const rowHasEstimate = Object.prototype.hasOwnProperty.call(
          currentRow,
          keyWithEstimated
        )

        // use formula/cumulative if needed.
        // note that:
        // - pre-calculated cumulative values are not summed here,
        // the value is returned as is as it is pre-calculated in the backend.
        // - if/when multiple rows match the current group, the latest value will be used
        // (this is why we're explicitly sorting mappedData before calling forEach)
        // - we want to have matching values if the group contains both variants
        // (estimate/verified) of the current key in order to avoid jumps in the graph.
        // This is achieved by using the latest value and preferring the estimate
        // in both variants if both variants are present in current group

        if (
          cumulative ||
          [GROUPING_QUARTER, GROUPING_FISCAL].includes(grouping)
        ) {
          // check whether current group has both variants:
          if (groupHasEstimate && groupHasVerified) {
            // if the row has estimate, use it.
            if (rowHasEstimate) {
              ;(sums[groupKey][keyWithEstimated] as number) =
                (currentRow[keyWithEstimated] as DataInput).cumulative || 0
              ;(sums[groupKey][keyWithoutEstimated] as number) =
                (currentRow[keyWithEstimated] as DataInput).cumulative || 0
              return
            }
          }

          ;(sums[groupKey][currentKey] as number) =
            (currentRow[currentKey] as DataInput).cumulative || 0
          return
        }

        // if useFormula and cumulative are not in use, perform the actual summing.
        // increment both the verified value and the estimate.
        // (needed to connect verified values with estimates)
        // The if-condition avoids double contribution from currentRows which have both variants in it.
        if (
          // increment the sum only if the current row does not have both variants
          // or if the current key equals the key of the verified value,
          // IE. prevent summing the estimate when currentRow contains both variants.
          !(
            currentKey === keyWithEstimated &&
            currentRow[keyWithoutEstimated] &&
            currentRow[keyWithEstimated]
          )
        ) {
          // increment both the verified value and the estimate,
          // just check if they have been initialized for the current group.
          if (groupHasVerified) {
            ;(sums[groupKey][keyWithoutEstimated] as number) +=
              (currentRow[currentKey] as DataInput).actual || 0
          }
          if (groupHasEstimate) {
            ;(sums[groupKey][keyWithEstimated] as number) +=
              (currentRow[currentKey] as DataInput).actual || 0
          }
        }
      })
    })

    const result: Array<{ [key: string]: string | number }> = []
    Object.keys(sums).forEach(currentKey => {
      const value = sums[currentKey]
      value.name = currentKey
      result.push(value)
    })

    return result
  }

  initGroupSums = (
    mappedData: Array<DataByDate>,
    grouping: string,
    fiscalStartMonth: number
  ) => {
    const sums: {
      [key: string]: {
        [key: string]: number | string
      }
    } = {}

    mappedData.forEach(currentRow => {
      const groupKey = dateToGroupKey(
        grouping,
        currentRow.name as string,
        fiscalStartMonth
      )
      if (!Object.prototype.hasOwnProperty.call(sums, groupKey)) {
        sums[groupKey] = {}
      }
      Object.keys(currentRow).forEach(currentKey => {
        if (currentKey === 'name') {
          return
        }
        if (!Object.prototype.hasOwnProperty.call(sums[groupKey], currentKey)) {
          sums[groupKey][currentKey] = 0
        }
      })
    })
    return sums
  }

  renderTarget = () => {
    const { graphProperties, targetAsGraph } = this.props
    const { graphTarget } = this.state
    const targetProperties = graphProperties && graphProperties.target
    if (targetProperties && targetAsGraph && graphTarget) {
      switch (targetProperties.type) {
        case 'line':
          return (
            <Line
              type="monotone"
              label={<>{graphTarget.title}</>}
              yAxisId={targetProperties.second_axis ? 1 : 0}
              dataKey="targetValue"
              strokeWidth={3}
              stroke={targetProperties.color || '#ffd800'}
            />
          )
        case 'bar':
          return (
            <Bar
              label={<>{graphTarget.title}</>}
              yAxisId={targetProperties.second_axis ? 1 : 0}
              dataKey="targetValue"
              stroke={targetProperties.color || '#ffd800'}
              fill={targetProperties.color || '#ffd800'}
            />
          )
        case 'area':
          return (
            <Area
              type="monotone"
              label={<>{graphTarget.title}</>}
              yAxisId={targetProperties.second_axis ? 1 : 0}
              dataKey="targetValue"
              stroke={targetProperties.color || '#ffd800'}
              fill={targetProperties.color || '#ffd800'}
            />
          )
        default:
          return (
            <Line
              type="monotone"
              label={<>{graphTarget.title}</>}
              yAxisId={targetProperties.second_axis ? 1 : 0}
              dataKey="targetValue"
              strokeWidth={3}
              stroke={targetProperties.color || '#ffd800'}
            />
          )
      }
    }

    return null
  }

  getAxisBoundaries = (values: Array<number>, values2: Array<number>) => {
    const { graphProperties } = this.props
    let yAxisMin =
      graphProperties && typeof graphProperties.y_axis_min !== 'undefined'
        ? parseInt(graphProperties.y_axis_min, 10)
        : Math.min(...values) -
          Math.abs(Math.max(...values.map(v => Math.abs(v))) * 0.1)
    let yAxisMax =
      graphProperties && typeof graphProperties.y_axis_max !== 'undefined'
        ? parseInt(graphProperties.y_axis_max, 10)
        : Math.max(...values) +
          Math.abs(Math.max(...values.map(v => Math.abs(v))) * 0.1)
    let yAxis2Min =
      graphProperties && typeof graphProperties.y_axis_2_min !== 'undefined'
        ? parseInt(graphProperties.y_axis_2_min, 10)
        : Math.min(...values2) -
          Math.abs(Math.max(...values2.map(v => Math.abs(v))) * 0.1)
    let yAxis2Max =
      graphProperties && typeof graphProperties.y_axis_2_max !== 'undefined'
        ? parseInt(graphProperties.y_axis_2_max, 10)
        : Math.max(...values2) +
          Math.abs(Math.max(...values2.map(v => Math.abs(v))) * 0.1)
    if (values2.length > 1) {
      if (values.length === 1) {
        yAxisMax = 0
        yAxisMin = 0
      }
      if (yAxisMin === 0 && yAxisMax === 0) {
        yAxisMin = yAxis2Min
        yAxisMax = yAxis2Max
      } else if (yAxis2Min === 0 && yAxis2Max === 0) {
        yAxis2Min = yAxisMin
        yAxis2Max = yAxisMax
      } else {
        while (
          Math.abs(yAxis2Min) / Math.abs(yAxis2Max) <
          Math.abs(yAxisMin) / Math.abs(yAxisMax)
        ) {
          yAxis2Min -= 1
        }
        while (
          Math.abs(yAxis2Max) / Math.abs(yAxis2Min) <
          Math.abs(yAxisMax) / Math.abs(yAxisMin)
        ) {
          yAxis2Max += 1
        }
      }
    }
    return [yAxisMax, yAxisMin, yAxis2Max, yAxis2Min]
  }

  getMappedDataInArray = (
    mappedData: Array<{ [key: string]: string | number }>
  ) => {
    const { graphProperties } = this.props
    const values: Array<number> = [0]
    const values2: Array<number> = [0]

    mappedData.forEach(entry =>
      Object.keys(entry).forEach(key => {
        if (key === 'name') {
          return
        }
        const value = entry[key]
        let cumulativeValue = entry[key]
        const keyWithoutEstimated = key
            .replace('-estimated', '')
            .replace('targetValue', 'target')
        if (
          graphProperties[keyWithoutEstimated] &&
          graphProperties[keyWithoutEstimated].stack_id
        ) {
          Object.keys(graphProperties).forEach(propertyKey => {
            if (
              propertyKey !== keyWithoutEstimated &&
              graphProperties[propertyKey].stack_id ===
                graphProperties[keyWithoutEstimated].stack_id &&
              entry[propertyKey] &&
              Math.sign(entry[propertyKey] as number) ===
                Math.sign(entry[keyWithoutEstimated] as number)
            ) {
              ;(cumulativeValue as number) += entry[propertyKey] as number
            }
          })
        }
        if (
          graphProperties[keyWithoutEstimated] &&
          graphProperties[keyWithoutEstimated].second_axis
        ) {
          values2.push(value as number)
          values2.push(cumulativeValue as number)
        } else {
          values.push(value as number)
          values.push(cumulativeValue as number)
        }
      })
    )
    return [values, values2]
  }

  buildValuesForRadarChart = () => {
    const { data, keys, cumulative, graphProperties } = this.props

    const values: Map<string, number> = new Map()

    for (const currentKey of keys) {
      //get data for the key, sorted by ascending start date.
      const dataForKey: Array<RawData> = data
        .filter(element => element.key === currentKey.key)
        .sort((a, b) => {
          const momentA = moment(a.start_date, ISO_FORMAT_DATE)
          const momentB = moment(b.start_date, ISO_FORMAT_DATE)
          if (momentA < momentB) {
            return -1
          }
          if (momentA == momentB) {
            return 0
          }
          // by now momentA > momentB
          return 1
        })

      // get latest cumulative value for the key
      const latestCumulativeValue: number =
        dataForKey[dataForKey.length - 1]?.cumulative_value ?? 0

      // set actual values
      const useFrontendCumulation: boolean =
        //explicit comparison to true due to possible undefined
        cumulative === true && !graphProperties[currentKey.key].cumulative_value

      const valueForKey: number = !useFrontendCumulation
        ? latestCumulativeValue
        : dataForKey.reduce((total, element) => total + element.value, 0)

      values.set(currentKey.key, valueForKey | 0)
    }

    return values
  }

  renderRadarChart = () => {
    const { keys, graphProperties, theme } = this.props

    let radarData = []
    const values: Map<string, number> = this.buildValuesForRadarChart()

    const filteredKeys = this.state.filteredKeys || []

    let filteredValues: Map<string, number> = new Map()

    //there's no point to display a radar chart with only one or two
    //selected terms (the chart degenerates to a line)
    //=> filter only if multiple selected terms
    const minimumKeyForFiltering = 3
    const applyFiltering =
      filteredKeys && filteredKeys.length >= minimumKeyForFiltering

    //use values by default, build a new map if actual filtering is applied
    filteredValues = values
    if (applyFiltering) {
      filteredValues = new Map()
      values.forEach((value, key) => {
        if (filteredKeys.includes(key)) {
          filteredValues.set(key, value)
        }
      })
    }

    // get the max
    let maxValue = 0
    filteredValues.forEach(value => {
      const val = Math.ceil(value ?? 0)
      if (val > maxValue) {
        maxValue = val
      }
    })

    // build radarData
    for (const key of keys) {
      if (!applyFiltering || filteredKeys.includes(key.key)) {
        const value = Math.ceil(values.get(key.key) ?? 0)
        radarData.push({
          subject: key.title,
          A: value,
          fullMark: maxValue,
          key: key.key
        })
      }
    }

    // sort radarData by title/subject to enforce
    // consistent appearance
    radarData = radarData.sort((a, b) => {
      if (a.subject < b.subject) {
        return -1
      }
      if (a.subject > b.subject) {
        return 1
      }
      return 0
    })

    const urlParams = new URLSearchParams(window.location.search)
    const isAnimationActive = urlParams.get('no-animation') === null

    // total sum of angles for polygon in degrees = (number of edges-2)*180
    // radarData.length >= 3 due to conditional filtterings
    // unless there are at most two terms to begin with,
    // but then the chart degenerates anyway
    const angleBetweenTerms = ((radarData.length - 2) * 180) / radarData.length

    const numberAxisOrientationAngle = angleBetweenTerms / 2

    return (
      <CardContainer isAnimationActive={isAnimationActive}>
        <ResponsiveContainer width="100%" height={900}>
          <RadarChart
            style={{ margin: 'auto' }}
            outerRadius={'95%'}
            data={radarData}
          >
            <PolarGrid gridType="circle" />
            <PolarAngleAxis
              tick={{ fill: theme.text.primary, fontSize: 14 }}
              dataKey="subject"
            />

            <PolarRadiusAxis
              angle={numberAxisOrientationAngle}
              tickFormatter={tick => {
                const unitSuffix = graphProperties.unit
                  ? ' ' + graphProperties.unit
                  : ''

                // '__ ' is prefixed to force some orthogonal margin between the number
                // and the axis. Recharts seems to trim leading whitespace.
                return '__ ' + tick.toLocaleString('fi') + unitSuffix
              }}
            />

            <Radar
              dataKey="A"
              stroke={graphProperties.target.color}
              fill={graphProperties.target.color}
              fillOpacity={0.6}
            />
            <Tooltip
              content={this.renderRadarChartToolTip}
              // prevent legend from rendering over the tooltip
              wrapperStyle={{ zIndex: 1 }}
            />
          </RadarChart>
        </ResponsiveContainer>
        {this.renderLegend()}
      </CardContainer>
    )
  }

  renderRadarChartToolTip = (props: TooltipProps) => {
    const { keys, graphProperties, percentages, data, t } = this.props
    const { graphTarget, filteredKeys } = this.state

    const date = this.getGroupingStartFromLabel(props.label)

    const values: Map<string, number> = this.buildValuesForRadarChart()

    //build payload
    const payload: TooltipPayload[] = []
    keys
      .map(key => key.key)
      .forEach(key => {
        //prevent duplicates
        if (
          payload.some(
            p => p.dataKey?.toString().replace('-estimated', '') === key
          )
        ) {
          return
        }
        //prevent keys that are not in filteredKeys
        if (
          filteredKeys &&
          filteredKeys.length > 0 &&
          !filteredKeys.includes(key)
        ) {
          return
        }

        payload.push({
          name: key,
          dataKey: key,
          value: values.get(key) || 0
        })
      })

    payload.sort((a, b) => this.compareTooltipData(a, b))

    const graphCardHeaderText = props.label as string

    return (
      <GraphCard>
        <div className="title-container">
          <h5>{graphCardHeaderText}</h5>
        </div>
        <div className="title-container">
          <h6>{t('tooltip-term-label')}</h6>
          <h6>{t('tooltip-value-label')}</h6>
        </div>
        {payload.reverse().map(payloadElement => {
          //"shortened" as in '-estimated'-suffix has been removed.
          const shortenedElementKey = payloadElement.dataKey
            ?.toString()
            .replace('-estimated', '')

          const dataObj = data.find(
            d => d.key === shortenedElementKey && d.start_date === date
          )

          if (payloadElement.dataKey === 'targetValue') {
            return (
              <div key="targetValue">
                <Block
                  color={(graphProperties.target || {}).color || '#ffd800'}
                  circle={graphProperties.target.type === 'line'}
                  stacked={graphProperties.target.type === 'stacked'}
                />
                <span>{graphTarget?.title}</span>
                <span>
                  {formatFloat(payloadElement.value as number)}
                  {graphProperties.unit ? ` ${graphProperties.unit}` : ''}
                </span>
              </div>
            )
          }

          const hasPercentageData = (dataObj &&
            percentages[dataObj.id] &&
            typeof percentages[dataObj.id] === 'number') as boolean

          const formattedPercentage =
            hasPercentageData && dataObj !== undefined
              ? formatFloat((percentages[dataObj.id] as number) * 100)
              : 0

          // "duplicate estimate" as in the payload also contains
          // the non-estimate variant
          const isDuplicateEstimate = (typeof payloadElement.dataKey ===
            'string' &&
            payloadElement.dataKey.endsWith('-estimated') &&
            props.payload?.some(
              p2 =>
                typeof payloadElement.dataKey === 'string' &&
                p2.dataKey === payloadElement.dataKey.replace('-estimated', '')
            )) as boolean

          const isStacked = (graphProperties[shortenedElementKey as string]
            .type === 'stacked') as boolean

          return isDuplicateEstimate ? null : (
            <React.Fragment key={payloadElement.dataKey as string}>
              <div>
                <Block
                  color={graphProperties[shortenedElementKey as string].color}
                  circle={
                    graphProperties[shortenedElementKey as string].type ===
                    'line'
                  }
                  stacked={isStacked}
                >
                  {isStacked ? (
                    <p>
                      {graphProperties[shortenedElementKey as string].stack_id}
                    </p>
                  ) : null}
                </Block>
                <span>
                  {
                    keys.find(k => k.key === (shortenedElementKey as string))
                      ?.title
                  }
                </span>
                <span>
                  {formatFloat(payloadElement.value as number)}
                  {` ${graphProperties.unit}`}
                  {hasPercentageData ? (
                    <span className="percentage">
                      {' '}
                      ({formattedPercentage}%)
                    </span>
                  ) : null}
                </span>
              </div>
            </React.Fragment>
          )
        })}
      </GraphCard>
    )
  }

  render() {
    const {
      data,
      keys,
      graphProperties,
      graphTarget,
      darkNumbers,
      height,
      isPending,
      organization: { activeOrganization, data: orgData },
      timeGrouping: { selectedGrouping: grouping },
      cumulative
    } = this.props
    if (!data) {
      return null
    }
    const mappedData = this.generateData()
    const groupedData = this.groupMappedData(mappedData)
    const urlParams = new URLSearchParams(window.location.search)
    const isAnimationActive = urlParams.get('no-animation') === null

    const [values, values2] = this.getMappedDataInArray(groupedData)
    const [yAxisMax, yAxisMin, yAxis2Max, yAxis2Min] = this.getAxisBoundaries(
      values,
      values2
    )
    const tickCount =
      graphProperties && typeof graphProperties.tick_count !== 'undefined'
        ? parseInt(graphProperties.tick_count, 10)
        : 9

    const org = findOrg(activeOrganization, orgData)
    const startOfFiscalYear = org
      ? parseInt(org.start_of_fiscal_year, 10) - 1
      : 0

    if (graphProperties.target.type === 'radar') {
      return this.renderRadarChart()
    }
    return (
      <CardContainer isAnimationActive={isAnimationActive}>
        <ChartContainer
          height={height || '500px'}
          ref={this.containerRef}
          isAnimationActive={isAnimationActive}
        >
          {isPending ? null : (
            <ResponsiveContainer height="100%" width="100%">
              <ComposedChart
                data={groupedData}
                stackOffset="sign"
                ref={this.chartRef}
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              >
                <pattern
                  id="pattern-stripe"
                  width="3"
                  height="3"
                  patternUnits="userSpaceOnUse"
                  patternTransform="rotate(45)"
                >
                  <rect
                    width="1.5"
                    height="3"
                    transform="translate(0,0)"
                    fill="white"
                  />
                </pattern>
                <mask id="mask-stripe">
                  <rect
                    x="0"
                    y="0"
                    width="100%"
                    height="100%"
                    fill="url(#pattern-stripe)"
                  />
                </mask>
                <defs>
                  {Object.keys(graphProperties || {}).map(k => (
                    <linearGradient
                      key={k}
                      id={`color${k}`}
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop
                        offset="0%"
                        stopColor={graphProperties[k].color}
                        stopOpacity={1.0}
                      />
                      <stop
                        offset="100%"
                        stopColor={graphProperties[k].color}
                        stopOpacity={1.0}
                      />
                    </linearGradient>
                  ))}
                </defs>
                {graphTarget && (
                  <ReferenceLine
                    y={graphTarget.target_value}
                    label={
                      <Label
                        value={graphTarget.title}
                        fill={darkNumbers ? '#333' : 'white'}
                      />
                    }
                    stroke="#ffd800"
                    fill="white"
                    strokeDasharray="3 3"
                  />
                )}
                <CartesianGrid
                  vertical={false}
                  stroke={darkNumbers ? '#aaa' : '#efefef44'}
                />
                <XAxis
                  dataKey="name"
                  tick={{ fontSize: 10, fill: darkNumbers ? '#333' : '#fff' }}
                />
                <YAxis
                  allowDataOverflow
                  yAxisId={0}
                  tick={{
                    fontSize: 13,
                    fill: darkNumbers ? '#333' : '#fff'
                  }}
                  tickLine={{ stroke: '#000' }}
                  tickCount={tickCount}
                  domain={[
                    yAxisMin,
                    Math.max(
                      yAxisMax,
                      graphTarget ? graphTarget.target_value * 1.01 : yAxisMax
                    )
                  ]}
                  scale="linear"
                  width={110}
                  tickFormatter={v =>
                    `${
                      graphProperties.show_decimals
                        ? formatFloat(v)
                        : formatInt(v)
                    } ${graphProperties.unit ? graphProperties.unit : ''}`
                  }
                />
                {graphProperties &&
                Object.values(graphProperties).some(
                  s =>
                    typeof s === 'object' && !Array.isArray(s) && s.second_axis
                ) ? (
                  <YAxis
                    yAxisId={1}
                    orientation="right"
                    scale="linear"
                    allowDataOverflow
                    domain={[yAxis2Min, yAxis2Max]}
                    tick={{
                      fontSize: 13,
                      fill: darkNumbers ? '#333' : '#fff'
                    }}
                    tickLine={{ stroke: '#000' }}
                    tickCount={tickCount}
                    width={110}
                    tickFormatter={v =>
                      `${
                        graphProperties.show_decimals
                          ? formatFloat(v)
                          : formatInt(v)
                      } ${
                        graphProperties.u_right ? graphProperties.u_right : ''
                      }`
                    }
                  />
                ) : null}
                <Tooltip
                  content={this.renderColorfulTooltipText}
                  // prevent legend from rendering over the tooltip
                  wrapperStyle={{ zIndex: 1 }}
                />
                <ReferenceLine
                  x={moment().format(PRESENTATION_MONTH_FORMAT)}
                  stroke="#747474"
                />
                {keys.map(
                  k =>
                    graphProperties &&
                    graphProperties[k.key] &&
                    graphProperties[k.key].type === 'bar' && (
                      <Bar
                        key={k.key}
                        yAxisId={graphProperties[k.key].second_axis ? 1 : 0}
                        label={<>{k.title}</>}
                        dataKey={k.key}
                        fill={`url(#color${k.key})`}
                        isAnimationActive={isAnimationActive}
                        shape={
                          <CustomShape
                            term={k}
                            rawData={data}
                            startOfFiscal={startOfFiscalYear}
                            grouping={grouping}
                            cumulative={cumulative}
                          />
                        }
                      />
                    )
                )}
                {keys.map(
                  k =>
                    graphProperties &&
                    graphProperties[k.key] &&
                    graphProperties[k.key].type === 'stacked' && (
                      <Bar
                        key={k.key}
                        yAxisId={graphProperties[k.key].second_axis ? 1 : 0}
                        label={<>{k.title}</>}
                        stackId={graphProperties[k.key].stack_id as string}
                        dataKey={k.key}
                        fill={`url(#color${k.key})`}
                        isAnimationActive={isAnimationActive}
                        shape={
                          <CustomShape
                            term={k}
                            rawData={data}
                            startOfFiscal={startOfFiscalYear}
                            grouping={grouping}
                            cumulative={cumulative}
                          />
                        }
                      />
                    )
                )}
                {keys.map(
                  k =>
                    graphProperties &&
                    graphProperties[k.key] &&
                    graphProperties[k.key].type === 'area' && (
                      <Area
                        key={k.key}
                        yAxisId={graphProperties[k.key].second_axis ? 1 : 0}
                        type="monotone"
                        dataKey={k.key}
                        strokeWidth={5}
                        fill={`url(#color${k.key})`}
                        isAnimationActive={isAnimationActive}
                        stroke={
                          graphProperties && graphProperties[k.key]
                            ? graphProperties[k.key].color
                            : '#8884d8'
                        }
                      />
                    )
                )}
                {keys.map(
                  k =>
                    graphProperties &&
                    graphProperties[k.key] &&
                    graphProperties[k.key].type === 'area' && (
                      <Area
                        key={`${k.key}-estimated`}
                        yAxisId={graphProperties[k.key].second_axis ? 1 : 0}
                        type="monotone"
                        dataKey={`${k.key}-estimated`}
                        strokeWidth={5}
                        strokeDasharray="5 5"
                        fill={`url(#color${k.key})`}
                        isAnimationActive={isAnimationActive}
                        stroke={
                          graphProperties && graphProperties[k.key]
                            ? graphProperties[k.key].color
                            : '#8884d8'
                        }
                      />
                    )
                )}
                {keys.map(
                  k =>
                    graphProperties &&
                    graphProperties[k.key] &&
                    graphProperties[k.key].type === 'line' && (
                      <Line
                        key={k.key}
                        yAxisId={graphProperties[k.key].second_axis ? 1 : 0}
                        type="monotone"
                        label={<>{k.title}</>}
                        dataKey={k.key}
                        strokeWidth={5}
                        isAnimationActive={isAnimationActive}
                        stroke={
                          graphProperties && graphProperties[k.key]
                            ? graphProperties[k.key].color
                            : '#8884d8'
                        }
                      />
                    )
                )}
                {keys.map(
                  k =>
                    graphProperties &&
                    graphProperties[k.key] &&
                    graphProperties[k.key].type === 'line' && (
                      <Line
                        key={`${k.key}-estimated`}
                        yAxisId={graphProperties[k.key].second_axis ? 1 : 0}
                        type="monotone"
                        label={<>{k.title}</>}
                        dataKey={`${k.key}-estimated`}
                        strokeWidth={5}
                        strokeDasharray="3 3"
                        isAnimationActive={isAnimationActive}
                        stroke={
                          graphProperties && graphProperties[k.key]
                            ? graphProperties[k.key].color
                            : '#8884d8'
                        }
                      />
                    )
                )}
                {this.renderTarget()}
              </ComposedChart>
            </ResponsiveContainer>
          )}
        </ChartContainer>
        {this.renderLegend()}
      </CardContainer>
    )
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    { getTargetAsGraph, setTargetToGraph, getPercentageValue },
    dispatch
  )

const mapStateToProps = ({
  organization,
  timeGrouping,
  target: { graphTarget, targetAsGraph },
  rawData: { isPending, pendingPercentages, percentages }
}: RootState) => ({
  organization,
  timeGrouping,
  graphTarget,
  targetAsGraph,
  isPending,
  pendingPercentages,
  percentages
})

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ChartArea)
)

import React, { Component } from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Roadmap from 'components/roadmap/Roadmap'
import { listRoadmap } from 'store/actions/roadmap'
import { bindActionCreators } from 'redux'
import 'assets/scss/react-tabs.scss'
import { getOrganizationUsers } from 'store/actions/organization'
import { triggerRefresh } from 'store/actions/task'
import CreateRoadmapModal from 'components/modals/CreateRoadmapModal'
import styled from 'styled-components'
import RemoveRoadmapModal from 'components/modals/RemoveRoadmapModal'
import EditRoadmapModal from 'components/modals/EditRoadmapModal'
import { Title } from 'components/blocks'
import LoadingIndicator from 'components/blocks/LoadingIndicator'
import { listTarget } from 'store/actions/target'
import { RoadmapOrderModal } from 'components/modals'
import { getRawDataTimespan } from 'store/actions/rawdata'
import moment from 'moment'
import { ISO_FORMAT_DATE } from 'util/dates'
import { RootState } from 'store/reducers'
import { AppDispatch } from 'store/store'

const StyledTab = styled(Tab)`
  display: inline-flex;
  align-items: center;
  line-height: 1;
  min-height: 30px;
  &.react-tabs__tab {
    background-color: ${props => props.theme.roadMap.tabBg};
    color: ${props => props.theme.roadMap.tabColor};

    &--selected {
      background-color: ${props => props.theme.roadMap.selectedTabBg};
      color: ${props => props.theme.roadMap.selectedTabColor};

      .icon {
        background-color: rgb(206, 32, 51);
        &.edit-button {
          background-color: #aaa;
        }
      }
    }
  }
  .icon {
    margin-left: 10px;
  }
`
const TitleContainer = styled.div`
  margin-bottom: 26px;
  span {
    font-size: 0.8rem;
    color: ${props => props.theme.text.primary};
    font-family: 'Open Sans';
    cursor: pointer;
    text-decoration: underline;
  }
`
const RoadMapsContainer = styled.div`
  .react-tabs__tab-list {
    margin: 0;
    border-bottom: none;
  }
  .react-tabs__tab-panel {
    display: flex;
    position: relative;
    width: calc(100% + 58px);
    padding-bottom: 30px;
    background: ${props => props.theme.roadMap.background};
    left: -30px;
    right: -28px;
    top: -2px;
    bottom: -30px;
    > div {
      padding: 0 30px;
    }
  }
`

const LoadOverlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #33333355;
  z-index: 1000;
`

type RoadmapsReduxState = ReturnType<typeof mapStateToProps>
type RoadmapsReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface RoadmapsProps {
  t: TFunction
}

interface RoadmapsState {
  results: RootState['roadmap']['list']['results']
  updateTime: number | null
  tabIndex: number
  showRoadmapOrderModal: boolean
}

class Roadmaps extends Component<
  RoadmapsProps & RoadmapsReduxState & RoadmapsReduxDispatch,
  RoadmapsState
> {
  constructor(
    props: RoadmapsProps & RoadmapsReduxState & RoadmapsReduxDispatch
  ) {
    super(props)
    this.state = {
      results: [],
      updateTime: null,
      tabIndex: 1,
      showRoadmapOrderModal: false
    }
  }

  async componentDidMount() {
    const {
      listTarget: listTargetAction,
      getRawDataTimespan: getRawDataTimespanAction
    } = this.props
    await getRawDataTimespanAction(true, true)

    const { timeSpan } = this.props

    await listTargetAction({
      start_date: moment(timeSpan.end_date)
        .startOf('month')
        .format(ISO_FORMAT_DATE),
      end_date: moment(timeSpan.end_date)
        .endOf('month')
        .format(ISO_FORMAT_DATE),
      in_target_groups: true
    })
    await this.refresh()
    const {
      roadmap: {
        list: { results }
      }
    } = this.props
    if (results.length) {
      this.setTab(results[0].id)
    }
  }

  componentDidUpdate() {
    const {
      task: { refresh },
      triggerRefresh: triggerRefreshAction
    } = this.props
    if (refresh) {
      this.refresh()
      triggerRefreshAction(false)
    }
  }

  refresh = async () => {
    const {
      listRoadmap: listRoadmapAction,
      getOrganizationUsers: getOrganizationUsersAction
    } = this.props
    await listRoadmapAction()
    await getOrganizationUsersAction()
    const {
      roadmap: {
        list: { results }
      }
    } = this.props
    this.setState({ results, updateTime: Date.now() })
  }

  setTab = (roadmapId: number) => {
    const { results: roadmaps } = this.state
    let tabIndex = 0
    roadmaps.forEach(roadmap => {
      if (roadmap.id === roadmapId) {
        this.setState({ tabIndex })
      }
      tabIndex += 1
    })
  }

  setTabIndex = (index: number) => {
    this.setState({ tabIndex: index })
  }

  render() {
    const {
      results: roadmaps,
      updateTime,
      tabIndex,
      showRoadmapOrderModal
    } = this.state
    const { t, roadmap: roadmapState, maintask, user } = this.props
    const loading = roadmapState.isPending || maintask.isPending
    const tabTitles: JSX.Element[] = []
    const tabPanels: JSX.Element[] = []
    if (updateTime === null) {
      return null
    }
    const isRoadmapManager = (user || {}).roadmap_manager === true
    roadmaps.forEach(roadmap => {
      const actions: JSX.Element[] = []
      if (isRoadmapManager) {
        actions.push(<EditRoadmapModal roadmap={roadmap} />)
        actions.push(<RemoveRoadmapModal roadmap={roadmap} />)
      }
      tabTitles.push(
        <StyledTab key={`tab-${roadmap.id}`} className="react-tabs__tab">
          {roadmap.title}
          {actions}
        </StyledTab>
      )
      tabPanels.push(
        <TabPanel key={`tabpanel-${roadmap.id}`}>
          <Roadmap
            key={`roadmap-container-${roadmap.id}`}
            updateTime={updateTime}
            roadmap={roadmap}
            roadmaps={roadmaps}
            setTab={this.setTab}
            isRoadmapManager={isRoadmapManager}
          />
        </TabPanel>
      )
    })
    if (isRoadmapManager) tabTitles.push(<CreateRoadmapModal />)

    return (
      <RoadMapsContainer className="App">
        {loading && (
          <LoadOverlay>
            <LoadingIndicator />
          </LoadOverlay>
        )}
        <TitleContainer>
          <Title>{t('roadmap')}</Title>
          {isRoadmapManager && (
            <span
              onClick={() => this.setState({ showRoadmapOrderModal: true })}
            >
              {t('order-roadmaps')}
            </span>
          )}
        </TitleContainer>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={index => this.setTabIndex(index)}
        >
          <TabList>{tabTitles}</TabList>
          {tabPanels}
          <TabPanel> </TabPanel>
        </Tabs>
        <RoadmapOrderModal
          show={showRoadmapOrderModal}
          onClose={() =>
            this.setState({ showRoadmapOrderModal: false }, this.refresh)
          }
        />
      </RoadMapsContainer>
    )
  }
}

const mapStateToProps = ({
  error,
  roadmap,
  task,
  maintask,
  target,
  auth,
  rawData
}: RootState) => ({
  error,
  roadmap,
  task,
  maintask,
  target,
  user: auth.user,
  timeSpan: rawData.timespan
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      listRoadmap,
      getOrganizationUsers,
      triggerRefresh,
      listTarget,
      getRawDataTimespan
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Roadmaps))

import { Organization } from 'store/reducers/organization'

export const findOrg = (
  activeOrganizationId: number | null | undefined,
  data: Organization[]
): Organization | undefined => {
  if (activeOrganizationId === null || activeOrganizationId === undefined) {
    return undefined
  }
  let foundOrganization: Organization | undefined = data.find(
    (o: Organization) => o.id === activeOrganizationId
  )
  if (!foundOrganization) {
    for (const o of data) {
      if (o.children && o.children.length > 0) {
        foundOrganization = findOrg(activeOrganizationId, o.children)
        if (foundOrganization) {
          foundOrganization = { ...foundOrganization, parentId: o.id }
          break
        }
      }
    }
  }
  return foundOrganization ?? undefined
}

/**
 * Returns the organization name
 * @param org Organization state data
 * @returns organization name
 */
export const getOrgName = (org: {
  activeOrganization: number | null | undefined
  data: Organization[]
}): string | null => {
  const o = findOrg(org.activeOrganization, org.data)
  return o ? o.name : null
}

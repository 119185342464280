import React, { Component } from 'react'
import { connect } from 'react-redux'
import { GraphView } from 'pages'
import { getGraphPathName } from 'util/format'
import PrivateRoute from './PrivateRoute'
import { RootState } from 'store/reducers'

type GraphRoutesReduxState = ReturnType<typeof mapStateToProps>

class GraphRoutes extends Component<GraphRoutesReduxState> {
  render() {
    const {
      settings: { mappings }
    } = this.props
    if (!mappings) {
      return null
    }
    return mappings.map(m => (
      <React.Fragment key={m.id}>
        {m.redirect ? null : (
          <PrivateRoute
            component={GraphView}
            path={`/${getGraphPathName(m.view_title)}`}
            exact
          />
        )}
        {m.children.length
          ? m.children.map(c => (
              <PrivateRoute
                key={c.id}
                component={GraphView}
                path={`/${getGraphPathName(c.view_title)}`}
                exact
              />
            ))
          : null}
      </React.Fragment>
    ))
  }
}

const mapStateToProps = ({ graph }: RootState) => ({
  settings: graph.settings
})

export default connect(mapStateToProps)(GraphRoutes)

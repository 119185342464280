import React, { useEffect, useState } from 'react'
import { Card, Title } from 'components/blocks'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { listRoadmap, partialUpdateRoadmap } from 'store/actions/roadmap'
import { OrderedItem, reorder } from './EditCreateReportModal'
import { RootState } from 'store/reducers'

const ModalContainer = styled.div`
  display: ${(props: { show: boolean }) => (props.show ? 'block' : 'none')};
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 999;
  background: #33333399;
  .modal-content {
    background: #fff;
    margin: auto;
    margin-top: 10vh;
    width: 100%;
    max-width: 768px;
    padding: 1rem;
    box-shadow: none;
    > h1 {
      color: #333;
      margin: 0;
    }
  }
  .report-graphs {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    > span {
      border: 1px solid #aaa;
      border-radius: 6px;
      background: #ddd;
      color: #333;
      padding: 0.5rem 0.7rem;
      &:not(:last-of-type) {
        margin-bottom: 0.6rem;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    button {
      box-sizing: border-box;
      background: #ffd800;
      border: none;
      border-radius: 6px;
      color: #333;
      font-family: 'Open Sans';
      font-size: 0.8rem;
      letter-spacing: 0;
      line-height: 1;
      text-align: center;
      cursor: pointer;
      padding: 0.5rem 0.7rem;
    }
  }
`

export default function RoadmapOrderModal({
  onClose,
  show
}: {
  onClose: () => void
  show: boolean
}) {
  const { t } = useTranslation()
  const [orderedRoadmaps, setOrderedRoadmaps] = useState<OrderedItem[]>([])
  const dispatch = useDispatch()
  const {
    list: { results }
  } = useSelector((state: RootState) => state.roadmap)
  useEffect(() => {
    setOrderedRoadmaps(results)
  }, [results])
  const reorderRoadmaps = (result: DropResult) => {
    const newOrderedGraphs = [...orderedRoadmaps]

    if (!result.destination) {
      return
    }

    const items = reorder(
      newOrderedGraphs,
      result.source.index,
      result.destination.index
    ) as OrderedItem[]

    setOrderedRoadmaps(items)
  }
  const saveOrdering = async () => {
    await Promise.all(
      orderedRoadmaps.map((o: OrderedItem, i) =>
        dispatch(partialUpdateRoadmap(o.id, JSON.stringify({ order: i })))
      )
    )
    dispatch(listRoadmap())
    onClose()
  }
  return (
    <ModalContainer show={show}>
      <Card className="modal-content">
        <Title>{t('order-roadmaps')}</Title>
        <DragDropContext onDragEnd={reorderRoadmaps}>
          <Droppable droppableId="droppable">
            {provided => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="report-graphs"
              >
                {orderedRoadmaps.map((r, index) => (
                  <Draggable
                    key={r.id}
                    draggableId={r.id.toString()}
                    index={index}
                  >
                    {prov => {
                      return (
                        <span
                          {...prov.draggableProps}
                          {...prov.dragHandleProps}
                          ref={prov.innerRef}
                        >
                          {r.title}
                        </span>
                      )
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="actions">
          <button type="button" onClick={saveOrdering}>
            {t('reorder')}
          </button>
        </div>
      </Card>
    </ModalContainer>
  )
}

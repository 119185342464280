import {
  GET_TARGET_GROUP,
  LIST_TARGET_GROUP,
  HIDE_TARGET_GROUP
} from 'store/actions/targetgroup'

import produce from 'immer'
import { RESET_STATE } from 'store/actions/reset'
import { AnyAction } from 'redux'
import { Target } from './target'

type TargetGroup = {
  children: Array<TargetGroup>
  has_children: boolean
  id: number
  level: number
  lft: number
  organization: number
  parent: number | null
  rght: number
  targets: Target[]
  title: string
  tree_id: 5
  user: {
    id: number
    username: string
    avatar: string | null
    full_name: string
  }
}

interface TargetGroupState {
  data: { [key: number]: TargetGroup }
  list: {
    results: Array<TargetGroup>
  }
  isPending: boolean
  error: Array<Error> | Error | null
}

const initialState: TargetGroupState = {
  data: {},
  list: {
    results: []
  },
  error: null,
  isPending: false
}

const targetGroup = (state = initialState, action: AnyAction) => {
  const { type, payload } = action

  switch (type) {
    case `${GET_TARGET_GROUP}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${GET_TARGET_GROUP}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${GET_TARGET_GROUP}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.data[payload.id] = payload
      })

    case `${LIST_TARGET_GROUP}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${LIST_TARGET_GROUP}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${LIST_TARGET_GROUP}_FULFILLED`:
      return produce(state, draft => {
        draft.list = payload
        draft.isPending = false
      })

    case `${HIDE_TARGET_GROUP}`:
      return produce(state, draft => {
        delete draft.data[payload.id]
      })

    case RESET_STATE:
      return initialState

    default:
      return state
  }
}

export default targetGroup

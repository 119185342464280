import { Get } from 'api/index'
import { createAsyncAction } from 'redux-promise-middleware-actions'
import { RootState } from 'store/reducers'
import { AppDispatch } from 'store/store'

export const GET_TARGET = 'GET_TARGET'
export const GET_TARGET_AS_GRAPH = 'GET_TARGET_AS_GRAPH'
export const LIST_TARGET = 'LIST_TARGET'
export const SET_TARGET_TO_GRAPH = 'SET_TARGET_TO_GRAPH'

export const getTarget = createAsyncAction(GET_TARGET, async (id, params) => {
  const url = `/api/target/${id}/`
  const data = await Get<RootState['target']['data']>(url, params)
  return data
})

export const listTarget = createAsyncAction(LIST_TARGET, async params => {
  const url = `/api/target/`
  const data = await Get<RootState['target']['list']>(url, params)
  return data
})

export const setTargetToGraph = (target: RootState['target']['data']) => (
  dispatch: AppDispatch
) =>
  dispatch({
    type: SET_TARGET_TO_GRAPH,
    payload: target
  })

export const getTargetAsGraph = createAsyncAction(
  GET_TARGET_AS_GRAPH,
  async (id, start, end) => {
    const url = `/api/target/${id}/get_target_as_graph/`
    const data = await Get<RootState['target']['targetAsGraph']>(url, {
      start_date: start,
      end_date: end
    })
    return data
  }
)

import React, { Component } from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Card, Title } from 'components/blocks'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { convertToRaw, EditorState } from 'draft-js'
import { convertFromHTML } from 'draft-convert'
import { uploadNotificationFiles } from 'store/actions/notification'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import toHtml from 'draftjs-to-html'
import ApiError from 'ApiError'
import { toast } from 'react-toastify'
import { getOrganizationUsers } from 'store/actions/organization'
import Select from 'react-select'
import { FileObject, Files } from './CreateNotificationModal'
import { RootState } from 'store/reducers'
import { Notification } from 'store/reducers/notification'
import { AppDispatch } from 'store/store'
import { Patch } from 'api'

const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #33333366;
  visibility: ${(props: { isOpen: boolean }) =>
    props.isOpen ? 'visible' : 'hidden'};
  pointer-events: ${props => (props.isOpen ? 'all' : 'none')};
  display: flex;
  overflow-y: auto;
  > div {
    position: relative;
    margin: auto;
    box-shadow: none;
    width: 100%;
    max-width: 600px;
    padding: 0.938rem;
    opacity: ${props => (props.isOpen ? '1' : '0')};
    transform: translateY(${props => (props.isOpen ? '0' : '-50px')});
    transition: transform, opacity 0.3s ease-in-out;
    > h1 {
      margin-top: 0;
    }
    > input {
      width: 100%;
      margin-bottom: 0.938rem;
      padding: 0.625rem;
      border-radius: 5px;
      border: 1px solid #dedede;
      &[type='file'] {
        margin-bottom: 0;
      }
    }
    .editor-wrapper {
      border: 1px solid #ddd;
      min-height: 300px;
      padding: 0.625rem;
      margin-bottom: 0.938rem;
    }
    .notification-recipients {
      margin-bottom: 1rem;
    }
  }
`
const CloseButton = styled.span`
  position: absolute;
  right: 0.938rem;
  top: 0.938rem;
  font-size: 1.875rem;
  cursor: pointer;
`
const Button = styled.div`
  width: 100%;
  cursor: pointer;
  border-radius: 3px;
  background: #b9c5c8;
  box-shadow: 2px 2px 0 0 #93a4a8;
  padding: 0.938rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.875rem;
  color: white;
  user-select: none;
  margin-top: 0.938rem;
`
const ExistingFile = styled.div`
  width: 100%;
  margin-bottom: 0.938rem;
  padding: 0.625rem;
  border-radius: 5px;
  border: 1px solid #dedede;
  span {
    float: right;
    color: #aaa;
    cursor: pointer;
  }
`

type EditNotificationModalReduxState = ReturnType<typeof mapStateToProps>
type EditNotificationModalReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface EditNotificationModalProps {
  isOpen: boolean
  t: TFunction
  onClose: (update?: boolean) => void
  notification: Notification | null
}

interface EditNotificationModalState {
  editorState: EditorState
  title: string
  files: FileObject[]
  existingFiles: Notification['files']
  notificationRecipients: Notification['notification_recipients']
  sendNotifications: boolean
}

class EditNotificationModal extends Component<
  EditNotificationModalProps &
    EditNotificationModalReduxState &
    EditNotificationModalReduxDispatch,
  EditNotificationModalState
> {
  constructor(
    props: EditNotificationModalProps &
      EditNotificationModalReduxState &
      EditNotificationModalReduxDispatch
  ) {
    super(props)
    this.state = {
      editorState: EditorState.createEmpty(),
      title: '',
      files: [],
      existingFiles: [],
      notificationRecipients: [],
      sendNotifications: false
    }
  }

  componentDidMount() {
    const { getOrganizationUsers: getOrganizationUsersAction } = this.props
    getOrganizationUsersAction()
  }

  componentDidUpdate(
    prevProps: EditNotificationModalProps &
      EditNotificationModalReduxState &
      EditNotificationModalReduxDispatch
  ) {
    const { notification } = this.props
    if (prevProps.notification === null && notification !== null) {
      this.updateModalDetails()
    }
  }

  onEditorStateChange = (state: EditorState) => {
    this.setState({ editorState: state })
  }

  updateNotification = async () => {
    const {
      title,
      editorState,
      files,
      existingFiles,
      sendNotifications,
      notificationRecipients
    } = this.state
    const filesToUpload = files.filter(f => f.file).map(f => f.file)
    const {
      uploadNotificationFiles: uploadNotificationFilesAction,
      t,
      onClose,
      notification
    } = this.props
    if (!notification) {
      return
    }
    const raw = toHtml(convertToRaw(editorState.getCurrentContent()))
    const url = `/api/notification/${notification.id}/`
    const data = await Patch<Notification>(
      url,
      JSON.stringify({
        title,
        text_value: raw,
        send_notifications: sendNotifications,
        notification_recipients: notificationRecipients
      })
    )
    if (data instanceof ApiError && data.messages) {
      const messages = Object.keys(data.messages).map(
        m => `${t(m)}: ${data.messages ? data.messages[m][0] : ''}\n`
      )
      messages.forEach(m => toast.error(m))
      return
    }

    await uploadNotificationFilesAction(
      data.id,
      null,
      existingFiles.map(f => f.id)
    )
    await uploadNotificationFilesAction(data.id, filesToUpload as File[])
    toast.success(t('notification-updated'))
    onClose(true)
  }

  onFilesChange = (files: FileObject[]) => this.setState({ files })

  sendNotifications = async (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const target = event.target as HTMLInputElement
    if (target) {
      this.setState({ sendNotifications: target.checked })
    }
  }

  handleChange = (value: Array<{ value: number; label: string }>) => {
    if (value) {
      const notificationRecipients: number[] = []
      value.forEach(user => {
        notificationRecipients.push(user.value)
      })
      this.setState({ notificationRecipients })
    }
  }

  updateModalDetails() {
    const { notification } = this.props
    if (!notification) {
      return
    }
    const state = convertFromHTML(notification.text_value)
    this.setState({
      title: notification.title,
      editorState: EditorState.createWithContent(state),
      existingFiles: notification.files,
      files: [],
      sendNotifications: notification.send_notifications,
      notificationRecipients: notification.notification_recipients
    })
  }

  render() {
    const {
      editorState,
      title,
      existingFiles,
      sendNotifications,
      notificationRecipients
    } = this.state
    const {
      isOpen,
      t,
      onClose,
      notification,
      organization: { users }
    } = this.props
    let userSelection: string | React.ReactNode = ''
    if (sendNotifications) {
      const options = users.map(u => ({
        value: u.id,
        label: u.full_name
      }))
      const value = options.filter(o =>
        notificationRecipients.includes(o.value)
      )
      userSelection = (
        <Select
          className="notification-recipients"
          isMulti
          onChange={this.handleChange}
          options={options}
          placeholder="Notification recipients"
          value={value}
        />
      )
    }
    return (
      <ModalContainer isOpen={isOpen}>
        <Card>
          <CloseButton onClick={() => onClose()}>&times;</CloseButton>
          <Title>{t('edit-notification')}</Title>
          <input
            value={title}
            onChange={({ target: { value } }) =>
              this.setState({ title: value })
            }
            placeholder={t('write-notification-title')}
          />
          <Editor
            editorState={editorState}
            editorClassName="editor-wrapper"
            onEditorStateChange={this.onEditorStateChange}
            editorStyle={{
              maxHeight: 200
            }}
            toolbar={{
              options: [
                'inline',
                'blockType',
                'fontSize',
                'list',
                'textAlign',
                'link',
                'history'
              ]
            }}
          />
          <input
            id="send-notifications"
            onClick={event => this.sendNotifications(event)}
            type="checkbox"
            style={{
              display: 'inline-block',
              marginRight: '15px',
              width: 'auto'
            }}
            checked={sendNotifications}
          />
          <label
            style={{ display: 'inline-block' }}
            htmlFor="send-notifications"
          >
            {t('send-notification')}
          </label>
          {userSelection}
          {existingFiles.map(e => (
            <ExistingFile key={e.id}>
              {e.file_title}{' '}
              <span
                onClick={() =>
                  this.setState({
                    existingFiles: existingFiles.filter(e2 => e2.id !== e.id)
                  })
                }
              >
                &times;
              </span>
            </ExistingFile>
          ))}
          {notification !== null && <Files onChange={this.onFilesChange} />}
          <Button onClick={this.updateNotification}>{t('update')}</Button>
        </Card>
      </ModalContainer>
    )
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      uploadNotificationFiles,
      getOrganizationUsers
    },
    dispatch
  )

const mapStateToProps = ({ organization }: RootState) => ({
  organization
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(EditNotificationModal))

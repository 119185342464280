import React, { Component } from 'react'
import styled, { withTheme, DefaultTheme } from 'styled-components/macro'
import Icon from 'components/Icon'
import { DARK_THEME } from 'theme'
import { Notification } from 'store/reducers/notification'

const FileContainer = styled.a`
  display: flex;
  align-items: center;
  color: ${props => props.theme.notifications.fileName};
  font-family: 'Open Sans';
  font-size: 0.938rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.125rem;
  cursor: pointer;
  margin-bottom: 0.625rem;
  &:last-of-type {
    margin-bottom: 0;
  }
  &:hover {
    color: #0a0a0a;
  }
  .icon {
    margin-right: 0.625rem;
    flex-basis: 50px;
  }
  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

class File extends Component<{
  data: Notification['files'][0]
  theme: DefaultTheme
}> {
  renderIcon = (file: string, theme: DefaultTheme) => {
    const darkMode = theme.id === DARK_THEME
    const splittedFile = file.split('?')[0].split('.')
    const ending = splittedFile[splittedFile.length - 1]
    switch (ending) {
      case 'pdf':
        return (
          <Icon
            iconName="pdf"
            size={30}
            color={darkMode ? '#E93E5E' : '#990022'}
          />
        )
      case 'xlsx':
        return (
          <Icon
            iconName="xls"
            size={30}
            color={darkMode ? '#B5F861' : '#004400'}
          />
        )
      case 'csv':
        return (
          <Icon
            iconName="csv"
            size={30}
            color={darkMode ? '#B5F861' : '#004400'}
          />
        )
      default:
        return <Icon iconName="unknownFile" size={30} color="#0044aa" />
    }
  }

  render() {
    const { data, theme } = this.props
    let fileName: string | string[] = data.notification_file.split('/')
    fileName = fileName[fileName.length - 1]
    return (
      <FileContainer
        href={data.notification_file}
        download
        title={fileName}
        target="_blank"
      >
        {this.renderIcon(data.notification_file, theme)}
        <span>{data.file_title ? data.file_title : fileName}</span>
      </FileContainer>
    )
  }
}
export default withTheme(File)

const formatFloat = (x: number | string) => {
  let value = x
  if (typeof value !== 'number') {
    value = parseFloat(value)
  }

  const parts = value.toFixed(2).split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return parts.join('.')
}

const formatInt = (x: number | string) => {
  let value = x
  if (typeof value !== 'number') {
    value = parseInt(value, 10)
  }

  const parts = value.toFixed(0)
  return parts.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

const formatPercent = (x: number | string) => {
  let value = x
  if (typeof value !== 'number') {
    value = parseFloat(value)
  }
  return `${value.toFixed(2)} %`
}

const getGraphPathName = (title: string) =>
  encodeURI(
    title
      .toLowerCase()
      .split(/\s/g)
      .join('-')
      .split('ä')
      .join('a')
      .split('ö')
      .join('o')
      .split('å')
      .join('a')
      .split(/[^0-9a-zA-Z *]/)
      .join('-')
  )

export { formatFloat, formatInt, formatPercent, getGraphPathName }

import React, { Component, createRef } from 'react'
import EditTaskModal from 'components/modals/EditTaskModal'
import RemoveTaskModal from 'components/modals/RemoveTaskModal'
import styled from 'styled-components/macro'
import { TFunction, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from 'store/reducers'
import { TaskType } from 'store/reducers/task'
import moment from "moment";

const HorizontalLine = styled.div`
  border-bottom: 1px solid #cfdbde;
  margin-bottom: 1rem;
  padding-bottom: 0.75rem;
`

const TooltipContainer = styled.div`
  position: fixed;
  background: #fff;
  border: 1px solid #cfdbde;
  padding: 0.8rem 1rem;
  min-width: 153px;
  z-index: 999;
  visibility: ${(props: {
    show: boolean
    position: { x: number; y: number } | null
  }) => (props.show ? 'visible' : 'hidden')};
  ${props =>
    props.position
      ? `
    top: ${props.position.y}px;
    left: ${props.position.x}px;
  `
      : ''}
  .edit-link,
  .remove-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    span {
      font-family: 'Open Sans';
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 14px;
    }
    &:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }
  }
  .edit-link span {
    color: #7b8f94;
  }
  .remove-link span {
    color: #ce2033;
  }
  .data {
    h6 {
      color: #434343;
      font-family: 'Open Sans';
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 12px;
      margin-top: 0;
      margin-bottom: 10px;
      &:not(:first-of-type) {
        margin-top: 1.5rem;
      }
      .percentage {
        color: #4191cd;
      }
    }
    p,
    a {
      display: block;
      color: #354b7d;
      font-family: 'Open Sans';
      font-size: 13px;
      letter-spacing: 0;
      line-height: 13px;
      margin-top: 0;
      margin-bottom: 10px;
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
  }
`

type TaskTooltipReduxState = ReturnType<typeof mapStateToProps>

interface TaskTooltipProps {
  show: boolean
  position: { x: number; y: number } | null
  task: TaskType
  onClose: () => void
  onHover: () => void
  t: TFunction
  isRoadmapManager?: boolean
}

class TaskTooltip extends Component<TaskTooltipProps & TaskTooltipReduxState> {
  tooltipRef: React.RefObject<HTMLDivElement>

  constructor(props: TaskTooltipProps & TaskTooltipReduxState) {
    super(props)
    this.tooltipRef = createRef()
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.checkOutsideClick)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.checkOutsideClick)
  }

  checkOutsideClick = (e: MouseEvent) => {
    const { current } = this.tooltipRef
    const { onClose } = this.props

    if (!current) {
      return
    }

    const { left, top, bottom, right } = current.getBoundingClientRect()
    if (
      e.clientX < left ||
      e.clientX > right ||
      e.clientY < top ||
      e.clientY > bottom
    ) {
      onClose()
    }
  }

  render() {
    const {
      show,
      position,
      task,
      t,
      users,
      target: {
        list: { results }
      },
      onHover,
      onClose,
      isRoadmapManager
    } = this.props
    const userObj = users.find(u => u.id === task.user)
    const userName = userObj ? userObj.full_name : ''
    const targets = results.filter(r => task.targets.includes(r.id))
    const average =
      targets.reduce((a, b) => a + b.current_value / b.target_value, 0) /
      targets.length
    const startMoment = moment(task.start_date)
    const endMoment = moment(startMoment).add(task.date_length, 'months')
    return (
      <>
        <TooltipContainer
          ref={this.tooltipRef}
          show={show}
          position={position}
          onClick={e => e.stopPropagation()}
          onMouseEnter={() => onHover()}
          onMouseLeave={() => onClose()}
        >
          <div className="data">
            <h6>{t('task-name')}</h6>
            <p>{task.title}</p>
            <h6>{t('time-range')}</h6>
            <p>{startMoment.format('MM/YYYY')} - {endMoment.format('MM/YYYY')}</p>
            {targets.length ? (
              <h6>
                {t('Targets')}{' '}
                <span className="percentage">
                  {(average || 0) * 100}% / 100%
                </span>
              </h6>
            ) : null}
            {!targets.length ? (
              <h6>
                {t('task-progress')}{' '}
                <span className="percentage">{task.progress}% / 100%</span>
              </h6>
            ) : null}
            {targets.map(r => (
              <Link to={`/?target_id=${r.id}`} key={r.id}>
                {r.title} &gt;&gt;
              </Link>
            ))}
            <h6>{t('responsible')}</h6>
            <p>{userName}</p>
          </div>
          {isRoadmapManager && <HorizontalLine />}
          {isRoadmapManager && <EditTaskModal task={task} />}
          {isRoadmapManager && <RemoveTaskModal task={task} />}
        </TooltipContainer>
      </>
    )
  }
}

const mapStateToProps = ({ organization: { users }, target }: RootState) => ({
  users,
  target
})

export default withTranslation()(connect(mapStateToProps)(TaskTooltip))

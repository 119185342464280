import { Get, Post } from 'api/index'
import { createAsyncAction } from 'redux-promise-middleware-actions'
import { OrganizationUser } from 'store/reducers/organization'

export const GET_ORGANIZATION_USERS = 'GET_ORGANIZATION_USERS'
export const GET_ACTIVE_ORGANIZATION = 'GET_ACTIVE_ORGANIZATION'
export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS'
export const ACTIVATE_ORGANIZATION = 'ACTIVATE_ORGANIZATION'

export const getOrganizationUsers = createAsyncAction(
  GET_ORGANIZATION_USERS,
  async () => {
    const url = '/api/user/get_organization_users/'
    const data = await Get<{ users: OrganizationUser[] }>(url)
    return data
  }
)

export const getActiveOrganization = createAsyncAction(
  GET_ACTIVE_ORGANIZATION,
  async () => {
    const url = '/api/user/activate_organization/'
    const data = await Get(url)
    return data
  }
)

export const getOrganizations = createAsyncAction(
  GET_ORGANIZATIONS,
  async () => {
    const url = `/api/user/activate_organization/?list=true`
    const data = await Get(url)
    return data
  }
)

export const activateOrganization = createAsyncAction(
  ACTIVATE_ORGANIZATION,
  async (id: number) => {
    const url = '/api/user/activate_organization/'
    const data = await Post(url, JSON.stringify({ id }))
    return data
  }
)

import React from 'react'
import ReactDOM from 'react-dom'
import { Post } from 'api'
import App from './App'
import * as serviceWorker from './serviceWorker'
import 'react-month-picker/css/month-picker.css'

import './i18n'

declare global {
  interface Window { dataLayer: Array<unknown>; }
}

const initialize = async () => {
  const token = new URLSearchParams(window.location.search).get('api-token')
  const orgId = new URLSearchParams(window.location.search).get('org-id')
  if (token) {
    sessionStorage.setItem('API_TOKEN', token)
  }

  if (orgId) {
    const url = '/api/user/activate_organization/'
    await Post(url, JSON.stringify({ id: orgId }))
  }

  ReactDOM.render(<App />, document.getElementById('root'))

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister()
}

initialize()

import React from 'react'
import { Route, Redirect, RouteComponentProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { RootState } from 'store/reducers'
import { User } from 'store/reducers/auth'

const PrivateRoute: React.FC<{
  component: React.ReactNode
  userPermission?: keyof User
  path: string
  exact?: boolean
}> = ({ component: Component, userPermission, ...rest }) => {
  const { tokenExpired, user } = useSelector((state: RootState) => state.auth)
  let isAuthenticated = false

  let redirect = false

  const token = sessionStorage.getItem('API_TOKEN')
  if (token !== null) {
    isAuthenticated = true
  }

  if (tokenExpired) {
    sessionStorage.removeItem('API_TOKEN')
    redirect = true
  }

  if (isAuthenticated && userPermission && !user) {
    return null
  }

  if (isAuthenticated && userPermission && user) {
    redirect = !user[userPermission]
  }

  const RenderedComponent = Component as React.ComponentClass

  /* eslint-disable */
  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) =>
        !isAuthenticated || redirect ? (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        ) : (
          <RenderedComponent {...props} />
        )
      }
    />
  )
  /* eslint-enable */
}

export default PrivateRoute

import React, { Component } from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Card, Title } from 'components/blocks'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { convertToRaw, EditorState } from 'draft-js'
import {
  createReport,
  listReportDataMap,
  listReportGraph,
  updateReport
} from 'store/actions/report'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggingStyle
} from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import toHtml from 'draftjs-to-html'
import ApiError from 'ApiError'
import { toast } from 'react-toastify'
import moment from 'moment'
import { DoneMark } from 'components/strategy/Targets'
import { convertFromHTML } from 'draft-convert'
import Icon from 'components/Icon'
import EditReportItemContentModal from './EditReportItemContentModal'
import { RootState } from 'store/reducers'
import { Report, ReportDataMap, ReportGraph } from 'store/reducers/report'

export const reorder = (
  list: Array<unknown>,
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #33333366;
  visibility: ${(props: { isOpen: boolean }) =>
    props.isOpen ? 'visible' : 'hidden'};
  pointer-events: ${props => (props.isOpen ? 'all' : 'none')};
  display: flex;
  justify-content: center;
  overflow-y: auto;
  align-items: flex-start;
  > div {
    position: relative;
    box-shadow: none;
    margin-top: 10rem;
    width: 100%;
    max-width: 600px;
    padding: 0.938rem;
    opacity: ${props => (props.isOpen ? '1' : '0')};
    transform: translateY(${props => (props.isOpen ? '0' : '-50px')});
    transition: transform, opacity 0.3s ease-in-out;
    > h1 {
      margin-top: 0;
      margin-bottom: 1rem;
      margin-right: 0;
      margin-left: 0;
    }
    > input {
      width: 100%;
      margin-bottom: 0.938rem;
      padding: 0.625rem;
      border-radius: 5px;
      border: 1px solid #dedede;
      &[type='file'] {
        margin-bottom: 0;
      }
    }
    .editor-wrapper {
      border: 1px solid #ddd;
      min-height: 300px;
      padding: 0.625rem;
      margin-bottom: 0.938rem;
      .DraftEditor-root {
        z-index: 0;
      }
    }
    .report-graphs {
      display: flex;
      flex-direction: column;
      max-height: 250px;
      overflow-y: auto;
      ::-webkit-scrollbar {
        width: 0.625rem;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #00000099;
        border-radius: 0.625rem;
        border: solid 3px transparent;
      }
      ::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 5px #00000099;
        border-radius: 0.625rem;
        border: solid 3px transparent;
      }
      > span {
        cursor: pointer;
        padding: 15px;
        border: 1px solid #ddd;
        background: #fafafa;
        border-radius: 6px;
        color: #333;
        font-family: 'Open Sans';
        strong {
          font-size: 0.8rem;
        }
        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
        .icon {
          display: inline-block;
          margin-right: 5px;
          background-color: #ccc;
          &:hover {
            background-color: #aaa;
          }
        }
        .square {
          padding: 0.25rem 0.5rem;
          background: #aaa;
          color: white;
          display: inline-block;
          font-size: 0.75rem;
          border-radius: 6px;
          margin-left: 5px;
        }
        > div:not(.icon):not(.square) {
          float: right;
          margin: 0;
          min-width: 18px;
          min-height: 18px;
          max-height: 18px;
          max-width: 18px;
        }
      }
    }
  }
`
const CloseButton = styled.span`
  position: absolute;
  right: 0.938rem;
  top: 0.938rem;
  font-size: 1.875rem;
  cursor: pointer;
`
const Button = styled.div`
  width: 100%;
  cursor: pointer;
  border-radius: 3px;
  background: #b9c5c8;
  box-shadow: 2px 2px 0 0 #93a4a8;
  padding: 0.938rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.875rem;
  color: white;
  user-select: none;
  margin-top: 0.938rem;
`

type EditCreateReportModalReduxState = ReturnType<typeof mapStateToProps>
type EditCreateReportModalReduxDispatch = ReturnType<typeof mapDispatchToProps>
export type OrderedItem = {
  mapping?: number
  type?: string
  id: number
  title?: string
}

interface EditCreateReportModalProps {
  isOpen: boolean
  t: TFunction
  report: Report | null
  onClose: (update: boolean) => void
}

interface EditCreateReportState {
  editorState: EditorState
  title: string
  selectedGraphs: number[]
  selectedDataMaps: number[]
  itemToEdit: ReportGraph | ReportDataMap | null
  orderedItems: Array<ReportGraph | ReportDataMap>
}

class EditCreateReportModal extends Component<
  EditCreateReportModalProps &
    EditCreateReportModalReduxState &
    EditCreateReportModalReduxDispatch,
  EditCreateReportState
> {
  cardContainer: HTMLDivElement | null
  constructor(
    props: EditCreateReportModalProps &
      EditCreateReportModalReduxState &
      EditCreateReportModalReduxDispatch
  ) {
    super(props)
    this.cardContainer = null
    this.state = {
      editorState: EditorState.createEmpty(),
      title: '',
      selectedGraphs: [],
      selectedDataMaps: [],
      itemToEdit: null,
      orderedItems: []
    }
  }

  componentDidUpdate(
    prevProps: EditCreateReportModalProps &
      EditCreateReportModalReduxState &
      EditCreateReportModalReduxDispatch
  ) {
    const { report } = this.props
    if (report !== null && prevProps.report === null) {
      this.updateContent(report as Report)
    }
  }

  updateContent = (report: Report) => {
    const {
      reportState: { graphList, dataMapList }
    } = this.props
    const { content, properties } = report
    if (!content) {
      this.setState({ editorState: EditorState.createEmpty() })
      return
    }
    const blocksFromHTML = convertFromHTML(content as string)
    const list: Array<ReportDataMap | ReportGraph> = [
      ...graphList,
      ...dataMapList
    ]
    const order: Array<OrderedItem> = (
      properties.order || []
    ).map((o: { type: string; id: number } | number) =>
      typeof o === 'number' ? { type: 'graph', id: o } : o
    )
    const orderedItems = order
      .map(item =>
        list.find(
          (item2: ReportDataMap | ReportGraph) =>
            item2.id === item.id &&
            (('mapping' in item2 && item.type === 'graph') ||
              (!('mapping' in item2) && item.type === 'data-map'))
        )
      )
      .filter(item => typeof item !== 'undefined') as Array<
      ReportDataMap | ReportGraph
    >
    list
      .filter(
        (item: ReportDataMap | ReportGraph) =>
          !orderedItems.some(
            (item2?: ReportDataMap | ReportGraph) =>
              item2?.id === item.id && 'mapping' in item2 && 'mapping' in item
          )
      )
      .forEach(item => {
        if (typeof item === 'undefined') {
          return
        }
        orderedItems.push(item)
      })
    this.setState({
      editorState: EditorState.createWithContent(blocksFromHTML),
      selectedGraphs: report.report_graphs,
      selectedDataMaps: report.report_data_maps,
      title: report.title,
      orderedItems
    })
  }

  onEditorStateChange = (state: EditorState) => {
    this.setState({ editorState: state })
  }

  updateOrCreateReport = async () => {
    const {
      title,
      editorState,
      selectedGraphs,
      selectedDataMaps,
      orderedItems
    } = this.state
    const {
      updateReport: updateReportAction,
      createReport: createReportAction,
      t,
      onClose,
      report,
      reportState: { graphList, dataMapList }
    } = this.props
    const raw = toHtml(convertToRaw(editorState.getCurrentContent()))
    const list = [...graphList, ...dataMapList]
    const properties = {
      order: orderedItems
        ? orderedItems.map(o => ({
            id: o.id,
            type: 'mapping' in o && o.mapping ? 'graph' : 'data-map'
          }))
        : list.map(o => ({
            id: o.id,
            type: 'mapping' in o ? 'graph' : 'data-map'
          }))
    }
    const data = report
      ? await updateReportAction(
          report.id,
          JSON.stringify({
            title,
            content: raw,
            report_graphs: selectedGraphs,
            report_data_maps: selectedDataMaps,
            properties
          })
        )
      : await createReportAction(
          JSON.stringify({
            title,
            content: raw,
            report_graphs: selectedGraphs,
            report_data_maps: selectedDataMaps,
            properties
          })
        )
    if (data instanceof ApiError && data.messages) {
      const messages = Object.keys(data.messages).map(
        m => `${t(m)}: ${data.messages ? data.messages[m][0] : ''}\n`
      )
      messages.forEach(m => toast.error(m))
      return
    }

    toast.success(report ? t('report-updated') : t('report-created'))
    onClose(true)
  }

  orderGraphs = (result: DropResult) => {
    const { orderedItems } = this.state
    const {
      reportState: { graphList }
    } = this.props
    const newOrderedItems = orderedItems.length
      ? [...orderedItems]
      : [...graphList]

    if (!result.destination) {
      return
    }

    const items = reorder(
      newOrderedItems,
      result.source.index,
      result.destination.index
    ) as Array<ReportGraph | ReportDataMap>

    this.setState({
      orderedItems: items
    })
  }

  addToSelectedGraphs = (r: ReportGraph) => {
    const { selectedGraphs } = this.state
    if (selectedGraphs.includes(r.id)) {
      this.setState({
        selectedGraphs: selectedGraphs.filter(r2 => r2 !== r.id)
      })
      return
    }
    this.setState({
      selectedGraphs: [...selectedGraphs, r.id]
    })
  }

  addToSelectedDataMaps = (r: ReportDataMap) => {
    const { selectedDataMaps } = this.state
    if (selectedDataMaps.includes(r.id)) {
      this.setState({
        selectedDataMaps: selectedDataMaps.filter(r2 => r2 !== r.id)
      })
      return
    }
    this.setState({
      selectedDataMaps: [...selectedDataMaps, r.id]
    })
  }

  renderReportItems = () => {
    const {
      reportState: { graphList, dataMapList },
      t
    } = this.props
    const { selectedGraphs, selectedDataMaps, orderedItems } = this.state
    const list = [...graphList, ...dataMapList]
    const graphs = orderedItems.length ? orderedItems : list
    return (
      <DragDropContext onDragEnd={this.orderGraphs}>
        <Droppable droppableId="droppable">
          {provided => (
            <div
              {...provided.droppableProps} // eslint-disable-line
              ref={provided.innerRef}
              className="report-graphs"
            >
              {graphs.map((r, index: number) => (
                <Draggable
                  key={r.id}
                  draggableId={r.id.toString()}
                  index={index}
                >
                  {prov => {
                    const topOffset = this.cardContainer
                      ? this.cardContainer.getBoundingClientRect().top
                      : 0
                    const leftOffset = this.cardContainer
                      ? this.cardContainer.getBoundingClientRect().left
                      : 0
                    return (
                      <span
                        {...prov.draggableProps} // eslint-disable-line
                        {...prov.dragHandleProps} // eslint-disable-line
                        style={{
                          ...prov.draggableProps.style,
                          top:
                            (prov.draggableProps.style as DraggingStyle).top -
                            topOffset,
                          left:
                            (prov.draggableProps.style as DraggingStyle).left -
                            leftOffset
                        }}
                        ref={prov.innerRef}
                        onClick={() =>
                          'mapping' in r
                            ? this.addToSelectedGraphs(r)
                            : this.addToSelectedDataMaps(r)
                        }
                      >
                        <Icon
                          iconName="edit"
                          size={15}
                          onClick={() => this.setState({ itemToEdit: r })}
                        />
                        {moment(r.created).format('DD.MM.YYYY HH:mm')}{' '}
                        {'mapping' in r ? r.mapping.view_title : t('data-map')}{' '}
                        <strong>
                          {r.content.length > 0
                            ? `(${t('modified-content')})`
                            : null}
                        </strong>
                        {('mapping' in r && selectedGraphs.includes(r.id)) ||
                        (!('mapping' in r) &&
                          selectedDataMaps.includes(r.id)) ? (
                          <DoneMark />
                        ) : null}
                      </span>
                    )
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  render() {
    const { editorState, title, itemToEdit } = this.state
    const {
      isOpen,
      t,
      onClose,
      listReportGraph: listReportGraphAction,
      listReportDataMap: listReportDataMapAction,
      report
    } = this.props
    return (
      <ModalContainer isOpen={isOpen}>
        <Card
          ref={ref => {
            this.cardContainer = ref
          }}
        >
          <CloseButton onClick={() => onClose(true)}>&times;</CloseButton>
          <Title>{report ? t('edit-report') : t('create-report')}</Title>
          <input
            value={title}
            onChange={({ target: { value } }) =>
              this.setState({ title: value })
            }
            placeholder={t('write-report-title')}
          />
          <Editor
            editorState={editorState}
            editorClassName="editor-wrapper"
            onEditorStateChange={this.onEditorStateChange}
            editorStyle={{
              maxHeight: 200
            }}
            toolbar={{
              options: [
                'inline',
                'blockType',
                'fontSize',
                'list',
                'textAlign',
                'link',
                'history'
              ]
            }}
          />
          {this.renderReportItems()}
          <EditReportItemContentModal
            isOpen={itemToEdit !== null}
            item={itemToEdit as ReportGraph | ReportDataMap}
            onClose={() =>
              this.setState({ itemToEdit: null }, async () => {
                await listReportGraphAction()
                await listReportDataMapAction()
                const {
                  reportState: { graphList, dataMapList }
                } = this.props
                const { orderedItems } = this.state
                if (orderedItems.length) {
                  const list = [...graphList, ...dataMapList]
                  const newOrderedItems = orderedItems.map(
                    o => list.find(o2 => o2.id === o.id) || o
                  )
                  this.setState({ orderedItems: newOrderedItems })
                }
              })
            }
          />
          <Button onClick={this.updateOrCreateReport}>
            {report ? t('update') : t('create')}
          </Button>
        </Card>
      </ModalContainer>
    )
  }
}

const mapStateToProps = ({ report }: RootState) => ({
  reportState: report
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      updateReport,
      createReport,
      listReportGraph,
      listReportDataMap
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(EditCreateReportModal))

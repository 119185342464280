import {
  CALCULATE_NUMBER_FROM_FORMULA,
  GET_GRAPH_SETTINGS,
  SET_GRAPH_STYLE_OVERRIDE
} from 'store/actions/graph'

import produce from 'immer'
import { RESET_STATE } from 'store/actions/reset'
import { AnyAction } from 'redux'
import { TermOfClassification } from './term'

export type GraphMapping = {
  id: number
  children: Array<GraphMapping>
  view_title: string
  view_icon: string
  terms_used: Array<TermOfClassification>
  redirect: boolean
  graph_properties: {
    past_months: string
    future_months: string
    y_axis_min: string
    y_axis_max: string
    y_axis_2_min: string
    y_axis_2_max: string
    tick_count: string
    u_right: string
    unit: string
    related_pages: Array<string>
    related_pages_title: string
    show_decimals: boolean
  } & {
    [key: string]: {
      type: string
      color: string
      stack_id: string | null
      second_axis: string | null
      cumulative_value: string | null
      calc_formula_for_numbers: boolean
    }
  }
}

interface GraphState {
  graphStyleOverride: boolean
  settings: {
    mappings: Array<GraphMapping>
    restricted_views: Array<string>
    organization: number | null
    additional_information: { [key: number]: { sort_order: number } }
  }
  numbersFromFormula: { [key: string]: number }
  isPending: boolean
  error: Array<Error> | Error | null
}

const initialState: GraphState = {
  graphStyleOverride: false,
  settings: {
    mappings: [],
    restricted_views: [],
    organization: null,
    additional_information: {}
  },
  numbersFromFormula: {},
  isPending: false,
  error: null
}

const graph = (state = initialState, action: AnyAction) => {
  const { type, payload } = action

  switch (type) {
    case `${GET_GRAPH_SETTINGS}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${GET_GRAPH_SETTINGS}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${GET_GRAPH_SETTINGS}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.settings = payload
      })

    case SET_GRAPH_STYLE_OVERRIDE:
      return produce(state, draft => {
        draft.graphStyleOverride = payload
      })

    case `${CALCULATE_NUMBER_FROM_FORMULA}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${CALCULATE_NUMBER_FROM_FORMULA}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${CALCULATE_NUMBER_FROM_FORMULA}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.numbersFromFormula[payload.key] = payload.value
      })

    case RESET_STATE:
      return initialState

    default:
      return state
  }
}

export default graph

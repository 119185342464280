import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components/macro'
import { TargetGroups, ConnectingLines } from 'components/strategy'
import { LINE_LENGTH } from 'components/strategy/ConnectingLines'
import { RootState } from 'store/reducers'

const TreeContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
`
const Root = styled.div`
  width: 100%;
  height: 0;
  margin-top: -${LINE_LENGTH}px;
`

type TargetTreeReduxState = ReturnType<typeof mapStateToProps>
interface TargetTreeProps {
  targetGroupParams: { [key: string]: string }
}

class TargetTree extends Component<TargetTreeProps & TargetTreeReduxState> {
  renderTree() {
    const renders: JSX.Element[] = []
    const {
      targetGroup: {
        list: { results }
      },
      targetGroupParams
    } = this.props

    const recursiveTreeRender = (
      groups: RootState['targetGroup']['list']['results']
    ) => {
      const {
        targetGroup: { data }
      } = this.props
      renders.push(
        <React.Fragment
          key={`${groups.map(g => g.id).join('_')}_${Object.keys(data).length}`}
        >
          <TargetGroups
            targetGroups={groups}
            targetGroupParams={targetGroupParams}
          />
          <ConnectingLines />
        </React.Fragment>
      )
      let children: RootState['targetGroup']['list']['results'] = []
      groups.forEach(g => {
        if (g.children && g.children.length) {
          children = [...children, ...g.children]
        }
        const loadedObj = data[g.id]
        if (loadedObj && loadedObj.children && loadedObj.children.length) {
          children = [...children, ...loadedObj.children]
        }
      })
      if (children.length) {
        recursiveTreeRender(children)
      }
    }

    recursiveTreeRender(results)
    return renders
  }

  render() {
    const {
      targetGroup: {
        list: { results }
      }
    } = this.props

    if (!results) {
      return null
    }

    return (
      <TreeContainer>
        {results.length > 1 ? (
          <>
            <Root>
              <div data-id="root">&nbsp;</div>
            </Root>
            <ConnectingLines />
          </>
        ) : null}
        {this.renderTree()}
      </TreeContainer>
    )
  }
}

const mapStateToProps = ({ targetGroup }: RootState) => ({
  targetGroup
})

export default connect(mapStateToProps)(TargetTree)

import {
  GET_TERM_OF_CLASSIFICATION,
  GET_TIMELINE_FOR_DATA,
  LIST_TERM_OF_CLASSIFICATION
} from 'store/actions/term'

import produce from 'immer'
import { RESET_STATE } from 'store/actions/reset'
import { AnyAction } from 'redux'
import { Target } from './target'

export type TermOfClassification = {
  additional_parents: Array<number>
  formula: string
  id: number
  key: string
  organization: number
  parent: number
  sum_values_in_tree: boolean
  targets: Array<Target>
  title: string
  unit: string | null
}

interface TermState {
  data: TermOfClassification | null
  list: {
    results: Array<TermOfClassification>
  }
  timeline: { end_date?: string; start_date?: string }
  isPending: boolean
  error: Array<Error> | Error | null
}

const initialState: TermState = {
  data: null,
  list: {
    results: []
  },
  timeline: {},
  isPending: false,
  error: null
}

const term = (state = initialState, action: AnyAction) => {
  const { type, payload } = action

  switch (type) {
    case `${GET_TERM_OF_CLASSIFICATION}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${GET_TERM_OF_CLASSIFICATION}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${GET_TERM_OF_CLASSIFICATION}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.data = payload
      })

    case `${LIST_TERM_OF_CLASSIFICATION}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${LIST_TERM_OF_CLASSIFICATION}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${LIST_TERM_OF_CLASSIFICATION}_FULFILLED`:
      return produce(state, draft => {
        draft.list = payload
        draft.isPending = false
      })

    case `${GET_TIMELINE_FOR_DATA}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${GET_TIMELINE_FOR_DATA}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${GET_TIMELINE_FOR_DATA}_FULFILLED`:
      return produce(state, draft => {
        draft.timeline = payload
        draft.isPending = false
      })

    case RESET_STATE:
      return initialState

    default:
      return state
  }
}

export default term

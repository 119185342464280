import React, { useState, useEffect } from 'react'
import { RootState } from 'store/reducers'
import { Organization } from 'store/reducers/organization'
import styled from 'styled-components/macro'
import { findOrg } from 'util/organization'
import { useSelector, useDispatch } from 'react-redux'
import { activateOrganization } from 'store/actions/organization'
import { saveTreeState } from 'store/actions/app'

const HotLinkContainer = styled.div`
  z-index: 999;
  bottom: 40px;
  right: 40px;
  position: fixed;
  font-weight: bold;
  min-width: 33px;
  height: auto;
  min-height: 50px;
  &:hover .menu {
    display: block;
  }
`
const Menu = styled.div`
  display: none;
`
const HotLink = styled.div`
  text-align: center;
  margin-bottom: 12px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  height: 20px;
  color: #333;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background: #ffd800;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin: 12px 0px;
`
const SpeedDial = styled.div`
  text-align: center;
  margin-bottom: 12px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
  height: 20px;
  color: #333;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background: #ffd800;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin: 12px 0px;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  float: right;
`

const OrganizationHotLinks: React.FC<{
  onOrganizationChange: () => void
}> = ({ onOrganizationChange }) => {
  const dispatch = useDispatch()
  const [parentOrg, setParentOrg] = useState<Organization | null>(null)
  const { data, activeOrganization } = useSelector(
    (state: RootState) => state.organization
  )
  useEffect(() => {
    if (!parentOrg || parentOrg.id !== activeOrganization) {
      const org = findOrg(activeOrganization, data)
      if (org && !org.parentId && org.children && org.children.length > 0) {
        setParentOrg(org)
      } else if (org && org.parentId) {
        const o = findOrg(org.parentId, data)
        if (o) {
          setParentOrg(o)
        }
      }
    }
  })
  const changeActiveOrganization = (o: Organization) => {
    dispatch(activateOrganization(o.id))
    if (window.dataLayer) {
      window.dataLayer.push({
        organizationId: o.id
      })
    }
    onOrganizationChange()
    dispatch(saveTreeState(null))
  }
  const getHotLink = (o: Organization, level: number) => {
    return (
      <HotLink
        style={{ marginLeft: level * 10 + 'px' }}
        onClick={() => changeActiveOrganization(o)}
        key={o.id}
      >
        {o.name}
      </HotLink>
    )
  }
  const renderOrg = (org: Organization, level: number) => {
    let list: JSX.Element[] = [getHotLink(org, level)]
    if (org.children && org.children.length > 0) {
      for (const o of org.children) {
        list = list.concat(renderOrg(o, level + 1))
      }
    }
    return list
  }
  if (!parentOrg) return <></>
  return (
    <HotLinkContainer>
      <Menu className="menu">{parentOrg && renderOrg(parentOrg, 0)}</Menu>
      <SpeedDial>+</SpeedDial>
    </HotLinkContainer>
  )
}

export default OrganizationHotLinks

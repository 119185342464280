import { AppDispatch } from 'store/store'

export const SELECT_TIME_GROUPING = 'SELECT_TIME_GROUPING'

export const selectTimeGrouping = (newGrouping: string) => (
  dispatch: AppDispatch
) => {
  return dispatch({
    type: SELECT_TIME_GROUPING,
    payload: { newGrouping }
  })
}

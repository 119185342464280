import {
  CREATE_ROADMAP,
  UPDATE_ROADMAP,
  GET_ROADMAP,
  LIST_ROADMAP,
  DELETE_ROADMAP
} from 'store/actions/roadmap'

import produce from 'immer'
import { AnyAction } from 'redux'

export type RoadmapType = {
  id: number
  title: string
  organization: number
  order: number
}

interface RoadMapState {
  data: RoadmapType | null
  created: RoadmapType | null
  list: {
    results: Array<RoadmapType>
  }
  isPending: boolean
  error: Array<Error> | Error | null
}

const initialState: RoadMapState = {
  data: null,
  created: null,
  list: { results: [] },
  isPending: false,
  error: null
}

const roadmap = (state = initialState, action: AnyAction) => {
  const { type, payload } = action

  switch (type) {
    case `${GET_ROADMAP}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${GET_ROADMAP}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${GET_ROADMAP}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.data = payload
      })

    case `${LIST_ROADMAP}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${LIST_ROADMAP}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${LIST_ROADMAP}_FULFILLED`:
      return produce(state, draft => {
        draft.list = payload
        draft.isPending = false
      })

    case `${CREATE_ROADMAP}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${CREATE_ROADMAP}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${CREATE_ROADMAP}_FULFILLED`:
      return produce(state, draft => {
        draft.created = payload
        draft.isPending = false
      })

    case `${UPDATE_ROADMAP}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${UPDATE_ROADMAP}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${UPDATE_ROADMAP}_FULFILLED`:
      return produce(state, draft => {
        draft.created = payload
        draft.isPending = false
      })

    case `${DELETE_ROADMAP}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${DELETE_ROADMAP}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
      })

    case `${DELETE_ROADMAP}_FULFILLED`:
      return produce(state, draft => {
        draft.created = payload
        draft.isPending = false
      })

    default:
      return state
  }
}

export default roadmap

import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { TFunction, withTranslation } from 'react-i18next'

const Container = styled.div`
  display: flex;
  align-items: center;
  span {
    cursor: pointer;
    font-family: 'Open Sans';
    font-size: 0.75rem;
    letter-spacing: 0;
    line-height: 1;
    margin-right: 5px;
    &:last-of-type {
      margin-left: 5px;
      margin-right: 0;
    }
  }
`

const SwitchContainer = styled.div`
  position: relative;
  background: #9e9e9e8a;
  border-radius: 0.625rem;
  width: 1.563rem;
  height: 7px;
  cursor: pointer;
`

const SwitchPosition = styled.div`
  position: absolute;
  height: 0.75rem;
  width: 0.75rem;
  background-color: #ffd800;
  border-radius: 100%;
  ${(props: { position: string }) =>
    props.position === 'monthly' ? 'left: 0' : 'right: 0'};
  top: calc(50% - 6px);
`

interface DataSwitchProps {
  t: TFunction
  onChange: (position: string) => void
}

interface DataSwitchState {
  position: string
}

class DataSwitch extends Component<DataSwitchProps, DataSwitchState> {
  constructor(props: DataSwitchProps) {
    super(props)
    this.state = {
      position: 'monthly'
    }
  }

  setSwitchPosition = (clickedPosition: string | null = null) => {
    const { onChange } = this.props
    const { position } = this.state
    let newPosition = position === 'monthly' ? 'cumulative' : 'monthly'
    if (clickedPosition) {
      newPosition = clickedPosition
    }
    this.setState({
      position: newPosition
    })
    if (onChange) {
      onChange(newPosition)
    }
  }

  render() {
    const { t } = this.props
    const { position } = this.state
    return (
      <Container>
        <span onClick={() => this.setSwitchPosition('monthly')}>
          {t('monthly')}
        </span>
        <SwitchContainer onClick={() => this.setSwitchPosition()}>
          <SwitchPosition position={position} />
        </SwitchContainer>
        <span onClick={() => this.setSwitchPosition('cumulative')}>
          {t('cumulative')}
        </span>
      </Container>
    )
  }
}

export default withTranslation()(DataSwitch)

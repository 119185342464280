import { Get, Post } from 'api/index'
import { createAsyncAction } from 'redux-promise-middleware-actions'
import { RootState } from 'store/reducers'
import { AppDispatch } from 'store/store'

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const USER_READ = 'USER_READ'
export const PASSWORD_RESET = 'PASSWORD_RESET'
export const PASSWORD_CONFIRM = 'PASSWORD_CONFIRM'
export const PASSWORD_CONFIRM_RESET = 'PASSWORD_CONFIRM_RESET'
export const PASSWORD_CHANGE = 'PASSWORD_CHANGE'
export const SET_NOTIFICATIONS_READ = 'SET_NOTIFICATIONS_READ'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const AZURE_CONNECT = 'AZURE_CONNECT'

export const login = createAsyncAction(
  LOGIN,
  async (body: { username: string; password: string }) => {
    const url = `/api/login/`
    const data = await Post(url, JSON.stringify(body))
    return data
  }
)

export const azureLogin = createAsyncAction(
  LOGIN,
  async (body: { id_token: string }) => {
    const url = `/api/auth/azure/`
    const data = await Post(url, JSON.stringify(body))
    return data
  }
)

export const azureConnect = createAsyncAction(
  AZURE_CONNECT,
  async (body: { id_token: string }) => {
    const url = `/api/auth/azure/connect/`
    const data = await Post(url, JSON.stringify(body))
    return data
  }
)

export const logout = createAsyncAction(LOGOUT, async () => {
  const url = `/api/logout/`
  const data = await Post(url)
  return data
})

export const userRead = createAsyncAction(USER_READ, async () => {
  const url = `/api/user/get_user_details/`
  const data = await Get<RootState['auth']['user']>(url)
  return data
})

export const resetPassword = (body: { email: string }) => (
  dispatch: AppDispatch
) => {
  const url = `/api/auth/password/reset/`
  return dispatch({
    type: PASSWORD_RESET,
    payload: Post(url, JSON.stringify(body))
  })
}

export const resetPasswordConfirm = createAsyncAction(
  PASSWORD_CONFIRM,
  async (body: {
    new_password1: string
    new_password2: string
    uid: string
    token: string
  }) => {
    const url = `/api/auth/password/reset/confirm/`
    const data = await Post(url, JSON.stringify(body))
    return data
  }
)

export const resetPasswordConfirmReset = () => (dispatch: AppDispatch) =>
  dispatch({
    type: PASSWORD_CONFIRM_RESET
  })

export const setNotificationsRead = () => (dispatch: AppDispatch) =>
  dispatch({
    type: SET_NOTIFICATIONS_READ
  })

export const changePassword = createAsyncAction(
  PASSWORD_CHANGE,
  async (body: {
    old_password: string
    new_password1: string
    new_password2: string
  }) => {
    const url = '/api/auth/password/change/'
    const data = await Post<{ detail: string }>(url, JSON.stringify(body))
    return data
  }
)

export const setLanguage = createAsyncAction(
  SET_LANGUAGE,
  async (language: string) => {
    const url = '/api/user/set_language/'
    const data = await Post(url, JSON.stringify({ language }))
    return data
  }
)

import { Delete, Get, Post, Patch } from 'api/index'
import { createAsyncAction } from 'redux-promise-middleware-actions'
import { RootState } from 'store/reducers'
import { MilestoneType } from 'store/reducers/milestone'

export const GET_MILESTONE = 'GET_MILESTONE'
export const LIST_MILESTONE = 'LIST_MILESTONE'
export const CREATE_MILESTONE = 'CREATE_MILESTONE'
export const UPDATE_MILESTONE = 'UPDATE_MILESTONE'
export const DELETE_MILESTONE = 'DELETE_MILESTONE'

export const getMilestone = createAsyncAction(
  GET_MILESTONE,
  async (id: number) => {
    const url = `/api/milestone/${id}/`
    const data = await Get<MilestoneType>(url)
    return data
  }
)

export const listMilestone = createAsyncAction(
  LIST_MILESTONE,
  async (roadmapId: number) => {
    const url = `/api/milestone/?roadmapId=${roadmapId}`
    const data = await Get<RootState['milestone']['list']>(url)
    return data
  }
)

export const createMilestone = createAsyncAction(
  CREATE_MILESTONE,
  async (body: string) => {
    const url = `/api/milestone/`
    const data = await Post<MilestoneType>(url, body)
    return data
  }
)

export const updateMilestone = createAsyncAction(
  UPDATE_MILESTONE,
  async (body: string, id: number) => {
    const url = `/api/milestone/${id}/`
    const data = await Patch(url, body)
    return data
  }
)

export const deleteMilestone = createAsyncAction(
  DELETE_MILESTONE,
  async (id: number) => {
    const url = `/api/milestone/${id}/`
    const data = await Delete(url)
    return data
  }
)

import React, { Component } from 'react'
import moment from 'moment'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'
import MonthSelector, { RangeValue } from 'components/blocks/MonthSelector'
import { setSelections } from 'store/actions/monthselector'
import { bindActionCreators } from 'redux'
import { RootState } from 'store/reducers'
import { Organization } from 'store/reducers/organization'
import { AppDispatch } from 'store/store'
import { findOrg } from 'util/organization'

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export type Years = { [key: string]: Array<string> }
type Timespan = { start_date: string; end_date: string }

const processNoDataProp = (years: Years) => {
  const newYears = { ...years }
  const start = moment()
  while (start.year() > 2016) {
    const year = start.format('YYYY')
    const month = start.format('YYYY-MM')
    if (!newYears[year]) {
      newYears[year] = [month]
    }

    newYears[year] = Array.from(new Set([...newYears[year], month]))
    start.subtract(1, 'month')
  }
  return newYears
}

const processDataProp = (
  years: Years,
  data: Array<{ [key: string | number]: unknown }>,
  keyToDate: string | number
) => {
  const newYears = { ...years }
  data.forEach(d => {
    const key = d[keyToDate]
    if (typeof key !== 'string') {
      return
    }
    const momentObj = moment(key)
    const year = momentObj.format('YYYY')
    const month = momentObj.format('YYYY-MM')
    if (!newYears[year]) {
      newYears[year] = [month]
      return
    }

    newYears[year] = Array.from(new Set([...newYears[year], month]))
  })
  return newYears
}

const processFiscalYearYTD = (activeOrganization: Organization) => {
  const months: string[] = []
  const startMonth = parseInt(activeOrganization.start_of_fiscal_year, 10)
  const start =
    moment().month() < startMonth
      ? moment()
          .subtract(1, 'year')
          .set('month', startMonth - 1)
      : moment().set('month', startMonth - 1)
  while (start.month() <= moment().month()) {
    months.push(start.format('YYYY-MM'))
    start.add(1, 'month')
  }
  return months
}

export const processTimeSpan = (
  years: Years,
  timeSpan: Timespan,
  activeOrganization: Organization
) => {
  const newYears = { ...years }
  const startOfFiscalYear = parseInt(
    activeOrganization.start_of_fiscal_year,
    10
  )
  const start = moment(timeSpan.start_date)
  const end = moment(timeSpan.end_date)
  while (start <= end) {
    const year =
      start.month() + 1 >= startOfFiscalYear
        ? `${start.format('YYYY')} - ${parseInt(start.format('YYYY'), 10) + 1}`
        : `${parseInt(start.format('YYYY'), 10) - 1} - ${start.format('YYYY')}`
    const month = start.format('YYYY-MM')
    if (!newYears[year]) {
      newYears[year] = []
    }
    if (!newYears[year].includes(month)) {
      newYears[year].push(month)
    }
    start.add(1, 'month')
  }
  return newYears
}

type DateSelectorReduxState = ReturnType<typeof mapStateToProps>
type DateSelectorReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface DateSelectorProps {
  data?: Array<{ [key: string | number]: unknown }>
  location: string
  keyToDate?: string
  useCurrentYearAsYtd?: boolean
  noData?: boolean
  onDateChange: (years: Years) => void
  timeSpan?: Timespan
  singleSelect?: boolean
  rangeSelect?: boolean
  noYtd?: boolean
  ytdEmpty?: boolean
}

interface DateSelectorState {
  years: Years
}

class DateSelector extends Component<
  DateSelectorProps & DateSelectorReduxState & DateSelectorReduxDispatch,
  DateSelectorState
> {
  static getDerivedStateFromProps(
    props: DateSelectorProps &
      DateSelectorReduxState &
      DateSelectorReduxDispatch,
    state: DateSelectorState
  ) {
    if (
      (props.keyToDate || props.noData || props.timeSpan) &&
      ((props.data && props.data.length > 0) ||
        props.noData ||
        props.timeSpan) &&
      props.organization.activeOrganization
    ) {
      let years: Years = {}
      const activeOrganization = findOrg(
        props.organization.activeOrganization,
        props.organization.data
      )
      if (
        !props.useCurrentYearAsYtd &&
        !props.noYtd &&
        activeOrganization &&
        activeOrganization.start_of_fiscal_year
      ) {
        years.YTD = processFiscalYearYTD(activeOrganization)
      }
      if (props.data && props.keyToDate) {
        years = processDataProp(years, props.data, props.keyToDate)
      } else if (props.noData) {
        years = processNoDataProp(years)
      } else if (props.timeSpan && activeOrganization) {
        years = processTimeSpan(years, props.timeSpan, activeOrganization)
      }
      if (Object.keys(years).length !== Object.keys(state.years).length) {
        return { years }
      }
    }
    return null
  }

  constructor(
    props: DateSelectorProps &
      DateSelectorReduxState &
      DateSelectorReduxDispatch
  ) {
    super(props)
    this.state = { years: {} }
  }

  selectYear = (year: string) => {
    const {
      onDateChange,
      singleSelect,
      rangeSelect,
      location,
      monthselector
    } = this.props
    const selection = this.getSelection(location, monthselector).value as Years
    let newSelection = { ...selection }
    if (newSelection[year]) {
      delete newSelection[year]
      this.setSelections(newSelection)
      onDateChange(newSelection)
      return
    }

    if (singleSelect) {
      newSelection = {}
    }

    newSelection[year] = []
    if (Object.keys(newSelection).length > 1) {
      Object.keys(newSelection).forEach(k => {
        newSelection[k] = []
      })
    }
    if (rangeSelect && Object.keys(newSelection).length > 2) {
      Object.keys(newSelection)
        .filter(y => {
          const yStart = parseInt(y.split('-')[0], 10)
          const newYStart = parseInt(year.split('-')[0], 10)
          return yStart < newYStart
        })
        .forEach(y => {
          delete newSelection[y]
        })
    }
    this.setSelections(newSelection)
    onDateChange(newSelection)
  }

  selectMonth = (month: string) => {
    const {
      onDateChange,
      singleSelect,
      rangeSelect,
      location,
      monthselector
    } = this.props
    const selection = this.getSelection(location, monthselector).value as Years
    const newSelection = { ...selection }
    const year = Object.keys(selection)[0]
    if (newSelection[year].includes(month)) {
      newSelection[year] = newSelection[year].filter(m => m !== month)
      this.setSelections(newSelection)
      onDateChange(newSelection)
      return
    }

    newSelection[year] = singleSelect ? [month] : [...newSelection[year], month]
    if (rangeSelect && newSelection[year].length > 2) {
      const sortedMonths = newSelection[year].sort((a, b) =>
        moment(a, 'YYYY-MM') <= moment(b, 'YYYY-MM') ? -1 : 1
      )
      newSelection[year] = [sortedMonths[0], month]
    }
    this.setSelections(newSelection)
    onDateChange(newSelection)
  }

  checkMonthBetweenSelections = (selection: Years, month: string) => {
    const orderedSelections = Object.values(selection)[0].sort((a, b) =>
      moment(a, 'YYYY-MM') <= moment(b, 'YYYY-MM') ? -1 : 1
    )
    const monthMoment = moment(month, 'YYYY-MM')
    const firstSelection = moment(orderedSelections[0], 'YYYY-MM')
    const lastSelection = moment(
      orderedSelections[orderedSelections.length - 1],
      'YYYY-MM'
    )
    return monthMoment >= firstSelection && monthMoment <= lastSelection
  }

  checkYearBetweenSelections = (selection: Years, year: string) => {
    const orderedSelections = Object.keys(selection).sort((a, b) =>
      parseInt(a, 10) <= parseInt(b, 10) ? -1 : 1
    )
    const yearInteger = parseInt(year, 10)
    const firstSelection = parseInt(orderedSelections[0], 10)
    const lastSelection = parseInt(
      orderedSelections[orderedSelections.length - 1],
      10
    )
    return yearInteger >= firstSelection && yearInteger <= lastSelection
  }

  getSelection = (
    location: string,
    monthselector: DateSelectorReduxState['monthselector']
  ) => {
    if (monthselector && monthselector.selections) {
      return monthselector.selections[location]
    }
    return {}
  }

  setSelections = (value: Years, rangeValue?: RangeValue) => {
    const {
      setSelections: setSelectionsAction,
      location,
      monthselector
    } = this.props
    const selections = {
      ...(monthselector.selections ? monthselector.selections : {})
    }
    selections[location] = { value, rangeValue }
    setSelectionsAction(selections)
  }

  onRangeChange = (value: Years, rangeValue: RangeValue) => {
    const { onDateChange } = this.props
    this.setSelections(value, rangeValue)
    onDateChange(value)
  }

  onInit = () => {
    const { onDateChange, location, monthselector } = this.props
    const { value } = this.getSelection(location, monthselector)
    onDateChange(value as Years)
  }

  render() {
    const { years } = this.state
    const {
      organization: { data: orgData, activeOrganization: aId },
      rangeSelect,
      noYtd,
      ytdEmpty,
      location,
      monthselector
    } = this.props
    const activeOrganization = findOrg(aId, orgData)
    const initialValue = this.getSelection(location, monthselector)
      ? this.getSelection(location, monthselector).rangeValue
      : null
    if (!activeOrganization) {
      return null
    }
    return (
      <DateContainer>
        <MonthSelector
          onInit={() => this.onInit()}
          initialValue={initialValue as RangeValue | undefined}
          onRangeChange={(value, rangeValue) => {
            if (!value) {
              return
            }
            this.onRangeChange(value, rangeValue as RangeValue)
          }}
          activeOrganization={activeOrganization}
          rangeSelect={rangeSelect as boolean}
          years={years}
          noYtd={noYtd}
          ytdEmpty={ytdEmpty}
        />
      </DateContainer>
    )
  }
}

const mapStateToProps = ({ organization, monthselector }: RootState) => ({
  organization,
  monthselector
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      setSelections
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(DateSelector)

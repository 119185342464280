import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { DateSelection } from './Roadmap'

const EmptyContainer = styled.div`
  width: ${(props: { dateSelection: { rowLength: number } }) =>
    100 / props.dateSelection.rowLength}%;
  height: 100%;
  text-align: left;
  display: inline-block;
`

class Empty extends Component<{
  dateSelection: DateSelection
  children: React.ReactNode | React.ReactNode[]
}> {
  render() {
    const { children, dateSelection } = this.props
    return (
      <>
        <EmptyContainer dateSelection={dateSelection} className="empty-cell">
          {children}
        </EmptyContainer>
      </>
    )
  }
}

export default withTranslation()(Empty)

import { getGraphPathName } from './format'
import { Location } from 'history'
import { GraphMapping } from 'store/reducers/graph'

const encodeQueryParams = (params: { [key: string]: string | number | boolean }) => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&')

  return query === '' ? query : `?${query}`
}

const isGraphPage = (location: Location, mappings: Array<GraphMapping>) => {
  const { pathname } = location
  if (pathname === '/roadmap') {
    return true
  }
  return (mappings || []).some(
    m =>
      pathname.indexOf(getGraphPathName(m.view_title)) !== -1 ||
      m.children.some(
        c => pathname.indexOf(getGraphPathName(c.view_title)) !== -1
      )
  )
}

// Default export omitted because this file is supposed to contain multiple utility-functions
// eslint-disable-next-line
export { encodeQueryParams, isGraphPage }

import {
  SAVE_ACTIVE_THEME,
  SAVE_TREE_STATE,
  SET_MENU_STATE
} from 'store/actions/app'

import produce from 'immer'
import { RESET_STATE } from 'store/actions/reset'
import { SYSTEM } from 'theme'
import { AnyAction } from 'redux'
import { ReactD3TreeItem } from 'react-d3-tree'

interface AppState {
  menuState: string | null
  treeState: ReactD3TreeItem[] | null
  treeUpdated: number
  activeTheme: string
}

const initialState: AppState = {
  menuState: localStorage.getItem('menu'),
  treeState: null,
  treeUpdated: 0,
  activeTheme: localStorage.getItem('activeTheme') || SYSTEM
}

const app = (state = initialState, action: AnyAction) => {
  const { type, payload } = action
  switch (type) {
    case `${SET_MENU_STATE}`:
      return produce(state, draft => {
        draft.menuState = payload
      })

    case `${SAVE_TREE_STATE}`:
      return {
        ...state,
        treeState: payload,
        treeUpdated: Date.now()
      }

    case SAVE_ACTIVE_THEME:
      return {
        ...state,
        activeTheme: payload
      }

    case RESET_STATE:
      return { ...initialState, activeTheme: state.activeTheme }

    default:
      return state
  }
}

export default app

import React, { Component } from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Card, Title } from 'components/blocks'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ApiError from 'ApiError'
import { toast } from 'react-toastify'
import Select from 'react-select/creatable'
import { getOrganizationUsers } from 'store/actions/organization'
import { sendReport } from 'store/actions/report'
import { DefaultTheme, withTheme } from 'styled-components'
import { RootState } from 'store/reducers'
import { Report } from 'store/reducers/report'
import { AppDispatch } from 'store/store'

const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #33333366;
  visibility: ${(props: { isOpen: boolean }) =>
    props.isOpen ? 'visible' : 'hidden'};
  pointer-events: ${props => (props.isOpen ? 'all' : 'none')};
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    position: relative;
    box-shadow: none;
    width: 100%;
    max-width: 600px;
    max-height: 100vh;
    padding: 0.938rem;
    opacity: ${props => (props.isOpen ? '1' : '0')};
    transform: translateY(${props => (props.isOpen ? '0' : '-50px')});
    transition: transform, opacity 0.3s ease-in-out;
    > h1 {
      margin-top: 0;
      margin-bottom: 1rem;
      margin-left: 0;
    }
  }
`
const CloseButton = styled.span`
  position: absolute;
  right: 0.938rem;
  top: 0.938rem;
  font-size: 1.875rem;
  cursor: pointer;
`
const Button = styled.div`
  width: 100%;
  cursor: pointer;
  border-radius: 3px;
  background: #b9c5c8;
  box-shadow: 2px 2px 0 0 #93a4a8;
  padding: 0.938rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.875rem;
  color: white;
  user-select: none;
  margin-top: 0.938rem;
`

type SendReportModalReduxState = ReturnType<typeof mapStateToProps>
type SendReportModalReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface SendReportModalProps {
  isOpen: boolean
  t: TFunction
  report: Report | null
  onClose: (arg?: boolean) => void
  theme: DefaultTheme
}

interface SendReportModalState {
  reportRecipients: Array<number>
  reportEmailRecipients: Array<string>
}

class SendReportModal extends Component<
  SendReportModalProps &
    SendReportModalReduxState &
    SendReportModalReduxDispatch,
  SendReportModalState
> {
  constructor(
    props: SendReportModalProps &
      SendReportModalReduxState &
      SendReportModalReduxDispatch
  ) {
    super(props)
    this.state = {
      reportRecipients: [],
      reportEmailRecipients: []
    }
  }

  componentDidMount = async () => {
    const { getOrganizationUsers: getOrganizationUsersAction } = this.props
    await getOrganizationUsersAction()
  }

  sendReport = async () => {
    const {
      onClose,
      report,
      sendReport: sendReportAction,
      t,
      theme
    } = this.props
    if (!report) {
      return
    }
    const { reportRecipients, reportEmailRecipients } = this.state
    const data = await sendReportAction(
      report.id,
      JSON.stringify({
        users: reportRecipients,
        emails: reportEmailRecipients,
        theme: theme.id
      })
    )
    if (data instanceof ApiError) {
      if (!data.messages) {
        return
      }
      const messages = Object.keys(data.messages).map(
        m => `${t(m)}: ${data && data.messages ? data.messages[m][0] : ''}\n`
      )
      messages.forEach(m => toast.error(m))
      return
    }

    toast.success(t('report-sent'))
    onClose(true)
  }

  handleChange = (value: Array<{ value: string | number; label: string }>) => {
    if (value && value.length > 0) {
      const reportRecipients: number[] = []
      const reportEmailRecipients: string[] = []
      value.forEach(user => {
        if (typeof user.value === 'number') {
          reportRecipients.push(user.value)
        } else {
          reportEmailRecipients.push(user.value)
        }
      })
      this.setState({ reportRecipients, reportEmailRecipients })
    }
  }

  render() {
    const { isOpen, t, onClose, users } = this.props
    const options = users.map(user => ({
      value: user.id,
      label: `${user.full_name}`
    }))
    return (
      <ModalContainer isOpen={isOpen}>
        <Card>
          <CloseButton onClick={() => onClose()}>&times;</CloseButton>
          <Title>{t('send-report')}</Title>
          <Select
            isMulti
            onChange={this.handleChange}
            options={options}
            placeholder={t('report-recipients')}
          />
          <Button onClick={this.sendReport}>{t('send')}</Button>
        </Card>
      </ModalContainer>
    )
  }
}

const mapStateToProps = ({ organization: { users } }: RootState) => ({
  users
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      getOrganizationUsers,
      sendReport
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withTheme(SendReportModal)))

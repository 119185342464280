import React, { Component } from 'react'
import moment from 'moment'
import { TFunction, withTranslation } from 'react-i18next'
import { Title } from 'components/blocks'
import { connect } from 'react-redux'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { resetErrorMessage } from 'store/actions/error'
import {
  deleteReport,
  listReport,
  listReportDataMap,
  listReportGraph
} from 'store/actions/report'
import { ReportList } from 'components/reports'
import DateSelector, { Years } from 'components/notification/DateSelector'
import styled from 'styled-components/macro'
import { CreateGraphModal, EditCreateReportModal } from 'components/modals'
import { RouteComponentProps, withRouter } from 'react-router'
import { Switch } from './GraphView'
import { RootState } from 'store/reducers'

const TitleArea = styled.div`
  display: flex;
  flex-direction: column;
  h1 {
    display: flex;
    align-items: center;
  }
  > div {
    margin-bottom: 1.25rem;
  }
`

const CreateButton = styled.span`
  cursor: pointer;
  border-radius: 3px;
  margin-left: 1.25rem;
  background: #b9c5c8;
  box-shadow: 2px 2px 0 0 #93a4a8;
  padding: 0 0.625rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.875rem;
  color: white;
  user-select: none;
`

type ReportsReduxState = ReturnType<typeof mapStateToProps>
type ReportsReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface ReportsProps {
  t: TFunction
}

interface ReportsState {
  results: RootState['report']['list']
  graphResults: RootState['report']['graphList']
  dataMapResults: RootState['report']['dataMapList']
  createModalOpen: boolean
  createGraphModalOpen: boolean
}

class Reports extends Component<
  ReportsProps & ReportsReduxState & ReportsReduxDispatch & RouteComponentProps,
  ReportsState
> {
  constructor(
    props: ReportsProps &
      ReportsReduxState &
      ReportsReduxDispatch &
      RouteComponentProps
  ) {
    super(props)
    this.state = {
      results: [],
      graphResults: [],
      dataMapResults: [],
      createModalOpen: false,
      createGraphModalOpen: false
    }
  }

  async componentDidMount() {
    const {
      listReport: listReportAction,
      listReportGraph: listReportGraphAction,
      listReportDataMap: listReportDataMapAction
    } = this.props
    await listReportAction()
    await listReportGraphAction()
    await listReportDataMapAction()
    const {
      report: { list, graphList, dataMapList }
    } = this.props
    this.setState({
      results: list,
      graphResults: graphList,
      dataMapResults: dataMapList
    })
  }

  updateList = (dateObj: Years) => {
    const {
      report: { list }
    } = this.props
    let newResults = [...list]
    if (Object.keys(dateObj).length === 0) {
      this.setState({ results: newResults })
      return
    }
    newResults = newResults.filter(r =>
      Object.keys(dateObj).includes(
        moment(r.published)
          .year()
          .toString()
      )
    )
    const months = Object.values(dateObj)[0]
    if (months && months.length) {
      newResults = newResults.filter(r =>
        months.includes(moment(r.published).format('YYYY-MM'))
      )
    }
    this.setState({ results: newResults })
  }

  openCreateModal = () => this.setState({ createModalOpen: true })

  openCreateGraphModal = () => this.setState({ createGraphModalOpen: true })

  closeCreateModal = (update: boolean) => {
    this.setState(
      { createModalOpen: false, createGraphModalOpen: false },
      update
        ? async () => {
            const {
              listReport: listReportAction,
              listReportGraph: listReportGraphAction,
              listReportDataMap: listReportDataMapAction
            } = this.props
            await listReportAction()
            await listReportGraphAction()
            await listReportDataMapAction()
            const {
              report: { list, graphList, dataMapList }
            } = this.props
            this.setState({
              results: list,
              graphResults: graphList,
              dataMapResults: dataMapList
            })
          }
        : () => null
    )
  }

  render() {
    const { createModalOpen, createGraphModalOpen } = this.state
    const {
      report: { list },
      t,
      listReport: listReportAction,
      listReportGraph: listReportGraphAction,
      listReportDataMap: listReportDataMapAction,
      history
    } = this.props
    const {
      results: filteredResults,
      graphResults,
      dataMapResults
    } = this.state
    return (
      <div className="App">
        <Switch>
          <span onClick={() => history.push('/notifications')}>
            {t('notifications')}
          </span>
          <span className="selected">{t('reports')}</span>
        </Switch>
        <TitleArea>
          <Title>
            {t('reports')}{' '}
            <CreateButton onClick={this.openCreateModal}>
              + {t('create-report')}
            </CreateButton>
            <CreateButton onClick={this.openCreateGraphModal}>
              + {t('create-report-graph')}
            </CreateButton>
          </Title>
          <DateSelector
            location="reports"
            data={list}
            keyToDate="published"
            onDateChange={this.updateList}
            useCurrentYearAsYtd
          />
        </TitleArea>
        <ReportList
          results={filteredResults}
          graphResults={graphResults}
          dataMapResults={dataMapResults}
          updateList={async () => {
            await listReportAction()
            await listReportGraphAction()
            await listReportDataMapAction()
            const {
              report: { list, graphList, dataMapList }
            } = this.props
            this.setState({
              results: list,
              graphResults: graphList,
              dataMapResults: dataMapList
            })
          }}
        />
        <EditCreateReportModal
          isOpen={createModalOpen}
          onClose={this.closeCreateModal}
          report={null}
        />
        <CreateGraphModal
          isOpen={createGraphModalOpen}
          onClose={this.closeCreateModal}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ error, report, auth }: RootState) => ({
  error,
  report,
  user: auth.user
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      resetErrorMessage,
      listReport,
      deleteReport,
      listReportGraph,
      listReportDataMap
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(Reports)))

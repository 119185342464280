import { RootState } from 'store/reducers'
import { AppDispatch } from 'store/store'

export const SET_SELECTIONS = 'SET_SELECTIONS'

export const setSelections = (
  selections: RootState['monthselector']['selections']
) => (dispatch: AppDispatch) => {
  return dispatch({
    type: SET_SELECTIONS,
    payload: { selections }
  })
}

import React, { Component } from 'react'
import moment from 'moment'
import { TFunction, withTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { TargetMeter } from 'components/strategy'
import { TargetModal } from 'components/modals'
import Icon from 'components/Icon'
import { formatFloat, formatInt, getGraphPathName } from 'util/format'
import Done from 'assets/icons/done.svg'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { setTargetToGraph } from 'store/actions/target'
import { PRESENTATION_MONTH_FORMAT } from 'util/dates'
import { RootState } from 'store/reducers'
import { Target } from 'store/reducers/target'

const Container = styled.div`
  padding: 0.625rem 1.25rem;
  &:not(:last-of-type) {
    border-bottom: 1px solid #f0f0f0;
  }
  &.interactive {
    cursor: pointer;
    &:hover {
      background: #dddddd33;
    }
  }
`
const InfoContainer = styled.div`
  display: flex;
  flex-grow: 1;
  span.title {
    display: inline-block;
    color: ${props => props.theme.strategy.targetTitle};
    font-family: 'Open Sans';
    margin-top: 0;
    margin-bottom: 0.875rem;
    font-size: 0.813rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1.125rem;
  }
`
const MetaInfo = styled.p`
  font-family: 'Open Sans';
  margin-top: 0.625rem;
  margin-bottom: 0;
  color: #999999;
  font-size: 0.813rem;
  letter-spacing: 0;
  line-height: 1.125rem;
  text-align: right;
  float: right;
  white-space: nowrap;
`
const InfoAndMeter = styled.div`
  display: flex;
  flex-direction: ${(props: { firstLevel: boolean }) =>
    props.firstLevel ? 'row' : 'column'};
  .meter {
    width: 100%;
    max-width: ${props => (props.firstLevel ? '250px' : 'none')};
    margin-left: ${props => (props.firstLevel ? '1.25rem' : '0')};
  }
`
export const DoneMark = styled.div`
  background: url(${Done});
  background-size: cover;
  min-width: 35px;
  min-height: 35px;
  max-height: 35px;
  max-width: 35px;
  margin-right: 0.938rem;
  align-self: center;
  margin-bottom: 0.875rem;
  flex-basis: 100%;
`

const InfoContent = styled.div`
  width: 100%;
  > div {
    display: flex;
    .icon {
      background-color: #333;
      cursor: pointer;
      &:hover {
        background-color: #aaa;
      }
    }
    span:first-of-type {
      flex-grow: 1;
      color: #b9c5c8;
      font-family: 'Open Sans';
      font-size: 0.813rem;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 1.125rem;
    }
    .title {
      width: 90%;
    }
  }
`

type TargetsReduxState = ReturnType<typeof mapStateToProps>
type TargetsReduxDispatch = ReturnType<typeof mapDispatchToProps>

interface TargetsProps {
  group: RootState['targetGroup']['data'][0]
  t: TFunction
}

class Targets extends Component<
  TargetsProps & TargetsReduxState & TargetsReduxDispatch & RouteComponentProps,
  { selectedTarget: Target | null }
> {
  constructor(
    props: TargetsProps &
      TargetsReduxState &
      TargetsReduxDispatch &
      RouteComponentProps
  ) {
    super(props)
    this.state = {
      selectedTarget: null
    }
  }

  componentDidMount() {
    const { location } = this.props
    const params = new URLSearchParams(location.search)
    const targetId = params.get('target_id')
    if (targetId) {
      const { group } = this.props
      const target = group.targets.find(t => t.id === parseInt(targetId, 10))
      if (target) {
        this.setState({ selectedTarget: target })
      }
    }
  }

  checkLinkToGraph = async (target: RootState['target']['data']) => {
    const { setTargetToGraph: setTargetToGraphAction } = this.props
    if (target && target.link_tag && target.link_tag !== '-') {
      setTargetToGraphAction(target)
      const { history } = this.props
      const url = getGraphPathName(target.link_tag)
      history.push(url)
    }
  }

  render() {
    const { selectedTarget } = this.state
    const {
      group,
      t: translate,
      settings: { mappings }
    } = this.props
    if (group.targets.length === 0) {
      return null
    }

    return (
      <>
        {group.targets.map((t: Target) => {
          if (t === null) {
            return null
          }
          return (
            <Container
              key={t.id}
              onClick={
                t.link_tag &&
                t.link_tag !== '-' &&
                (mappings || []).some(
                  m =>
                    getGraphPathName(m.view_title) ===
                      getGraphPathName(t.link_tag) ||
                    m.children.some(
                      (c: RootState['graph']['settings']['mappings'][0]) =>
                        getGraphPathName(c.view_title) ===
                        getGraphPathName(t.link_tag)
                    )
                )
                  ? () => this.checkLinkToGraph(t)
                  : () => null
              }
              className={
                t.link_tag &&
                t.link_tag !== '-' &&
                (mappings || []).some(
                  m =>
                    getGraphPathName(m.view_title) ===
                      getGraphPathName(t.link_tag) ||
                    m.children.some(
                      (c: RootState['graph']['settings']['mappings'][0]) =>
                        getGraphPathName(c.view_title) ===
                        getGraphPathName(t.link_tag)
                    )
                )
                  ? ' interactive'
                  : ''
              }
            >
              <InfoAndMeter firstLevel={group.parent === null}>
                <InfoContainer>
                  {(!t.reversed_target &&
                    t.current_value / t.target_value >= 1) ||
                  (t.reversed_target &&
                    t.target_value / t.current_value >= 1) ? (
                    <DoneMark />
                  ) : null}
                  <InfoContent>
                    <div>
                      <span>
                        {moment(t.end_date).format(PRESENTATION_MONTH_FORMAT)}
                      </span>
                      <Icon
                        iconName="info"
                        size={24}
                        onClick={(e: { stopPropagation: () => void }) => {
                          e.stopPropagation()
                          this.setState({ selectedTarget: t })
                        }}
                      />
                    </div>
                    <span className="title">{t.title}</span>
                  </InfoContent>
                </InfoContainer>
                <div className="meter">
                  <TargetMeter target={t} />
                  <MetaInfo>
                    {translate('accomplishment_and_target')}:{' '}
                    <strong>
                      {t.value_numeric_type === 'int'
                        ? formatInt(t.current_value)
                        : formatFloat(t.current_value)}{' '}
                      {t.measurement_type_prefix} /{' '}
                      {t.value_numeric_type === 'int'
                        ? formatInt(t.target_value)
                        : formatFloat(t.target_value)}{' '}
                      {t.measurement_type_prefix}
                    </strong>
                  </MetaInfo>
                </div>
              </InfoAndMeter>
            </Container>
          )
        })}
        <TargetModal
          isOpen={selectedTarget !== null}
          target={selectedTarget as Target}
          onClose={() => this.setState({ selectedTarget: null })}
        />
      </>
    )
  }
}

const mapStateToProps = ({ graph }: RootState) => ({
  settings: graph.settings
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ setTargetToGraph }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Targets)))

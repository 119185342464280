import moment from 'moment'
import { GraphMapping } from 'store/reducers/graph'
import { Organization } from 'store/reducers/organization'
import { RawData } from 'store/reducers/rawdata'
import {
  GROUPING_MONTH,
  GROUPING_FISCAL,
  GROUPING_QUARTER
} from 'store/reducers/timeGrouping'
import { findOrg } from './organization'

export const LONG_PRESENTATION_FORMAT = 'MMMM YYYY'
export const ISO_FORMAT_DATE = 'YYYY-MM-DD'
export const PRESENTATION_MONTH_FORMAT = 'MM/YYYY'
export const MONTH_FORMAT = 'YYYY-MM'

export const dateToFiscal = (date: moment.Moment, fiscalStartMonth: number) => {
  let fiscalEndMonth = fiscalStartMonth - 1
  if (fiscalEndMonth === 0) {
    fiscalEndMonth = 12
  }
  const offset = fiscalStartMonth === 1 ? 0 : 1
  let fiscal = date.year() + offset
  const momentAdjustedMonth = date.month() + 1
  if (momentAdjustedMonth < fiscalStartMonth) {
    fiscal -= 1
  }
  return String(fiscal)
}

export const dateToQuarter = (
  date: moment.Moment,
  fiscalStartMonth: number
) => {
  const fiscal = dateToFiscal(date, fiscalStartMonth)
  const momentAdjustedMonth = date.month() + 1
  const adjustedMonth = ((12 + momentAdjustedMonth - fiscalStartMonth) % 12) + 1
  const quarter = Math.ceil(adjustedMonth / 3)
  return `${quarter.toString()}/${fiscal.toString()}`
}

export const dateToQuarterStart = (
  date: moment.Moment,
  fiscalStartMonth: number
) => {
  const momentAdjustedMonth = date.month() + 1
  const adjustedMonth = ((12 + momentAdjustedMonth - fiscalStartMonth) % 12) + 1
  let monthsToReduce = 0
  while ((adjustedMonth - monthsToReduce) % 3 !== 1) {
    monthsToReduce += 1
  }
  let actualMonth = momentAdjustedMonth - monthsToReduce
  const momentAdjustedActualMonth = actualMonth - 1
  let actualYear = date.year()
  if (actualMonth <= 0) {
    actualMonth += 12
    actualYear -= 1
  }

  const returnValue = moment({
    year: actualYear,
    month: momentAdjustedActualMonth,
    day: 1
  })
  return returnValue
}

export const dateToQuarterEnd = (
  date: moment.Moment,
  fiscalStartMonth: number
) => {
  const momentAdjustedMonth = date.month() + 1
  const adjustedMonth = ((12 + momentAdjustedMonth - fiscalStartMonth) % 12) + 1
  let monthsToAdd = 0
  while ((adjustedMonth + monthsToAdd) % 3 !== 0) {
    monthsToAdd += 1
  }
  let actualMonth = momentAdjustedMonth + monthsToAdd
  const momentAdjustedActualMonth = actualMonth - 1
  let actualYear = date.year()
  if (actualMonth > 12) {
    actualMonth -= 12
    actualYear += 1
  }

  const returnValue = moment({
    year: actualYear,
    month: momentAdjustedActualMonth,
    day: 1
  }).endOf('month')

  return returnValue
}

const dateToFiscalStart = (
  date: { month: () => number; year: () => number },
  fiscalStartMonth: number
) => {
  const momentAdjustedMonth = date.month() + 1
  const momentAdjustedFiscalStart = fiscalStartMonth - 1
  if (momentAdjustedMonth >= fiscalStartMonth) {
    return moment({
      year: date.year(),
      month: momentAdjustedFiscalStart,
      day: 1
    })
  }
  return moment({
    year: date.year() - 1,
    month: momentAdjustedFiscalStart,
    day: 1
  })
}

const dateToFiscalEnd = (
  date: { month: () => number; year: () => number },
  fiscalStartMonth: number
) => {
  const momentAdjustedMonth = date.month() + 1
  let returnValue = null
  const momentAdjustedFiscalStart = fiscalStartMonth - 1
  if (momentAdjustedMonth >= fiscalStartMonth) {
    if (fiscalStartMonth === 1) {
      returnValue = moment({
        year: date.year(),
        // note that moment uses 0-11 as months
        // for some arcane reason...
        // we want december here => 11.
        month: 11,
        day: 31
      })
      return returnValue
    }
    returnValue = moment({
      year: date.year() + 1,
      month: momentAdjustedFiscalStart - 1,
      day: 1
    }).endOf('month')
    return returnValue
  }
  returnValue = moment({
    year: date.year(),
    month: momentAdjustedFiscalStart - 1,
    day: 1
  }).endOf('month')
  return returnValue
}

export const quarterStartDateFromLabel = (
  label: string,
  fiscalStartMonth: number
) => {
  const [quarterNumberWithQ, year] = label.split('/')
  const quarterNumber = parseInt(quarterNumberWithQ.split('Q')[1], 10)
  const fiscalYear = parseInt(year, 10)
  const offset = fiscalStartMonth === 1 ? 0 : 1
  const fiscalStart = moment(`${fiscalYear - offset}-${fiscalStartMonth}-01`)
  fiscalStart.add((quarterNumber - 1) * 3, 'months')
  return fiscalStart
}

export const fiscalStartDateFromLabel = (
  label: string,
  fiscalStartMonth: number
) => {
  const offset = fiscalStartMonth === 1 ? 0 : 1
  const fiscalYear = parseInt(label, 10)
  const fiscalStart = moment(`${fiscalYear - offset}-${fiscalStartMonth}-01`)
  return fiscalStart
}

// note that these functions return their input date's month's
// first day if the input date already is in the start/end month
// of the time period.
export const dateToNextQuarterStartMonth = (
  date: moment.Moment,
  fiscalStartMonth: number
) => {
  const momentAdjustedMonth = date.month() + 1
  const adjustedMonth = ((12 + momentAdjustedMonth - fiscalStartMonth) % 12) + 1
  const copy = moment(date)
  if (adjustedMonth % 3 === 1) {
    return copy.startOf('month')
  }
  const returnValue = dateToQuarterStart(copy, fiscalStartMonth).add(
    3,
    'months'
  )
  return returnValue
}

export const dateToPreviousQuarterEndMonth = (
  date: moment.Moment,
  fiscalStartMonth: number
) => {
  const momentAdjustedMonth = date.month() + 1
  const adjustedMonth = ((12 + momentAdjustedMonth - fiscalStartMonth) % 12) + 1
  const copy = moment(date)
  if (adjustedMonth % 3 === 0) {
    return copy.endOf('month')
  }
  const returnValue = dateToQuarterEnd(copy, fiscalStartMonth).subtract(
    3,
    'months'
  )
  return returnValue
}

export const dateToNextFiscalStartMonth = (
  date: moment.Moment,
  fiscalStartMonth: number
) => {
  const momentAdjustedMonth = date.month() + 1
  const adjustedMonth = ((12 + momentAdjustedMonth - fiscalStartMonth) % 12) + 1
  const copy = moment(date)
  if (adjustedMonth === 1) {
    return copy.startOf('month')
  }
  return dateToFiscalStart(copy, fiscalStartMonth).add(1, 'years')
}

export const dateToPreviousFiscalEndMonth = (
  date: moment.Moment,
  fiscalStartMonth: number
) => {
  const momentAdjustedMonth = date.month() + 1
  const adjustedMonth = ((12 + momentAdjustedMonth - fiscalStartMonth) % 12) + 1
  const copy = moment(date)
  if (adjustedMonth === 12) {
    return copy.endOf('month')
  }
  const fiscalEnd = dateToFiscalEnd(copy, fiscalStartMonth)
  const returnValue = fiscalEnd.subtract(1, 'years')
  return returnValue
}

export const dateToGroupKey = (
  grouping: string,
  date: string,
  fiscalStartMonth: number
) => {
  let key = ''
  if (grouping === GROUPING_MONTH) {
    key = moment(date).format(PRESENTATION_MONTH_FORMAT)
  }
  if (grouping === GROUPING_FISCAL) {
    key = dateToFiscal(moment(date), fiscalStartMonth)
  }
  if (grouping === GROUPING_QUARTER) {
    key = `Q${dateToQuarter(moment(date), fiscalStartMonth)}`
  }
  return key
}

const yearMonthToYear = (yearMonth: string) => {
  /*
    yearMonth is assumed to be of form
    YYYY-MM
  */
  return yearMonth.split('-')[0]
}

const timeLineFromDatesAndOrganization = (
  dates: { [key: string]: Array<string> },
  organizationData: Array<Organization>,
  activeOrganization: number | null,
  graphProperties?: GraphMapping['graph_properties'] | null,
  ytdEnd?: string
) => {
  /*
    dates-parameter is of the following forms:
    {
      'min_year - max_year': [
        'min_yearmonth',
        'max_yearmonth'
      ]
    }
    or:
    {
      'YTD': []
    }
  */

  const years = Object.keys(dates)
    //'YTD' is temporarily mapped to date in order to be able to sort
    .map(y => (y === 'YTD' ? moment().format('YYYY') : y))
    .sort((a: string, b: string) =>
      parseInt(yearMonthToYear(a), 10) <= parseInt(yearMonthToYear(b), 10)
        ? -1
        : 1
    )
    .map(y => (y === moment().format('YYYY') ? 'YTD' : y))

  // get start of the org's fiscal year:
  const org = findOrg(activeOrganization, organizationData)
  const fiscalStartMonth = parseInt(org?.start_of_fiscal_year || '1', 10)
  // adjust the end month (start-1) from 0 to 12 if necessary:
  const fiscalEndMonth = fiscalStartMonth - 1 === 0 ? 12 : fiscalStartMonth - 1
  // get date with correct month,
  // -1 to compensate for moments blasphemous convention to start months from zero
  const adjustedFiscalStartMonth = moment().set('month', fiscalStartMonth - 1)
  // subtract a year if the fiscal start is in the future
  const adjustedFiscalStart =
    adjustedFiscalStartMonth > moment()
      ? adjustedFiscalStartMonth.subtract(1, 'year').clone()
      : adjustedFiscalStartMonth

  if (years.length === 0 && graphProperties) {
    return {
      start:
        typeof graphProperties.past_months === 'string'
          ? moment(graphProperties.past_months).startOf('month')
          : moment()
              .subtract(graphProperties.past_months, 'month')
              .startOf('month'),
      stop:
        typeof graphProperties.future_months === 'string'
          ? moment(graphProperties.future_months).endOf('month')
          : moment()
              .add(graphProperties.future_months, 'month')
              .endOf('month')
    }
  }

  if (years.length === 0) {
    const startMonth = `${adjustedFiscalStart.format(
      'YYYY'
    )}-${fiscalStartMonth}`

    const endMonth = `${adjustedFiscalStart
      .add(fiscalStartMonth - 1 === 0 ? 0 : 1, 'year')
      .format('YYYY')}-${fiscalEndMonth}`
    return {
      start: moment(startMonth, MONTH_FORMAT, true).startOf('month'),
      stop: moment(endMonth, MONTH_FORMAT, true).endOf('month')
    }
  }

  // by now years.length > 0 as other cases have returned
  const firstYear = years[0]
  const lastYear = years[years.length - 1]

  if (
    years.length > 1 ||
    !dates[firstYear] ||
    (dates[firstYear] && dates[firstYear].length === 0)
  ) {
    const getYTDStartYear = () =>
      moment().month() + 1 > fiscalStartMonth
        ? moment()
            .year()
            .toString()
        : moment()
            .subtract(1, 'year')
            .year()
            .toString()

    const startYearString =
      firstYear === 'YTD' ? getYTDStartYear() : firstYear.split(' - ')[0]
    const endString = lastYear.split(' - ')[1]

    return {
      // -1 to compensate for moments outrageous convention to start months from zero
      start: moment(startYearString).set('month', fiscalStartMonth - 1),
      stop:
        lastYear === 'YTD'
          ? moment(ytdEnd || undefined).startOf('month')
          : moment(endString).set('month', fiscalEndMonth - 1)
    }
  }

  const firstYearDates = [...dates[firstYear]]
  const [startMonth] = firstYearDates.sort((a, b) =>
    moment(a, MONTH_FORMAT) <= moment(b, MONTH_FORMAT) ? -1 : 1
  )
  const endYearDates = [...dates[lastYear]]
  const [endMonth] = endYearDates
    .sort((a, b) =>
      moment(a, MONTH_FORMAT) <= moment(b, MONTH_FORMAT) ? -1 : 1
    )
    .reverse()
  return {
    start: moment(startMonth, [MONTH_FORMAT, 'YYYY-M'], true).startOf('month'),
    stop: moment(endMonth, [MONTH_FORMAT, 'YYYY-M'], true).endOf('month')
  }
}

export const isYTDSelected = (
  dates: Array<string>,
  organizationState: { data: Array<Organization>; activeOrganization: number }
) => {
  const { data, activeOrganization } = organizationState
  const org = findOrg(activeOrganization, data)
  if (org) {
    const startOfFiscalYear = parseInt(org?.start_of_fiscal_year || '1', 10)
    const fiscalStart = moment().set('month', startOfFiscalYear - 1)
    if (fiscalStart > moment()) {
      fiscalStart.subtract(1, 'year')
    }
    const startMonth = `${fiscalStart.format('YYYY')}-${startOfFiscalYear}`
    const endMonth = `${fiscalStart
      .add(startOfFiscalYear - 1 === 0 ? 0 : 1, 'year')
      .format('YYYY')}-${
      startOfFiscalYear - 1 === 0 ? 12 : startOfFiscalYear - 1
    }`
    const startString = `${moment(startMonth, MONTH_FORMAT).year()}-${moment(
      startMonth,
      MONTH_FORMAT
    ).month() + 1}`
    const endString = `${moment(endMonth, MONTH_FORMAT).year()}-${moment(
      endMonth,
      MONTH_FORMAT
    ).month() + 1}`
    return (
      dates.includes(
        moment(startString)
          .startOf('month')
          .format(ISO_FORMAT_DATE)
      ) &&
      dates.includes(
        moment(endString)
          .endOf('month')
          .format(ISO_FORMAT_DATE)
      )
    )
  }
  return false
}

export const isDataForMonthVerified = (
  name: string,
  rawData: RawData[],
  term: { key: string }
) => {
  // Get the date from the x-axis label of the graph.
  const isoFormatName = moment(name, PRESENTATION_MONTH_FORMAT)
    .startOf('month')
    .format(ISO_FORMAT_DATE)

  // Get the data for the given term and month. If all of the
  // data is verified, the month is considered as actual data and
  // not estimates.
  const filteredData = rawData.filter(
    r => r.key === term.key && r.start_date === isoFormatName
  )
  return filteredData.length && filteredData.every(r => r.is_verified)
}

export const isFutureDataVerified = (
  name: string,
  rawData: RawData[],
  term: { key: string }
) => {
  // To be used with when cumulative state is set on the UI.
  // Checks if there are verified data points in the future for
  // given term key. This is mostly needed if only one term is selected
  // and there are some missing data points.
  const isoFormatName = moment(name, PRESENTATION_MONTH_FORMAT)
    .startOf('month')
    .format(ISO_FORMAT_DATE)

  const verifiedData = rawData.filter(
      r => r.key === term.key && r.is_verified
    ).map(data => data.start_date)

  return verifiedData.some((element) => element > isoFormatName)
}

export const isDataForQuarterVerified = (
  name: string,
  rawData: RawData[],
  term: { key: string },
  startOfFiscal: number
) => {
  // Parse the quarter number and year from the label
  // in the graph's x-axis
  const [quarter, year] = name.split('/')
  const fiscalStartMoment = moment(`${year}-${startOfFiscal + 1}`, MONTH_FORMAT)
    .subtract(startOfFiscal === 0 ? 0 : 1, 'year')
    .startOf('month')
  const quarterNumber = parseInt(quarter.replace('Q', ''), 10)

  // Generate moment objects from the start and end of the current
  // quarter.
  const quarterStart = moment(fiscalStartMoment).add(
    (quarterNumber - 1) * 3,
    'months'
  )
  const quarterEnd = moment(fiscalStartMoment)
    .add(quarterNumber * 3, 'months')
    .subtract(1, 'day')

  // Get the data for the given term and quarter. If all of the
  // data is verified, the quarter is considered as actual data and
  // not estimates.
  const filteredData = rawData.filter(
    r =>
      r.key === term.key &&
      quarterStart <= moment(r.start_date) &&
      moment(r.end_date) <= quarterEnd
  )
  return filteredData.length && filteredData.every(r => r.is_verified)
}

export const isDataForFiscalVerified = (
  name: string,
  rawData: RawData[],
  term: { key: string },
  startOfFiscal: number
) => {
  // Get fiscal's start and end from the x-axis label of the graph
  // and generate moment objects from them.
  const fiscalStartMoment = dateToNextFiscalStartMonth(
    moment(name, 'YYYY').startOf('year'),
    startOfFiscal + 1
  ).subtract(startOfFiscal === 0 ? 0 : 1, 'year')
  const fiscalEndMoment = moment(fiscalStartMoment)
    .add(1, 'year')
    .subtract(1, 'day')

  // Get the data for the given term and fiscal. If all of the
  // data is verified, the fiscal is considered as actual data and
  // not estimates.
  const filteredData = rawData.filter(
    r =>
      r.key === term.key &&
      fiscalStartMoment <= moment(r.start_date) &&
      moment(r.end_date) <= fiscalEndMoment
  )
  return filteredData.length && filteredData.every(r => r.is_verified)
}

export default timeLineFromDatesAndOrganization

import styled, { DefaultTheme } from 'styled-components/macro'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { isGraphPage } from 'util/url'
import { connect } from 'react-redux'
import { RootState } from 'store/reducers'
import { GraphMapping } from 'store/reducers/graph'

type ReduxProps = Partial<ReturnType<typeof mapStateToProps>>

interface TitleProps {
  isStrategy?: boolean
  theme?: DefaultTheme
}

const Title = styled.h1`
  font-family: 'Open Sans';
  font-weight: 100;
  color: ${(props: TitleProps & RouteComponentProps & ReduxProps) =>
    isGraphPage(props.location, props.mappings as GraphMapping[]) &&
    !props.graphStyleOverride
      ? (props.theme as DefaultTheme).graphPage.titleColor
      : (props.theme as DefaultTheme).text.title};
  font-size: 1.5rem;
  letter-spacing: 0;
  line-height: 1.813rem;
  ${props => (props.isStrategy ? 'padding: 0 1.875rem;' : '')}
  word-break: break-word;
`

const mapStateToProps = ({
  graph: { settings, graphStyleOverride }
}: RootState) => ({
  mappings: settings.mappings,
  graphStyleOverride
})

export default withRouter(connect(mapStateToProps, null)(Title))

import { Delete, Get, Patch, Post } from 'api/index'
import { createAsyncAction } from 'redux-promise-middleware-actions'
import { RootState } from 'store/reducers'
import { MainTaskType } from 'store/reducers/maintask'

export const GET_MAINTASK = 'GET_MAINTASK'
export const LIST_MAINTASK = 'LIST_MAINTASK'
export const CREATE_MAINTASK = 'CREATE_MAINTASK'
export const UPDATE_MAINTASK = 'UPDATE_MAINTASK'
export const DELETE_MAINTASK_ROW = 'DELETE_MAINTASK_ROW'
export const DELETE_MAINTASK = 'DELETE_MAINTASK'

export const getMaintask = createAsyncAction(
  GET_MAINTASK,
  async (id: number) => {
    const url = `/api/maintask/${id}/`
    const data = await Get<MainTaskType>(url)
    return data
  }
)

export const listMaintask = createAsyncAction(
  LIST_MAINTASK,
  async (roadmapId: number) => {
    const url = `/api/maintask/?roadmapId=${roadmapId}`
    const data = await Get<RootState['maintask']['list']>(url)
    return data
  }
)

export const createMaintask = createAsyncAction(
  CREATE_MAINTASK,
  async (body: string) => {
    const url = `/api/maintask/`
    const data = await Post<RootState['maintask']['created']>(url, body)
    return data
  }
)

export const updateMaintask = createAsyncAction(UPDATE_MAINTASK, async (body: string, id: number) => {
  const url = `/api/maintask/${id}/`
  const data = await Patch(url, body)
  return data
})

export const deleteMaintaskRow = createAsyncAction(DELETE_MAINTASK_ROW, async (id: number | string, rowIndex: number) => {
  const url = `/api/maintask/${id}/delete_row/?rowIndex=${rowIndex}`
  const data = await Post(url)
  return data
})

export const deleteMaintask = createAsyncAction(DELETE_MAINTASK, async (id: string | number) => {
  const url = `/api/maintask/${id}/`
  const data = await Delete(url)
  return data
})
